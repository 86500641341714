<template>
  <Label v-if="category" :label="label" :color="color" :small="small" :mini="mini" />
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    collection: {
      type: String,
      required: true,
    },
    resourceId: {
      type: Number,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    category(): any {
      return this.$db.shopData[this.collection].find(o => o.id == this.resourceId)
    },
    label(): string {
      return this.category.name
    },
    color(): string {
      return this.category.color
    },
  },
})
</script>
