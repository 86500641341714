<template>
  <el-radio-group
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :size="size"
    :disabled="disabled"
  >
    <slot>
      <nice-radio-button
        v-for="item in filteredOptions"
        :key="item[idKey]"
        :label="item[idKey]"
        :disabled="disabledButtons"
      >
        <fa-icon v-if="item.icon" :name="item.icon" />
        <span v-else-if="translateFor">{{ $t(`${translateFor}.${item[labelKey]}`) }}</span>
        <span v-else>{{ item[labelKey] }}</span>
      </nice-radio-button>
    </slot>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    value: {},
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledButtons: { type: Boolean, default: false },
    size: { type: String, default: "small" },
    translateFor: { type: String, default: "" },
    labelKey: { type: String, default: "name" },
    idKey: { type: String, default: "id" },
    options: {},
  },
  computed: {
    filteredOptions() {
      if (!this.options) return
      if (!Array.isArray(this.options)) {
        return _.map(this.options, (label, key) => ({ id: key, [this.labelKey]: label }))
      }

      return this.options
    },
  },
}
</script>
