<template>
  <div class="pageHeader">
    <div class="d-flex align-items-center">
      <span class="pageHeader-title">
        <slot name="title">{{ title }}</slot>
      </span>
      <ul v-if="helpUrl" class="details-header-actions left">
        <li class="details-action">
          <a :href="helpUrl" target="_blank">
            <fa-icon name="question-circle" />
          </a>
        </li>
      </ul>
      <slot name="leftMenu" />
    </div>
    <div class="d-flex align-items-center">
      <slot name="rightButtons" />

      <dropdown v-if="$slots.headerMoreDropdown">
        <slot name="headerMoreDropdown" />
      </dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    helpUrl: {
      type: String,
    },
  },
}
</script>

<style>
.pageHeader {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#quicktask .pageHeader {
  margin-left: 20px;
}

.pageHeader-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding: 0.5rem 0;
}
.pageHeader-subTitle {
  color: #666;
  font-size: 12px;
}
.pageHeader-subTitle a {
  color: #666;
}

.pane-button {
  color: #0f55eb;
  display: block;
  font-size: 16px;
  position: relative;
  padding: 0.5rem 0;
}
.pane-button i {
  font-size: 1.5rem;
  vertical-align: bottom;
}
.pane-button:disabled {
  opacity: 0.7;
}
</style>
