<template>
  <el-radio
    :border="border"
    :value="value"
    @input="$emit('input', $event || null)"
    @change="$emit('input', $event)"
    :label="label"
  >
    <slot></slot>
  </el-radio>
</template>

<script>
export default {
  props: {
    value: {},
    label: [String, Number],
    disabled: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
  },
}
</script>

<style>
fieldset .el-radio {
  white-space: normal;
  display: inline-flex;
  font-weight: normal !important;
  word-break: normal;
}
fieldset .el-radio {
  margin-top: 3px;
}
</style>