<template>
  <Label :color="allCompleted ? '#13ce66' : '#777777'" small>
    <fa-icon name="check-square" class="mr-1" />
    <span>{{ completedTasks }}/{{ allTasks }}</span>
  </Label>
</template>

<script>
export default {
  props: {
    subTasks: {
      type: Array,
      required: true,
    },
    resourceId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    completedTasks() {
      return this.subTasks.filter(t => t.done || t.done_at).length
    },
    allTasks() {
      return this.subTasks.length
    },
    allCompleted() {
      return this.completedTasks === this.allTasks
    },
  },

  mounted() {
    this.$pusher.on(`todo:${this.resourceId}:updated`, todo => {
      this.$emit("update:subTasks", this.subTasks.filter(t => t.title != todo.title).concat([todo]))
    })
  },
}
</script>