<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({ setup() {} })
</script>
<script setup lang="ts">
import { useIntegrations } from "@/integrations/use-integrations"
import useI18n from "@/plugins/use-i18n"
import { useIntegration } from "@/integrations/use-integration"
import { computed, ComputedGetter, onMounted, onUnmounted, Ref } from "vue"
import { Integration, IntegrationId } from "@/integrations/types/integration-service"
import { useIntegrationAPI } from "@/integrations/use-integration-api"
import { get, set, toRef, toRefs } from "@vueuse/core"
import { UseConfigurationReponse } from "@/integrations/types/integration-page"
import { Component } from "vue/types/umd"
import ConfigurationConsent from "@/views/admin/components/configurations/ConfigurationConsent.vue"
import ConfigurationActions from "@/views/admin/components/configurations/ConfigurationActions.vue"
import ConfigurationDetailsTable from "@/views/admin/components/configurations/ConfigurationDetailsTable.vue"
import { useDelayedState, useState } from "@/utils/composables"
import { useNotifications } from "@/integrations/use-notifications"

const props = defineProps<{
  integration: Integration
  showDeactivateButton?: boolean
}>()
const emit = defineEmits(["enable", "disable"])
const { t } = useI18n()
const { integration, showDeactivateButton } = toRefs(props)
const configuration = computed(() => get(integration)?.configuration)
const {
  isUpdating,
  onEnable,
  onDisable,
  isValidConfiguration,
  requiresValidConfiguration,
  consentRequired,
  lastConsent,
  pendingChanges,
  hasPendingChanges,
  onChange,
  onSave,
  allowSave,
  isEnabled,
  mapping,
  isReady,
} = useIntegrationAPI(integration)

const ConfigurationComponent = computed(() => get(mapping)?.ConfigurationComponent)

const [consentGiven, setConsentGiven] = useDelayedState(() => {
  const _integration = get(integration)
  if (!_integration) return
  return !!_integration.consent || get(isEnabled)
})
const [configurationResponse, setConfigurationResponse] = useState<UseConfigurationReponse>()

const { openSuccess } = useNotifications()

const handleDisable = async () => {
  console.log("handleDisable")
  setConfigurationResponse(undefined)
  // if (!isAdmin) {
  //   return
  // }
  emit("disable")
  const response = await onDisable()
  setConsentGiven(false) // reset consent in UI when disabling
  setConfigurationResponse(response)

  openSuccess(t("integrations.messages.disabled", { name: get(mapping).name }))
}

const handleDeactivate = async () => {
  console.log("handleDeactivate")
  emit("disable")

  const response = await onSave({ widgetHidden: true })
  setConfigurationResponse(response)
}

const handleEnable = async () => {
  console.log("handleEnable")
  setConfigurationResponse(undefined)
  // if (!isAdmin) {
  //   return
  // }
  // when pending changes are present, save them first
  if (get(hasPendingChanges)) {
    const saveResponse = await onSave?.()
    if (saveResponse?.status === "error") {
      setConfigurationResponse(saveResponse)
      return
    }
  }

  emit("enable")
  set(pendingChanges, { widgetHidden: false })
  const response = await onEnable()
  setConfigurationResponse(response)

  openSuccess(t("integrations.messages.enabled", { name: get(mapping).name }))
}

let timer: NodeJS.Timeout | undefined = undefined
onMounted(() => {
  timer = setTimeout(() => {
    setConfigurationResponse(undefined)
  }, 3000)
})
onUnmounted(() => clearTimeout(timer))
</script>
<template>
  <settings-container :description="mapping.summary" v-if="isReady" :header-fixed="false">
    <template #title>
      <div class="flex flex-col">
        <div class="mt-3 mb-2">
          <slot name="logo">
            <img v-if="mapping.imageUrl" :src="mapping.imageUrl" :alt="mapping.name" class="max-h-10 max-w-36" />
          </slot>
        </div>
      </div>
    </template>
    <template #description>
      <div class="max-w-3xl">
        <article
          v-if="mapping.description"
          class="mt-2 text-gray-700 section-article msg-body"
          v-html="mapping.description"
        />
        <a v-if="mapping.faqLink" :href="mapping.faqLink">{{ $(`integrations.actions.faqLink`) }}</a>
      </div>
    </template>
    <div class="max-w-3xl">
      <component
        :is="ConfigurationComponent"
        :is-updating="isUpdating"
        :pending-changes="pendingChanges"
        @save="onSave"
        @change="onChange"
        v-if="ConfigurationComponent"
        class="mt-3"
      />
      <configuration-details-table :integration="integration" :mapping="mapping" class="mt-3" />
      <configuration-consent
        :disabled="isUpdating || (configuration.enabled && consentGiven)"
        :lastConsent="lastConsent"
        :consent-text="mapping.consentText"
        :consent-given="consentGiven"
        @consent-change="setConsentGiven"
        v-if="consentRequired"
        class="mt-3"
      />
      <configuration-actions
        :mapping="mapping"
        :disabled="isUpdating"
        :allow-enable="requiresValidConfiguration ? isValidConfiguration : true"
        :enabled="configuration.enabled"
        :consent-given="consentGiven"
        :consent-required="consentRequired"
        @disable="handleDisable"
        @enable="handleEnable"
        @deactivate="handleDeactivate"
        class="mt-3"
        :show-deactivate-button="!isEnabled && showDeactivateButton"
      />
    </div>
  </settings-container>
</template>
