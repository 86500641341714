<template>
  <div>
    <div class="mb-1">
      <div class="flex items-center">
        <label><fa-icon name="clock" /></label>
        <date-picker
          v-model="resource.startsAt"
          :type="resource.allDay ? 'date' : 'datetime'"
          :format="eventFormat"
          class="w-100 date-picker-select cursor-pointer"
          :placeholder="$t('panes.taskForm.startsAtPh')"
          size="small"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="updateDate('startsAt')"
        />
        <span v-if="resource.endsAt">-</span>
        <date-picker
          v-model="resource.endsAt"
          :type="resource.allDay ? 'date' : 'datetime'"
          :format="eventFormat"
          class="w-100 date-picker-select cursor-pointer"
          :placeholder="$t('panes.taskForm.startsAtPh')"
          size="small"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="updateDate('endsAt')"
        />
      </div>
      <span class="text-gray-500 text-sm block ml-4" v-if="resource.recurring">
        <fa-icon name="repeat" class="mr-1" />
        {{ $t("task.eventDetails.series") }}
      </span>
      <span class="text-gray-500 text-sm block ml-4" v-if="resource.bufferBefore || resource.bufferAfter">
        {{ $t("task.eventDetails.withBuffer", { time: bufferFormatted }) }}
      </span>
    </div>

    <div class="mb-1" v-if="resource.private">
      <label class="mr-1 text-success"><fa-icon name="lock" /></label>
      <span>{{ $t("task.eventDetails.private") }}</span>
    </div>

    <div class="mb-1" v-if="resource.location">
      <label><fa-icon name="map-marker-alt" class="mr-2" /></label>
      <span>{{ resource.location }}</span>
    </div>

    <div class="mb-1" v-if="roomResources.length > 0">
      <label class="mr-1">{{ $t("task.eventDetails.resources") }}</label>
      <span>{{ roomResources.map(o => o.name).join(", ") }}</span>
    </div>

    <div class="mb-1" v-if="resource.onlineMeetingUrl">
      <label class="mr-1 semi-bold">{{ $t(`task.eventDetails.${this.onlineMeetingLabel}`) }}</label>
      <div class="mt-1">
        <a :href="resource.onlineMeetingUrl" target="_blank" class="btn btn-primary btn-xs">
          <fa-icon name="external-link" />
          {{ $t("task.eventDetails.join") }}
        </a>
      </div>
    </div>

    <slot />

    <participants :resource="resource" class="py-3" />
    <suggested-events
      :resource="resource"
      class="py-3"
      v-if="resource.canAccess && resource.suggestedEvent && resource.state == 'neutral'"
    />

    <section class="py-2" v-if="resource.canAccess && resource.rateable">
      <p class="mb-2">
        <strong>{{ $t("task.eventDetails.rate") }}</strong>
      </p>
      <div class="btn-group btn-group-toggle">
        <label class="btn btn-sm" :class="{ 'btn-success active': resource.state == 'took_place' }">
          <input
            type="radio"
            name="task_state"
            id="task_state_took_place"
            value="took_place"
            class="hidden"
            @input="setState('took_place')"
          />
          {{ $t("task.eventDetails.tookPlace") }}
        </label>
        <label class="btn btn-sm" :class="{ 'btn-danger active': resource.state == 'cancelled' }">
          <input
            type="radio"
            name="task_state"
            id="task_state_cancelled"
            value="cancelled"
            class="hidden"
            @input="setState('cancelled')"
          />
          {{ $t("task.eventDetails.cancelled") }}
        </label>
      </div>
    </section>

    <!-- <% if @task.event_invite_id.present? && @task.broker == current_broker %>
      <div id="rsvp-<%= @task.event_invite_id %>" class="mt-3">
        <% t("task.rsvp_status").each do |key, value| %>
          <%= link_to value, rsvp_services_task_path(@task.id, external_event_id: @task.event_invite_id, status: key), data: { rsvp_status: key, remote: true, method: :post, disable_with: "#{value}..." }, class: "btn btn-#{t(key, scope: 'task.rsvp_status_colors') if @task.rsvp_status == key.to_s}", style: "min-width: 90px" %>
        <% end %>
      </div>
    <% end %> -->

    <div class="py-3" v-if="resource.massViewingUrl">
      <p class="mb-1">
        <strong>{{ $t("task.eventDetails.link") }}</strong>
      </p>
      <div class="d-flex">
        <nice-input size="small" :value="translatedUrl" class="w-100" />
        <nice-button type="primary" size="small" class="btn ml-1" @click="copyUrl">{{ $t("task.copy") }}</nice-button>
      </div>
    </div>

    <viewings v-if="resource.massViewingUrl" :resource="resource" class="mb-3" />
  </div>
</template>

<script>
import Participants from "./Participants"
import SuggestedEvents from "./SuggestedEvents"
import Viewings from "./Viewings"
import eventBus from "@/config/event-bus"

export default {
  props: {
    resource: {},
    date: {
      type: Date,
    },
  },
  data() {
    return {
      startsAt: this.resource?.startsAt,
      endsAt: this.resource?.endsAt,
    }
  },
  components: { Participants, SuggestedEvents, Viewings },
  computed: {
    roomResources() {
      if (!this.resource.roomResourceEmails) return []
      return this.$db.shopData.roomResources.filter(rr => this.resource.roomResourceEmails.includes(rr.email))
    },
    bufferFormatted() {
      const { startsAt, endsAt, bufferBefore, bufferAfter } = this.resource
      const { time } = this.$customFilters

      if (!bufferBefore && !bufferAfter) return

      return [
        `${time(moment(startsAt).subtract(bufferBefore, "minutes"))}`,
        `${time(moment(endsAt).add(bufferAfter, "minutes"))}`,
      ].join(" – ")
    },
    eventFormat() {
      return this.resource.allDay ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm"
    },
    onlineMeetingLabel() {
      if (!this.resource.onlineMeetingUrl) return
      if (this.resource.onlineMeetingUrl.includes("google.com")) return "google"
      return "microsoft"
    },
    translatedUrl() {
      return (
        this.resource.massViewingUrl +
        (["en", "hu", "es"].includes(this.$db.broker.locale) ? `?locale=${this.$db.broker.locale}` : "")
      )
    },
  },
  methods: {
    updateDate(type) {
      const payload = {
        [type]: this.resource.allDay ? moment(this.resource[type]).set({ h: 0, m: 0 }).toDate() : this.resource[type],
      }
      this.$api
        .mutation("updateTask", { payload, id: this.resource.id }, "task { id }")
        .then(() => {
          App.flashy(this.$t("general.changeSaved"))
        })
        .catch(this.catchError)
    },
    catchError(e) {
      this.resource.startsAt = this.startsAt
      this.resource.endsAt = this.endsAt
      return this.$axios.handleError(e)
    },
    setState(state) {
      state = this.resource.state === state ? "neutral" : state
      this.resource.state = state
      this.$api
        .mutation("updateTaskState", { taskId: this.resource.id, state, date: this.date }, "followupResourceType")
        .then(({ followupResourceType }) => {
          if (state === "neutral") return
          // console.log("done", followupResourceType)
          if (followupResourceType) {
            eventBus.$emit("quick-view", {
              type: followupResourceType,
              mode: "edit",
              params:
                followupResourceType == "message"
                  ? { source: { taskId: this.resource.id } }
                  : { followupTaskId: this.resource.id },
            })
          } else {
            const title =
              state === "took_place"
                ? this.$t("task.eventDetails.tookPlaceLong")
                : this.$t("task.eventDetails.cancelledLong")
            eventBus.$emit("quick-view", {
              type: "note",
              mode: "edit",
              params: { followupTaskId: this.resource.id, source: { title } },
            })
          }
        })
        .catch(this.$axios.handleError)
    },
    copyUrl() {
      this.$util.copy(this.translatedUrl)
      App.flashy(this.$t("task.eventDetails.copySuccess"))
    },
  },
}
</script>

<style>
.date-picker-select {
  width: 120px !important;
}
.date-picker-select > .el-input__inner {
  padding: 0 !important;
  text-align: center !important;
  border: none !important;
}

.date-picker-select:hover > .el-input__inner {
  text-decoration: underline;
}

.date-picker-select > .el-input__suffix,
.date-picker-select > .el-input__prefix {
  display: none;
}
</style>
