<template>
  <el-popover
    ref="popover"
    placement="bottom"
    :visible-arrow="false"
    trigger="click"
    :width="width"
    transition="none"
    v-model="visible"
    @show="$emit('open')"
    :append-to-body="appendToBody"
  >
    <slot slot="reference" name="reference">
      <a href="#" @click.prevent :class="buttonClass">
        <slot name="title">
          <fa-icon name="ellipsis-h" />
        </slot>
      </a>
    </slot>
    <main @click="visible = false">
      <slot />
    </main>
  </el-popover>
</template>

<script>
export default {
  props: {
    buttonClass: {
      type: String,
      default: "tableActions-action",
    },
    width: {
      type: String,
      default: "240",
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
}
</script>

<style scoped>
main {
  max-height: 80vh;
  overflow: auto;
}
</style>
