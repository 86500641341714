<template>
  <settings-section>
    <template #description>
      <slot name="description" />
    </template>
    <slot name="header" :items="value" />
    <alert v-if="value && !value.length && (emptyState || $slots['empty-state'])" class="mb-3">
      <slot name="empty-state">{{ emptyState }}</slot>
    </alert>

    <component 
      :is="disableDragging ? 'div' : 'draggable'" 
      :list="value" 
      :group="{ name: 'g1' }" 
      @change="$emit('update:value', value)" >
      <nestable-list-item
        v-for="item in value"
        :key="`${item.isFolder ? 'fldr': 'id'}${item.id}`"
        class="mb-2"
        :add-func="addFunc"
        :edit-func="editFunc"
        :delete-func="deleteFunc"
        :show-actions="showActions(item)"
        :children="item.children"
        :sortable="sortable"
        :item="item"
        :initial-show-nested-items="initialShowNestedItems"
        :search="search"
        :useComponent="useComponent"
        :disableDragging="disableDragging"
        @update:value="$emit('update:value', value)"
        @multiplex="payload => $emit('multiplex', payload)"
      >
        <template v-slot:icon>
          <slot name="icon" :item="item" />
        </template>
        <template v-slot:title>
          <slot name="name" :item="item">
            {{ item.name }}
          </slot>
        </template>
        <template v-slot:actions>
          <slot name="actions" :item="item" />
        </template>
      </nestable-list-item>
    </component>

    <slot name="add">
      <ps-button
        class="mt-3 max-w-none"
        active
        @click="$emit('new')"
        icon="plus"
        :title="$t('settings.create', { label: label })"
      />
    </slot>
  </settings-section>
</template>

<script>
import i18n from "@/i18n/index"
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    sortable: {
      type: Boolean,
      default: false
    },
    emptyState: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default: () => i18n.t("settings.entry")
    },
    showActions: {
      type: Function,
      default: _ => true
    },
    addFunc: {
      type: Function,
      required: false
    },
    editFunc: {
      type: Function,
      required: false
    },
    deleteFunc: {
      type: Function,
      required: false
    },
    size: {
      type: Number,
      required: false
    },
    search: {
      type: Number
    },
    useComponent: {
      type: String
    },
    disableDragging: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initialShowNestedItems() {
      return !this.size || this.size <= 200
    }
  }
}
</script>
