<template>
  <el-radio-button :label="label" :disabled="disabled">
    <slot></slot>
  </el-radio-button>
</template>

<script>
export default {
  props: {
    label: {},
    disabled: { type: Boolean, default: false },
  },
}
</script>

<style>
/* Bugfix: global label style has margin-bottom */
.el-radio-button {
  margin: 0;
}

/* Make Radio Buttons narrower */
.el-radio-button--small .el-radio-button__inner {
  padding: 7px 9px;
}
.el-radio-button__inner {
  padding: 10px 15px;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: rgba(0, 0, 0, 0.07);
  color: black;
  box-shadow: none;
}

/* fix: remove ugly outline on safari when radio button is clicked */
.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: none;
}
</style>