<template>
  <el-select
    size="small"
    filterable
    v-cancel-read-only
    :multiple="multiple"
    clearable
    default-first-option
    :value="value"
    :allow-create="allowCreate"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    v-bind="$attrs"
  >
    <el-option v-for="item in options" :key="item" :label="item" :value="item" />
  </el-select>
</template>

<script>
export default {
  props: {
    value: {},
    multiple: { type: Boolean, default: true },
    allowCreate: { type: Boolean, default: false },
    placeholder: { type: String, default: "Keine Angabe" },
    size: { type: String, default: "small" },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
}
</script>
