<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
  setup() {},
})
</script>
<script setup lang="ts">
import { toRefs } from "@vueuse/core"

const props = defineProps<{
  image: any
  locale: string
  value: boolean
}>()

const emit = defineEmits(["selected"])
const { image, locale, value } = toRefs(props)
</script>
<template>
  <div
    :data-id="image.id"
    class="property-image"
    :class="{
      'is-floorplan': image.is_floorplan,
      'is-private': image.is_private,
    }"
  >
    <div class="image-bg" :style="{ 'background-image': `url('${image.photo_url_thumb}')` }"></div>
    <p class="image-title">{{ image[`title_${locale}`] }}</p>
    <span tabindex="-1" class="action-trigger edit-trigger">
      <nice-checkbox :value="value" @input="value => emit('selected', value)" size="medium" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.property-image {
  width: 140px;
  height: 140px;
  margin: 1rem 1rem 0 0;
  float: left;
  outline: 1px solid #dedede;
  border-radius: 3px;
  position: relative;

  &.is-selected {
    outline: 3px solid rgb(169, 206, 255);
  }
}

.property-image .image-bg {
  width: 100%;
  height: calc(100% - 36px);
  background-size: cover;
  background-position: center;
  position: relative;
}

.property-image .action-trigger {
  position: absolute;
  top: 10px;
  width: 25px;
  height: 25px;
  background: #fff;
  outline: 1px solid #dedede;
  padding-top: 2px;
  border-radius: 2px;
  color: white;
  text-align: center;
  display: none;
  display: block;
}

.property-image .delete-trigger {
  right: 10px;
}

.property-image .edit-trigger {
  left: 10px;
}

.property-image .fullscreen-trigger {
  top: initial;
  right: 10px;
  bottom: 46px;
}

.property-image .watermark-trigger {
  left: 55px;
}

.property-image .image-indicators {
  position: absolute;
  left: 10px;
  bottom: 46px;
  line-height: 30px;
  color: white;
}

.property-image .image-floorplan-indicator {
  background: rgba(0, 0, 0, 0.7);
  margin-right: 10px;
  padding: 0 6px;
  display: none;
}

.property-image .image-private-indicator {
  background: rgba(0, 0, 0, 0.7);
  padding: 0 6px;
  display: none;
}

.property-image .image-title {
  width: 100%;
  height: 36px;
  padding: 8px;
  border: none;
  overflow: hidden;
  white-space: nowrap;
}

.property-image.is-floorplan .image-floorplan-indicator,
.property-image.is-private .image-private-indicator {
  display: inline-block;
}
</style>
