<template>
  <nice-input
    type="textarea"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :autosize="autosize"
    size="small"
    v-bind="$attrs"
    @keydown.native.enter="$attrs.resize == 'none' ? $event.preventDefault() : undefined"
  />
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: String,
    },
    minRows: {
      type: Number,
      default: 3,
    },
    maxRows: {
      type: Number,
      default: 60,
    },
  },
  computed: {
    autosize() {
      return { minRows: this.minRows, maxRows: this.maxRows }
    },
  },
})
</script>
<style>
.el-textarea__inner {
  text-overflow: ellipsis;
  word-break: break-word;
}
</style>
