<template>
  <div class="app-panel">
    <page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
      </template>
      <template v-slot:rightButtons>
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="submit"
          class="ml-2 pane-button"
          :class="{ disabled: submitting }"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          SMS senden
        </a>
      </template>
    </page-header>

    <div class="app-panel-body">
      <form id="letter-form" @submit.prevent="submit">
        <form-row title="Absender">
          <db-select v-model="params.brokerId" collection="unarchivedBrokers" />
        </form-row>

        <form-row title="Empfänger">
          <p class="ml-1">
            <span>{{ client.name }}</span>
            <span class="text-gray-500" v-if="smsNumber">({{ smsNumber }})</span>
            <span v-else class="text-sm text-red-600">(keine Mobilnummer!)</span>
          </p>
        </form-row>

        <form-row title="Objekt hinzufügen">
          <property-select v-model="params.propertyIds" multiple />
        </form-row>

        <form-row title="Projekte hinzufügen">
          <project-select v-model="params.projectIds" multiple />
        </form-row>

        <form-row direction="column" title="Inhalt der SMS">
          <div>
            <div class="relative">
              <nice-textarea v-model="params.body" />
              <character-counter :count="params.body.length" :total="160" style="bottom: 0.5rem; top: initial" />
            </div>
            <p class="footnote p-2">
              Bei mehr als 160 Zeichen werden weitere SMS-Nachrichten verschickt, die jeweils einzeln kosten. Variablen
              sind hier NICHT verfügbar.
            </p>
          </div>
        </form-row>
      </form>
    </div>
  </div>
</template>

<script>
import CharacterCounter from "@/components/form/CharacterCounter.vue"
export default {
  props: ["client"],

  components: { CharacterCounter },

  data() {
    return {
      submitting: false,
      params: {
        brokerId: this.$db.broker.id,
        body: "",
        propertyIds: [],
        projectIds: [],
      },
    }
  },
  methods: {
    submit() {
      this.submitting = true

      this.$api
        .mutation("createTextMessage", this.payload)
        .then(_ => {
          App.flashy("SMS wurde angelegt und wird im Hintergrund verschickt.")
          this.$emit("close")
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.submitting = false
        })
    },
    close() {
      this.$emit("close")
    },
  },
  computed: {
    payload() {
      return {
        payload: {
          ...this.params,
          clientIds: [this.client.id],
        },
      }
    },
    smsNumber() {
      return (
        this.client.homeCell ||
        this.client.clientAddresses.filter(p => !p.label || p.label.match(/mobil/i)).map(p => p.value)[0]
      )
    },
  },
}
</script>