<template>
  <section :class="{ branch: isChild }" :id="`item-${item.id}`">
    <main class="w-100 flex">
      <div :class="{ ['end-branch']: isChild }"></div>
      
      <div class="relative rounded border shadow-sm w-100" :class="isDummy ? 'bg-location-dummy' : 'bg-white'">
        <component 
          v-if="useComponent" 
          @collapse="toggleCollapse" 
          :class="isDummy ? 'bg-location-dummy' : 'bg-white'" 
          :is="useComponent" 
          :item="item"
          :showActions="showActions"
          :children="children"
          :search="search"
          :showNestedItems="showNestedItems"
          :sortable="sortable"
          @edit="editFunc(item)"
          @delete="deleteFunc(item)"
          @add="addFunc(item)"
          @multiplex="payload => $emit('multiplex', payload)"
        ></component>
        <template v-else>
          <div
            @click="toggleCollapse"
            class="px-3 py-2 text-dark standard-hover d-flex align-items-center"
            :class="{ pointer: showActions && ((children && children.length) || sortable) }"
          >
            <span v-if="$slots.icon" class="mr-3 text-gray-600 text-xl">
              <nestable-list-icon
                :children="children"
                :search="search"
                :showNestedItems="showNestedItems"
                :item="item"
                :sortable="sortable"
              >
                <template v-slot:icon>
                  <slot name="icon"/>
                </template>
              </nestable-list-icon>
            </span>
            <span class="txt-overflow w-full">
              <slot name="title">
                <span class="block font-medium">{{ title }}</span>
              </slot>
              <span class="block text-sm text-muted">
                <slot name="subtitle" />
              </span>
            </span>
          </div>

          <slot v-if="!useComponent" name="actions">
            <hover-actions>
              <hover-action v-if="showActions" @click="addFunc(item)" icon="plus" />
              <hover-action v-if="showActions" @click="editFunc(item)" icon="pencil" />
              <hover-action v-if="showActions" @click="deleteFunc(item)" icon="trash-alt" class="text-danger" />
            </hover-actions>
          </slot>
        </template>
      </div>
    </main>
    <div
      v-if="((search && item.isMatch) || (showNestedItems && !search)) && ((children && children.length) || sortable)"
      class="mt-2"
      :class="{ 'ml-3': isChild }"
    >
      <component
        :is="disableDragging ? 'div' : 'draggable'"
        :list="children || []"
        :group="{ name: `g1` }"
        @change="$emit('update:value', children)"
        class="draggable-container"
      >
        <nestable-list-item
          v-for="nestedItem in children || []"
          :key="`${nestedItem.isFolder ? 'fldr': 'id'}${nestedItem.id}`"
          :add-func="addFunc"
          :edit-func="editFunc"
          :delete-func="deleteFunc"
          :show-actions="true"
          :children="nestedItem.children"
          :item="nestedItem"
          :sortable="sortable"
          is-child
          :initial-show-nested-items="initialShowNestedItems"
          :search="search"
          :useComponent="useComponent"
          :disableDragging="disableDragging"
          @multiplex="payload => $emit('multiplex', payload)"
        >
          <template v-slot:icon>
            <slot name="icon" :item="nestedItem" />
          </template>
          <template v-slot:title>
            <slot name="name" :item="nestedItem">
              {{ nestedItem.name }}
            </slot>
          </template>
          <template v-slot:actions>
            <slot name="actions" :item="nestedItem" />
          </template>
        </nestable-list-item>
      </component>
    </div>
  </section>
</template>

<script>
import NestableListIcon from "./NestableListIcon.vue";

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    children: {
      type: Array,
      required: false,
    },
    addFunc: {
      type: Function,
      required: false,
    },
    editFunc: {
      type: Function,
      required: false,
    },
    deleteFunc: {
      type: Function,
      required: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
    isDummy: {
      type: Boolean,
      default: false,
    },
    initialShowNestedItems: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Number,
    },
    useComponent: {
      type: String
    },
    disableDragging: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showNestedItems: this.initialShowNestedItems,
    }
  },
  methods: {
    toggleCollapse() {
      this.showNestedItems = !this.showNestedItems
    },
  },
  components: {
    NestableListIcon
  }
}
</script>

<style scoped>
.end-branch {
  border-left: 2px solid rgb(201, 201, 201);
  border-bottom: 2px solid rgb(201, 201, 201);
  padding-left: 1rem;
  border-bottom-left-radius: 4px;
  height: 27px;
  margin-left: -2px;
  margin-top: -7px;
}

.branch {
  border-left: 2px solid rgb(201, 201, 201);
  margin-left: 1.25rem;
  padding-bottom: 0.5rem;
}
.sorting--active .branch {
  padding-bottom: 0;
}
.branch:last-child {
  border-left: none;
  border-bottom: none;
  margin-left: 1.4rem;
  padding-bottom: 0;
}
.bg-location-dummy {
  opacity: 0.4;
  background: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 4px,
    rgb(229 231 235) 4px,
    rgb(229 231 235) 7px
  );
}
.draggable-container {
  /* border: 2px solid red; */
  padding-bottom: 0.5rem;
}
</style>
