<template>
  <div class="d-flex align-items-center justify-content-center" v-if="value">
    <nice-input
      size="small"
      type="number"
      v-if="commissionType === 'percentage'"
      v-model="value.percentage"
      @change="value.fixed = null"
    />
    <currency-input v-else v-model="value.fixed" @change.native="value.percentage = null" />
    <nice-radio-group
      v-model="commissionType"
      class="ml-2 whitespace-nowrap"
      :options="[
        { id: 'percentage', name: '%' },
        { id: 'fixed', name: $db.shopData.isoCurrency || 'EUR' },
      ]"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      commissionType: "percentage",
    }
  },
  mounted() {
    if (this.value) return
    this.$emit("input", { percentage: null, fixed: null })
  },
}
</script>
