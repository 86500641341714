export class APIService {
  constructor(private readonly serviceName: string) {
    this.serviceName = serviceName
  }

  get name() {
    return `api/cloudios/${this.serviceName}/propstack`
  }
}

export const APIMapping = {
  integrationsService: new APIService("integration-extension-service"),
}

export default APIMapping
