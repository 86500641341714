<template>
  <el-popover
    ref="popover"
    placement="bottom"
    :visible-arrow="false"
    trigger="click"
    :width="width"
    transition="none"
    v-model="visible"
  >
    <slot slot="reference" name="button" v-bind="{ activeFilters }">
      <button class="tableActions-action" :class="{ 'tableActions-action-active': activeFilters }">
        <fa-icon name="filter" />
        <span class="ml-2">{{ $t("filterPopover.btn") }}</span>
        <span v-show="activeFilters > 0">({{ activeFilters }})</span>
      </button>
    </slot>
    <template slot="default">
      <div class="el-popover-inner">
        <slot name="default" v-bind="{ close }" />
        <button v-if="resetFilters" @click="resetFilters" class="mt-3 w-full border hover:bg-gray-50 p-2">
          <fa-icon name="trash-alt" />
          <span class="ml-2">{{ $t("filterPopover.reset") }}</span>
        </button>
      </div>
    </template>
  </el-popover>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    width: {
      type: Number,
      default: 400,
    },
    activeFilters: {
      type: Number,
      default: 0,
    },
    resetFilters: {
      type: Function,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    close() {
      this.visible = false
    },
  },
  computed: {},
})
</script>

<style lang="scss" scoped>
</style>