<template>
  <div>
    <slot v-bind="{ shop, fetch, loading, update, updating }" v-if="fetched"></slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"

export default Vue.extend({
  props: {
    fields: {
      type: Array as PropType<string[]>,
      required: true,
    },
    init: {
      type: Function,
      required: false,
    },
    omittedKeys: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    autoRefreshDb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shop: null,
      loading: false,
      updating: false,
    }
  },
  computed: {
    fetched(): boolean {
      return !!this.shop
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    update(shop, callback) {
      const oldState = JSON.parse(JSON.stringify(this.shop))
      this.shop = { ...this.shop, ...shop }
      const payload = _.omit(shop, this.omittedKeys)
      this.updating = true
      return this.$api
        .updateShop(payload)
        .then(res => {
          if (callback) callback()
          if (this.autoRefreshDb) this.$db.updateShopFields(payload)
          return res
        })
        .catch(err => {
          this.shop = oldState
          this.$axios.handleError(err)
        })
        .finally(() => {
          this.updating = false
        })
    },
    async fetch() {
      this.loading = true
      const res = await this.$graphql(
        `{
          shop {
              ${this.fields.join(",")}
          }
        }`, {}, false, false, true
      ).catch((err) => {
        this.$emit("fetch-error", err.response.data.errors)
      })

      if(!res) {
        this.loading = false
        return
      }

      this.shop = res.shop
      if (this.init) {
        this.shop = this.init(this.shop)
      }
      this.loading = false
      this.$emit("update:shop", this.shop)
    },
  },
})
</script>
