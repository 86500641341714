import { FormField, FormFieldScope, FormFieldType } from "@/interfaces"

const pLabelOptions = (fieldName: string) => {
  return ({ db }) => {
    const mapping = db.shopData.propertyOptionsMapping[fieldName]
    return Object.keys(mapping).map(key => ({ id: key, name: mapping[key] }))
  }
}

const FORM_FIELDS: Record<string, FormField> = {
  name: {},
  status: {
    type: FormFieldType.dropdown,
    options: [
      { name: "acquisition", id: "ACQUISITION", translate: true },
      { name: "progress", id: "PROGRESS", translate: true },
      { name: "sales", id: "SALES", translate: true },
      { name: "sold", id: "SOLD", translate: true },
    ],
  },
  note: { type: FormFieldType.text },
  warningNotice: { type: FormFieldType.string },

  brokerId: {
    dbOptions: "brokers",
    type: FormFieldType.dropdown,
  },
  readBrokerIds: {
    type: FormFieldType.multiselect,
    dbOptions: "brokers",
  },
  writeBrokerIds: {
    type: FormFieldType.multiselect,
    dbOptions: "brokers",
  },
  departmentIds: {
    type: FormFieldType.multiselect,
    dbOptions: "departments",
  },
  writeDepartmentIds: {
    type: FormFieldType.multiselect,
    dbOptions: "departments",
  },

  title: { type: FormFieldType.string },
  subHeadline: { type: FormFieldType.string },
  tagline: { type: FormFieldType.string },
  lpPropertyListColumns: {
    type: FormFieldType.multiselect,
    options: [
      { name: "apartmentNum", id: "apartment_number", translate: true },
      { name: "floor", id: "floor", translate: true },
      { name: "type", id: "object_type", translate: true },
      { name: "category", id: "rs_category", translate: true },
      { name: "parkingSpaces", id: "number_of_parking_spaces", translate: true },
      { name: "parkingSpacesP", id: "parking_space_price", translate: true },
      { name: "parkingSpaceNumber", id: "parking_space_number", translate: true },
      { name: "rooms", id: "rooms", translate: true },
      { name: "space", id: "space", translate: true },
      { name: "plot", id: "plot_area", translate: true },
      { name: "usableFloorSpace", id: "usable_floor_space", translate: true },
      { name: "floors", id: "number_of_floors", translate: true },
      { name: "rented", id: "rented", translate: true },
      { name: "divisible", id: "min_divisible", translate: true },
      { name: "baseRent", id: "base_rent", translate: true },
      { name: "subsidy", id: "rent_subsidy", translate: true },
      { name: "price", id: "price", translate: true },
      { name: "pricePerSqm", id: "price_per_sqm", translate: true },
      { name: "status", id: "status", translate: true },
      { name: "floorplan", id: "floorplan", translate: true },
      { name: "street", id: "street", translate: true },
      { name: "zipCode", id: "zip_code", translate: true },
      { name: "warmRent", id: "total_rent", translate: true },
      { name: "serviceCharge", id: "service_charge", translate: true },
    ],
  },
  forRent: { type: FormFieldType.boolean },
  showUnitsOnLandingPage: { type: FormFieldType.boolean },
  enableLinkingToUnitsLp: {
    type: FormFieldType.boolean,
  },
  landingPageBlocked: { type: FormFieldType.boolean },
  logoUrl: { type: FormFieldType.photo },
  scoutId: { type: FormFieldType.dropdown, dbOptions: "scoutProjects", refetchable: true },

  inquiryDepartmentId: {
    type: FormFieldType.dropdown,
    dbOptions: "departmentsForUseInInquiryDistribution",
  },

  address: { component: "property-address-fields" },
  lat: { type: FormFieldType.number, precision: 7 },
  lng: { type: FormFieldType.number, precision: 7 },
  locationId: { type: FormFieldType.dropdown, dbOptions: "groupedLocations" },
  hideAddress: { type: FormFieldType.boolean },
  cadastralDistrict: { type: FormFieldType.number },
  plotNumber: { type: FormFieldType.string },
  district: { type: FormFieldType.string },

  courtage: { type: FormFieldType.string },
  // internalBrokerage: { label: "Innenprovision", type: FormFieldType.string },
  courtageNote: { type: FormFieldType.text },

  descriptionNote: { type: FormFieldType.text, snippets: "description_note" },
  locationNote: { type: FormFieldType.text, snippets: "location_note" },
  furnishingNote: { type: FormFieldType.text, snippets: "furnishing_note" },
  // otherNote: { type: FormFieldType.text, label: "Sonstiges", snippets: "other_note" },

  longDescriptionNote: {
    type: FormFieldType.richtext,
    snippets: "description_note",
  },
  longLocationNote: { type: FormFieldType.richtext, snippets: "location_note" },
  longFurnishingNote: { type: FormFieldType.richtext, snippets: "furnishing_note" },
  // longOtherNote: { type: FormFieldType.richtext, label: "Sonstiges (lang)", snippets: "other_note" },

  energyCertificateAvailability: {
    type: FormFieldType.dropdown,
    options: pLabelOptions("energy_certificate_availability"),
  },
  energyCertificateCreationDate: {
    type: FormFieldType.dropdown,
    options: pLabelOptions("energy_certificate_creation_date"),
  },
  energyCertificateStartDate: { type: FormFieldType.string },
  energyCertificateEndDate: { type: FormFieldType.string },
  buildingEnergyRatingType: {
    type: FormFieldType.dropdown,
    options: pLabelOptions("building_energy_rating_type"),
  },
  energyEfficiencyClass: {
    type: FormFieldType.dropdown,
    options: pLabelOptions("energy_efficiency_class"),
  },
  thermalCharacteristic: { type: FormFieldType.string },
  thermalCharacteristicElectricity: { type: FormFieldType.number },
  thermalCharacteristicHeating: { type: FormFieldType.number },
  coEmission: { type: FormFieldType.number },
  energyConsumptionContainsWarmWater: {
    type: FormFieldType.boolean,
  },
  heatingType: { type: FormFieldType.dropdown, options: pLabelOptions("heating_type") },
  firingTypes: {
    type: FormFieldType.dropdown,
    options: pLabelOptions("firing_types"),
  },
  constructionYear: { type: FormFieldType.number, unit: "year" },
  equipmentTechnologyConstructionYear: { type: FormFieldType.number, unit: "year" },
  constructionYearUnknown: { type: FormFieldType.boolean },
  autHwb: { type: FormFieldType.number },
  autHwbClass: { type: FormFieldType.dropdown, options: pLabelOptions("aut_hwb_class") },
  autFgee: { type: FormFieldType.number },
  autFgeeClass: { type: FormFieldType.dropdown, options: pLabelOptions("aut_fgee_class") },

  owners: {
    component: "owner-field",
    scope: FormFieldScope.owner,
    type: FormFieldType.relationship,
  },
  partners: {
    component: "owner-field",
    scope: FormFieldScope.partner,
    type: FormFieldType.relationship,
  },

  translations: { component: "project-translations" },
}

export default FORM_FIELDS
