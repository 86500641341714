<script lang="ts">
import { computed, defineComponent } from "vue"
export default defineComponent({ setup() {} })
</script>
<script setup lang="ts">
import {
  IntegerationPermissionNamespace,
  IntegrationProviderPermissions,
} from "@/integrations/types/integration-service"
import useI18n from "@/plugins/use-i18n"

const props = defineProps<{
  customPermissions?: string[]
  permissions?: IntegrationProviderPermissions
}>()

const { t } = useI18n()
const permissionDetails = computed(() =>
  props.permissions
    ? Object.entries(props.permissions)
        .filter(([_, permissions]) => !!permissions) // filter out unset permission namespaces
        .map(([namespace, permissions]) => {
          const label = t(`integrations.permissions.namespace.${namespace}`)
          const words = Object.entries(permissions)
            .filter(([_, value]) => Boolean(value)) // filter out non-existing of non requried permissions
            .map(([permission, _]) => t(`integrations.permissions.${permission}`))

          // const Icon = PermissionIconMap[namespace as IntegerationPermissionNamespace]

          return {
            label,
            words,
            // Icon,
          }
        })
    : []
)
</script>
<template>
  <ul class="text-neutral-500">
    <li className="flex items-center" :key="permissionDetail.label" v-for="permissionDetail in permissionDetails">
      <span className="ml-2">{{ permissionDetail.label }} {{ permissionDetail.words.join(" / ") }}</span>
    </li>
    <li className="flex items-center" :key="text" v-for="text in customPermissions">
      <span className="ml-2">{{ text }}</span>
    </li>
  </ul>
</template>
