<template>
  <el-button-group>
    <slot></slot>
  </el-button-group>
</template>

<script>
export default {
  props: {},
}
</script>

<style>
</style>