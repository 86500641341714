<template>
  <el-tooltip
    :effect="effect"
    :placement="placement"
    :offset="offset"
    :visible-arrow="false"
    :transition="transition"
    :disabled="disabled"
    :tabindex="tabindex"
    :open-delay="openDelay"
  >
    <template v-slot:content>
      <slot name="content">
        <div v-if="htmlAllowed" v-html="content" :class="{ 'text-center': textCenter }"></div>
        <div v-else v-text="content" :class="{ 'text-center': textCenter }"></div>
      </slot>
    </template>
    <slot />
  </el-tooltip>
</template>

<script>
export default {
  props: {
    value: {},
    effect: { type: String, default: "dark" },
    placement: { type: String, default: "bottom" },
    offset: { type: Number },
    tabindex: { type: Number, default: 0 },
    openDelay: { type: Number },
    textCenter: {
      type: Boolean,
      default: false,
    },
    disabled: { type: Boolean, default: false },
    htmlAllowed: {
      type: Boolean,
      default: true,
    },
    transition: { type: String },
    content: { type: String, default: "" },
  },
}
</script>

<style>
.el-tooltip__popper.is-dark a {
  color: inherit;
  text-decoration: underline;
}

.el-tooltip__popper {
  max-width: 1000px;
}
</style>