<template>
  <el-switch
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :width="width"
    :active-color="activeColor"
    :disabled="disabled"
    :active-text="activeText"
    :inactive-text="inactiveText"
  />
</template>

<script>
export default {
  props: {
    value: {},
    disabled: { type: Boolean, default: false },
    width: { type: Number, default: null },
    activeColor: { type: String, default: "" },
    activeText: { type: String, default: "" },
    inactiveText: { type: String, default: "" },
  },
}
</script>
