<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({ setup() {} })
</script>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    visible: boolean
    link: string
    title: string
  }>(),
  {
    visible: false,
  }
)
const emit = defineEmits(["update:visible"])
</script>
<template>
  <el-dialog
    :visible="props.visible"
    @update:visible="emit('update:visible', $event)"
    @close="emit('update:visible', false)"
    :title="props.title"
    :fullscreen="true"
    custom-class="iframe-modal"
    append-to-body
  >
    <iframe :src="props.link" class="h-full w-full" :title="props.title" />
  </el-dialog>
</template>
