<template>
  <div class="flowchart-container border border-blue-300 mx-8 py-3 h-full">
    <div class="flex items-center flex-column m-auto">
      <flowchart-node
        v-for="node in nodes"
        :node="node"
        :id="node.index"
        :selected="node.index === selected?.index"
        :recipesConfig="recipesConfig"
        :recipe="recipe"
        :recipes="recipes"
        @select="select"
      >
      </flowchart-node>
    </div>
  </div>
</template>
<script>
import FlowchartNode from "./FlowchartNode.vue"

export default {
  props: {
    recipe: {
      type: Object,
      required: true
    },
    recipes: {
      type: Array,
      required: true
    },
    recipesConfig: {
      type: Object,
      required: true
    },
    selected: {
      type: Object
    },
    nodes: {
      type: Array
    }
  },
  components: {
    FlowchartNode
  },
  methods: {
    select(node) {
      this.$emit("select", node)
    }
  }
}
</script>

<style>
.flowchart-container {
  min-width: 600px;
}

.flowchart-container > div {
  width: 300px;
}
</style>