<template>
  <el-select
    :multiple="multiple"
    :allow-create="allowCreate"
    filterable
    v-cancel-read-only
    :clearable="clearable"
    :remote="async"
    :remote-method="async ? remoteMethod : undefined"
    :value="value"
    default-first-option
    @input="$emit('input', $event || null)"
    @change="onChange($event)"
    :placeholder="placeholderWithFallback"
    :size="size"
  >
    <template v-if="grouped">
      <el-option-group v-for="group in totalOptions" :key="group.id" :label="group.name">
        <el-option v-for="item in group.children" :key="item.id" :label="item[labelKey]" :value="item.id">
          <slot name="option" v-bind="{ item }">{{ item[labelKey] }}</slot>
        </el-option>
      </el-option-group>
    </template>
    <template v-else>
      <el-option
        v-for="item in totalOptions"
        :key="item.id"
        :label="item[labelKey]"
        :value="item.id"
        :disabled="!showDisabledRegularly && item.disabled"
      >
        <slot name="option" v-bind="{ item }">{{ item[labelKey] }}</slot>
      </el-option>
    </template>
  </el-select>
</template>

<script>
import { debounce } from "debounce"

const placeholders = {
  connections: "connections",
  uniqConnections: "connections",
  brokers: "brokers",
  activeBrokers: "brokers",
  unarchivedBrokers: "brokers",
  departments: "departments",
  projects: "projects",
  messageCategories: "categories",
  noteCategories: "categories",
  todoCategories: "categories",
  eventCategories: "categories",
  letterCategories: "categories",
  decisionCategories: "categories",
  clientSources: "sources",
  clientGroups: "tag",
  propertyGroups: "tag",
  activityGroups: "tag",
  cancelationReasons: "cancelationReasons",
  clientStatuses: "statuses",
  clientReasons: "reasons",
  propertyStatuses: "statuses",
  groupedDealStages: "dealStages",
  teams: "teams",
  letterTemplates: "letterTemplates",
  groupedLocations: "locations",
  groupedClientGroups: "tags",
  groupedPropertyGroups: "tags",
  dealPipelines: "dealPipelines",
  roomResources: "resources",
  scoutContacts: "contacts",
  clientSmartViews: "smartViews",
  propertySmartViews: "smartViews",
  activitySmartViews: "smartViews",
  documentTagsSelect: "documentTags",
  taskPipelines: "dealPipelines",
  taskStages: "dealStages",
  projectStatuses: "projectStatuses",
}

const SALUTATION_OPTIONS = [
  { id: "mr", name: "nameSalutationMr" },
  { id: "ms", name: "nameSalutationMs" },
  { id: "mrms", name: "nameSalutationMrms" },
]

export default {
  props: {
    value: {},
    placeholder: {
      type: String,
    },
    size: {
      type: String,
      default: "small",
    },
    collection: {
      type: String,
      required: true,
    },
    additionalOptions: {
      type: Array,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterFunc: {
      type: Function,
      required: false,
    },
    label: {
      type: String,
    },
    showDisabledRegularly: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        if (!newVal || newVal === oldVal) return
        this.preselect(newVal)
      },
      immediate: true,
    },
  },

  data() {
    return {
      filteredOptions: [],
      fetchedItems: undefined,
    }
  },

  methods: {
    onChange(val) {
      this.$emit("change", val || null)
    },
    preselect(val) {
      if (!this.async) return true
      this.filteredOptions = (
        this.multiple ? val.map(v => this.items.find(o => o?.id == v)) : [this.items.find(o => o?.id == val)]
      ).filter(Boolean)
    },
    remoteMethod: debounce(function (query) {
      if (query) {
        this.filteredOptions = this.items.filter(item => {
          return (item.name ?? item.internalName)?.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
      } else {
        this.filteredOptions = []
      }
    }),
    fetch() {
      this.$graphql(`query dbSelectFor${this.collection} { shop { ${this.collection} { id name } } }`)
        .then(({ shop }) => {
          this.fetchedItems = shop[this.collection]
        })
        .catch(this.$axios.handleError)
    },
  },
  computed: {
    async() {
      return this.items?.length > 200
    },
    placeholderWithFallback() {
      const key = placeholders[this.label || this.collection]
      return key
        ? this.$t("dbSelect.text", {
            placeholder: this.$t(`dbSelect.placeholders.${key}`),
          })
        : this.$t("niceSelect.placeholder")
    },
    grouped() {
      const GROUPABLE_COLLECTIONS = ["groupedDealStages"]
      return GROUPABLE_COLLECTIONS.includes(this.collection)
    },
    activeBrokers() {
      return this.$db.get("brokers").filter(b => b.active)
    },
    unarchivedBrokers() {
      return this.$db.get("brokers").filter(b => !b.archived)
    },
    uniqConnections() {
      return this.$db.get("connections").filter(o => !o.parentConnectionId)
    },
    roomResources() {
      return this.$db.get("roomResources").map(rr => ({ id: rr.email, name: rr.name }))
    },
    salutations() {
      return SALUTATION_OPTIONS.map(o => ({ ...o, name: this.$t(`detailView.${o.name}`) })).concat(
        this.$db.get("salutations").map(o => ({ id: o.internalName, name: o.name }))
      )
    },
    todoAndDecisionCategories() {
      return this.$db.shopData.todoCategories.concat(this.$db.shopData.decisionCategories)
    },
    groupedDealStages() {
      return this.$db.shopData.dealPipelines.map(dp => ({
        id: dp.id,
        name: dp.name,
        children: this.$db.shopData.dealStages.filter(ds => ds.dealPipelineId == dp.id),
      }))
    },
    groupedClientGroups() {
      const superGroups = this.$db.shopData.superGroups
      return this.$db.shopData.clientGroups
        .map(g => {
          const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
          return {
            id: g.id,
            name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    groupedPropertyGroups() {
      const superGroups = this.$db.shopData.superGroups
      return this.$db.shopData.propertyGroups
        .map(g => {
          const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
          return {
            id: g.id,
            name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    labelKey() {
      if (["brokers", "activeBrokers", "unarchivedBrokers"].includes(this.collection)) {
        return "internalName"
      }

      return "name"
    },
    items() {
      let items =
        this.fetchedItems ||
        this[this.collection] ||
        (typeof this.$db[this.collection] == "function" ? this.$db[this.collection]() : this.$db.get(this.collection))
      if (this.additionalOptions) items = items.concat(this.additionalOptions)
      if (this.filterFunc) items = items.filter(this.filterFunc)
      return items
    },
    totalOptions() {
      return this.async ? this.filteredOptions : this.items
    },
  },
  mounted() {
    if (this.lazy) this.fetch()
  },
}
</script>
