<template>
  <div class="mb-2">
    <strong>{{ label }}</strong>
    <span class="pull-right">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },
}
</script>
