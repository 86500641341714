<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="page == 1 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <router-link
      v-for="item in groupedItems"
      :key="item.id"
      class="flex items-center rounded p-2 mx-1 text-black hover:bg-black/5"
      :to="item.type == 'broker' ? '' : $customFilters.clientPath(item.id, $db.broker)"
    >
      <aside class="mr-2">
        <fa-icon name="birthday-cake" class="text-lg" />
      </aside>
      <main class="flex-grow pl-1 overflow-hidden">
        <p class="leading-snug whitespace-nowrap text-ellipsis" :title="item.name">{{ item.name }}</p>
      </main>
      <span v-if="item.phone" class="text-gray-600 text-sm ml-2 whitespace-nowrap">
        {{ item.phone }}
      </span>
      <nice-divider v-if="item.phone" direction="vertical" />
      <time class="text-gray-600 text-sm" style="min-width: 48px">
        {{ item.dob }}
      </time>
    </router-link>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p v-if="!groupedItems.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.birthdays") }}
    </p>
  </widget-container>
</template>

<script>
import moment from "moment"
import WidgetContainer from "./WidgetContainer.vue"

export default {
  components: { WidgetContainer },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      noMore: false,
      page: 1,
    }
  },
  methods: {
    async fetchData() {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.post("/api/v1/contacts/search", {
        per: 10,
        page: this.page,
        sort_by: "birthday",
        order: "asc",
        filter_set: { must: this.filters },
        include_children: true,
        exclude_locked: true,
      })

      this.items = this.items.concat(data.data)
      this.loading = false
      this.noMore = 10 * this.page >= data.meta.total_count
      this.page++
    },
  },
  computed: {
    rangeStart() {
      return moment()
        .set("year", 1972)
        .add(this.widget.daysStart || 0, "days")
        .startOf("day")
    },
    rangeEnd() {
      return moment().set("year", 1972).add(this.widget.daysEnd, "days").endOf("day")
    },
    rangeStartExceedsToPrevYear() {
      return Boolean(this.widget.daysStart) && moment().add(this.widget.daysEnd, "days").year() < moment().year()
    },
    rangeEndExceedsToNextYear() {
      return Boolean(this.widget.daysEnd) && moment().add(this.widget.daysEnd, "days").year() > moment().year()
    },
    filters() {
      let filters = [
        {
          bool: {
            should: [{ term: { archived: false } }, { bool: { must_not: { exists: { field: "archived" } } } }],
          },
        },
      ]

      if (this.widget.brokerIds && this.widget.brokerIds.length) {
        filters.push({
          bool: {
            should: [
              { terms: { responsible_broker: this.widget.brokerIds } },
              { terms: { second_broker: this.widget.brokerIds } },
            ],
          },
        })
      }

      if (this.widget.categoryIds && this.widget.categoryIds.length) {
        filters.push({ terms: { status_id: this.widget.categoryIds } })
      }

      if (this.rangeEndExceedsToNextYear || this.rangeStartExceedsToPrevYear) {
        filters.push({
          bool: {
            should: [
              {
                range: {
                  birthday: {
                    gte: this.rangeStart.format(),
                  },
                },
              },
              {
                range: {
                  birthday: { lte: this.rangeEnd.format() },
                },
              },
            ],
          },
        })
      } else {
        filters.push({ range: { birthday: { gte: this.rangeStart.format() } } })

        if (Number.isInteger(this.widget.daysEnd)) {
          filters.push({ range: { birthday: { lte: this.rangeEnd.format() } } })
        }
      }

      return filters
    },
    birthdayBrokers() {
      return this.$db.shopData.brokers
        .filter(b => {
          if (!b.dob || b.archived) return false
          const dob = moment(b.dob).year(1972)
          return dob >= this.rangeStart && (!Number.isInteger(this.widget.daysEnd) || dob <= this.rangeEnd)
        })
        .map(o => ({ ...o, type: "broker" }))
    },
    groupedItems() {
      return this.items
        .concat(this.birthdayBrokers)
        .sort((b1, b2) => (moment(b1.dob).year(1972).isAfter(moment(b2.dob).year(1972)) ? 1 : -1))
        .map(o => ({
          ...o,
          dob: moment(o.dob).format("DD. MMM"),
        }))
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
