<template>
  <div class="nice-select">
    <select @change="$emit('change', $event)" class="form-control" :class="{ 'alt-design': !value && altDesign }">
      <option value="" :selected="!value">{{ $t("panes.messageForm.snippetSelect") }}</option>
      <optgroup v-for="group in options" :key="group.name" :label="group.name">
        <option v-for="item in group.snippets" :key="item.id" :value="item.id" :selected="item.id == value">
          {{ item.name }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    altDesign: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      loading: false,
    }
  },

  methods: {
    async fetchData(query) {
      this.loading = true
      const { shop } = await this.$graphql(
        `query snippetCategories {
        shop {
          snippetCategories {
            id name
            snippets(forCurrentBroker: true) {
              id name
            }
          }
        }
      }`,
        undefined,
        true
      )
      this.options = shop.snippetCategories
      this.loading = false
    },
  },

  mounted() {
    this.fetchData(this.value)
  },
}
</script>

<style scoped>
.alt-design {
  color: #c0c4cc;
  padding: 0 30px 0 12px;
  min-height: unset!important;
  height: 32px;
}
</style>