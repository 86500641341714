import combokeys from "@/utils/shortcuts"
import eventBus from "@/config/event-bus"

export default {
  data() {
    return {
      selectedRowIndex: null
    }
  },
  watch: {
    selectedRowIndex() {
      this.$emit("update:selectedRowIndex", this.selectedRowIndex)
    }
  },
  methods: {
    setSelectedRowIndex(idx) {
      this.selectedRowIndex = idx
    },
    registerShortcuts() {
      const formOpened = () => {
        return document.getElementById("message-form")
      }
      combokeys.bind("down", "table", e => {
        if (formOpened()) return
        e.preventDefault()
        if (this.selectedRowIndex < this.data.length) {
          this.selectedRowIndex++
        }
      })
      combokeys.bind("up", "table", e => {
        if (formOpened()) return
        e.preventDefault()
        if (this.selectedRowIndex > 1) {
          this.selectedRowIndex--
        }
      })
      combokeys.bind("enter", "table", _ => {
        if (formOpened()) return
        if (this.selectedRowIndex) {
          const resource = this.data[this.selectedRowIndex - 1]
          if (resource) this.visitResource(resource)
        }
      })
      combokeys.bind("esc", "table", e => {
        if (formOpened()) return
        e.preventDefault()
        this.selectedRowIndex = null
        eventBus.$emit("quick-view-clear")
      })

      combokeys.setScope("table")
    }
  },
  mounted() {
    this.registerShortcuts()
  },
  beforeDestroy() {
    combokeys.unbind("down, up, enter, esc", "table")
  }
}
