<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue"
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import config from "@/config/config"
import { Broker } from "@/interfaces"

const { userId, broker } = defineProps<{
  userId: String
  broker: Broker
}>()

const requiresPrompt = ref(false)
const showBanner = ref(false)

const beamsAuthEndpoint = "/api/v1/pusher/beams-auth"
let beamsClient, beamsTokenProvider
const initBeams = () => {
  beamsClient = new PusherPushNotifications.Client({
    instanceId: config.PUSHER_BEAMS_INSTANCE_ID,
  })
  beamsTokenProvider = new PusherPushNotifications.TokenProvider({
    url: [config.BASE_URL, beamsAuthEndpoint].filter(Boolean).join(""),
  })

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () =>
      navigator.serviceWorker.register("/service-worker.js").then(
        registration => console.log("Service Worker registration successful with scope: ", registration.scope),
        err => console.log("Service Worker registration failed: ", err)
      )
    )
  }
}

const startBeams = () => {
  return beamsClient
    .start()
    .then(beamsClient => beamsClient.getDeviceId())
    .then(deviceId => {
      requiresPrompt.value = false
      console.log("Successfully registered with Beams. Device ID:", deviceId)
    })
    .catch(e => {
      requiresPrompt.value = true
      console.error(e)
    })
}

const authenticateBeams = () => {
  if (!beamsClient) return
  startBeams().then(() => {
    beamsClient
      .setUserId(userId, beamsTokenProvider)
      .then(() => {
        console.log("Successfully authenticated User. User ID:", userId)
      })
      .catch(console.error)
  })
}

const checkSafari = () => {
  const safariDetected = window.safari !== undefined
  if (safariDetected) console.log("Safari detected")
  return safariDetected
}

const checkNotificationsEnabled = () => {
  return broker.notificationEnabled
}

const checkShowingBanner = () => {
  if (checkSafari() && checkNotificationsEnabled()) {
    showBanner.value = true
  }
}

onMounted(() => {
  initBeams()
  authenticateBeams()
  // checkShowingBanner()
})

onUnmounted(() => {
  beamsClient.stop().catch(console.error)
})
</script>

<template>
  <el-alert title="" type="warning" v-if="requiresPrompt && showBanner" center>
    <p class="warningBanner">
      Registrierung von Push-Notifiations fehlgeschlagen.
      <button @click="authenticateBeams">Erneut versuchen</button>
    </p>
  </el-alert>
</template>

<style scoped>
.warningBanner {
  padding: 0.2rem 0.7rem;
  background: rgba(#999999, 0.15) !important;
  color: #222222;
  border-radius: 3px;
  font-size: 0.85rem;
  margin-left: 234px;
}

button {
  text-decoration: underline;
}
</style>

