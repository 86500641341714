<template>
  <div>
    <el-popover ref="searchfilterspopover" placement="bottom" width="320" trigger="click" transition="none">
      <slot slot="reference" name="reference" :selectLabel="selectLabel">
        <ps-button :active="!!selectedSmartView" icon="th-list" :title="selectLabel" />
      </slot>

      <div class="el-popover-inner">
        <div class="standardList-wrap">
          <list-item :title="$t('smartViews.default')" @click="reset" :selected="!selectedSmartView" />
          <list-item
            v-for="item in smartViews"
            :key="item.id"
            :item="item"
            :selected="item.id == selectedSmartViewId"
            :editable="item.brokerId === $db.broker.id || $db.broker.admin"
            @edit="editSmartView(item)"
            @click="clicked(item)"
          />
          <slot name="list" />
        </div>
        <footer class="text-right mt-2">
          <a v-if="smartViews.length > 0" href="#" class="light m-l-15" @click.prevent="filterSettingsVisible = true">
            {{ $t("smartViews.edit") }}
          </a>
        </footer>
      </div>
    </el-popover>
    <el-dialog :title="$t('smartViews.editModalTitle')" :visible.sync="filterSettingsVisible" width="450px">
      <p class="text-right mb-2">{{ $t("smartViews.setDefault") }}</p>
      <div class="standardList-wrap">
        <editable-search-filter-item
          v-for="item in smartViews"
          :key="item.id"
          :filter="item"
          :entity="entity"
          :selected="item.id == defaultSmartViewId"
          @delete="deleteSmartView(item)"
          @change="updateDefaultSmartView"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <nice-button type="primary" @click="filterSettingsVisible = false">{{ $t("smartViews.editDone") }}</nice-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditableSearchFilterItem from "@/components/search-filters/SearchFilter"

const BROKER_FIELD_MAPPING = {
  for_contacts: "defaultClientsFilter",
  for_properties: "defaultPropertiesFilter",
  for_activities: "defaultActivitiesFilter",
  for_todos: "defaultTodosFilter",
  for_deals: "defaultDealsFilter",
}

export default {
  props: {
    smartViews: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: "Smart-Views",
    },
    selectedSmartViewId: {
      type: Number,
    },
    entity: {
      type: String,
    },
  },
  data() {
    const brokerField = BROKER_FIELD_MAPPING[this.entity]
    return {
      filterSettingsVisible: false,
      defaultSmartViewId: this.$db.broker[brokerField] && parseInt(this.$db.broker[brokerField]),
    }
  },
  components: {
    EditableSearchFilterItem,
  },
  methods: {
    reset() {
      // this.$emit("update:selectedSmartViewId")
      this.$emit("reset")
    },
    clicked(item) {
      // this.$emit("update:selectedSmartViewId", item.id)
      this.$emit("change", item)
    },
    async updateDefaultSmartView(item) {
      this.defaultSmartViewId = item
      const brokerField = BROKER_FIELD_MAPPING[this.entity]
      this.$db.updateBroker({
        [brokerField]: `${item}`,
      })
    },
    deleteSmartView(item) {
      this.$warn(
        {
          title: this.$t("smartViews.delete.title"),
          desc: this.$t("smartViews.delete.desc", { name: item.name }),
          confirmButtonText: this.$t("smartViews.delete.submit"),
          redButton: true,
        },
        async () => {
          await this.$axios.delete(`/services/search_filters/${item.id}`)
          if (item.id == this.selectedSmartViewId) this.reset()
          this.$emit(
            "update:smartViews",
            this.smartViews.filter(o => o.id != item.id)
          )
          this.$emit("delete", item)
          App.flashy(this.$t("smartViews.delete.success"))
        }
      )
    },
    editSmartView(item) {
      this.$emit("edit", item.id)
    },
  },
  computed: {
    selectedSmartView() {
      return this.smartViews.find(o => o.id == this.selectedSmartViewId)
    },
    selectLabel() {
      return this.selectedSmartView ? this.selectedSmartView.name : this.placeholder
    },
  },
  mounted() {
    if (this.defaultSmartViewId && !this.selectedSmartViewId) {
      const smartView = this.smartViews.find(s => s.id === this.defaultSmartViewId)
      this.$emit("change", smartView)
    }
  },
}
</script>
