<template>
  <el-select
    v-if="!loading"
    filterable
    v-cancel-read-only
    clearable
    :value="value"
    @input="$emit('input', $event || null)"
    @change="$emit('change', $event)"
    placeholder="Textbaustein auswählen"
    size="small"
  >
    <el-option-group v-for="group in options" :key="group.name" :label="group.name">
      <el-option v-for="item in group.snippets" :key="item.id" :label="item.name" :value="item.id" />
    </el-option-group>
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      options: [],
      loading: false,
    }
  },

  methods: {
    async fetchData() {
      this.loading = true
      const { shop } = await this.$graphql(
        `query snippetCategories {
        shop {
          snippetCategories {
            id name
            snippets(forCurrentBroker: true) {
              id name
            }
          }
        }
      }`,
        undefined,
        true
      )
      this.options = shop.snippetCategories
      this.loading = false
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>
