<template>
  <dropdown button-class="details-action-link">
    <dropdown-section v-if="$db.planActive('pro', 'reshare')">
      <dropdown-item
        :title="$t('property.detailDropdown.lp')"
        :link="`/preview/properties/${resource.id}/lp`"
        new-tab
      />
      <dropdown-item
        :title="$t('property.detailDropdown.report')"
        :link="`/preview/properties/${resource.id}/report`"
        new-tab
      />
      <dropdown-item
        v-if="$db.featureActive('property_statistics')"
        :title="$t('property.detailDropdown.statistics')"
        :link="`/portfolio/properties/${resource.id}/preview?template_type=statistics`"
        new-tab
      />
      <dropdown-item :title="$t('property.detailDropdown.lpShare')" @click="lpShareVisible = true" />
      <dropdown-item :title="$t('property.detailDropdown.propertyShare')" @click="partnerShareVisible = true" />
    </dropdown-section>
    <dropdown-section v-if="$db.planActive('pro', 'reshare')">
      <dropdown-item :link="`/preview/properties/${resource.id}/print?locale=${translatedLocales[0]}`" new-tab>
        <template v-slot:title>
          <div class="relative">
            {{ $t("property.detailDropdown.printExposee") }}
            <hover-actions v-if="translatedLocales.length > 1" right="0" top="0">
              <ps-button
                size="small"
                class="ps-hover border border-gray-300 border-solid"
                v-for="locale in translatedLocales.slice(0, 2)"
                :key="locale"
                :visit="`/preview/properties/${resource.id}/print?locale=${locale}`"
                new-tab
              >
                <flag v-if="locale" :locale="locale" />
              </ps-button>
              <el-popover
                v-if="translatedLocales.length > 2"
                ref="printexposeepopover"
                placement="bottom"
                width="150"
                trigger="hover"
                transition="none"
                popper-class="locale-popover"
              >
                <hover-action slot="reference" size="sm" icon="ellipsis-h" />
                <div class="flex flex-col items-center justify-center p-2">
                  <ps-button
                    v-for="locale in translatedLocales.slice(2)"
                    :key="locale"
                    :visit="`/preview/properties/${resource.id}/print?locale=${locale}`"
                    new-tab
                    style="width: 30px"
                  >
                    <flag v-if="locale" :locale="locale" />
                  </ps-button>
                </div>
              </el-popover>
            </hover-actions>
          </div>
        </template>
      </dropdown-item>
      <dropdown-item
        :title="$t('property.detailDropdown.onepager')"
        :link="`/preview/properties/${resource.id}/print?one_pager=true`"
        new-tab
      />
      <template v-for="template in letterTemplates">
        <dropdown-item
          :key="template.id"
          :link="
            teams && teams.length > 1
              ? undefined
              : `/services/letters/${template.id}?property_id=${resource.id}&locale=${translatedLocales[0]}`
          "
          v-on:click="
            teams && teams.length > 1
              ? showTeamsModal({ templateId: template.id, propertyId: resource.id, locale })
              : undefined
          "
          new-tab
        >
          <template v-slot:title>
            <div class="relative">
              {{ template.name }}
              <hover-actions v-if="translatedLocales.length > 1" right="0" top="0">
                <ps-button
                  size="small"
                  class="ps-hover border border-gray-300 border-solid"
                  v-for="locale in translatedLocales.slice(0, 2)"
                  :key="`${template.id}-${locale}`"
                  :visit="
                    teams && teams.length > 1
                      ? undefined
                      : `/services/letters/${template.id}?property_id=${resource.id}&locale=${locale}`
                  "
                  new-tab
                >
                  <flag v-if="locale" :locale="locale" />
                </ps-button>
                <el-popover
                  v-if="translatedLocales.length > 2"
                  ref="lettertemplatepopover"
                  placement="bottom"
                  width="20"
                  trigger="hover"
                  transition="none"
                  popper-class="locale-popover"
                >
                  <hover-action slot="reference" size="sm" icon="ellipsis-h" />
                  <div class="flex flex-col items-center justify-center p-2">
                    <ps-button
                      v-for="locale in translatedLocales.slice(2)"
                      :key="`${template.id}-${locale}`"
                      :visit="
                        teams && teams.length > 1
                          ? undefined
                          : `/services/letters/${template.id}?property_id=${resource.id}&locale=${locale}`
                      "
                      style="width: 30px"
                      new-tab
                    >
                      <flag v-if="locale" :locale="locale" />
                    </ps-button>
                  </div>
                </el-popover>
              </hover-actions>
            </div>
          </template>
        </dropdown-item>
      </template>
    </dropdown-section>
    <dropdown-section v-if="resource.editable">
      <dropdown-item :title="$t('property.detailDropdown.copy')" :link="`/portfolio/properties/${resource.id}/copy`" />
      <dropdown-item
        :title="$t('property.detailDropdown.create_variant')"
        :link="`/portfolio/properties/${resource.id}/new_variant`"
        v-if="$db.planActive('pro', 'reshare')"
      />
      <dropdown-item
        :title="$t('property.detailDropdown.merge')"
        v-if="$db.planActive('pro', 'reshare')"
        @click="mergeVisible = true"
      />
    </dropdown-section>
    <dropdown-section v-if="resource.editable">
      <dropdown-item
        :title="resource.archived ? $t('property.detailDropdown.unarchive') : $t('property.detailDropdown.archive')"
        @click="toggleArchive"
      />
      <dropdown-item
        v-if="$db.broker.canDeleteProperties"
        :title="$t('property.detailDropdown.delete')"
        @click="deleteResource"
        class="text-danger"
      />
    </dropdown-section>
    <dropdown-section v-if="$root.dbackdoor && resource.oldCrmId">
      <tooltip :delay="500">
        <dropdown-item title="Copy old CRM-ID" @click="copyOldCrmId" />
        <template v-slot:content>
          <span>
            ID:
            <span class="font-mono">{{ resource.oldCrmId }}</span>
          </span>
        </template>
      </tooltip>
    </dropdown-section>

    <merge-modal :visible.sync="mergeVisible" :propertyId="resource.id" :propertyName="resource.name" />
    <link-copy-modal
      :visible.sync="lpShareVisible"
      :title="`${$t('property.detailDropdown.lpPublic')} – ${resource.name}`"
      :url="resource.lpShareUrl"
    />
    <link-copy-modal
      :visible.sync="partnerShareVisible"
      :title="`${$t('property.detailDropdown.partner')} – ${resource.name}`"
      :url="resource.partnerShareUrl"
    />
    <select-option-modal
      :visible.sync="teamsVisible"
      :title="$t('property.detailDropdown.teamSelect.title')"
      :label="$t('property.detailDropdown.teamSelect.label')"
      :button="$t('property.detailDropdown.teamSelect.title')"
      :text="$t('property.detailDropdown.teamSelect.text')"
      v-if="teams && teams.length > 1"
      :options="teams"
      @submit="teamsSelected"
    />
  </dropdown>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { DetailProperty } from "../../interfaces"
import MergeModal from "./MergeModal.vue"
import LinkCopyModal from "../LinkCopyModal.vue"
import SelectOptionModal from "./SelectOptionModal.vue"
import { LetterTemplate } from "@/config/db"
import Flag from "@/components/Flag.vue"

export default Vue.extend({
  props: {
    resource: Object as PropType<DetailProperty>,
  },
  components: {
    MergeModal,
    LinkCopyModal,
    SelectOptionModal,
    Flag,
  },
  data() {
    return {
      teamsVisible: false,
      mergeVisible: false,
      lpShareVisible: false,
      partnerShareVisible: false,
      linkParams: undefined as any,
    }
  },
  methods: {
    showTeamsModal(params) {
      this.linkParams = params
      this.teamsVisible = true
    },
    teamsSelected(teamId) {
      const { templateId, propertyId, locale } = this.linkParams
      window.open(`/services/letters/${templateId}?property_id=${propertyId}&locale=${locale}&team_id=${teamId}`)
    },
    copyOldCrmId() {
      this.$util.copy(this.resource.oldCrmId)
      App.flashy("ID successfully copied!")
    },
    toggleArchive() {
      this.resource.archived = !this.resource.archived
      this.$axios.put(`/portfolio/properties/${this.resource.id}/archive`).then(_ => {
        if (this.resource.archived) {
          Turbolinks.visit("/portfolio/properties")
          App.flashy(this.$t("property.detailDropdown.archiveSuccess"))
        } else {
          App.flashy(this.$t("property.detailDropdown.unarchiveSuccess"))
        }
      })
    },
    deleteResource() {
      this.$warn(
        {
          title: this.$t("property.detailDropdown.delete"),
          desc: this.$t("property.detailDropdown.delWarn", { name: this.resource.name }),
          confirmButtonText: this.$t("client.detailDropdown.delBtn"),
          redButton: true,
        },
        () => {
          this.$axios
            .delete(`/portfolio/properties/${this.resource.id}`)
            .then(_ => {
              Turbolinks.visit("/portfolio/properties")
              App.flashy(this.$t("property.detailDropdown.delSuccess", { name: this.resource.name }))
            })
            .catch(this.$axios.handleError)
        }
      )
    },
  },
  computed: {
    letterTemplates(): Array<LetterTemplate> {
      return this.$db.shopData.letterTemplates.filter(lt => lt.category == "exposee")
    },
    teams(): any[] {
      if (this.$db.broker.accessTeamIds && this.$db.broker.accessTeamIds.length > 0) {
        return this.$db.shopData.teams.filter(t => this.$db.broker.accessTeamIds.includes(t.id))
      }
      return []
    },
    translatedLocales(): string[] {
      const locales = [this.$db.shopData.language]
        .concat(this.$db.shopData.supportedPropertyLocales || [])
        .filter(Boolean)
      return this.resource.translatedLocales?.filter(locale => locales.includes(locale)) || []
    },
  },
})
</script>

<style>
.locale-popover {
  min-width: 43px !important;
  width: 43px !important;
  padding-left: 3px;
}

.ps-hover:hover {
  background-color: #ededed;
}
</style>