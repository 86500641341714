import moment from "moment"
import axios from "../../config/axios"

const beginningOfToday = moment().startOf("day")
const formatMessage = (state, email) => {
  const date = moment(email.created_at)
  return {
    ...email,
    id: email.id,
    date: date.format(date.isAfter(beginningOfToday) ? "LT" : "DD. MMM"),
    sender: {
      name: email.sender,
      email: email.from,
    },
    starred: email.star,
    spammed: email.spam,
    isDraft: email.is_draft,
    attachments: email.message_attachments_count,
    commentsCount: email.comments_count,
    deletedAt: email.deleted_at,
    createdAt: email.created_at,
  }
}

export default {
  namespaced: true,

  state: {
    inboxBrokerId: null,
    isLoading: true,
    total: 0,
    messages: [],
    selected: [],
  },

  getters: {
    allSelected: state => {
      return state.messages.length > 0 && state.selected.length == state.messages.length
    },
    checkedAllIndeterminate: state => {
      return state.selected.length > 0 && state.selected.length < state.messages.length
    },
  },

  mutations: {
    setInboxBrokerId(state, inboxBrokerId) {
      state.inboxBrokerId = inboxBrokerId
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },

    setTotal(state, total) {
      state.total = total
    },

    reset(state) {
      state.messages = []
      state.selected = []
      state.isLoading = true
    },

    setMessages(state, messages) {
      state.messages = messages
    },

    setSelected(state, selected) {
      state.selected = selected
    },

    removeMessages(state, messagesToBeRemoved) {
      state.messages = state.messages.filter(message => {
        return !messagesToBeRemoved.includes(message.id)
      })
    },

    setMessagesReadStatus(state, { messagesToBeUpdated, status }) {
      state.messages = state.messages.map(message => {
        if (!messagesToBeUpdated.includes(message.id)) return message
        return { ...message, read: status }
      })
    },

    setMessagesStarredStatus(state, { messagesToBeUpdated, status }) {
      state.messages = state.messages.map(message => {
        if (!messagesToBeUpdated.includes(message.id)) return message
        return { ...message, starred: status }
      })
      if (state.message && messagesToBeUpdated.includes(state.message)) {
        state.message.starred = status
      }
    },
  },

  actions: {
    fetchMessages(
      context,
      { page, per, type, q, filter, inboxBrokerId, smartFolder, folderId, global, withLoading = true }
    ) {
      context.commit("setIsLoading", withLoading)
      const params = {
        q,
        page,
        per,
        inbox_broker_id: inboxBrokerId,
        global: global,
        only_unread: filter.onlyUnread,
        only_with_attachments: filter.hasAttachments,
        only_with_category: filter.category,
        client_source_id: filter.source,
        group_id: filter.groupId,
        only_with_sender: filter.sender,
        property: filter.propertyId,
        only_with_recipient: filter.recipient,
      }

      let url
      if (type == "smart_folders" && smartFolder) {
        url = `/api/v1/messages/smart_folders/${smartFolder}`
      } else if (folderId) {
        url = `/api/v1/messages/folders/${folderId}`
      } else {
        url = `/api/v1/messages/${type == "inbox" ? "" : type}`
      }

      return axios.get(url, { params }).then(res => {
        context.commit("setTotal", res.data.meta.total_count)
        context.commit(
          "setMessages",
          res.data.data.map(e => formatMessage(context.state, e))
        )
        context.commit("setIsLoading", false)
      })
    },

    updateMessages(context, { action, messages, withLoading = true, ...payload }) {
      context.commit("setIsLoading", withLoading)
      return axios
        .post("/mailbox/messages/mass_update", {
          message_action: action,
          message_ids: messages,
          ...payload,
        })
        .then(_ => {
          switch (action) {
            case "mark_as_read":
            case "mark_as_unread":
              // no need anymore, update comes from realtime server
              // context.commit("setMessagesReadStatus", {
              //   messagesToBeUpdated: messages,
              //   status: action == "mark_as_read"
              // });
              break
            case "star":
            case "unstar":
              // context.commit("setMessagesStarredStatus", {
              //   messagesToBeUpdated: messages,
              //   status: action == "star"
              // });
              break
            case "update_attributes":
              break
            default:
              context.commit("removeMessages", messages)
          }
        })
        .then(_ => context.commit("setIsLoading", false))
    },

    messagesSynced({ state }, messages) {
      state.messages = state.messages.map(message => {
        const found = messages.find(m => m.id == message.id)
        if (found)
          return {
            ...message,
            read: found.read,
            starred: found.star,
            client_source_id: found.client_source_id,
            message_category_id: found.message_category_id,
            group_ids: found.group_ids || [],
          }

        return message
      })
    },
  },
}
