export let cachedClients: any[] = []
export let cachedProperties: any[] = []
export let cachedProjects: any[] = []

export const cacheClients = (newData: any[]) => {
  cachedClients = cachedClients.filter(p => !newData.map(o => o.id).includes(p.id)).concat(newData)
}
export const cacheProperties = (newData: any[]) => {
  cachedProperties = cachedProperties.filter(p => !newData.map(o => o.id).includes(p.id)).concat(newData)
}
export const cacheProjects = (newData: any[]) => {
  cachedProjects = cachedProjects.filter(p => !newData.map(o => o.id).includes(p.id)).concat(newData)
}
