<template>
  <div class="pages-editor">
    <textarea ref="input" :value="value" @input="$emit('input', $event)" v-bind="$attrs"></textarea>
    <take-over-link-button
      v-if="hasTakeOver && $attrs.placeholder"
      @update="setValue($attrs.placeholder)"
      class="mt-1"
    />
  </div>
</template>

<script>
import TakeOverLinkButton from "@/views/admin/components/TakeOverLinkButton.vue"
import { initDescriptionEditorFor, initHtmlEditorFor } from "@/config/richtext-editor"

export default {
  components: { TakeOverLinkButton },
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
    hasTakeOver: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "description",
    },
  },
  froalaEditor: undefined,
  methods: {
    setValue(newValue) {
      this.$options.froalaEditor.html.set(newValue)
    },
  },
  mounted() {
    const initEditor = this.type == "description" ? initDescriptionEditorFor : initHtmlEditorFor
    const options =
      this.type == "description"
        ? {}
        : {
            pluginsEnabled: [
              "align",
              "codeView",
              "colors",
              "fontAwesome",
              "fontFamily",
              "fontSize",
              "image",
              "link",
              "lists",
              "url",
              "fullscreen",
              "table",
            ],
          }

    const that = this
    this.$options.froalaEditor = initEditor(
      this.$refs.input,
      {
        ...options,
        events: {
          contentChanged(e) {
            that.$emit("input", this.html.get())
          },
          blur(e) {
            if (!this.codeView.isActive()) return
            this.html.set(this.codeView.get())
          },
        },
      },
      () => {
        if (this.readonly) this.$options.froalaEditor.edit.off()
      }
    )
  },
}
</script>
