<template>
  <div class="hover-actions" :style="position" :class="{ invisible: !alwaysVisible }">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    top: String,
    right: String,
    bottom: String,
    left: String,
    alwaysVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    position(): any {
      if (!this.top && !this.right && !this.bottom && !this.left) {
        return {
          right: "10px",
          top: "0px",
          bottom: "0px",
        }
      }

      return {
        top: this.top,
        right: this.right,
        bottom: this.bottom,
        left: this.left,
      }
    },
  },
})
</script>

<style scoped>
.hover-actions {
  position: absolute;
  display: flex;
  align-items: center;
}
.hover-actions.active,
*:hover > .hover-actions,
.psTable-row:hover .hover-actions {
  visibility: visible;
}
.hover-actions > *:not(:first-child) {
  margin-left: 0.5rem;
}
</style>