<template>
  <widget-container :widget="widget" :config="config" :loading="loading">
    <section v-for="(events, key) in groupedData" :key="key" class="mt-2">
      <h5 class="text-lg px-2 py-1 mx-1">{{ getSectionTitle(key) }}</h5>
      <div
        v-for="item in events"
        :key="item.id"
        class="flex items-center rounded p-2 mx-1 cursor-pointer hover:bg-black/5"
        :class="{
          'line-through text-gray-400': item.state == 'took_place' || item.state == 'cancelled',
        }"
        @click="open(item)"
      >
        <time
          class="mr-2 text-sm self-stretch relative"
          style="min-width: 90px; top: 2px"
          :class="{ 'text-gray-700': item.state !== 'took_place' && item.state !== 'cancelled' }"
        >
          <span v-if="item.allDay">{{ $t("widget.events.allDay") }}</span>
          <span v-else class="inline-flex">
            <span class="text-center" style="width: 33px">{{ $customFilters.time(item.startsAt) }}</span>
            <span class="mx-2">–</span>
            <span class="text-center" style="width: 33px">{{ $customFilters.time(item.endsAt) }}</span>
          </span>
        </time>
        <div class="self-stretch mr-2">
          <tooltip :content="item.category && item.category.name">
            <div
              class="h-6 rounded-full"
              :class="{ invisible: !item.category || !item.category.color }"
              style="width: 2px"
              :style="{ background: item.category && item.category.color }"
            ></div>
          </tooltip>
        </div>
        <main class="flex-grow pl-1">
          <p class="leading-snug">
            <tooltip v-if="item.confirmedByParticipant" :content="$t('widget.events.confirmedByParticipant')">
              <fa-icon name="badge-check" class="text-success mr-1" />
            </tooltip>
            {{ item.title }}
            <fa-icon v-if="item.recurring" name="repeat" class="ml-1 text-sm" :title="$t('widget.events.recurring')" />
            <comments-indicator
              resource-type="Task"
              :resource-id="item.id"
              :counter.sync="item.commentsCount"
              class="ml-1"
            />
          </p>
          <footer @click.stop class="text-sm">
            <router-link
              v-if="item.client"
              :to="$customFilters.clientPath(item.client.id, $db.broker)"
              @click.native.stop
            >
              {{ item.client.name }}
            </router-link>
            <nice-divider direction="vertical" v-if="item.client && item.property" />
            <router-link v-if="item.property" :to="`/portfolio/properties/${item.property.id}`" @click.native.stop>
              {{ item.property.name }}
            </router-link>
          </footer>
        </main>
        <avatar v-if="item.broker_id != $db.broker.id" :broker="item.broker" class="ml-2 shrink-0" size="24px" />
      </div>
    </section>
    <p v-if="!items.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.events") }}
    </p>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"
import CommentsIndicator from "@/components/CommentsIndicator"
import eventBus from "@/config/event-bus"
import { getDateRange } from "@/config/calendar/helpers"

export default {
  components: { WidgetContainer, CommentsIndicator },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
    }
  },
  methods: {
    async fetchData(loading = true) {
      if (this.loading) return
      this.loading = loading
      const { daysStart, daysEnd, categoryIds, brokerIds } = this.widget
      const { events } = await this.$graphql(
        `{
          events(start: $start, end: $end, brokerIds: $brokerIds, categoryIds: $categoryIds) {
            id title brokerId categoryId allDay
            state startsAt endsAt recurring
            commentsCount confirmedByParticipant
            clients(limit: 1) { id name }
            properties(limit: 1) { id name }
          }
        }`,
        {
          brokerIds: brokerIds && brokerIds.length ? brokerIds : [this.$db.broker.id],
          start: moment()
            .add(daysStart || 0, "days")
            .startOf("day")
            .format(),
          end: moment()
            .add(daysEnd || 0, "days")
            .endOf("day")
            .format(),
          categoryIds: categoryIds || [],
        }
      )

      this.items = _.orderBy(events, "startsAt", "asc")
        .filter(e => !categoryIds || !categoryIds.length || categoryIds.includes(e.categoryId))
        .map(event => ({
          ...event,
          broker: this.$db.shopData.brokers.find(b => b.id === event.brokerId),
          category: this.$db.shopData.eventCategories.find(b => b.id === event.categoryId),
          client: event.clients && event.clients.length ? event.clients[0] : undefined,
          property: event.properties && event.properties.length ? event.properties[0] : undefined,
          ...this.$util.objWithSnakeCaseComp(event),
        }))
      this.loading = false
    },
    open(item) {
      eventBus.$emit("quick-view", {
        type: "event",
        mode: "view",
        id: item.id,
        postfix: item.startsAt,
        params: { date: new Date(item.startsAt) },
      })
    },
    getSectionTitle(key) {
      return moment(key, "YYYY-MM-DD").calendar()
    },
  },
  computed: {
    groupedData() {
      return this.items.reduce((acc, o) => {
        const startsAt = moment(o.startsAt)
        const endsAt = moment(o.endsAt)
        const range = getDateRange(startsAt, endsAt)
        range.forEach(r => {
          const key = r.format("YYYY-MM-DD")
          acc[key] = acc[key] || []
          acc[key].push({
            ...o,
            startsAt:
              range.length > 1 && r > startsAt
                ? moment(r).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                : startsAt,
            endsAt:
              range.length > 1 && r.endOf("day") < endsAt
                ? moment(r).set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                : endsAt,
          })
        })
        return acc
      }, {})
    },
  },
  mounted() {
    this.fetchData()

    this.$pusher.on(`task:${this.$db.broker.id}:saved`, () => this.fetchData(false))
    this.$pusher.on(`task:${this.$db.broker.id}:deleted`, ({ id }) => {
      this.items = this.items.filter(o => o.id != id)
    })
  },
}
</script>
