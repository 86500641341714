<template>
  <el-dialog :title="title" :visible="visible" @update:visible="$emit('update:visible')" width="500px" append-to-body>
    <p class="mb-2 text-lg text-center font-medium">{{ $t("linkCopyModal.share") }}</p>
    <nice-input ref="input" :value="url" class="w-full" />
    <nice-button type="primary" @click="copy" class="mt-2 w-full">
      <fa-icon name="clipboard" class="mr-1" />
      {{ $t("linkCopyModal.copy") }}
    </nice-button>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
    },
    url: {
      type: String,
    },
  },
  methods: {
    copy() {
      this.$refs.input.select()
      document.execCommand("copy")
      App.flashy(this.$t("linkCopyModal.success"))
    },
  },
}
</script>