<template>
  <nice-select :value="value" @input="$emit('input', $event)" :options="options" v-bind="$attrs" />
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    options() {
      const array = [{ id: "me", name: "Mit mir geteilt" }]
      if (this.$db.broker.availableInboxes.length)
        array.push(...this.$db.broker.availableInboxes[0].smartFolders.map(sf => ({ ...sf, id: `sf-${sf.id}` })))
      return array
    },
  },
}
</script>