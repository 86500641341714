<template>
  <div v-if="loading" class="messages-empty">
    <span class="spinner spinner-sm"></span>
  </div>
  <div v-else-if="resource" class="app-panel">
    <div id="publishing-detail-container" class="app-panel-body scrollable">
      <nav class="py-3 px-4 mb-2 d-flex align-items-center justify-content-between">
        <div>
          <a href="#" @click.prevent="close" class="fs-18 mr-3 ml-1" style="position: relative; top: 2px">
            <fa-icon name="times" />
          </a>
        </div>
      </nav>
      <main class="flex">
        <div class="mr-auto my-1 ml-6 invisible" />
        <div class="py-0 pl-4 pr-6 w-11/12">
          <header class="form-group">
            <h3 class="notes-title">
              {{ $t("panes.publishingForm.title", { portal: resource.portal.name }) }}
            </h3>
          </header>

          <div class="mb-1" v-if="resource.brokerId">
            <label>{{ $t("panes.taskDetail.assignedTo") }}</label>
            <strong>{{ taskCreator.internalName }}</strong>
          </div>

          <div class="d-flex mb-2" v-if="resource.property">
            <span class="msg-participantLabel mr-2" style="line-height: 1.7">{{ $t("panes.taskDetail.linked") }}</span>
            <p>
              <router-link :to="resource.property.path" style="padding: 6px; line-height: 1.7">
                {{ resource.property.name }}
              </router-link>
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: Number },
  },
  data() {
    return {
      loading: true,
      resource: null,
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const { publishing } = await this.$graphql(
          `query publishing {
            publishing(id: $id) {
              id propertyId portalId publishDate brokerId postUrl
              property { path name }
              portal { name }
            }
          }`,
          { id: this.id }
        )

        this.resource = publishing
        this.loading = false
      } catch (e) {
        console.error(e)
        this.close()
      }
    },
    deleteTask() {
      return
    },
    close() {
      this.$emit("close")
    },
  },
  computed: {
    taskCreator() {
      return this.$db.shopData.brokers.find(o => o.id == this.resource.brokerId)
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
