<template>
  <div :class="{ disabled: !selected.length }" class="massActions-container">
    <p v-if="selected.length > 1" class="mr-2 footnote">{{ selected.length + $t("inbox.selected") }}</p>
    <mass-action-button
      class="massActions-button"
      v-if="isVisible('unarchive')"
      @click="actionSelected({ action: 'unarchive' })"
      icon="inbox"
      :title="$t('inbox.actions.unarchive')"
    />
    <mass-action-button
      v-if="isVisible('archive')"
      @click="actionSelected({ action: 'archive' })"
      :title="$t('inbox.actions.archive')"
      icon="archive"
    />
    <mass-action-button
      v-if="isVisible('mark_as_read')"
      @click="actionSelected({ action: 'mark_as_read' })"
      :title="$t('inbox.actions.markAsRead')"
      icon="envelope-open"
    />
    <mass-action-button
      v-if="isVisible('delete') && ($db.broker.canDeleteLinkedMessages || type == 'drafts')"
      @click="actionSelected({ action: 'delete' })"
      icon="trash-alt"
      :title="type == 'trash' ? $t('inbox.actions.deleteForever') : $t('inbox.actions.delete')"
    />
    <mass-action-button
      v-if="isVisible('restore')"
      @click="actionSelected({ action: 'restore' })"
      icon="undo"
      :title="$t('inbox.actions.restore')"
    />
    <select-popover
      v-if="isVisible('move') && folders.length > 0"
      :title="$t('inbox.actions.selectFolder')"
      :items="folders"
      :empty-option="type == 'smart_folders' ? $t('inbox.actions.remove') : null"
      :showSelectedIndicator="false"
      @click="f => actionSelected({ action: 'move', folder_id: f.id })"
    >
      <mass-action-button slot="reference" :title="$t('inbox.actions.move')" icon="folder" />
    </select-popover>
    <el-dropdown trigger="click" @command="actionSelected" placement="bottom">
      <mass-action-button icon="ellipsis-h" :title="$t('inbox.actions.moreActions')" />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="{ action: 'mark_as_unread' }">
          {{ $t("inbox.actions.markAsUnread") }}
        </el-dropdown-item>
        <el-dropdown-item :command="{ action: 'star' }">{{ $t("inbox.actions.favorite") }}</el-dropdown-item>
        <el-dropdown-item v-if="$db.broker.canBulkEdit" :command="{ action: 'edit' }">
          {{ $t("inbox.actions.edit") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <edit-form-dialog :visible.sync="editFormVisible" :selected="selected" />
  </div>
</template>

<script>
import MassActionButton from "./MassActionButton"
import EditFormDialog from "./EditFormDialog"

export default {
  props: ["type", "selected"],

  components: {
    MassActionButton,
    EditFormDialog,
  },

  data() {
    return {
      editFormVisible: false,
    }
  },

  methods: {
    isVisible(action) {
      const actions = {
        index: ["archive", "mark_as_read", "delete", "move"],
        sent: ["delete", "move"],
        favorites: ["archive", "delete"],
        spam: ["archive", "mark_as_read", "delete"],
        archived: ["unarchive", "mark_as_read", "delete"],
        trash: ["delete", "restore"],
        drafts: ["delete"],
        me: ["mark_as_read"],
        send_later: ["delete"],
        smart_folders: ["archive", "mark_as_read", "delete", "move"],
      }
      actions["inbox"] = actions["index"]
      return actions[this.type]?.includes(action) ?? false
    },
    actionSelected({ action = "move", ...payload }) {
      if (action == "edit") {
        this.editFormVisible = true
        return
      }

      this.$emit("trigger-action", action, payload)
    },
  },

  computed: {
    currentInbox() {
      const inboxBrokerId =
        Number(this.$route.query.inboxBrokerId) || this.$db.broker.availableInboxes.map(o => o.id)[0]
      return this.$db.broker.availableInboxes.find(i => i.id == inboxBrokerId)
    },
    folders() {
      return this.currentInbox ? this.currentInbox.smartFolders : []
    },
  },
}
</script>

<style scoped>
.massActions-container {
  display: flex;
  align-items: center;
}
</style>
