import { IIntegrationOrdersService } from "../types/integration-order-service"
import IntegrationOrdersService from "./integration-order-service"
import IntegrationService from "./integration-service"

export class ServiceProvider {
  public readonly integrations: typeof IntegrationService
  public readonly orders: IIntegrationOrdersService

  constructor() {
    this.integrations = IntegrationService
    this.orders = IntegrationOrdersService
  }
}

export default new ServiceProvider()
