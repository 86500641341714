import { debounce } from "debounce"
import { set, get, del } from "idb-keyval"

function pickBy(object, fn) {
  const obj = {}
  for (const key in object) {
    if (fn(key, object[key])) {
      obj[key] = object[key]
    }
  }
  return obj
}

export function saveStateToLocalStorage(key, data) {
  // console.log("saved stated to:", key, data);
  localStorage.setItem(key, JSON.stringify(data))
}

export function removeState(key) {
  localStorage.removeItem(key)
}

export function getSavedStateFromLocalStorage(key) {
  var savedState = localStorage.getItem(key)
  return savedState ? JSON.parse(savedState) : null
}

export function saveStateToIndexedDb(key, data) {
  set(key, data).catch(err => console.log("Cache set operation is failed!", err))
}

export function removeStateFromIndexedDb(key) {
  del(key).catch(err => console.log("Cache delete operation is failed!", err))
}

export function getSavedStateFromIndexedDb(key) {
  return get(key).catch(err => console.log("Cache get operation is failed!", err))
}

export default {
  watch: {
    $data: {
      handler: debounce(function () {
        this.saveState()
      }, 25),
      deep: true,
    },
  },

  created() {
    this.loadState()
  },

  methods: {
    loadState: function () {
      if (!this.saveStateConfig) return

      var savedState = getSavedStateFromLocalStorage(this.cacheKey)

      if (!savedState) return

      this.$withoutWatchers(() => {
        for (let [key, value] of Object.entries(savedState)) {
          if (this.attributeIsManagedBySaveState(key)) {
            if (this.saveStateConfig.onLoad) {
              value = this.saveStateConfig.onLoad(key, value)
            }

            this.$data[key] = value
          }
        }
      })
    },

    saveState: function () {
      const data = pickBy(this.$data, attr => this.attributeIsManagedBySaveState(attr))
      saveStateToLocalStorage(this.cacheKey, data)
    },

    attributeIsManagedBySaveState: function (attribute) {
      const config = this.saveStateConfig

      if (config.excludeProperties) return config.excludeProperties.includes(attribute)

      if (!config.saveProperties) return true

      return config.saveProperties.includes(attribute)
    },

    removeState: function () {
      removeState(this.cacheKey)
    },
  },

  computed: {
    saveStateConfig() {
      return {
        ...this.$options.saveStateConfig,
        cacheKey: `${this.$options.saveStateConfig.cacheKey}-${this.$db.broker.id}`,
      }
    },
    cacheKey() {
      return this.saveStateConfig.cacheKey
    },
  },
}
