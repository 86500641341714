<template>
  <tooltip v-if="availability" :content="availability.label">
    <fa-icon :name="availability.icon" />
  </tooltip>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"

export default Vue.extend({
  props: {
    broker: {
      type: Object as PropType<any>,
    },
  },
  computed: {
    availabilities(): any {
      return {
        Büro: { icon: "building", label: this.$t("header.availabilities.office") },
        "Home Office": { icon: "home", label: this.$t("header.availabilities.homeOffice") },
        "Im Urlaub": { icon: "tree-palm", label: this.$t("header.availabilities.vacation") },
        Krank: { icon: "thermometer", label: this.$t("header.availabilities.sick") },
      }
    },
    availability(): any {
      return this.availabilities[this.broker.availability]
    },
  },
})
</script>
