<template>
  <settings-section
    :id="collection"
    ref="body"
    :title="headline"
    :description="description"
    :loading="showLoadingIndicator && loading"
  >
    <template #description>
      <slot name="description" />
    </template>
    <slot name="header" :items="items" />
    <alert v-if="items && !items.length && (emptyState || $slots['empty-state'])" class="mb-3">
      <slot name="empty-state">{{ emptyState }}</slot>
    </alert>
    <draggable :list="items" @change="updateOrder" handle=".handle" v-if="type === 'draggable'">
      <settings-list-item
        v-for="item in filteredItems"
        :key="item.id"
        class="mb-2"
        @edit="openEditForm(item)"
        @delete="deleteItem(item)"
        :show-actions="showActions(item)"
        :sortable="sortable"
      >
        <template v-slot:icon>
          <slot name="icon" :item="item"></slot>
        </template>
        <template v-slot:title>
          <slot name="name" :item="item">
            {{ item.name }}
          </slot>
        </template>
        <template v-slot:actions>
          <slot name="actions" v-bind="{ item, edit: () => openEditForm(item), remove: () => deleteItem(item) }" />
        </template>
        <template v-slot:after>
          <slot name="after" :item="item"></slot>
        </template>
      </settings-list-item>
    </draggable>
    <categorizable
      :list="filteredItems"
      :property="categorize"
      :title-formatter="categorizeFormatter"
      v-else-if="type === 'categorizable'"
    >
      <template v-slot="{ items }">
        <settings-list-item
          v-for="item in items"
          :key="item.id"
          class="mb-2"
          @edit="openEditForm(item)"
          @delete="deleteItem(item)"
          :show-actions="showActions(item)"
          :sortable="sortable"
        >
          <template v-slot:icon>
            <slot name="icon" :item="item"></slot>
          </template>
          <template v-slot:title>
            <slot name="name" :item="item">
              {{ item.name }}
            </slot>
          </template>
          <template v-slot:actions>
            <slot name="actions" v-bind="{ item, edit: () => openEditForm(item), remove: () => deleteItem(item) }" />
          </template>
          <template v-slot:after>
            <slot name="after" :item="item"></slot>
          </template>
        </settings-list-item>
      </template>
    </categorizable>

    <slot name="add" :openForm="openNewForm" :addItem="addItem">
      <ps-button
        class="mt-3 max-w-none"
        active
        @click="openNewForm"
        icon="plus"
        :title="$t('settings.create', { label: label })"
      />
    </slot>
    <form-dialog
      :name="label"
      :id="editing && editing.id"
      :visible="!!editing"
      :submit-func="save"
      @close="closeEditForm"
      @complete="handleSave"
      :saving="saving"
      :width="formWidth"
      :disabled="disabled"
      :validate="() => validate(editing)"
      :footer-note="
        editing && items && items.find(o => o.id == editing.id) && items.find(o => o.id == editing.id).updatedAt
          ? $t('settings.lastEdited', { date: $customFilters.datetime(items.find(o => o.id == editing.id).updatedAt) })
          : ''
      "
    >
      <slot name="form" v-if="editing" v-bind="{ item: editing, saving }"></slot>
    </form-dialog>

    <form-dialog
      :close-on-click-modal="false"
      :visible.sync="resolve.visible"
      :title="$t('settings.stillInUse', { label: label })"
      :saving="resolve.saving"
      :submit-func="migrateItem"
      :disabled="resolve.radio === undefined"
      width="500px"
      :submitButtonTitle="$t('settings.confirmAndDelete')"
      button-type="danger"
      @complete="migrationComplete"
    >
      <form-section>
        <form-row class="break-normal">
          <alert class="mb-2">
            {{ $t("settings.settingsList.warning", { error: resolve.error }) }}
          </alert>
        </form-row>
        <form-row :title="$t('settings.dataDecision')" direction="column">
          <nice-radio-group
            v-model="resolve.radio"
            :options="[
              { id: 0, name: $t('settings.deleteEverywhere', { label: label }) },
              { id: 1, name: migrateTranslations.assign },
            ]"
          />
        </form-row>
        <form-row :title="migrateTranslations.new" :class="{ invisible: resolve.radio !== 1 }">
          <nice-select
            v-model="resolve.nextId"
            :placeholder="$t('settings.settingsList.pleaseSelect')"
            :options="resolveItemsCollection"
          />
        </form-row>
      </form-section>
    </form-dialog>
  </settings-section>
</template>

<script>
import SettingsListItem from "./ListItem"
import Categorizable from "./Categorizeable"

const singularize = word => {
  if (_.endsWith(word, "Statuses")) {
    return word.replace(/Statuses$/, "Status")
  } else if (_.endsWith(word, "ies")) {
    return word.replace(/ies$/, "y")
  } else {
    return word.replace(/s$/, "")
  }
}

export default {
  components: { SettingsListItem, Categorizable },
  props: {
    type: {
      type: String,
      default: "draggable",
    },
    init: {
      type: Function,
      required: false,
    },
    replace: {
      type: Function,
      required: false,
    },
    categorize: {
      type: String,
      required: false,
    },
    categorizeFormatter: {
      type: Function,
      required: false,
    },
    collection: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      required: false,
    },
    value: {
      type: Array,
      required: false,
    },
    headline: String,
    description: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: () => "Eintrag",
    },
    filterList: {
      type: Function,
      default: items => items,
    },
    formatPayload: {
      type: Function,
      default: payload => payload,
    },
    showActions: {
      type: Function,
      default: _ => true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    initialFormData: {
      required: true,
    },
    formWidth: {
      type: String,
      default: "700px",
    },
    omittedKeys: {
      type: Array,
      default: () => ["id"],
    },
    emptyState: {
      type: String,
      required: false,
    },
    hasMigrateTo: {
      type: Boolean,
      required: false,
    },
    migrateMutationName: {
      type: String,
      required: false,
    },
    migrateMutationParams: {
      type: Object,
    },
    migrationItems: {
      type: Array,
      required: false,
    },
    readonlyKeys: {
      type: Array,
      default: () => [],
    },
    customFetchPartial: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLoadingIndicator: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Function,
      default: () => true,
    },
    optimisticSort: {
      type: String,
    },
    onItemsChange: {
      type: Function,
    },
  },
  watch: {
    value() {
      this.items = this.value
    },
    $route() {
      if (this.$route.query[this.collection]) {
        const found = this.items.find(n => n.id == this.$route.query[this.collection])
        if (found) this.openEditForm(found)
      } else {
        this.closeEditForm()
      }
      if (this.$route.hash) {
        this.scrollToElement(this.$route.hash)
      }
    },
  },
  data() {
    return {
      activeCollapse: null,
      items: this.value,
      editing: null,
      saving: false,
      resolve: {
        visible: false,
      },
      loading: false,
      currentItem: null,
    }
  },
  async mounted() {
    if (this.query && !this.value) this.fetchData()
    else {
      setTimeout(() => {
        this.maybeOpenDialog()
      }, 500)
    }
    if (this.$route.hash) {
      setTimeout(() => {
        this.scrollToElement(this.$route.hash)
      }, 500)
    }
  },
  computed: {
    collectionSingular() {
      return _.upperFirst(singularize(this.collection))
    },
    filteredItems() {
      return this.filterList(this.items || [])
    },
    resolveItemsCollection() {
      if (!this.resolve || !this.resolve.item) return []
      const items = this.migrationItems || this.items
      return items.filter(i => i.id !== this.resolve.item.id)
    },
    migrateTranslations() {
      return this.$t("admin.content.migrate")[this.collection] || {}
    },
  },
  methods: {
    refresh() {
      this.items = [...this.items]
    },
    async fetchData() {
      this.loading = true
      const { shop } = await this.$graphql(`{ shop { ${this.query} } }`)
      let items = shop[this.collection]
      if (this.init) {
        items = this.init(items)
      }
      this.loading = false
      this.items = items
      this.maybeOpenDialog()
      this.onItemsChange?.(this.items)
    },
    openNewForm() {
      this.editing = JSON.parse(JSON.stringify(this.initialFormData))
    },
    openEditForm(item) {
      this.editing = JSON.parse(
        JSON.stringify(_.pick(item, Object.keys(this.initialFormData).concat(["id"]).concat(this.readonlyKeys)))
      )
      if (this.$route.query[this.collection] != item.id)
        this.$router.push({ query: { ...this.$route.query, [this.collection]: item.id } })
    },
    closeEditForm() {
      this.$emit("close")
      this.editing = null
      if (this.$route.query[this.collection])
        this.$router.push({ query: { ...this.$route.query, [this.collection]: undefined } })
    },
    maybeOpenDialog() {
      if (this.$route.query[this.collection]) {
        const found = this.items.find(n => n.id == this.$route.query[this.collection])
        if (found) this.openEditForm(found)
      }
    },
    async updateOrder(event) {
      const order = this.items.map(item => item.id)
      await this.$api.sort(this.collectionSingular, order)
      this.onItemsChange?.(this.items)
    },
    async handleSave(data) {
      if (this.editing.id) {
        this.items = this.items.map(o =>
          o.id == this.editing.id ? (!!this.replace ? this.replace(data, o) : data) : o
        )
        App.flashy(this.$t("settings.processed", { label: this.label }))
      } else {
        this.items.push(data)
        App.flashy(this.$t("settings.created", { label: this.label }))
      }
      if (this.init && !this.replace) this.items = this.init(this.items)
      if (this.optimisticSort) this.items = _.sortBy(this.items, this.optimisticSort)
      this.$emit("save", { editing: this.editing, items: this.items, data })
      this.editing = null
      this.onItemsChange?.(this.items)
    },
    addItem(newItem) {
      this.items.push(newItem)
      if (this.optimisticSort) this.items = _.sortBy(this.items, this.optimisticSort)
      this.onItemsChange?.(this.items)
    },
    close() {
      this.editing = null
      this.saving = false
    },
    async save() {
      this.saving = true
      const payload = this.formatPayload(
        _.omit(this.editing, this.omittedKeys, this.readonlyKeys),
        _.pick(this.editing, this.omittedKeys, this.readonlyKeys)
      )
      const responsePayload = this.query.replace(/(?:\r\n|\r|\n)/g, " ").match(/{.+}/)[0]
      let res
      try {
        if (this.editing.id) {
          res = await this.$api.update(this.collectionSingular, this.editing.id, payload, responsePayload)
        } else {
          res = await this.$api.create(this.collectionSingular, payload, responsePayload)
        }
        this.customFetchPartial ? this.customFetchPartial() : this.$db.fetchPartialData(this.collection)
      } catch (err) {
        throw err
      } finally {
        this.saving = false
      }
      return res
    },
    migrateItem() {
      return this.$api.mutation(this.migrateMutationName || `migrate${this.collectionSingular}`, {
        id: this.resolve.item.id,
        nextId: this.resolve.nextId,
        ...this.migrateMutationParams,
      })
    },
    migrationComplete() {
      this.items = this.items.filter(it => it.id !== this.resolve.item.id)
      App.flashy(this.$t("settings.deleted", { label: this.label }))
      this.$db.fetchPartialData(this.collection)
      this.$emit("delete", { items: this.items, data: this.resolve.item })
      this.resolve = {
        visible: false,
      }
      this.onItemsChange?.(this.items)
    },
    deleteItem(item) {
      this.$confirm(this.$t("settings.settingsList.deleteBody"), this.$t("settings.settingsList.deleteTitle"), {
        confirmButtonText: this.$t("settings.settingsList.confirmDelete"),
        cancelButtonText: this.$t("warnPlugin"),
        type: "warning",
      })
        .then(() => {
          this.$api
            .destroy(this.collectionSingular, item.id)
            .then(_ => {
              this.items = this.items.filter(it => it.id !== item.id)
              App.flashy(this.$t("settings.deleted", { label: this.label }))
              this.$db.fetchPartialData(this.collection)
              this.$emit("delete", { items: this.items, data: item })
              this.onItemsChange?.(this.items)
            })
            .catch(e => {
              if (this.hasMigrateTo) {
                this.resolve = { item, error: e.error_messages.join(", "), visible: true }
              } else {
                this.$axios.handleError(e)
              }
            })
        })
        .catch(() => {})
    },
    scrollToElement(hash) {
      const el = this.$refs.body.$refs.body
      if (el && el.id == hash.substring(1)) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.handle {
  cursor: grab;
  padding: 6px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}
</style>
