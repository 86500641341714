<template>
  <div class="searchFilter">
    <dynamic-filter
      v-for="filter in filterSet"
      :filter-field-names="filterFieldNames"
      :key="filter.key"
      :filter="filter"
      :columns="activeColumns"
      :filterType="entity"
      @remove="removeFilter"
      @update="updateFilters"
    />
    <p v-if="filterSet.length <= 0" class="searchFilter-empty">{{ $t("filter.empty") }}</p>
    <footer class="searchFilter-footer">
      <button class="searchFilter-add" @click.prevent="addFilter">
        <fa-icon name="plus" />
        {{ $t("filter.add") }}
      </button>
    </footer>
  </div>
</template>
<script lang="ts">
import Vue from "vue"
import DynamicFilter from "@/components/DynamicFilter.vue"
import moment from "moment-timezone"
import { getPropertyColumns } from "@/utils/get-property-columns"
import { getClientColumns } from "@/utils/get-client-columns"
import { getActivityColumns } from "@/utils/get-activity-columns"
import { getPluralizedName } from "@/utils/get-pluralized-name"
import { buildQuery } from "@/mixins/elastic-filters"

const COLUMNS_MAP = {
  client: getClientColumns,
  property: getPropertyColumns,
  activity: getActivityColumns,
}

export default Vue.extend({
  props: {
    value: {
      type: Object,
    },
    entity: {
      type: String,
    },
  },
  data(props: any): any {
    const keyBase = Date.now()
    return {
      filterSet: props?.value?.filterSet?.map((f, i) => ({ ...f, key: f.key || keyBase + i })) ?? [],
    }
  },
  components: { DynamicFilter },
  computed: {
    filterFieldNames(): string[] {
      return this.filterSet.map(o => o.fieldName)
    },
    columnsFunction(): (db: any) => any[] {
      return COLUMNS_MAP[this.entity]
    },
    pluralizedEntity(): string {
      return getPluralizedName(this.entity)
    },
    columns(): any[] {
      return this.columnsFunction(this.$db).map(c => ({
        ...c,
        label: c.isCustomField
          ? c.label
          : this.$t(`${this.pluralizedEntity}.formFields.${c.value.replace(/\.raw|\.keyword|\.id/, "")}`),
      }))
    },
    activeColumns(): any[] {
      return this.columns.filter(c => c.dependsOn?.(this.valuesKvp) ?? true)
    },
    valuesKvp(): Record<string, any> {
      return this.filterSet.reduce((agg, cur) => {
        agg[cur.fieldName] = cur.value
        return agg
      }, {})
    },
    filters(): any {
      return buildQuery(this.filterSet, this.$db)
    },
  },
  methods: {
    updateFilters(esData) {
      this.filterSet = _.orderBy(this.filterSet.filter(f => f.key !== esData.key).concat([esData]), "key")
      this.$emit("input", { filterSet: this.filterSet, filters: this.filters })
    },

    addFilter() {
      this.filterSet = this.filterSet.concat([{ key: Date.now() }])
      this.$emit("input", { filterSet: this.filterSet, filters: this.filters })
    },

    removeFilter(key) {
      this.filterSet = this.filterSet.filter(f => key !== f.key)
      this.$emit("input", { filterSet: this.filterSet, filters: this.filters })
    },
  },
})
</script>
