<template>
  <el-select
    ref="select"
    :value="value"
    @input="$emit('input', $event)"
    @change="changed"
    multiple
    filterable
    v-cancel-read-only
    remote
    allow-create
    :placeholder="placeholder"
    default-first-option
    :remote-method="fetch"
    :loading="loading"
    :size="size"
  >
    <el-option
      v-for="item in searchResults"
      :key="item.email"
      :label="item.name + ' (' + ($root.backdoor ? 'Redacted' : item.email) + ')'"
      :disabled="!item.email"
      :value="item.email"
    >
      <p class="pull-left">
        {{ item.name }}
        <tooltip
          v-if="item.warnAboutContactAcceptance"
          :content="$t('emailSelect.missingContactPermission')"
          class="ml-1"
        >
          <fa-icon name="exclamation-triangle" class="text-red-400" />
        </tooltip>
      </p>
      <p v-redacted class="pull-right light m-r-30">
        {{ item.email ? item.email : $t("emailSelect.missing") }}
      </p>
    </el-option>
  </el-select>
</template>

<script>
import Fuse from "fuse.js"
import * as _ from "lodash"

let brokerSearch = null

export default {
  props: {
    value: {},
    emails: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      searchResults: [],
      selectedItems: [],
      loading: false,
    }
  },
  methods: {
    changed($event) {
      this.$refs.select.query = ""
      const selected = this.searchResults.filter(i => $event.includes(i.email))
      this.selectedItems = _.uniqBy(
        this.selectedItems.filter(i => $event.includes(i.email)).concat(selected),
        i => i.email
      )
      this.$emit("update-recipients", selected)
    },
    select(filter) {
      let selected = this.selectedItems.filter(i => this.value.includes(i.email))
      selected = selected.filter(i => filter(i))
      this.selectedItems = selected
      this.$emit(
        "input",
        selected.map(e => e.email)
      )
      this.$emit("update-recipients", selected)
    },
    fetch(q) {
      if (!q) return

      this.loading = true

      return this.$axios.getWithCancel(`/services/contacts?q=${q}`).then(({ data }) => {
        const emails = this.emails
          .filter(email => _.includes(email, q))
          .map(email => ({ email, name: email, type: "email" }))
        const brokers = brokerSearch
          .search(q)
          .map(result => ({ email: result.item.email, name: result.item.name, type: "broker", id: result.item.id }))

        const departments = this.departments
          .filter(d => _.includes(d.name.toLowerCase(), q.toLowerCase()))
          .map(d => ({
            label: this.$t("emailSelect.label", { name: d.name, emails: d.emails.length }),
            emails: d.emails,
            email: d.id,
            name: d.name,
            type: "department",
            id: d.id,
          }))

        this.loading = false
        data = _.flatten(
          data.map(client =>
            client.emails.map(email => ({
              name: client.name,
              email,
              type: "client",
              id: client.id,
              warnAboutContactAcceptance: this.$db.shopData.gdprCompliantMailSending && !client.accept_contact,
            }))
          )
        )
        this.searchResults = _.uniqBy(brokers.concat(data).concat(emails).concat(departments), "email")
      })
    },
  },
  mounted() {
    if (!brokerSearch)
      brokerSearch = new Fuse(
        this.$db.shopData.brokers.filter(b => !b.archived),
        {
          keys: ["name", "email"],
          threshold: 0.1,
        }
      )

    if (this.autofocus) {
      this.$refs.select.visible = true
    }

    // prefill options, in order to select other email adresses from client
    if (this.value.length > 0) {
      this.fetch(this.value[0]).then(() => {
        const selected = this.searchResults.filter(i => this.value.includes(i.email))
        this.$emit("update-recipients", selected)
      })
    }
  },
}
</script>

<style>
.red-label .el-select__tags span .el-tag {
  background-color: rgba(255, 0, 0, 0.267);
}
</style>
