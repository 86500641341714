<template>
  <div>
    <div class="mb-1" v-if="resource.dueDate">
      <label class="mr-1"><fa-icon name="clock" /></label>
      <span>{{ $customFilters.datetime(resource.dueDate) }}</span>
    </div>

    <slot />
    <section class="py-2" v-if="resource.canAccess || canWrite">
      <p class="mb-2">
        <strong>{{ $t("task.decisionDetails.decision") }}</strong>
        <i @click="reset" v-if="resource.decisionOptionId && canWrite">
          <fa-icon name="times" class="cursor-pointer text-danger ml-1" />
        </i>
      </p>
      <div class="btn-group btn-group-toggle" :disabled="!canWrite">
        <nice-radio-group v-model="resource.decisionOptionId" style="max-width: 471px">
          <nice-radio
            :border="true"
            v-for="decision in resource.decisionOptions"
            :key="decision.id"
            :label="decision.id"
            class="truncate w-min mr-1 ml-0 mb-1"
            style="max-width: inherit"
          >
            {{ decision.name }}
          </nice-radio>
        </nice-radio-group>
      </div>
    </section>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  props: {
    resource: {},
  },
  computed: {
    canWrite() {
      return (
        this.$db.broker.admin ||
        this.$db.broker.departmentIds.includes(this.resource.departmentId) ||
        this.resource.brokerId == this.$db.broker.id ||
        this.resource.brokers.map(b => b.id).includes(this.$db.broker.id)
      )
    },
  },
  watch: {
    "resource.decisionOptionId": function (newValue) {
      this.$api
        .mutation(
          "toggleTaskCompletion",
          { taskId: this.resource.id, decisionOptionId: newValue },
          "followupResourceType"
        )
        .then(({ followupResourceType }) => {
          if (followupResourceType) {
            eventBus.$emit("quick-view", {
              type: followupResourceType,
              mode: "edit",
              params:
                followupResourceType == "message"
                  ? { source: { taskId: this.resource.id } }
                  : { followupTaskId: this.resource.id },
            })
          }
        })
        .catch(this.$axios.handleError)
    },
  },
  methods: {
    reset() {
      this.resource.decisionOptionId = null
    },
  },
}
</script>
