<template>
  <dropdown-section v-if="$root.enterprise">
    <dropdown-item :title="$t('worklists.actions.create')" @click="open" />
    <dropdown-item :title="$t('worklists.actions.add')" @click="openAdd" :class="{ disabled: worklists.length <= 0 }" />
    <dropdown-item
      :title="$t('worklists.actions.remove')"
      @click="openSub"
      :class="{ disabled: worklists.length <= 0 }"
    />
    <worklist-form :visible.sync="modalVisible" :selected="selected" :entity="entity" />
    <worklist-update-modal
      :visible.sync="updateModalVisible"
      :subtracting="updateModalForSubtracting"
      :selected="selected"
      :entity="entity"
      @clear-selected="$emit('clear-selected')"
    />
  </dropdown-section>
</template>

<script>
import WorklistForm from "./WorklistForm"
import WorklistUpdateModal from "./WorklistUpdateModal"

export default {
  components: { WorklistForm, WorklistUpdateModal },
  props: {
    selected: {
      type: Array,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      updateModalVisible: false,
      updateModalForSubtracting: false,
    }
  },

  methods: {
    open() {
      this.modalVisible = true
    },
    openAdd() {
      this.updateModalVisible = true
      this.updateModalForSubtracting = false
    },
    openSub() {
      this.updateModalVisible = true
      this.updateModalForSubtracting = true
    },
  },
  computed: {
    worklistType() {
      return this.entity == "for_properties" ? "propertyWorklists" : "worklists"
    },
    worklists() {
      return this.$store.getters[`searchFilter/${this.worklistType}`]
    },
  },
}
</script>