<template>
  <a href="#" class="result-item standard-hover" @click.prevent="$emit('click', client)">
    <div class="result-item-body">
      <p>
        <strong>{{ client.name }}</strong>
      </p>
      <p class="light">{{ client.email }}</p>
    </div>
    <div class="result-item-indicator">
      <fa-icon name="plus" class="icon-indicator" />
    </div>
  </a>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
}
</script>