<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({ setup() {} })
</script>
<script setup lang="ts">
import { IntegrationBilling } from "@/integrations/types/integration-service"
import { formatCurrency } from "@/utils/formatters/currency"
import useI18n from "@/plugins/use-i18n"
import { computed } from "vue"
import useCore from "@/plugins/use-core"
import { get } from "@vueuse/core"

const props = defineProps<{
  billing?: IntegrationBilling
}>()

const { t } = useI18n()
const DEFAULT_BILLING: IntegrationBilling = { price: 0, currency: "EUR" }

// fallback for the UI to display a free integration
const billing = computed(() => props.billing ?? DEFAULT_BILLING)
const isFree = computed(() => !get(billing)?.price) // not set or 0

const costString = computed(() => {
  const _billing = get(billing)
  if (!_billing) return
  return [
    formatCurrency(_billing.price ?? 0, { currency: _billing.currency ?? "EUR" }),
    _billing.interval ? t(`integrations.billing.interval.${_billing.interval.toLowerCase()}`) : undefined,
  ]
    .filter(Boolean)
    .join(" / ")
})

const { locale } = useI18n()
</script>
<template>
  <div>
    {{ isFree ? t("integrations.billing.nocost") : costString }}
    <span className="ml-1" v-if="billing.remark">({{ billing.remark[locale] }})</span>
  </div>
</template>
