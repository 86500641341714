<template>
  <db-select
    :value="brokerOrDepartmentId"
    collection="activeBrokers"
    @change="assignedChanged"
    :additional-options="additionalBrokerOptions"
  />
</template>

<script>
export default {
  props: {
    value: {},
  },
  methods: {
    assignedChanged(newValue) {
      if (!newValue) {
        this.$emit("input", { departmentId: null, brokerId: null })
      } else {
        if (typeof newValue === "string" && newValue.startsWith("d")) {
          this.$emit("input", { departmentId: parseInt(newValue.substr(1)), brokerId: null })
        } else {
          this.$emit("input", { departmentId: null, brokerId: newValue })
        }
      }
      this.$emit("change")
    },
  },
  computed: {
    brokerOrDepartmentId() {
      return this.value?.brokerId || (this.value?.departmentId ? `d${this.value?.departmentId}` : null)
    },
    additionalBrokerOptions() {
      let departments = this.$db.shopData.departments.map(d => ({
        id: `d${d.id}`,
        type: "department",
        internalName: d.name,
      }))
      return departments
    },
  },
}
</script>
