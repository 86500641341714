<template>
  <el-popover
    ref="popover"
    placement="bottom"
    :visible-arrow="false"
    trigger="click"
    width="240"
    transition="none"
    v-model="visible"
    @show="$emit('open')"
  >
    <a slot="reference">
      <avatar :broker="resource.broker" class="cursor-pointer" />
    </a>
    <main @click="visible = false">
      <dropdown-item v-if="this.$db.shopData.brokers.length > 20">
        <nice-input ref="input" :placeholder="$t('brokerDropdownSearch.search')" v-model="searchBroker" />
      </dropdown-item>
      <dropdown-section style="max-height: 250px">
        <dropdown-item
          v-for="broker in activeBrokers"
          :key="broker.id"
          :title="broker.internalName"
          @click="$emit('broker-change', broker)"
          style="padding: 0 10px"
        >
          <avatar :broker="broker" size="20px" style="margin-right: 10px" />
          {{ broker.internalName }}
        </dropdown-item>
        <p v-if="!activeBrokers.length" class="text-gray-500" style="padding: 5px 10px">
          {{ $t("brokerDropdownSearch.empty") }}
        </p>
      </dropdown-section>
    </main>
  </el-popover>
</template>

<script>
export default {
  props: ["resource"],
  data() {
    return {
      visible: false,
      inputFocus: false,
      searchBroker: "",
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.searchBroker = ""
        if (this.$db.shopData.brokers.length > 20) this.focus()
      }
    },
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
  },
  computed: {
    activeBrokers() {
      return this.searchBroker
        ? this.$db.shopData.brokers
            .filter(b => b.active)
            .filter(b =>
              this.searchBroker ? b.internalName.toLowerCase().includes(this.searchBroker.toLowerCase()) : b
            )
        : this.$db.shopData.brokers.filter(b => b.active)
    },
  },
}
</script>

<style scoped>
main {
  max-height: 80vh;
  overflow: auto;
}
</style>