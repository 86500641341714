<template>
  <nav class="page-sidebar bg-gray-50">
    <div class="sidebar-menu">
      <sidebar-menu :menu="menu" :show-one-child="true" @pin-item="fetchPins" />
    </div>
  </nav>
</template>

<script>
import InboxBrokerSelect from "../Inbox/InboxBrokerSelect.vue"
import i18n from "@/i18n/index.ts"

const lastVisitedPaths = {
  client: ({ id, db }) => `/contacts/clients/${id}` + (!db.broker.clientsOpenInDetails ? "/conversation" : ""),
  property: ({ id }) => `/portfolio/properties/${id}`,
  project: ({ id }) => `/portfolio/projects/${id}`,
}
const lastVisitedIcons = { client: "address-card", property: "home", project: "building" }

const renderLastVisited = ({ name, id, type }, db, sidebarPins) => ({
  href: lastVisitedPaths[type]({ id, db }),
  title: name,
  icon: `fal fa-${lastVisitedIcons[type]}`,
  lastVisited: true,
  sidebarPins: sidebarPins,
  id: id,
  type: type,
})

const getCrmMenu = ({
  inboxBrokerId,
  db,
  lastVisited,
  smartFolders,
  inboxCounters,
  sidebarPins,
  global,
  todosView,
}) => {
  const unreadMessagesCount = db.broker.unreadMessagesCount
  const uncompletedTodos = db.broker.uncompletedTodos
  const inboxes = db.broker.availableInboxes
  const inboxBrokerPath = global
    ? "?global=true"
    : inboxBrokerId && (!Array.isArray(inboxBrokerId) || inboxBrokerId.length > 0)
    ? `?inboxBrokerId=${inboxBrokerId}`
    : ""

  let inboxId
  if (!global && (!inboxBrokerId || inboxBrokerId.length < 2)) {
    inboxId = `${inboxBrokerId?.length ? inboxBrokerId[0] : db.broker.id}`
  }
  const { folderMap, customFolderRoots } =
    db.broker.inboxMenuItemsByBroker[inboxId] || db.broker.inboxMenuItemsByBroker["default"]
  const modifyHref = !inboxBrokerPath
    ? items => items
    : items => {
        items.forEach(item => Object.assign(item, { href: item["href"].split("?")[0] + inboxBrokerPath }))
        return items
      }
  const todosPipelineId = sessionStorage.getItem("kanbanPipelineId") || db.shopData.taskPipelines[0]?.id
  const todosPath =
    todosView === "kanban" && todosPipelineId ? `/tasks/todos/kanban/${Number(todosPipelineId)}` : "/tasks/todos"

  return db.planActive("pro", "reshare")
    ? [
        {
          header: true,
          title: i18n.t("sidebar.header"),
        },
        {
          href: "/dashboard",
          title: i18n.t("sidebar.dashboard"),
          icon: "fal fa-tachometer-alt-average",
        },
        {
          href: "/mailbox/messages" + inboxBrokerPath,
          title: i18n.t("sidebar.mailbox"),
          icon: "fal fa-envelope",
          hiddenOnCollapse: false,
          child: (inboxes && inboxes.length > 1 ? [{ component: InboxBrokerSelect }] : []).concat(
            modifyHref(
              [
                !!folderMap["%inbox%"]?.["child"]?.length ? folderMap["%inbox%"] : undefined,
                folderMap["%sent%"],
                {
                  href: `/mailbox/messages/favorites` + inboxBrokerPath,
                  title: i18n.t("sidebar.favorites"),
                  icon: "fal fa-star",
                  badge: {
                    attributes: { "data-count": inboxCounters.favoritesCount },
                    class: "details star",
                  },
                },
                Object.assign(folderMap["%spam%"], {
                  badge: {
                    attributes: { "data-count": inboxCounters.spamCount },
                    class: "details text-light",
                  },
                }),
                folderMap["%archive%"],
                folderMap["%trash%"],
                Object.assign(folderMap["%draft%"], {
                  badge: {
                    attributes: { "data-count": inboxCounters.draftsCount },
                    class: "details text-light",
                  },
                }),
                ...customFolderRoots,
                ...(db.shopData.brokers.length > 1
                  ? [
                      {
                        href: `/mailbox/messages/me`,
                        title: i18n.t("sidebar.me"),
                        icon: "fal fa-user",
                        badge: {
                          attributes: { "data-count": inboxCounters.assignedCount },
                          class: "details text-light",
                        },
                      },
                    ]
                  : []),
                ...smartFolders.map(({ id, name, color }) => ({
                  href: `/mailbox/smart_folders/${id}`,
                  title: name,
                  icon: { class: "fal fa-folder-download", attributes: { style: `color: ${color}` } },
                  badge: {
                    attributes: {
                      "data-count": inboxCounters.smartFolderCounts && inboxCounters.smartFolderCounts[id.toString()],
                    },
                    class: "details",
                  },
                })),
              ].filter(Boolean)
            )
          ),
          badge: {
            attributes: {
              "data-count":
                inboxCounters.unreadInboxCount == undefined ? unreadMessagesCount : inboxCounters.unreadInboxCount,
            },
            class: "details",
          },
        },
        {
          href: "/contacts/clients",
          title: i18n.t("sidebar.clients"),
          icon: "fal fa-address-card",
        },
        {
          href: "/calendar",
          title: i18n.t("sidebar.calendar"),
          icon: "fal fa-calendar-alt",
        },
        {
          href: "/opportunities",
          title: i18n.t("sidebar.opportunities"),
          icon: "fal fa-dollar-sign",
        },
        ...(db.extensionEnabled("commissionsplit") && (db.broker.commissionBeneficiary || db.broker.admin)
          ? [
              {
                href: "/portfolio/commissions",
                title: i18n.t("sidebar.commissions"),
                icon: "fal fa-badge-percent",
              },
            ]
          : []),
        {
          href: "/portfolio/properties",
          title: i18n.t("sidebar.properties"),
          icon: "fal fa-home",
        },
        ...(db.shopData.hiddenTabsInSidebar?.includes("projects")
          ? []
          : [
              {
                href: "/portfolio/projects",
                title: i18n.t("sidebar.projects"),
                icon: "fal fa-building",
              },
            ]),
        ...(db.shopData.externalReports && db.shopData.externalReports.length
          ? [
              {
                href: "/external-reports",
                title: i18n.t("sidebar.reports"),
                icon: "fal fa-chart-bar",
              },
            ]
          : []),
        ...(db.shopData.hiddenTabsInSidebar?.includes("mailings")
          ? []
          : [
              {
                href: "/marketing/mailings",
                title: i18n.t("sidebar.mailings"),
                icon: "fal fa-bullhorn",
              },
            ]),

        {
          component: { template: `<div class="m-t-15"></div>` },
        },
        {
          href: todosPath,
          title: i18n.t("sidebar.todos"),
          icon: "fal fa-check",
          badge: {
            attributes: { "data-count": uncompletedTodos || 0, id: "todos-counter" },
            class: "details red",
          },
        },
        {
          href: "/contacts/feed",
          title: i18n.t("sidebar.activities"),
          icon: "fal fa-list-alt",
        },
        {
          header: true,
          title: i18n.t("sidebar.lastSeen"),
        },
        ...(lastVisited ? lastVisited.map(e => renderLastVisited(e, db, sidebarPins)) : []),
      ]
    : [
        {
          header: true,
          title: i18n.t("sidebar.header"),
        },
        {
          href: "/portfolio/properties",
          title: i18n.t("sidebar.properties"),
          icon: "fal fa-home",
        },
        {
          href: "/immoscout24",
          title: i18n.t("sidebar.immoscout24"),
          icon: "fal fa-plug",
        },
        {
          header: true,
          title: i18n.t("sidebar.lastSeen"),
        },
        ...(lastVisited ? lastVisited.map(e => renderLastVisited(e, db, sidebarPins)) : []),
      ]
}

export default {
  data() {
    return {
      inboxCounters: {},
      sidebarPins: [],
      todosView: "todos",
    }
  },
  watch: {
    $route(to) {
      const { path } = this.$route
      if (path.startsWith("/tasks/todos")) {
        this.updateTodosCurrentView()
      }
      if (path.startsWith("/mailbox/messages")) {
        this.fetchMessages()
      }
    },
  },
  computed: {
    menu() {
      const { inboxCounters, lastVisited } = this
      const currentInbox = this.$db.broker.availableInboxes.find(i => i.id == this.inboxBrokerId)
      const smartFolders = !this.$route.query.global && currentInbox ? currentInbox.smartFolders || [] : []

      return getCrmMenu({
        db: this.$db,
        inboxBrokerId:
          this.inboxBrokerId.length > 1 || this.inboxBrokerId[0] !== this.$db.broker.id
            ? this.inboxBrokerId
            : undefined,
        lastVisited,
        inboxCounters,
        sidebarPins: this.sidebarPins,
        smartFolders,
        global: this.$route.query.global,
        todosView: this.todosView,
      })
    },
    inboxBrokerId() {
      return !!this.$route.query.inboxBrokerId
        ? this.$route.query.inboxBrokerId.split(",").filter(Boolean).map(Number)
        : [this.$db.broker.availableInboxes.map(o => o.id)[0]].filter(Boolean)
    },
    lastVisited() {
      const items = this.sidebarPins
        .concat(
          this.$store.state.lastVisited.items.filter(
            i => !this.sidebarPins.some(p => p.modelId == i.id && p.modelType == i.type)
          )
        )
        .slice(0, 5)
      return items.map(i => ({
        name: i.title || i.name,
        id: i.modelId ? Number(i.modelId) : i.id,
        type: i.modelType || i.type,
      }))
    },
  },
  mounted() {
    this.fetchPins()
    if (this.$route.path.startsWith("/mailbox/messages")) this.fetchMessages()
  },
  methods: {
    updateTodosCurrentView() {
      this.todosView = sessionStorage.getItem("todosView")
    },
    updateValues(data) {
      this.values = { ...this.values, ...data }
    },
    async fetchPins() {
      const { broker } = await this.$graphql(
        `{
          broker {
            sidebarPins { title modelId modelType }
          }
        }`
      )
      this.sidebarPins = broker.sidebarPins || []
    },
    async fetchMessages() {
      const { broker } = await this.$graphql(
        `{
          broker {
            messagesCounter(brokerIds: $brokerIds) {
              favoritesCount spamCount draftsCount assignedCount unreadInboxCount smartFolderCounts
            }
          }
        }`,
        {
          brokerIds: this.$route.query.global ? this.$db.broker.availableInboxes.map(o => o.id) : this.inboxBrokerId,
        }
      )

      this.inboxCounters = broker.messagesCounter
    },
  },
}
</script>

<style>
.sidebar-menu {
  overflow-y: auto;
}
</style>
