import i18n from "@/i18n"

const DEFAULT_FOLDERS = [
  {
    id: "%inbox%",
    path: "inbox",
    icon: "fal fa-envelope-open",
  },
  {
    id: "%sent%",
    path: "sent",
    icon: "fal fa-paper-plane",
  },
  {
    id: "%spam%",
    path: "spam",
    icon: "fal fa-exclamation-triangle",
  },
  {
    id: "%archive%",
    path: "archive",
    icon: "fal fa-archive",
  },
  {
    id: "%trash%",
    path: "trash",
    icon: "fal fa-trash-alt",
  },
  {
    id: "%draft%",
    path: "draft",
    icon: "fal fa-file",
  },
]

export const calculateInboxMenuItems = db => {
  i18n.locale = db.broker.locale
  return db.broker.availableInboxes.concat([{ id: "default" }]).reduce((agg, currentInbox) => {
    const folders = DEFAULT_FOLDERS.concat(currentInbox?.mailboxFolders ?? []).map(folder =>
      Object.assign({}, folder)
    ) as any[]
    const folderMap = folders.reduce((agg, cur) => {
      agg[cur.id] = cur
      return agg
    }, {})
    const customFolderRoots = [] as any[]
    folders.forEach(cur => {
      if (cur["parentId"]) {
        const parent = folderMap[cur["parentId"]]
        if (!!parent) (parent["child"] = parent["child"] || []).push(cur)
      } else if (!cur.id?.match(/^%.+%$/)) {
        customFolderRoots.push(cur)
        cur["icon"] = "fal fa-folder"
      }
      cur["icon"] = cur["icon"] || (cur["parentId"] ? "fal fa-folder" : "fal fa-envelope")
      cur["title"] = cur["label"] || cur["label"] || i18n.t(`sidebar.${cur["path"]}`)
      cur["href"] = `/mailbox/messages/${cur["path"]}`
    })
    agg[`${currentInbox.id}`] = { folderMap, customFolderRoots, folders }
    return agg
  }, {}) as Record<string, any>
}
