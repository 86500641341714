import ElementUI from "element-ui"
import i18n from "@/i18n/index"

export default (options, cb, catchCb = () => {}) => {
  ElementUI.MessageBox.confirm(
    options.desc, options.title, {
      confirmButtonText: options.confirmButtonText,
      cancelButtonText: options.cancelButtonText || i18n.t('warnPlugin'),
      type: "warning",
      confirmButtonClass: options.redButton ? "bg-red-500 border-red-500 hover:bg-red-400 hover:border-red-400 focus:bg-red-400 focus:border-red-400" : undefined,
    }
  )
  .then(cb)
  .catch(catchCb)
}