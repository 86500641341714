<template>
  <div>
    <div class="mb-2">
      <el-select filterable v-cancel-read-only v-model="ta.payload.todoTypeId" placeholder="Kategorie auswählen">
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </div>
    <div class="mb-2">
      <el-select filterable v-cancel-read-only v-model="ta.payload.dueDate" placeholder="Fälligkeit">
        <el-option v-for="item in dueDates" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </div>
  </div>
</template>

<script>
import { MOMENTS } from "@/config/followup-activity"

export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },

  computed: {
    options() {
      return this.shopData.todoCategories
    },
    dueDates() {
      const mom = MOMENTS.map(m => ({
        ...m,
        label: this.$t(`activityTypes.followupActivity.moments.${m.label}`, { days: m.days }),
      }))
      return mom
    },
  },
}
</script>
