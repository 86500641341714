<template>
  <import-csv
    @update:modalVisible="$emit('update:modalVisible', $event)"
    :schema="schema"
    :validate-async="validateAsync"
    :submit-async="submitAsync"
    type="property"
    v-bind="$attrs"
    example-url="https://pro-propstack-public.s3.eu-west-1.amazonaws.com/helpcenter/Objekte.csv"
    :store-key="`properties-csv-${$db.broker.id}`"
  />
</template>

<script lang="ts">
import ImportCsv from "@/components/ImportCsv.vue"
import { ALLOWED_HEADERS } from "@/config/property"
import Vue from "vue"

const OTHER_FIELDS = [
  "region",
  "datensatznummer",
  "te_nummer",
  "provision",
  "innenprovision",
  "flur",
  "wohnungsnummer",
  "amtsgericht",
  "grundbuchblatt",
  "grundbuchamt_von",
  "verkauft_am",
  "miteigentumsanteil",
  "energieausstellungsdatum",
  "energiegueltigbis",
  "teilungserklaerungs_nr",
  "auftrag_von",
  "auftrag_bis",
  "laengengrad",
  "breitengrad",
  "nutzflaeche_wohnen",
  "gesamtflaeche",
  "gartenflaeche",
  "vermietbare_flaeche",
  "gewerbeflaeche",
  "verkaufsflaeche",
  "buero_praxisflaeche",
  "teilbar_ab",
  "preis_qm",
  "nicht_umlegbare_kosten",
  "laenge_der_garage",
  "breite_der_garage",
  "hoehe_der_garage",
  "vermittlungspreis",
  "instandhaltungsruecklage",
  "co2_emissionen",
  "grz",
  "gfz",
  "etage",
  "etagenzahl",
  "is24_gruppennummer",
  "loggia",
  "einbaukueche",
  "alarmanlage",
  "gaeste_wc",
  "personenaufzug",
  "wbs_erforderlich",
  "kamin",
  "sauna",
  "pool",
  "wintergarten",
  "klimaanlage",
  "kauf_miete",
  "auftragsart",
  "preistyp",
  "bad",
  "propstack_id",
  "kategorie",
  "art",
  "unterkategorie",
  "projekt_id",
  "betreuer_id",
  "betreuer_e_mail",
  "betreuer",
  "merkmale",
  "eigentuemer",
  "eigentuemer_datensatznummer",
  "eigentuemer_email",
  "deal_phase",
  "deal_benutzer_id",
  "deal_datum",
  "deal_preis",
  "bilder",
  "dokumente",
  "portal_ansprechpartner_vorname",
  "portal_ansprechpartner_nachname",
  "portal_ansprechpartner_e_mail",
  "portal_ansprechpartner_telefon",
]

const SCHEMA = ALLOWED_HEADERS.concat(OTHER_FIELDS)

const customFieldKey = "customFieldGroupsForProperties"

export default Vue.extend({
  components: {
    ImportCsv,
  },
  computed: {
    schema(): { id: string; name: string }[] {
      return [
        ...SCHEMA.map(r => ({ id: r, name: this.$t(`clients.csv.modal.columns.property.${r}`) as string })),
        ...this.$db.shopData[customFieldKey]
          .flatMap(g => g.customFields)
          .map(r => ({ id: `cf_${r.name}`, name: r.prettyName })),
        ...this.$db.shopData.relationshipPartnerNames.map(r => ({ id: r.id, name: r.name })),
      ]
    },
  },
  methods: {
    validateAsync(rows) {
      return this.$api
        .mutation(
          "validatePropertyImportJson",
          { json: rows },
          "jsonImportValidation { unknownHeaders unknownValues { invalidValues jsonHeader } }"
        )
        .then(data => data.jsonImportValidation)
        .catch(this.$axios.handleError)
    },
    submitAsync(rows) {
      return this.$axios.post("/portfolio/import/csv", {
        rows: rows,
      })
    },
  },
})
</script>
