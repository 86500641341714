<template>
  <Print :open="open" :styles="styles" :title="message.subject" v-on:close="close">
    <div class="page">
      <div id="message_headers">
        <dl>
          <dt>{{ $t("messagePrint.from") }}</dt>
          <dd>{{ message.from.email }}</dd>
        </dl>

        <dl>
          <dt>{{ $t("messagePrint.re") }}</dt>
          <dd>
            <strong>{{ message.subject }}</strong>
          </dd>
        </dl>

        <dl>
          <dt>{{ $t("messagePrint.date") }}</dt>
          <dd>{{ date(message.createdAt) }}</dd>
        </dl>

        <dl>
          <dt>{{ $t("messagePrint.to") }}</dt>
          <dd>{{ list(message.to) }}</dd>
        </dl>

        <dl v-if="message.cc && message.cc.length">
          <dt>Cc:</dt>
          <dd>{{ list(message.cc) }}</dd>
        </dl>
        <dl v-if="message.messageAttachments && message.messageAttachments.length">
          <dt>{{ $t("messagePrint.att") }}</dt>
          <dd
            v-html="
              message.messageAttachments.map(y => y.name).join(`
            <br>
          `)
            "
          ></dd>
        </dl>
      </div>
      <br />
      <div v-html="message.body"></div>
    </div>
  </Print>
</template>

<script>
import Print from "@/components/Print"

export default {
  model: {
    prop: "open",
    event: "change",
  },
  props: {
    message: { type: Object },
    open: { type: Boolean },
  },
  components: {
    Print,
  },
  methods: {
    date(date) {
      return moment(date).format("LLL")
    },
    close() {
      this.$emit("update:open", false)
    },
    list(array) {
      return array.map(e => e.email).join(", ")
    },
  },
  data() {
    return {
      styles: ["/print.css"],
    }
  },
}
</script>
