<template>
  <p class="py-2 px-2 -mx-2 rounded font-mono text-sm standard-hover flex justify-between">
    <span>
      {{ $customFilters.date(priceChange.createdAt) }}
      <span v-if="broker">von {{ broker.name }}</span>
    </span>
    <span>
      {{ $customFilters.numberToCurrency(priceChange.price, currentCurrency) }}
    </span>
  </p>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { PriceChange } from "../../interfaces"

const CURRENCY_MAP = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  CHF: "CHF",
  HUF: "Ft",
  AED: "AED",
  euro: "€",
  chf: "CHF",
  aed: "AED",
}

export default Vue.extend({
  props: {
    priceChange: {
      type: Object as PropType<PriceChange>,
    },
    currency: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    broker(): Object | undefined {
      return this.$db.get("brokers").find(o => o.id == this.priceChange.brokerId)
    },
    currentCurrency(): String {
      return CURRENCY_MAP[this.currency || this.$db.shopData.isoCurrency]
    },
  },
})
</script>