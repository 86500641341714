<template>
  <form-dialog
    :visible="visible"
    :title="$t('properties.exportPortalDialog.title')"
    @submit="submit"
    @update:visible="$emit('update:visible', $event)"
    :submitButtonTitle="$t('properties.exportPortalDialog.submit')"
    width="500px"
    :showFooter="exportSelectedPortal !== 'ALL'"
    :disabled="exportSelectedPortal === ''"
    footer-type="bigButton"
    :autofocus="false"
  >
    <form-section>
      <form-row :title="$t('properties.exportPortalDialog.portals')" direction="column">
        <nice-select v-model="exportSelectedPortal" :options="selectablePortals" />
      </form-row>
    </form-section>

    <form-section v-if="exportSelectedPortal == 'ALL'">
      <form-row>
        <nice-button type="primary" class="w-100" @click="publishToAll(true)">
          {{ $t("properties.exportPortalDialog.allPortals") }}
        </nice-button>
      </form-row>
      <form-row>
        <nice-button type="danger" class="w-100" @click="publishToAll(false)">
          {{ $t("properties.exportPortalDialog.delFromAll") }}
        </nice-button>
      </form-row>
    </form-section>

    <form-section v-else>
      <form-row v-if="portalFromOpenimmo" :title="$t('properties.exportPortalDialog.mode')" direction="column">
        <nice-select v-model="openimmoOption" :options="openimmoOptions" />
      </form-row>
      <template v-if="exportSelectedPortal && !portalFromOpenimmo">
        <form-row :title="$t('properties.exportPortalDialog.sharePortal')">
          <input type="checkbox" value="portal" v-model="is24SelectedChannels" />
        </form-row>
        <form-row :title="$t('properties.exportPortalDialog.sharePage')">
          <input type="checkbox" value="homepage" v-model="is24SelectedChannels" />
        </form-row>
        <form-row :title="$t('properties.exportPortalDialog.scoutDel')">
          <input type="checkbox" value="1" v-model="is24Delete" />
        </form-row>
      </template>
    </form-section>
  </form-dialog>
</template>

<script>
export default {
  props: ["visible", "propertyIds"],
  data() {
    return {
      is24SelectedChannels: [],
      is24Delete: false,
      exportSelectedPortal: "",
      portalFromOpenimmo: false,
      openimmoOption: "ONLINE",
    }
  },

  watch: {
    exportSelectedPortal: {
      handler(selectedPortal) {
        this.portalFromOpenimmo =
          selectedPortal && selectedPortal !== "ALL" && !this.is24Portals.includes(selectedPortal)
      },
    },

    is24SelectedChannels: {
      handler(selectedPortals) {
        if (selectedPortals.length > 0) {
          this.is24Delete = false
        }
      },
    },

    is24Delete: {
      handler(checked) {
        if (checked) {
          this.is24SelectedChannels = []
        }
      },
    },
  },

  methods: {
    submit() {
      const data = {
        property_ids: this.propertyIds,
        connection_id: this.exportSelectedPortal,
        openimmo_option: this.openimmoOption,
        is24_portal: this.is24SelectedChannels.includes("portal"),
        is24_homepage: this.is24SelectedChannels.includes("homepage"),
        is24_delete: this.is24Delete,
      }

      this.$axios
        .post("/api/v1/export/cart/export", data)
        .then(() => {
          this.$emit("update:visible", false)
          App.flashy(this.$t("properties.exportPortalDialog.success"))
          this.$emit("update:propertyIds", [])
        })
        .catch(this.$axios.handleError)
    },

    publishToAll(active) {
      this.$axios
        .post("/export/publish/all", {
          property_ids: this.propertyIds,
          active,
        })
        .then(() => App.flashy(this.$t("properties.exportPortalDialog.successAll")))
        .catch(this.$axios.handleError)
      this.$emit("update:propertyIds", [])
      this.$emit("update:visible", false)
    },
  },

  computed: {
    openimmoOptions() {
      return {
        ONLINE: this.$t("properties.exportPortalDialog.options.online"),
        DELETE: this.$t("properties.exportPortalDialog.options.del"),
        VOLLABGLEICH: this.$t("properties.exportPortalDialog.options.match"),
      }
    },
    portals() {
      return this.$db.shopData.connections
    },
    is24Portals() {
      return this.portals.filter(p => p.format === "IS24").map(p => p.id)
    },
    selectablePortals() {
      return this.portals
        .filter(o => !o.parentConnectionId)
        .concat([{ id: "ALL", name: this.$t("properties.exportPortalDialog.options.all") }])
    },
  },
}
</script>