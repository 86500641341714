<template>
  <div class="app-panel">
    <columns-modal
      :visible.sync="columns.dialogVisible"
      :columns.sync="columns.data"
      @change="columnVisibleChanged"
      translate-for="activities"
      :reset="resetColumns"
    />

    <smart-view-form-dialog
      :visible.sync="smartViewFormVisible"
      :smart-views="smartViews"
      :params="formParams"
      entity="for_activities"
      :has-select="true"
      @saved="smartViewSaved"
      @cancel="smartViewFormVisible = false"
      :selected="selectedSmartView"
      :editId.sync="smartViewEditId"
    />

    <el-dialog
      :title="$t('activities.trackings')"
      :visible="tracking.modalVisible"
      @update:visible="trackingModalClosed"
      width="500px"
      class="trackings-dialog"
    >
      <div class="popover-body">
        <trackings v-if="tracking.messageId" :message-id="tracking.messageId" />
      </div>
    </el-dialog>

    <div class="tableActions">
      <div class="tableActions-group">
        <search-input v-model="q" :placeholder="$t('activities.search')" />

        <smart-view-popover
          v-if="smartViews.length > 0"
          :disabled="smartViewsLoading"
          :smartViews.sync="smartViews"
          @change="smartViewSelected"
          @reset="resetView"
          entity="for_activities"
          :selectedSmartViewId.sync="selectedSmartView"
          @edit="
            smartViewEditId = $event
            smartViewFormVisible = true
          "
        />

        <filter-popover :width="600" :reset-filters="resetView" :active-filters="activeFilters">
          <div class="searchFilter">
            <popover-filter-row
              :title="$t('activities.linkedContact')"
              v-if="clientView && allRelatedClients.length > 0"
              :class="{ disabled: params.clientChildrenId }"
            >
              <el-select
                filterable
                multiple
                v-cancel-read-only
                v-model="relatedClientIds"
                :placeholder="$t('activities.linkedContactPh')"
                size="small"
              >
                <el-option
                  v-for="client in allRelatedClients"
                  :key="client.id"
                  :label="client.name"
                  :value="client.id"
                />
              </el-select>
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.subcontact')" v-if="clientView && childrenClients.length > 0">
              <el-select
                filterable
                v-cancel-read-only
                clearable
                v-model="params.clientChildrenId"
                :placeholder="$t('activities.subcontactPh')"
                size="small"
              >
                <el-option v-for="client in childrenClients" :key="client.id" :label="client.name" :value="client.id" />
              </el-select>
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.date')">
              <date-picker
                v-model="params.createdRange"
                type="daterange"
                format="dd.MM.yyyy"
                :placeholder="$t('activities.datePh')"
                class="full-width"
                size="small"
              />
            </popover-filter-row>

            <popover-filter-row v-if="!projectView && !dealView" :title="$t('activities.projects')">
              <project-select multiple v-model="params.projects" />
            </popover-filter-row>

            <popover-filter-row v-if="!propertyView && !dealView" :title="$t('activities.properties')">
              <property-select multiple v-model="params.properties" />
            </popover-filter-row>

            <popover-filter-row v-if="!clientView && !dealView" :title="$t('activities.contacts')">
              <client-select multiple v-model="params.clients" disable-locked />
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.type')">
              <nice-select
                :placeholder="$t('activities.typePh')"
                v-model="params.itemTypes"
                :options="$t('activities.itemTypes')"
                multiple
              />
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.inquiries')" v-if="params.itemTypes.includes('inquiries')">
              <db-select
                multiple
                :placeholder="$t('activities.inquiriesPh')"
                v-model="params.sourceIds"
                collection="clientSources"
              />
            </popover-filter-row>

            <popover-filter-row v-if="hasMessageActivityType" :title="$t('activities.senderType')">
              <nice-select
                :placeholder="$t('activities.senderTypePh')"
                v-model="params.senderType"
                :options="{
                  '1': $t('activities.senderTypeOptions.incoming'),
                  '2': $t('activities.senderTypeOptions.outgoing'),
                }"
              />
            </popover-filter-row>

            <popover-filter-row
              v-if="hasReminderActivityType || hasMessageActivityType"
              key="doneSelect"
              :title="$t('activities.done')"
            >
              <nice-select
                :placeholder="$t('activities.donePh')"
                v-model="params.done"
                :options="{ true: $t('activities.doneOptions.true'), false: $t('activities.doneOptions.false') }"
                @clear="params.done = null"
              />
            </popover-filter-row>

            <popover-filter-row v-if="categoriesFilterEnabled" :title="$t('activities.category')" key="categorySelect">
              <nice-select
                multiple
                v-model="params.categories"
                :options="selectableCategories"
                :placeholder="$t('activities.categoryPh')"
              />
            </popover-filter-row>

            <popover-filter-row v-if="hasReminderActivityType" :title="$t('activities.woDueDate')">
              <div class="mt-1">
                <nice-checkbox v-model="params.withoutDueDate" />
              </div>
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.cancellation')" v-if="hasCancelationActivityType">
              <db-select multiple v-model="params.reasons" collection="cancelationReasons" />
            </popover-filter-row>

            <popover-filter-row key="groupIds" v-if="groups.length > 0" :title="$t('activities.tags')">
              <db-select
                multiple
                v-model="params.group_ids"
                collection="formattedActivityGroups"
                label="activityGroups"
              />
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.startRange')" v-if="hasEventActivityType">
              <date-picker
                v-model="params.startsRange"
                type="daterange"
                format="dd.MM.yyyy"
                :placeholder="$t('activities.datePh')"
                class="full-width"
              />
            </popover-filter-row>

            <popover-filter-row
              key="brokerId"
              v-if="$db.brokerCanSeeActivityOverview() && (!hasReminderActivityType || $db.broker.canSeeTodos)"
              :title="$t('activities.assignedTo')"
            >
              <db-select
                v-model="brokerIds"
                collection="activeBrokers"
                multiple
                :additional-options="additionalBrokerOptions"
              />
            </popover-filter-row>

            <popover-filter-row
              key="creatorId"
              v-if="$db.brokerCanSeeActivityOverview()"
              :title="$t('activities.createdBy')"
            >
              <db-select v-model="params.creator" collection="activeBrokers" />
            </popover-filter-row>

            <popover-filter-row
              key="responseBroker"
              v-if="$db.brokerCanSeeActivityOverview()"
              :title="$t('activities.supervisor')"
            >
              <db-select v-model="params.responsibleBroker" collection="activeBrokers" />
            </popover-filter-row>

            <popover-filter-row
              multiple
              v-model="params.team_ids"
              v-if="$db.shopData.tenantTeamActivitiesAssignment && $db.brokerCanSeeActivityOverview()"
              :title="$t('activities.teams')"
            >
              <db-select
                v-model="params.team_ids"
                collection="teams"
                multiple
                :placeholder="$t('activities.teamsPh')"
              />
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.withAttachments')">
              <div class="mt-1">
                <nice-checkbox v-model="params.onlyWithAttachments" />
              </div>
            </popover-filter-row>

            <popover-filter-row :title="$t('activities.includeAllVariants')" v-if="canIncludeAllVariants">
              <nice-checkbox v-model="params.includeAllVariants" class="mt-1" />
            </popover-filter-row>

            <popover-filter-row
              :title="$t('activities.hideDuplicates')"
              v-if="$db.shopData.activeFeatures.includes('duplicate_activities_filter')"
            >
              <div class="mt-1">
                <nice-checkbox v-model="params.unique" />
              </div>
            </popover-filter-row>
          </div>

          <footer class="flex justify-end">
            <button class="searchFilter-save" @click="smartViewFormVisible = true">
              {{ $t("activities.saveAsSmartView") }}
            </button>
          </footer>
        </filter-popover>

        <sort-button :sort-by.sync="params.sortBy" :order.sync="params.order" :sort-options="translatedSortOptions" />
      </div>

      <div class="tableActions-group">
        <dropdown v-if="!clientView" :class="{ disabled: selected.length <= 0 }">
          <template v-slot:title>
            {{ $t("activities.actions.button", { selected: selected.length }) }}
            <fa-icon name="angle-down" class="ml-1" />
          </template>
          <dropdown-section>
            <dropdown-item
              :title="$t('activities.actions.edit')"
              v-if="$db.broker.canBulkEdit"
              @click="massEditDialogVisible = true"
            />
            <dropdown-item
              v-if="$db.broker.admin"
              :title="$t('activities.actions.delete')"
              class="text-danger"
              @click="deleteDialogVisible = true"
            />
          </dropdown-section>
        </dropdown>
        <clients-actions-dropdown
          v-if="!clientView"
          :selected.sync="selectedClientIds"
          :title="$t('activities.actions.contact')"
        />
        <ps-button v-if="$db.broker.canExport" :title="$t('activities.actions.export')" @click="download" />
        <dropdown v-if="feedView">
          <dropdown-section>
            <dropdown-item :title="$t('activities.actions.expandColumns')" @click="columns.dialogVisible = true" />
          </dropdown-section>
        </dropdown>
      </div>
    </div>

    <mass-edit-dialog
      :title="$t('activities.actions.edit')"
      resourceType="activity"
      :fields="editableFields"
      :visible.sync="massEditDialogVisible"
      :selected="selected"
      @submitted="updateActvities"
    />

    <activities-mass-delete-dialog
      :visible.sync="deleteDialogVisible"
      :selected="selectedItems"
      @submitted="activitiesDeleted"
    />

    <ps-data-table
      :data="data"
      :columns="tableColumns"
      :per.sync="per"
      :page.sync="currentPage"
      :total="total"
      :page-sizes="[20, 100, 200]"
      :selected="clientView ? undefined : selectedItems"
      @update:selected="selectedItems = $event"
      :loading="loading"
      :unique-row="highlightedRows"
      should-select-items
      :current-sort="{ sortBy: params.sortBy, order: params.order }"
      :show-future-seperator="({ sortBy }) => !loading && sortBy === 'created_at'"
    >
      <template v-slot:icon="{ resource }">
        <p style="margin-left: 10px">
          <activities-icon :resource="resource" :interactive="resource.access_level !== 'list'" />
        </p>
      </template>
      <template v-slot:title="{ resource, setSelectedRowIndex }">
        <a href="#" @click.prevent="visitResource(resource, setSelectedRowIndex)" class="link">
          {{
            resource.title ||
            (resource.conversation_type == "message" ? $t("activities.noSubject") : $t("activities.noTitle"))
          }}
          <sub-tasks-indicator
            v-if="resource.sub_tasks && resource.sub_tasks.length > 0"
            class="mr-1"
            :resourceId="resource.activatable_id"
            :subTasks.sync="resource.sub_tasks"
          />
          <span class="ml-1" v-if="['Message', 'Task'].indexOf(resource.activatable_type) > -1">
            <comments-indicator
              :resource-type="resource.activatable_type"
              :resource-id="resource.activatable_id"
              :counter.sync="resource.comment_size"
            />
          </span>
          <Label
            v-if="
              resource.conversation_type == 'policy' &&
              resource.policy_category == 'buyer_order' &&
              resource.policy_read &&
              !resource.policy_accept_service
            "
            :label="$t('activities.verified')"
            color="#ff0000"
            mini
          />
        </a>
        <nice-badge
          v-if="resource.taskStage"
          :value="resource.taskStage.name"
          :style="`background-color: ${resource.taskStage.color}`"
          subType="taskStage"
        ></nice-badge>
        <div
          v-if="
            (params.properties?.length <= 0 && resource.property_names.length > 0) ||
            (params.projects?.length <= 0 && resource.project)
          "
          class="fs-12"
        >
          <router-link
            v-if="params.properties?.length <= 0 && resource.property_names.length > 0"
            :to="'/portfolio/properties/' + resource.property_ids[0]"
            class="mr-2"
          >
            {{ resource.property_names[0] }}
          </router-link>
          <router-link
            v-if="params.properties?.length <= 0 && params.projects?.length <= 0 && resource.project"
            :to="'/portfolio/projects/' + resource.project.id"
          >
            {{ resource.project.name }}
          </router-link>
        </div>
        <p class="text-sm text-gray-600 note-body" v-if="resource.body" v-html="resource.body"></p>
      </template>
      <template v-slot:client="{ resource }">
        <p v-if="resource.client_names && resource.client_names.length > 0">
          <router-link :to="$customFilters.clientPath(resource.client_ids[0], $db.broker)" class="underline link">
            {{ resource.client_names[0] }}
          </router-link>
        </p>
        <p v-if="resource.client_names && resource.client_names.length > 1">
          <router-link :to="$customFilters.clientPath(resource.client_ids[1], $db.broker)" class="underline link">
            {{ resource.client_names[1] }}
          </router-link>
        </p>
        <p v-if="resource.client_size > 2" class="footnote">
          +
          {{
            $customFilters.pluralize(
              resource.client_size - 2,
              $t("activities.moreContact"),
              $t("activities.moreContacts")
            )
          }}
        </p>
      </template>
      <template v-slot:property="{ resource }">
        <div>
          <router-link
            v-if="resource.property_names && resource.property_names.length > 0"
            :to="'/portfolio/properties/' + resource.property_ids[0]"
            class="underline link"
          >
            {{ resource.property_names[0] }}
          </router-link>
          <router-link
            v-if="
              (!resource.property_names || resource.property_names.length <= 0) &&
              params.projects.length <= 0 &&
              resource.project
            "
            :to="'/portfolio/projects/' + resource.project.id"
            class="underline link"
          >
            {{ resource.project.name }}
          </router-link>
        </div>
      </template>
      <template v-slot:category="{ resource }">
        <Label
          v-if="resource.category && resource.category.name"
          :label="resource.category.name"
          :color="resource.category.color"
        />
        <span v-else></span>
      </template>
      <template v-slot:trackings="{ resource }">
        <a href="#" v-if="resource.tracking_size > 0" @click.prevent="openTrackingsModal(resource.activatable_id)">
          <strong>
            <fa-icon name="bolt" />
          </strong>
        </a>
      </template>
      <template v-slot:attachments="{ resource }">
        <span v-if="resource.attachments_count > 0">
          <fa-icon name="paperclip" />
          {{ resource.attachments_count }}
        </span>
      </template>
      <template v-slot:date="{ resource }">
        <date-cell :datetime="resource.date" />
      </template>
      <template v-slot:broker="{ resource }">
        <avatar v-if="resource.broker" :broker="resource.broker" />
      </template>
      <template v-slot:original_created_at="{ resource }">
        <date-cell :datetime="resource.original_created_at" />
      </template>
      <template v-slot:client_broker_ids="{ resource }">
        <broker-avatars :brokerIds="resource.client_broker_ids" />
      </template>
    </ps-data-table>
  </div>
</template>

<script>
import { debounce } from "debounce"
import SaveStateMixin, { getSavedStateFromLocalStorage } from "@/mixins/save-state-mixin"
import CommentsIndicator from "@/components/CommentsIndicator"
import SubTasksIndicator from "@/components/SubTasksIndicator"
import ActivitiesIcon from "@/components/activities/Icon"
import Trackings from "@/components/Trackings"
import SmartViewPopover from "@/components/SmartViewPopover"
import SmartViewFormDialog from "@/components/search-filters/FormDialog"
import ColumnsModal from "@/components/ColumnsModal"
import FilterPopover from "@/components/FilterPopover.vue"
import eventBus from "@/config/event-bus"
import MassEditDialog from "@/components/MassEditDialog"
import ActivitiesMassDeleteDialog from "@/components/ActivitiesMassDeleteDialog"
import CustomColumnMixin, { formatColumns } from "@/mixins/custom-columns"
import { getCustomActivityColumns } from "@/utils/get-custom-activity-columns"

const SORT_OPTIONS = [{ value: "original_created_at" }, { value: "created_at" }]

const formatFeedRow = (data, options) =>
  data.map(row => {
    let category
    if (row.source_id) {
      category = options.clientSources.find(o => o.id === row.source_id)
    } else if (row.conversation_type === "message") {
      category = options.messageCategories.find(o => o.id === row.category_id)
    } else if (row.reason_id) {
      category = options.cancelationReasons.find(o => o.id === row.reason_id)
    } else if (row.conversation_type === "sms") {
      category = { name: "SMS", color: "#777777" }
      row.title = _.truncate(row.title, { length: 80 })
    } else if (row.conversation_type === "call") {
      category = { name: "Telefonat", color: "#777777" }
    } else {
      category = options.noteTypes.find(o => o.id === row.category_id)
    }

    return {
      ...row,
      category,
      date: row.starts_at ?? row.created_at,
      broker: options.brokers.find(o => row.broker_id === o.id),
      project: options.projects.find(o => row.project_ids.includes(o.id)),
      taskStage: options.taskStages.find(o => row.stage_id === o.id),
    }
  })

const formatParams = (val, $db) => {
  let departments = $db.shopData.departments
  const vars = departments.reduce((a, v) => ({ ...a, [`{{ department_${v.id} }}`]: v.brokerIds }), {
    "{{ broker_id }}": $db.broker.id,
  })
  if (vars[val] && vars[val].length > 1) return vars[val]
  const filteredVal = Array.isArray(val) ? val.filter(v => v !== "empty") : val !== "empty" ? val : []
  return Array.isArray(filteredVal)
    ? filteredVal.map(v => vars[v] || v).flat()
    : vars[filteredVal].flat() || filteredVal.flat()
}

const extractDepartments = val => {
  return (Array.isArray(val) ? val : [val])
    .filter(str => typeof str === "string")
    .map(str => parseInt(str?.match(/{{ department_(\d+) }}/)?.[1]))
    .filter(Boolean)
}

const reminderFilters = (orFilters, payload) => {
  if (payload.itemTypes.includes("reminder")) {
    const reminderFilters = []
    if (payload.done == "true") {
      reminderFilters.push({ bool: { must: [{ term: { done: true } }, { term: { conversation_type: "reminder" } }] } })
    }

    if (payload.done == "false") {
      reminderFilters.push({
        bool: {
          must: [{ bool: { must_not: { exists: { field: "done" } } } }, { term: { conversation_type: "reminder" } }],
        },
      })
    }

    if (payload.withoutDueDate) {
      reminderFilters.push({
        bool: {
          must: [
            { bool: { must_not: { exists: { field: "starts_at" } } } },
            { term: { conversation_type: "reminder" } },
          ],
        },
      })
    }

    if (reminderFilters.length === 0) {
      reminderFilters.push({ bool: { must: { term: { conversation_type: "reminder" } } } })
    }
    return [...orFilters, ...reminderFilters]
  }

  return orFilters
}

const messageFilters = (orFilters, payload) => {
  if (payload.itemTypes.includes("message")) {
    return [
      ...orFilters,
      {
        bool: {
          must: [
            payload.senderType ? { term: { sender_type: payload.senderType } } : null,
            { term: { conversation_type: "message" } },
            payload.done ? { term: { done: payload.done == "true" } } : null,
          ].filter(Boolean),
        },
      },
    ]
  }
  return orFilters
}

const cancelationFilters = (orFilters, payload) => {
  if (payload.itemTypes.includes("cancelation")) {
    if (payload.reasons.length > 0) {
      return [
        ...orFilters,
        { bool: { must: [{ terms: { reason_id: payload.reasons } }, { term: { conversation_type: "cancelation" } }] } },
      ]
    } else {
      return [...orFilters, { bool: { must: { term: { conversation_type: "cancelation" } } } }]
    }
  }
  return orFilters
}

const inquiriesFilters = (orFilters, payload) => {
  if (payload.itemTypes.includes("inquiries")) {
    if (payload.sourceIds.length) {
      return [...orFilters, { bool: { must: [{ terms: { source_id: payload.sourceIds } }] } }]
    } else {
      return [...orFilters, { bool: { must: [{ exists: { field: "source_id" } }] } }]
    }
  }
  return orFilters
}

export const formatPayloadToElastic = (payload, $db) => {
  let filters = []
  let orFilters = []

  if (payload.itemType && !payload.itemTypes?.length) payload.itemTypes = [payload.itemType] // legacy
  if (payload.categories?.length > 0 && payload.selectableCategories?.length > 0 && payload.itemTypes?.length < 2) {
    filters.push({ terms: { category_id: payload.categories } })
  }

  if (payload.brokerIds.length) {
    const noAssignee = payload.brokerIds.includes("empty")
    let broker_ids = formatParams(payload.brokerIds, $db)
    const departmentIds = extractDepartments(payload.brokerIds)
    filters.push({
      bool: {
        should: [
          noAssignee
            ? {
                bool: {
                  must: [
                    { bool: { must_not: { exists: { field: "broker_id" } } } },
                    { bool: { must_not: { exists: { field: "department_id" } } } },
                  ],
                },
              }
            : undefined,
          { terms: { broker_id: broker_ids } },
          departmentIds.length ? { terms: { department_id: departmentIds } } : undefined,
          $db.shopData.legacySearchServer
            ? { bool: { must: [{ terms: { "todos.broker_id": broker_ids } }] } }
            : {
                bool: {
                  must: [
                    {
                      nested: {
                        path: "todos",
                        query: {
                          bool: {
                            should: [
                              noAssignee
                                ? {
                                    bool: {
                                      must: [
                                        { bool: { must_not: { exists: { field: "todos.broker_id" } } } },
                                        { bool: { must_not: { exists: { field: "todos.department_id" } } } },
                                      ],
                                    },
                                  }
                                : undefined,
                              { terms: { "todos.broker_id": broker_ids } },
                            ].filter(Boolean),
                          },
                        },
                      },
                    },
                  ],
                },
              },
        ].filter(Boolean),
      },
    })
  }

  if (payload.creator) filters.push({ term: { creator_id: payload.creator } })
  if (payload.responsibleBroker) filters.push({ term: { client_broker_ids: payload.responsibleBroker } })
  if (payload.onlyWithAttachments) filters.push({ range: { attachments_count: { gte: 1 } } })
  if (payload.group_ids.length > 0) filters.push({ terms: { group_ids: payload.group_ids } })
  if (payload.team_ids?.length > 0) filters.push({ terms: { team_ids: payload.team_ids } })
  if (payload.projects?.length > 0) filters.push({ terms: { project_ids: payload.projects } })
  if (payload.properties?.length > 0) filters.push({ terms: { property_ids: payload.properties } })
  if (payload.clients.length > 0) filters.push({ terms: { client_ids: payload.clients } })

  orFilters = inquiriesFilters(orFilters, payload)
  orFilters = cancelationFilters(orFilters, payload)
  orFilters = messageFilters(orFilters, payload)

  if (payload.createdRange) {
    filters.push({ range: { created_at: { gte: payload.createdRange[0] } } })
    filters.push({ range: { created_at: { lte: moment(payload.createdRange[1]).endOf("day").format() } } })
  }
  if (payload.startsRange) {
    filters.push({ range: { starts_at: { gte: payload.startsRange[0] } } })
    filters.push({ range: { starts_at: { lte: moment(payload.startsRange[1]).endOf("day").format() } } })
  }

  orFilters = reminderFilters(orFilters, payload)

  if (orFilters.length > 0 || payload.itemTypes.length > 0) {
    const conditionalConversationTypes = ["inquiries", "reminder", "message", "cancelation"]
    const unconditionalConversationTypes = payload.itemTypes.filter(
      itemType => !conditionalConversationTypes.includes(itemType)
    )
    if (unconditionalConversationTypes.length > 0)
      orFilters = [{ bool: { must: { terms: { conversation_type: unconditionalConversationTypes } } } }, ...orFilters]
    filters.push({ bool: { should: orFilters } })
  }

  return {
    unique: payload.unique,
    filter_set: {
      must: filters,
    },
  }
}

const customColumnsMixin = CustomColumnMixin("customColumnsForActivities")

export default {
  props: {
    clientId: { type: Number },
    propertyIds: { type: Array, default: () => [] },
    projectIds: { type: Array, default: () => [] },
    client: { type: Object },
    property: { type: Object },
    view: { type: String, default: "feed" },
    customColumnChoices: {
      type: Array,
      default: () => getCustomActivityColumns(),
    },
  },

  mixins: [SaveStateMixin, customColumnsMixin],

  data(props) {
    return {
      q: this.$util.searchParam("q"),
      data: [],
      selectedItems: [],
      selectedSmartView: null,
      params: {
        sortBy: "created_at",
        order: "desc",
        onlyInquiries: false,
        sourceIds: [],
        projects: props.projectIds,
        properties: props.propertyIds,
        clients: props.view == "deal" ? [props.clientId] : [],
        clientChildrenIds: null,
        createdRange: null,
        startsRange: null,
        senderType: "",
        itemTypes: [],
        categories: [],
        reasons: [],
        group_ids: [],
        creator: "",
        responsibleBroker: null,
        onlyWithAttachments: false,
        includeAllVariants: true,
        withoutDueDate: false,
        done: null,
        unique: false,
        team_ids: [],
      },
      massEditDialogVisible: false,
      brokerIds: props.view == "feed" ? [this.$db.broker.id] : [],
      relatedClientIds: [],
      total: 0,
      currentPage: 1,
      per: 20,
      tracking: {
        modalVisible: false,
        messageId: null,
      },
      smartViewEditId: undefined,
      smartViews: [],
      smartViewsLoading: true,
      smartViewFormVisible: false,
      loading: true,
      deleteDialogVisible: false,
      customColumnsFromFilter: null,
      selectedCustomColumns: null,
    }
  },
  watch: {
    q() {
      this.currentPage = 1
      this.fetchData()
    },
    params: {
      handler() {
        this.currentPage = 1
        this.fetchData()
      },
      deep: true,
    },
    relatedClientIds() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage() {
      this.fetchData()
    },
    brokerIds() {
      this.currentPage = 1
      this.fetchData()
    },
    per() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  SORT_OPTIONS,
  methods: {
    handleColumnsChange(col) {
      // maybe make api call and update settings
    },

    updateSelectedCustomColumns(val) {
      this.selectedCustomColumns = val
    },

    fetchData: debounce(function () {
      this.loading = true
      this.$axios.postWithCancel(`/api/v1/activities/search`, this.searchParams).then(({ data }) => {
        this.data = formatFeedRow(data.data, {
          brokers: this.$db.get("brokers"),
          clientSources: this.$db.get("clientSources"),
          cancelationReasons: this.$db.get("cancelationReasons"),
          taskStages: this.$db.get("taskStages"),
          projects: this.projects,
          messageCategories: this.messageCategories,
          noteTypes: this.noteTypes,
        })
        this.total = data.meta.total_count
        this.loading = false
      })
    }, 30),

    activitiesDeleted() {
      this.selectedItems = []
      this.deleteDialogVisible = false
    },

    updateActvities() {
      App.flashy(
        this.$t("activities.updateActivity.text", {
          numerus: this.$customFilters.pluralize(
            this.selected.length,
            this.$t("activities.updateActivity.singular"),
            this.$t("activities.updateActivity.plural")
          ),
        })
      )
      this.selectedItems = []
      this.massEditDialogVisible = false
      setTimeout(() => this.fetchData(), 5000)
    },

    resetCategories() {
      this.params.categories = []
      this.params.reasons = []
      this.params.senderType = ""
      this.params.startsRange = null
    },

    resetView(override = true) {
      this.selectedSmartView = null
      this.params.sourceIds = []
      this.params.projects = this.projectIds || []
      this.params.properties = this.propertyIds || []
      this.params.clients = this.dealView ? [this.clientId] : []
      this.params.createdRange = null
      this.params.startsRange = null
      this.params.senderType = ""
      this.params.itemTypes = []
      this.params.categories = []
      this.params.reasons = []
      this.params.group_ids = []
      if (override) this.brokerIds = this.feedView ? [this.$db.broker.id] : []
      this.params.creator = ""
      this.params.responsibleBroker = null
      this.params.onlyWithAttachments = false
      this.params.includeAllVariants = true
      this.params.withoutDueDate = false
      this.params.done = null
      this.params.unique = false
    },

    download() {
      this.$axios
        .post("/api/v1/activities/export", this.searchParams)
        .then(_ => App.flashy(this.$t("activities.downloadSuccess")))
    },

    visitResource(resource, setSelectedRowIndex) {
      // if (resource.access_level == "list") return

      eventBus.$emit("quick-view", {
        type: resource.conversation_type,
        mode: "view",
        id: resource.activatable_id,
      })

      if (setSelectedRowIndex) setSelectedRowIndex(this.data.indexOf(resource) + 1)
    },

    openTrackingsModal(messageId) {
      this.tracking.messageId = messageId
      this.tracking.modalVisible = true
    },
    trackingModalClosed() {
      this.tracking.modalVisible = false
      this.tracking.messageId = null
    },

    smartViewSelected(item) {
      this.selectedSmartView = item.id
      Object.keys(item.esFilters).forEach(key => {
        if (key == "properties" && this.propertyView) return
        if (key == "clients" && this.clientView) return
        if (key == "projects" && this.projectView) return
        if (key == "deals" && this.dealView) return

        if (this.hasOwnProperty(key)) {
          this[key] = item.esFilters[key]
        } else if (this.params.hasOwnProperty(key)) {
          this.params[key] = item.esFilters[key]
        } else if (key === "itemType") {
          const itemType = item.esFilters.itemType
          this.params.itemTypes = itemType ? [itemType] : []
        }
      })
      this.customColumnsFromFilter = JSON.parse(item.params)?.columns
    },
    async fetchSmartViews() {
      const { shop } = await this.$graphql(
        `{
        shop {
          searchFilters(forEntity: "for_activities") {
            id name brokerId brokerIds departmentIds isPublic params esFilters storageType
          }
        }
      }`,
        undefined,
        true
      )

      this.smartViewsLoading = false
      this.smartViews = shop.searchFilters

      if (this.$route.query.smartview) {
        const smartView = this.smartViews.find(o => o.id == this.$route.query.smartview)
        if (smartView) {
          this.smartViewSelected(smartView)
        }
      }
    },
    editSmartView(id) {
      this.smartViewEditId = id
      this.smartViewFormVisible = true
    },

    highlightedRows(resource) {
      return (
        resource.done ||
        (resource.conversation_type === "message" && resource.sender_type === 2) ||
        resource.conversation_type == "note" ||
        resource.conversation_type == "policy" ||
        resource.conversation_type == "call" ||
        resource.conversation_type == "letter" ||
        resource.conversation_type == "cancelation" ||
        (resource.conversation_type == "event" && moment(resource.ends_at) < moment())
      )
    },

    smartViewSaved(obj) {
      this.smartViewFormVisible = false
      // this.searchFilters = this.searchFilters.filter(o => o.id !== obj.id).concat([obj])
      this.$db.setBroker({ searchFilters: this.$db.broker.searchFilters.filter(o => o.id != obj.id).concat([obj]) })
      this.selectedSmartViewId = obj.id
      this.selectedSmartView = obj.id

      setTimeout(() => this.fetchSmartViews(), 500)
    },

    async fetchDefaultColumns() {
      const {
        shop: { customColumnPresetForActivities },
      } = await this.$graphql(
        `query DefaultActivityColumns {
        shop { customColumnPresetForActivities }
      }`
      )
      if (!customColumnPresetForActivities) return
      const map = this.customColumnChoices.reduce((agg, cur) => {
        agg[cur.name] = cur
        return agg
      }, {})
      return customColumnPresetForActivities
        .map(c => {
          const definition = map[c.name]
          if (!definition) return undefined
          delete map[c.name]
          return { ...definition, visible: c.visible }
        })
        .filter(Boolean)
        .concat(Object.values(map))
    },
  },
  computed: {
    hasMessageActivityType() {
      return this.params.itemTypes.includes("message")
    },
    hasCancelationActivityType() {
      return this.params.itemTypes.includes("cancelation")
    },
    hasReminderActivityType() {
      return this.params.itemTypes.includes("reminder")
    },
    hasEventActivityType() {
      return this.params.itemTypes.includes("event")
    },
    hasInquiriesActivityType() {
      return this.params.itemTypes.includes("inquiries")
    },
    categoriesFilterEnabled() {
      return this.selectableCategories?.length > 0 && this.params.itemTypes.length < 2
    },
    additionalBrokerOptions() {
      let departments = this.$db.shopData.departments
      if (departments.length)
        departments = departments.map(d => ({
          id: `{{ department_${d.id} }}`,
          internalName: this.$t("activities.currentDepartments", { name: d.name }),
        }))
      return [{ id: "{{ broker_id }}", internalName: this.$t("activities.currentUser") }]
        .concat(departments)
        .concat({ id: "empty", internalName: this.$t("activities.isEmpty") })
    },
    allRelatedClients() {
      if (this.client) return this.client.relatedClients
      return []
    },
    translatedSortOptions() {
      return SORT_OPTIONS.map(o => ({ ...o, label: this.$t(`activities.columns.${o.value}`) }))
    },
    activeFilters() {
      let count = 0

      this.relatedClientIds.length && this.clientView && this.allRelatedClients.length > 0 ? count++ : null
      this.params.clientChildrenId && this.clientView && this.childrenClients.length > 0 ? count++ : null
      this.params.sourceIds.length && this.$db.broker.admin && this.hasInquiriesActivityType ? count++ : ""
      this.params.projects?.length && !this.projectView && !this.dealView ? count++ : []
      this.params.properties?.length && !this.propertyView && !this.dealView ? count++ : []
      this.params.clients.length && !this.clientView && !this.dealView ? count++ : []
      this.params.createdRange ? count++ : null
      this.params.itemTypes.length > 0 ? count++ : ""
      this.params.senderType && this.hasMessageActivityType ? count++ : ""
      this.params.startsRange && this.hasEventActivityType ? count++ : null
      this.params.categories?.length && this.selectableCategories?.length > 0 ? count++ : []
      this.params.reasons.length && this.hasCancelationActivityType ? count++ : []
      this.params.group_ids.length && this.groups.length > 0 ? count++ : []
      this.brokerIds.length &&
      this.$db.brokerCanSeeActivityOverview() &&
      (!this.hasReminderActivityType || this.$db.broker.canSeeTodos)
        ? count++
        : null
      this.params.creator && this.$db.brokerCanSeeActivityOverview() ? count++ : ""
      this.params.responsibleBroker && this.$db.brokerCanSeeActivityOverview() ? count++ : null
      this.params.onlyWithAttachments ? count++ : null
      this.canIncludeAllVariants && this.params.includeAllVariants ? count++ : null
      this.hasReminderActivityType && this.params.withoutDueDate ? count++ : null
      this.params.done ? count++ : null
      this.params.unique ? count++ : null
      return count
    },
    turbolinksKey() {
      if (this.clientId) return `feed-client-${this.clientId}`
      if (this.propertyIds.length) return `feed-property-${this.propertyIds[0]}`
      if (this.projectIds.length) return `feed-project-${this.projectIds[0]}`
      return "feed"
    },
    saveStateConfig() {
      return {
        cacheKey: this.turbolinksKey,
        saveProperties: this.feedView ? ["relatedClientIds", "brokerIds"] : this.clientView ? ["relatedClientIds"] : [],
      }
    },
    childrenClients() {
      if (this.client) return this.client.children
      return []
    },
    feedView() {
      return this.view == "feed"
    },
    clientView() {
      return this.view == "client"
    },
    propertyView() {
      return this.view == "property"
    },
    projectView() {
      return this.view == "project"
    },
    dealView() {
      return this.view == "deal"
    },
    selectableCategories() {
      const mapping = {
        message: this.messageCategories,
        note: this.$db.shopData.noteCategories.filter(c => !c.internal),
        reminder: this.$db.shopData.todoCategories.filter(c => !c.internal),
        event: this.$db.shopData.eventCategories.filter(c => !c.internal),
        letter: this.$db.shopData.letterCategories.filter(c => !c.internal),
        decision: this.$db.shopData.decisionCategories.filter(c => !c.internal),
      }

      return this.params.itemTypes.map(itemType => mapping[itemType] || []).flat()
    },
    filteredClientIds() {
      if (this.clientView || this.dealView) {
        if (this.params.clientChildrenId) return [this.params.clientChildrenId]
        const defaultClientIds = [this.clientId].concat(this.childrenClients?.map(o => o.id) || [])
        return this.relatedClientIds.length > 0 ? defaultClientIds.concat(this.relatedClientIds) : defaultClientIds
      } else {
        return this.params.clients
      }
    },
    elasticFilters() {
      return formatPayloadToElastic(
        {
          ...this.params,
          properties:
            this.canIncludeAllVariants && this.params.includeAllVariants
              ? [...this.propertyIds, ...(this.property.forks ?? []).map(o => o.id)]
              : this.params.properties,
          brokerIds: this.brokerIds,
          clients: this.filteredClientIds,
          selectableCategories: this.selectableCategories,
        },
        this.$db
      )
    },
    searchParams() {
      let params = {
        ...this.elasticFilters,
        client_ids: this.filteredClientIds,
        query: this.q,
        per: this.per,
        page: this.currentPage,
        sort_by: this.params.sortBy,
        order: this.params.order,
      }
      if (this.propertyIds?.length) params.properties = this.propertyIds
      if (this.projectIds?.length) params.projects = this.projectIds
      return params
    },
    formParams() {
      return {
        params: {
          ...this.elasticFilters,
          per: this.per,
          sort_by: this.params.sortBy,
          order: this.params.order,
          columns: this.selectedCustomColumns?.map(c => ({ name: c.name, visible: c.visible, width: c.width })),
        },
        es_filters: {
          ...this.params,
          brokerIds: this.brokerIds,
          per: this.per,
        },
      }
    },
    customColumns() {
      return this.selectedSmartView && this.customColumnsFromFilter?.length ? this.customColumnsFromFilter : null
    },
    tableColumns() {
      let cols = this.userColumns
      if (this.clientView) {
        cols = cols.filter(col => col.name != "client")
      } else if (this.propertyView || this.projectView) {
        cols = cols.filter(col => col.name != "property")
      }
      return cols.map(col => ({
        ...col,
        key: col.name,
        title: col.description ? "" : this.$t(`activities.columns.${col.name}`),
      }))
    },
    messageCategories() {
      return this.$db.shopData.messageCategories
    },
    projects() {
      return this.$db.shopData.projects
    },
    groups() {
      return this.$db.shopData.activityGroups
    },
    noteTypes() {
      return this.$db.shopData.noteCategories
        .concat(this.$db.shopData.todoCategories)
        .concat(this.$db.shopData.eventCategories)
        .concat(this.$db.shopData.letterCategories)
        .concat(this.$db.shopData.decisionCategories)
    },
    selectedClientIds: {
      get() {
        return [...new Set(this.selectedItems.filter(o => o.access_level == "full").flatMap(ci => ci.client_ids))]
      },
      set(value) {
        this.selectedItems = value?.length > 1 ? this.data.filter(c => value.includes(c.id)) : []
      },
    },
    editableFields() {
      return [
        {
          id: "client_ids",
          type: "clients",
        },
        {
          id: "property_ids",
          type: "properties",
        },
        {
          id: "project_ids",
          dbOptions: "projects",
          type: "multiselect",
        },
        {
          id: "add_access_broker_ids",
          dbOptions: "unarchivedBrokers",
        },
        {
          id: "sub_access_broker_ids",
          dbOptions: "unarchivedBrokers",
        },
        {
          id: "add_access_department_ids",
          dbOptions: "departments",
        },
        {
          id: "sub_access_department_ids",
          dbOptions: "departments",
        },
        {
          id: "done",
          type: "boolean",
        },
        !this.selectedItems.some(i => i.conversation_type !== "message")
          ? {
              id: "client_source_id",
              dbOptions: "clientSources",
            }
          : undefined,
        {
          id: "broker_id",
          dbOptions: "activeBrokers",
        },
        {
          id: "add_group_ids",
          dbOptions: "activityGroups",
          type: "multiselect",
        },
        {
          id: "sub_group_ids",
          dbOptions: "activityGroups",
          type: "multiselect",
        },
        this.categoryOptionsField ? { id: "category_id", dbOptions: this.categoryOptionsField } : undefined,
      ]
        .filter(Boolean)
        .map(f => ({ ...f, label: this.$t(`activities.editableFields.${f.id}`) }))
    },
    categoryOptionsField() {
      const conversationTypes = [...new Set(this.selectedItems.map(o => o.conversation_type))]
      if (conversationTypes.length > 1) return
      if (conversationTypes.includes("message")) return "messageCategories"
      if (conversationTypes.includes("event")) return "eventCategories"
      if (conversationTypes.includes("reminder")) return "todoCategories"
      if (conversationTypes.includes("letter")) return "letterCategories"
      if (conversationTypes.includes("note")) return "noteCategories"
      if (conversationTypes.includes("decision")) return "decisionCategories"
    },
    selected() {
      return this.selectedItems.filter(o => o.access_level == "full").map(o => o.id)
    },
    canIncludeAllVariants() {
      return this.property && this.property.forks?.length
    },
  },

  components: {
    CommentsIndicator,
    SubTasksIndicator,
    ActivitiesIcon,
    Trackings,
    SmartViewPopover,
    SmartViewFormDialog,
    ColumnsModal,
    FilterPopover,
    MassEditDialog,
    ActivitiesMassDeleteDialog,
  },

  created() {
    this.storageKey = this.turbolinksKey
    this.storageFields = ["params", "relatedClients", "total", "currentPage"]
  },

  mounted() {
    // TODO: this can be removed later on
    if (this.feedView) {
      const fromStorage = getSavedStateFromLocalStorage(`${this.saveStateConfig.cacheKey}`)
      if (fromStorage?.columns) {
        this.columns.data = formatColumns(
          this.customColumnChoices,
          fromStorage.columns.map(c => ({ ...c, name: c.key }))
        )
      }
    }

    if (this.$route.query.smartview) {
      const smartView = this.smartViews.find(o => o.id == Number(this.$route.query.smartview))
      this.selectedSmartView = smartView?.id ?? null
    } else {
      this.updateSelectedCustomColumns(this.columns.data)
    }

    if (this.$route.query.clientIds || this.$route.query.propertyIds || this.$route.query.projectIds) {
      this.selectedSmartView = null
      this.resetView(false)

      this.params.clients = (this.$route.query.clientIds?.split(",") || []).map(id => Number(id))
      this.params.properties = (this.$route.query.propertyIds?.split(",") || []).map(id => Number(id))
      this.params.projects = (this.$route.query.projectIds?.split(",") || []).map(id => Number(id))
    }

    this.$on("update-column-layout", this.updateSelectedCustomColumns)

    this.fetchData()
    this.fetchSmartViews()
    eventBus.$on("message-deleted", _ => this.fetchData())

    eventBus.$on("quick-view-submit", _ => (this.selectedItems = []))

    this.$pusher.on(`task:${this.$db.broker.id}:saved`, _ => this.fetchData())
    this.$pusher.on(`task:${this.$db.broker.id}:deleted`, _ => setTimeout(this.fetchData, 150))
  },
  beforeDestroy() {
    this.$off("update-column-layout")
    this.$pusher.off(`task:${this.$db.broker.id}:saved`)
    this.$pusher.off(`task:${this.$db.broker.id}:deleted`)
  },
}
</script>

<style scoped>
.note-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
