export default {
  install(Vue, _) {
    const message = (message, type) =>
      Vue.prototype.$message({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message,
        type,
        duration: type == "error" ? 3000 : 1500,
      })

    Vue.prototype.$flashy = msg => {
      message(msg, "success")
    }

    Vue.prototype.$alert = msg => {
      message(msg, "error")
    }
  },
}
