<template>
  <form-dialog
    title="Dokumente anhängen"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :disabled="!selected.length"
    @submit="submit"
    @close="$emit('close')"
    append-to-body
    :submit-button-title="`${selected.length} Dokument(e) anhängen`"
  >
    <spinner v-if="loading" />
    <el-checkbox-group v-else-if="resource" v-model="selected">
      <p v-if="!resource.documents.length">{{ title }} hat keine Dokumente</p>
      <header v-else class="mb-3">
        <p class="text-lg font-semibold">{{ title }} hat {{ resource.documents.length }} Dokumente</p>
        <p>Wähle die gewünschten Dokumente zum Anhängen aus.</p>
      </header>
      <section class="w-100">
        <card v-for="item in resource.documents" :key="item.id" class="mb-2 w-100">
          <nice-checkbox :label="item.id" class="documents-checkbox flex items-center w-100 py-1 px-3">
            <main class="w-100 flex items-center">
              <file-icon :contentType="item.contentType" class="text-2xl ml-1 mr-3 relative" />
              <p class="w-100">{{ item.title }}</p>
              <p class="ml-2 text-sm text-gray-600 whitespace-nowrap relative" style="top: 1px">
                {{ $customFilters.humanFileSize(item.fileSize) }}
              </p>
            </main>
          </nice-checkbox>
        </card>
      </section>
    </el-checkbox-group>
  </form-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ids: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      resource: null,
      loading: true,
      saving: false,
      selected: [],
    }
  },

  watch: {
    visible(newValue) {
      this.selected = []
      if (newValue) {
        this.fetchData()
      }
    },
  },

  computed: {
    title() {
      return (
        this.resource.name || (this.type === "property" ? "Objekt" : this.type === "project" ? "Projekt" : "Kontakt")
      )
    },
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.items = []
      const data = await this.$graphql(`query ${this.type}DocsModal {
          ${this.type}(id: ${this.ids[0]}) {
            name
            documents {
              id url title tags contentType fileSize createdAt folderId
              isPrivate onLandingPage isExposee isFloorplan
            }
          }
        }`)

      this.resource = data[this.type]
      this.loading = false
    },
    submit() {
      this.$emit(
        "submit",
        this.resource.documents.filter(d => this.selected.includes(d.id))
      )
    },
  },
}
</script>

<style>
.documents-checkbox .el-checkbox__input {
  position: relative;
  top: 1px;
}
.documents-checkbox .el-checkbox__label {
  width: 100%;
}
</style>