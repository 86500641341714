<template>
  <el-select
    ref="select"
    :multiple="multiple"
    filterable
    v-cancel-read-only
    clearable
    :value="value"
    default-first-option
    @input="input"
    @change="$emit('change', $event)"
    :placeholder="$t('propertySelect.placeholder')"
    :reserve-keyword="reserveKeyword"
    remote
    :remote-method="fetchProperties"
    :loading="loading"
    :size="size"
  >
    <el-option
      v-for="property in properties"
      :key="property.id"
      :label="property.name"
      :value="property.id"
      class="property-select"
    >
      <property-select-option :property="property" />
    </el-option>
  </el-select>
</template>

<script>
import PropertySelectOption from "./PropertySelectOption"
import { cacheProperties } from "@/config/remote-data-cache"

export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    reserveKeyword: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    variants: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PropertySelectOption,
  },

  data() {
    return {
      properties: [],
      loading: false,
      selectedProperties: [],
      selected: [],
    }
  },
  watch: {
    selected(newValue) {
      if (!newValue) return
      const selectedPropertyMap = this.selectedProperties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
      const propertyMap = this.properties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
      this.selectedProperties = this.selected.length
        ? this.selected.map(id => selectedPropertyMap[id] || propertyMap[id])
        : []
    },
    selectedProperties(newValue) {
      if (!newValue) return
      setTimeout(() => {
        const projectIds = this.selectedProperties.length
          ? [...new Set(this.selectedProperties.map(s => (s ? s.project_id : undefined)).filter(Boolean))]
          : undefined
        this.$emit("change-project", projectIds)
      }, 100)
    },
  },

  methods: {
    async fetchProperties(query) {
      if (!query) {
        this.properties = []
        return
      }

      this.loading = true
      const { data } = await this.$axios.get(`/portfolio/properties?q=${query}&include_variants=${this.variants}`)
      this.properties = data.data
      cacheProperties(data.data)
      this.loading = false
    },
    input($event) {
      this.$emit("input", $event)
      this.selected = $event
      // this.$refs.select.$refs.input.select()
    },
    prefillSetProperties() {
      const propertyMap = this.properties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
      this.selectedProperties = this.multiple ? this.value.map(id => propertyMap[id]) : propertyMap[this.value]
    },
    prefill() {
      if (!this.value) return
      const ids = this.multiple ? this.value.join(",") : this.value
      if (!ids) return

      this.$axios.get(`/portfolio/properties?property_ids=${ids}`).then(({ data }) => {
        this.properties = data.data
        this.prefillSetProperties()
        cacheProperties(data.data)
        this.$emit("ready")
      })
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.prefill()
    })
  },
}
</script>

<style scoped>
.property-select {
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
