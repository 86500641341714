<template>
  <span v-if="lowerCaseFieldType == 'dropdown'">{{ customOption ? customOption.name : "" }}</span>
  <span v-else-if="lowerCaseFieldType == 'multiselect'">{{ customOptions.map(o => o.name).join(", ") }}</span>
  <span v-else-if="lowerCaseFieldType == 'broker'">{{ broker ? broker.name : "" }}</span>
  <span v-else-if="lowerCaseFieldType == 'boolean'">
    <fa-icon v-if="value" name="check" />
  </span>
  <span v-else-if="lowerCaseFieldType == 'number'">{{ numberValue }}</span>
  <span v-else-if="lowerCaseFieldType == 'datetime'">{{ $customFilters.datetime(dateValue) }}</span>
  <span v-else-if="lowerCaseFieldType == 'date'">{{ $customFilters.date(value) }}</span>
  <span v-else>{{ value }}</span>
</template>

<script>
import { prettyNumber, prettySpace, numberToCurrency, formatNumberWithUnit } from "../../config/number-filters"

const CURRENCY_MAP = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  CHF: "CHF",
  HUF: "Ft",
  euro: "€",
  chf: "CHF",
  huf: "Ft",
  aed: "AED",
  AED: "AED",
}

export default {
  props: {
    cf: {},
    resource: {},
    showPriceUnit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CURRENCY_MAP,
    }
  },
  computed: {
    lowerCaseFieldType() {
      return this.cf.fieldType.toLowerCase()
    },
    value() {
      return this.resource[this.cf.fieldName]
    },
    numberValue() {
      if (!this.value) return
      if (this.cf.unit === "percent") return formatNumberWithUnit(this.value, "%", true, 1)
      if (this.cf.unit === "sqm") return prettySpace(this.value)
      if (this.cf.unit === "year") return this.value
      if (!!CURRENCY_MAP[this.cf.unit]) return numberToCurrency(this.value, CURRENCY_MAP[this.cf.unit], false)
      return prettyNumber(this.value)
    },
    dateValue() {
      if (this.value) return moment(this.value)
    },
    customOption() {
      return this.cf.customOptions.find(o => o.id == this.value)
    },
    broker() {
      return this.$db.get("brokers").find(o => o.id == this.value)
    },
    customOptions() {
      return this.cf.customOptions.filter(o => this.value && this.value.map(Number).includes(o.id))
    },
  },
}
</script>
