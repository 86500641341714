<script setup lang="ts">
import { computed, reactive, ref, onMounted } from "vue"
import useCore from "@/plugins/use-core"
import { useRoute } from "vue-router/composables"
import useI18n from "@/plugins/use-i18n"

const emit = defineEmits<{
  (event: "submitted", msg: string): void
}>()

const route = useRoute()
const { t } = useI18n()

const {
  db: {
    broker: { headerColor, role },
    shopData: { demo },
  },
  api,
  graphql,
  root,
} = useCore()

const form = reactive({
  rating: undefined as number | undefined,
  comment: "",
  consentForContact: false,
})
const hoverEmoji = ref()
const showOverlay = ref(false)
const submitting = ref(false)

const formValid = computed(() => {
  return form.rating
})

const activeEmojiLabel = computed(() => {
  const value = hoverEmoji.value ?? form.rating
  if (!value) return ""
  return t(`csat.rating.${value}`)
})

const devToggleRating = computed(() => {
  return route.query.devToggleRating === "1"
})

const styleColor = headerColor || "#0f55eb"

const icons = Object.entries(import.meta.glob("@/assets/images/csat/*.svg", { as: "url", eager: true })).reduce(
  (agg, [key, val]) => {
    const parts = key.slice(0, -4).split("/").slice(-1)[0].split("-")
    const type = parts.pop() as string
    const rating = parts.join("-")
    agg[rating] = Object.assign(agg[rating] ?? {}, { [type]: val })
    return agg
  },
  {} as Record<string, Record<string, string>>
)
const ratings = [1, 2, 3, 4, 5]
const iconMap = ["very-negative", "negative", "neutral", "positive", "very-positive"]
const getIcon = (rating: number, active: boolean) => icons[iconMap[rating - 1]][active ? "full" : "line"]

const setRating = value => {
  form.rating = form.rating !== value ? value : undefined
}

const toggleOverlay = () => {
  showOverlay.value = !showOverlay.value
}

const close = async () => {
  if (submitting.value) return
  try {
    submitting.value = true
    toggleOverlay()
    await api.mutation("createCsatAnswer", {})
  } finally {
    localStorage.setItem("csat-last-checked", Date.now().toString())
    submitting.value = false
  }
}

const submit = async () => {
  if (submitting.value) return
  try {
    submitting.value = true
    toggleOverlay()
    await api.mutation("createCsatAnswer", form)
    emit("submitted", t("csat.submitted") as string)
  } finally {
    localStorage.setItem("csat-last-checked", Date.now().toString())
    submitting.value = false
  }
}

const localBackoff = (timeElasped: number) => {
  const csatLastSurveyed = parseInt(localStorage.getItem("csat-last-checked") ?? "")
  if (isNaN(csatLastSurveyed)) return false
  const time = Date.now()
  return csatLastSurveyed + timeElasped > time
}

const shouldDisplaySurvey = async () => {
  if (!devToggleRating.value && (root.dbackdoor || role === "consulting" || demo || localBackoff(24 * 60 * 60 * 1000)))
    return

  try {
    const {
      broker: { csatShouldDisplaySurvey },
    } = await graphql("query shouldDisplaySurvey { broker { csatShouldDisplaySurvey } }")
    showOverlay.value = csatShouldDisplaySurvey || devToggleRating.value
  } catch (e) {
    console.error("Could not query shouldDisplaySurvey", e)
  }
}

onMounted(shouldDisplaySurvey)
</script>

<template>
  <div v-show="showOverlay" class="fixed bottom-5 right-5 bg-white p-4 rounded-tl-lg shadow-2xl" style="z-index: 99">
    <div class="w-full text-black text-center p-1 mt-4">
      <strong class="text-lg whitespace-pre">{{ t("csat.title") }}</strong>
    </div>
    <div class="flex flex-row mt-4 w-100 justify-center gap-4">
      <div
        class="cursor-pointer"
        @mouseover="hoverEmoji = rating"
        @mouseleave="hoverEmoji = null"
        @click="setRating(rating)"
        v-for="rating in ratings"
        :key="rating"
      >
        <img :src="getIcon(rating, hoverEmoji === rating || form.rating === rating)" width="20" />
      </div>
    </div>
    <div class="w-100 text-center h-5 mt-2 font-medium">{{ activeEmojiLabel }}</div>
    <textarea
      type="text"
      rows="4"
      cols="10"
      :placeholder="t('csat.placeholder')"
      class="w-full mt-4 p-2 border border-gray-300 rounded resize-none"
      v-model="form.comment"
    />
    <div class="flex items-start mt-2">
      <input
        type="checkbox"
        class="form-checkbox h-5 w-5 mt-0.5"
        :style="{ 'accent-color': styleColor }"
        id="consentForContact"
        v-model="form.consentForContact"
      />
      <label class="ml-2 text-gray-700 whitespace-pre-line -mt-6" for="consentForContact">
        {{ t("csat.checkbox") }}
      </label>
    </div>
    <button
      @click="submit"
      class="w-full mt-4 text-white px-4 py-2 rounded"
      :style="{ 'background-color': styleColor }"
      :disabled="!formValid"
    >
      {{ t("csat.button") }}
    </button>
    <button @click="close" class="absolute top-5 right-5 text-gray-500 hover:text-gray-700">
      <i class="fal fa-times"></i>
    </button>
  </div>
</template>
