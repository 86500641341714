<template>
  <el-progress
    :percentage="computePercentage"
    :show-text="showText"
    :class="computedStyleClass"
    :type="type"
    :status="status"
  >
    <slot></slot>
  </el-progress>
</template>

<script>
export default {
  props: {
    percentage: { type: Number },
    showText: { type: Boolean, default: true },
    subtype: { type: String },
    type: { type: String, default: "line" },
    status: { type: String },
    excessBar: { type: Boolean, default: false },
  },

  computed: {
    computePercentage() {
      if (this.percentage < 0) {
        return 0
      }
      if (this.percentage > 100) {
        return 100
      }
      return this.percentage
    },

    computedStyleClass() {
      switch (this.subtype) {
        case "contractDuration": {
          if (this.percentage >= 31) {
            return "green-bar"
          } else if (this.percentage <= 15) {
            return "red-bar"
          } else {
            return "orange-bar"
          }
        }
        case "commissionBar": {
          if (this.excessBar) return "darkred-bar excess-progress-bar"
          if (this.percentage < 100 || this.percentage > 100.00001) {
            return "red-bar"
          } else {
            return "green-bar"
          }
        }
      }
      return ""
    },
  },
}
</script>

<style>
.green-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #5cb87a;
}
.orange-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #e6a23c;
}
.red-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #f56c6c;
}

.darkred-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #7f1d1d;
}

.excess-progress-bar {
  transform: rotate(180deg);
}
.excess-progress-bar .el-progress-bar__outer {
  background: transparent;
}
</style>