<template>
  <div>
    <div class="mb-2" v-if="!ta.prefilledNoteTypeId">
      <el-select filterable v-cancel-read-only v-model="ta.payload.noteTypeId" :placeholder="$t('activities.note.category')">
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </div>
    <div class="mb-2">
      <input type="text" v-model="ta.payload.title" class="form-control" :placeholder="$t('activities.note.title')" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },

  computed: {
    options() {
      return this.shopData.noteCategories
    },
  },
}
</script>