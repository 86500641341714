import useI18n from "@/plugins/use-i18n"
import { useIntegrationCreateConsentMutation } from "./mutations/use-integration-create-consent-mutation"
import { useIntegrationDeleteConsentMutation } from "./mutations/use-integration-delete-consent-mutation"
import { UseConfigurationReponse } from "./types/integration-page"
import { IntegrationConsentRequest, IntegrationId } from "./types/integration-service"

type UseConsentResponse = UseConfigurationReponse

export const useIntegrationConsent = () => {
  const createConsentMutation = useIntegrationCreateConsentMutation()
  const deleteConsentMutation = useIntegrationDeleteConsentMutation()

  const { t } = useI18n()

  const storeConsent = async (
    integrationId: IntegrationId,
    consent: IntegrationConsentRequest
  ): Promise<UseConsentResponse> => {
    const result = await createConsentMutation.mutateAsync({
      integrationId,
      consent,
    })

    return result
      ? { status: "success", message: t("integrations.notifications.success.consent") }
      : { status: "error", message: t("integrations.notifications.error.consent") }
  }

  const revokeConsent = async (integrationId: IntegrationId): Promise<UseConsentResponse> => {
    const result = await deleteConsentMutation.mutateAsync(integrationId)

    return result
      ? { status: "success", message: t("integrations.notifications.success.consentRevoked") }
      : { status: "error", message: t("integrations.notifications.error.consentRevoked") }
  }

  return { storeConsent, revokeConsent }
}
