const FORBIDDEN_FIELDS = [
  "custom_fields",
  "partial_custom_fields",
  "relationships",
  "children",
  "client_addresses",
  "photo_url"
]

export function formatPayload(record: any) {
  const formatRecord = client => {
    const recordWithoutForbiddenAndCustomFields = _.omitBy(
      client,
      (v, key) => key == "skipDirty" || FORBIDDEN_FIELDS.includes(key) || _.startsWith(key, "cf_")
    )
    const recordWithOnlyCustomFields = _.pickBy(client, (v, key) => _.startsWith(key, "cf_"))
    recordWithoutForbiddenAndCustomFields.partial_custom_fields = _.mapKeys(recordWithOnlyCustomFields, (v, k) =>
      k.replace("cf_", "")
    )

    recordWithoutForbiddenAndCustomFields.client_addresses_attributes = client.client_addresses
    return recordWithoutForbiddenAndCustomFields
  }

  const formatted = formatRecord(record)
  formatted.children_attributes = record.children.map(formatRecord)

  return formatted
}
