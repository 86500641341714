<template>
  <tooltip :content="$t('detailView.copy')">
    <fa-icon class="icon px-1" name="clipboard" @click.native="copyToClipboard" v-bind="$attrs" />
  </tooltip>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  inheritAttrs: false,
  props: {
    text: String,
  },
  methods: {
    copyToClipboard(e) {
      e.preventDefault()
      const el = document.createElement("textarea")
      el.value = this.text
      el.setAttribute("readonly", "")
      el.style.position = "absolute"
      el.style.left = "-9999px"
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)
      App.flashy(this.$t("detailView.emailCopySuccess"))
    },
  },
})
</script>
