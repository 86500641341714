<template>
  <component :is="tag" v-bind="{ [isRouterLink ? 'to' : 'href']: href, ...$attrs }" @click.native="clicked">
    <slot />
  </component>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  name: "SidebarMenuLink",
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clicked() {
      eventBus.$emit("nav-bar-clicked", { href: this.href })
    },
  },
  computed: {
    isRouterLink() {
      return !!this.$router && this.item.href && !this.item.external
    },
    tag() {
      return this.isRouterLink ? (this.$nuxt ? "nuxt-link" : "router-link") : "a"
    },
    href() {
      if (!this.item.href) return "#"
      return this.item.href
    },
  },
}
</script>
