<template>
  <div v-if="loading" class="messages-empty">
    <span class="spinner spinner-sm"></span>
  </div>
  <div v-else-if="resource" class="app-panel">
    <div class="app-panel-body scrollable">
      <nav class="py-3 px-4 mb-2 d-flex align-items-center justify-content-between">
        <div>
          <a href="#" @click.prevent="$emit('close')" class="fs-18 mr-3 ml-1" style="position: relative; top: 2px">
            <fa-icon name="times" />
          </a>
        </div>
        <div>
          <dropdown>
            <dropdown-section>
              <dropdown-item new-tab title="Druckansicht öffnen" @click="printPageVisible = true" />
              <dropdown-item v-if="false" @click="deleteTask" title="Löschen" class="text-red-600" />
            </dropdown-section>
            <dropdown-section>
              <p @click.stop class="text-gray-600 text-xs px-3 py-2">Kennung: {{ externalId }}</p>
            </dropdown-section>
          </dropdown>
        </div>
      </nav>
      <main class="app-panel-body">
        <header class="mb-4">
          <h3 class="notes-title">{{ resource.name }}</h3>
        </header>

        <div class="mb-1" v-if="message">
          <label>Angebots-E-Mail:</label>
          <strong>
            <a href="#" @click.prevent="openMessage">{{ message.subject }}</a>
          </strong>
        </div>

        <section class="-mx-2">
          <table class="table table-bordered">
            <tbody>
              <tr v-if="resource.category == 'buyer_order'">
                <td style="width: 175px">Exposé-ID</td>
                <td>
                  <strong>{{ resource.messageId }}</strong>
                </td>
              </tr>
              <tr v-if="resource.properties && resource.properties.length">
                <td style="width: 175px">Objekt</td>
                <td>
                  <strong>{{ resource.properties.map(o => o.name).join(", ") }}</strong>
                </td>
              </tr>
              <tr v-if="resource.client">
                <td style="width: 175px">Versand an</td>
                <td>
                  <strong>{{ resource.client.name }}</strong>
                </td>
              </tr>
              <tr>
                <td style="width: 175px">E-Mail-Adresse</td>
                <td>
                  <strong>{{ resource.receiverEmail }}</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="width: 175px">Aufruf am</td>
                <td>
                  <strong>{{ $customFilters.datetime(resource.createdAt) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="width: 175px">IP-Adresse des Kunden</td>
                <td>
                  <strong>{{ resource.ip }}</strong>
                </td>
              </tr>
              <tr>
                <td style="width: 175px">Browser des Kunden</td>
                <td>
                  <strong>{{ resource.browserName }}</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered mt-3">
            <tbody>
              <tr>
                <td colspan="2">Der Kontakt {{ resource.client && resource.client.name }} hat folgendes bestätigt:</td>
              </tr>
              <template v-if="resource.brokerOrderTextSnapshot">
                <article v-html="resource.brokerOrderTextSnapshot" class="p-2"></article>
              </template>
              <template v-else-if="resource.selectedCheckboxLabels">
                <tr v-for="(checkbox, idx) in resource.selectedCheckboxLabels" :key="idx">
                  <td><strong v-html="checkbox"></strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon name="check" class="text-green-600" />
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-if="!resource.onlyContactAcceptance">
                  <td><strong>Widerrufsbelehrung gelesen und verstanden</strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon v-if="resource.read" name="check" class="text-green-600" />
                    <fa-icon v-else name="times" class="text-red-600" />
                  </td>
                </tr>
                <tr v-if="!resource.onlyContactAcceptance">
                  <td><strong>Vorzeitiger Beginn der Maklertätigkeit akzeptiert</strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon v-if="resource.acceptService" name="check" class="text-green-600" />
                    <fa-icon v-else name="times" class="text-red-600" />
                  </td>
                </tr>

                <tr v-if="resource.acceptTerms">
                  <td><strong>AGB gelesen und akzeptiert</strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon name="check" class="text-green-600" />
                  </td>
                </tr>

                <tr v-if="resource.acceptPrivacy">
                  <td><strong>Datenschutzerklärung gelesen und verstanden</strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon name="check" class="text-green-600" />
                  </td>
                </tr>

                <tr v-if="resource.acceptContact">
                  <td>
                    <strong>
                      Hiermit bestätige ich, dass ich zukünftig durch {{ $db.shopData.name }} kontaktiert und per E-Mail
                      über neue Objekte oder Firmenneuigkeiten informiert werden darf.
                    </strong>
                  </td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon v-if="resource.acceptContact" name="check" class="text-green-600" />
                    <fa-icon v-else name="times" class="text-red-600" />
                  </td>
                </tr>

                <tr v-if="resource.acceptGdpr">
                  <td><strong>Speicherung zugestimmt</strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon v-if="resource.acceptStorage" name="check" class="text-green-600" />
                    <fa-icon v-else name="times" class="text-red-600" />
                  </td>
                </tr>
                <tr v-if="resource.acceptGdpr">
                  <td><strong>Hinweispflichten Art. 13/14 der EU-DSGVO zur Kenntnis genommen</strong></td>
                  <td style="width: 50px" class="text-center">
                    <fa-icon name="check" class="text-green-600" />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </section>

        <div v-if="resource.properties.length && resource.lpUrl && !resource.noteAttachments.length" class="mb-3">
          <p>
            Landing-Page URL:
            <br />
            <a :href="resource.lpUrl" target="_blank" class="underline link">{{ resource.lpUrl }}</a>
          </p>
        </div>

        <div class="mb-3" v-if="resource.projects.length > 0">
          <h4 class="text-base">Projekte</h4>
          <nice-bar v-for="item in resource.projects" :key="item.id" type="light" class="mx-0">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </nice-bar>
        </div>
        <div class="mb-3" v-if="resource.properties.length > 0">
          <h4 class="text-base">Objekte</h4>
          <nice-bar v-for="item in resource.properties" :key="item.id" type="light" class="mx-0">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </nice-bar>
        </div>

        <div class="mb-3" v-if="resource.noteAttachments.length > 0">
          <h4 class="text-base">{{ $t("panes.taskDetail.data") }}</h4>
          <nice-bar v-for="item in resource.noteAttachments" :key="item.id" type="light" class="mx-0">
            <a :href="item.url" target="_blank">{{ item.name }}</a>
          </nice-bar>
        </div>
      </main>
    </div>
    <policy-print :open.sync="printPageVisible" :policy="resource" />
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"
import PolicyPrint from "@/views/print/Policy.vue"

export default {
  components: { PolicyPrint },
  props: {
    id: { type: Number },
  },
  data() {
    return {
      loading: true,
      resource: null,
      printPageVisible: false,
    }
  },
  methods: {
    async fetchData() {
      const { policy } = await this.$graphql(`query policy {
        policy(id: ${this.id}) {
          id name messageId category forExposee
          createdAt ip browserName
          selectedCheckboxLabels onlyContactAcceptance
          read acceptTerms acceptPrivacy acceptContact acceptGdpr acceptStorage
          receiverEmail client { id name }
          properties { id name path }
          projects { id name path }
          message { 
            id subject createdAt
            broker { name senderEmail companyName } 
          }
          lpUrl
          brokerOrderTextSnapshot
          noteAttachments { id name url }
        }
      }`)

      this.resource = policy
      this.loading = false
    },
    deleteTask() {
      this.$confirm("Soll die Aktivität wirklich gelöscht werden?", "Aktivität löschen")
        .then(_ => {
          this.$axios.delete(`/services/text_messages/${this.id}`)
          this.$emit("close")
          App.flashy("Aktivität wurde erfolgreich gelöscht")
        })
        .catch(_ => {})
    },
    openMessage() {
      eventBus.$emit("quick-view", { type: "message", mode: "view", id: this.resource.message.id })
    },
  },
  computed: {
    externalId() {
      return `P${this.id}`
    },
    message() {
      return this.resource.message
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
