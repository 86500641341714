import { countriesByAlpha3 } from "@/config/client/countries"
import FORM_FIELDS from "@/config/property/form-fields"
import { camelCase } from "@/utils/with-case"
import i18n from "@/i18n"
import { formatGroupsByCategories } from "@/config/detail-view-form"

const availableFields = [
  "broker_id",
  { id: "add_broker_ids", dbOptions: "brokers" },
  { id: "sub_broker_ids", dbOptions: "brokers" },
  { id: "add_department_ids", dbOptions: "departments" },
  { id: "sub_department_ids", dbOptions: "departments" },
  "property_status_id",
  "title",
  { id: "add_group_ids", dbOptions: "propertyGroups" },
  { id: "sub_group_ids", dbOptions: "propertyGroups" },
  "project_id",
  { id: "location_id", dbOptions: "groupedLocations" },
  { id: "city", type: "string" },
  { id: "country", options: ({ db }) => countriesByAlpha3(db.broker.locale || "de") },
  // "administrative_area_level_1",
  "archived",
  "note",
  "scout_contact_id",
  "scout_group_number",
  "price",
  "team_id",
  "sold_price",
  "maintenance_reserve",
  "rent_subsidy",
  "register_number",
  "land_registry",
  "cadastral_district",
  "plot_number",
  "garden",
  "parking_space_types",
  "number_of_parking_spaces",
  "construction_year",
  "construction_year_unknown",
  "internal_brokerage",
  "courtage",
  "courtage_note",
  "lat",
  "lng",
  {
    id: "internal_commission",
    fieldName: "massedit_internal_commission",
    component: "commission",
    resolve: (payload, value) =>
      Object.assign(payload, {
        internal_commission: value.fixed,
        internal_commission_percentage: value.percentage,
      }),
  },
  {
    id: "external_commission",
    fieldName: "massedit_external_commission",
    component: "commission",
    resolve: (payload, value) =>
      Object.assign(payload, {
        external_commission: value.fixed,
        external_commission_percentage: value.percentage,
      }),
  },
  { id: "openimmo_firstname", fieldName: "massedit_openimmo_firstname", type: "string" },
  { id: "openimmo_lastname", fieldName: "massedit_openimmo_lastname", type: "string" },
  { id: "openimmo_email", fieldName: "massedit_openimmo_email", type: "string" },
  { id: "openimmo_phone", fieldName: "massedit_openimmo_phone", type: "string" },
  { id: "description_note", type: "text" },
  { id: "location_note", type: "text" },
  { id: "furnishing_note", type: "text" },
  { id: "other_note", type: "text" },
]

function customFieldObject(field: Object) {
  return {
    id: `partial_custom_fields__${field.name}`,
    label: field.prettyName,
    dbOptions: field.fieldType == "Broker" ? "activeBrokers" : null,
    options: field.customOptions.length ? field.customOptions : null,
    type: field.fieldType.toLowerCase(),
    isCustomField: true,
  }
}

function customFieldTagsObject(field: Object, action: string, options: any) {
  let translationKey = action == "add" ? "add_group_ids" : "sub_group_ids"
  let translation = i18n.t(`properties.formFields.${translationKey}`)

  return {
    id: `partial_custom_fields__${action}_${field.name}_tags`,
    label: `${translation} ${field.prettyName}`,
    dbOptions: null,
    options: options,
    type: field.fieldType.toLowerCase(),
    isCustomField: true,
  }
}

export function customFieldFormat(field: Object, db: any) {
  if (field.fieldType === "Tags") {
    return customFieldTags(field, "Tags", db)
  } else if (field.fieldType === "TagsByCategory") {
    return customFieldTags(field, "TagsByCategory", db)
  } else {
    return customFieldObject(field)
  }
}

function customFieldTags(field: Object, type: string, db: any) {
  let options: any = []
  if (type == "Tags") {
    options = field.customOptions.length ? field.customOptions : null
  } else if (type == "TagsByCategory") {
    options = formatGroupsByCategories(field)({ db: db })
  }
  let actions = ["add", "sub"]
  const tags: any[] = []
  actions.forEach(action => {
    return tags.push(customFieldTagsObject(field, action, options))
  })
  return tags
}

export const getEditableFields = db => {
  let fields = availableFields.map(key => {
    if (typeof key == "object")
      return { ...key, options: typeof key.options == "function" ? key.options({ db }) : key.options }

    const field = FORM_FIELDS[camelCase(key)]
    return {
      id: key,
      ...field,
      options: typeof field.options == "function" ? field.options({ db }) : field.options,
    }
  })

  fields = fields.concat(
    db.shopData.customFieldGroupsForProperties
      .map(cg => cg.customFields)
      .flat()
      .map(cf => customFieldFormat(cf, db))
      .flat()
  )

  return fields
}
