<template>
  <nice-select
    :value="value"
    @input="$emit('input', $event)"
    :options="options"
    :clearable="clearable"
    :allow-create="allowCreate"
  />
</template>

<script>
import FormFields from "@/config/client/form-fields"
import { getDefaultFields } from "@/config/client/default-client-fields"
import detailViewForm from "@/config/detail-view-form"

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: true,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    if (this.type === "client") {
      const customDetailViewGroups = this.$db.shopData.clientDetailViewGroups
      const clientDetailViewGroups =
        customDetailViewGroups.length > 0 ? customDetailViewGroups : getDefaultFields(this.$db)

      const groups = detailViewForm(
        FormFields,
        clientDetailViewGroups,
        false,
        this.$db,
        this.$db.shopData.customFieldGroupsForClients
      )

      this.options = groups
        .map(g => g.fields)
        .flat()
        .filter(f => ["string", "link", "phone", undefined].includes(f.formField.type))
        .map(f => ({
          id: f.fieldName.startsWith("cf_") ? f.fieldName.replace("cf_", "partial_custom_fields__") : f.fieldName,
          name: f.detailViewField.title || this.$t(`clients.formFields.${f.fieldName}`),
        }))
    }
  },
}
</script>
