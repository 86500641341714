<template>
  <section style="width: 720px" class="first:mt-0 mt-12" ref="body">
    <slot name="title">
      <h3 v-if="title" class="text-xl">{{ title }}</h3>
    </slot>
    <slot name="description">
      <article v-if="description" class="mt-2 text-gray-700 section-article" v-html="description"></article>
    </slot>
    <div class="holder mt-4">
      <spinner v-if="loading"></spinner>
      <slot v-else></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
    },
  },
}
</script>

<style>
.section-article a {
  color: black;
  text-decoration: underline;
}
</style>
