<template>
  <el-slider
    :min="min"
    :max="max"
    :step="step"
    :range="range"
    :show-input="showInput"
    :value="value"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    :label="label"
    :format-tooltip="formatTooltip"
    :readonly="readonly"
    :class="{ readonly: readonly }"
  >
    <slot></slot>
  </el-slider>
</template>

<script>
export default {
  props: {
    value: {},
    label: [String, Number],
    disabled: { type: Boolean, default: false },
    showInput: { type: Boolean, default: false },
    range: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    step: { type: Number, default: 1 },
    formatTooltip: { type: Function },
    readonly: { type: Boolean, default: false },
  },
}
</script>

<style>
.el-slider__runway {
  margin-top: 12px;
  margin-bottom: 12px;
}

.readonly {
  pointer-events: none;
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
</style>