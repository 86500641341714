<template>
  <div>
    <div class="flex items-center">
      <p class="whitespace-nowrap">{{ $t("dashboard.dayFilter.today") }}</p>
      <nice-radio-group
        v-model="plusMinus"
        class="whitespace-nowrap ml-3"
        @change="setValue"
        v-if="!operator"
        :options="[
          { id: 'plus', icon: 'plus' },
          { id: 'minus', icon: 'minus' },
        ]"
      />
      <fa-icon v-else :name="operator" class="whitespace-nowrap ml-2" />
      <nice-input size="small" type="number" v-model="days" placeholder="x" @input="setValue" min="0" class="flex-grow ml-3" />
      <p class="whitespace-nowrap ml-3">{{ $t("dashboard.dayFilter.days") }}</p>
    </div>
    <p class="text-gray-600 text-sm" v-if="nicelyFormatted">~› {{ nicelyFormatted }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    operator: {},
  },
  data() {
    return {
      plusMinus: this.operator || (this.value && this.value < 0 ? "minus" : "plus"),
      days: Number.isInteger(this.value) ? Math.abs(this.value) : null,
    }
  },
  methods: {
    setValue() {
      const value = this.plusMinus == "minus" && Number.isInteger(this.days) ? -this.days : this.days
      this.$emit("input", value)
    },
  },
  computed: {
    nicelyFormatted() {
      if (Number.isInteger(this.value)) {
        return moment().add(this.value, "days").format("LL")
      }
    },
  },
}
</script>