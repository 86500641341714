<template>
  <div>
    <div class="form-group">
      <el-select
        filterable
        v-cancel-read-only
        v-model="ta.payload.propertyStatusId"
        :placeholder="'Status auswählen (aktueller Status: ' + currentStatusName + ')'"
      >
        <el-option v-for="item in shopData.propertyStatuses" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentStatusName() {
      return (
        this.shopData.properties[0] &&
        this.shopData.properties[0].propertyStatus &&
        this.shopData.properties[0].propertyStatus.name
      )
    },
  },
}
</script>
