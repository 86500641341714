<template>
  <vuedraggable v-bind="$attrs" :forceFallback="isSafari" v-on="$listeners">
    <slot></slot>
  </vuedraggable>
</template>

<script>
import vuedraggable from "vuedraggable"

const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1

export default {
  components: { vuedraggable },
  computed: {
    isSafari() {
      return isSafari
    },
  },
}
</script>
