<template>
  <el-divider :direction="direction">
    <slot></slot>
  </el-divider>
</template>

<script>
export default {
  props: {
    direction: { type: String, default: "horizontal" },
  },
}
</script>

<style>
</style>