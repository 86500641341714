<template>
  <form-dialog
    :title="$t('admin.createBrokerModal.title')"
    :visible="visible"
    @update:visible="$emit('visible', $event)"
    @close="$emit('update:visible', false)"
    width="660px"
    footerType="bigButton"
    :submitButtonTitle="$t('admin.createBrokerModal.submit')"
    :disabled="disabled"
    :submitFunc="submit"
    :autofocus="false"
  >
    <form-section>
      <form-row :title="$t('admin.createBrokerModal.emailAddress')">
        <nice-input size="small" v-model="email" placeholder="test@beispiel.de" />
      </form-row>
      <form-row :title="$t('admin.createBrokerModal.senderName')">
        <nice-input size="small" v-model="senderName" />
      </form-row>
      <form-row :title="$t('admin.createBrokerModal.lastname')">
        <nice-input size="small" v-model="lastName" disabled="true" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script lang="ts">
import Vue from "vue"
import { EmailValidation } from "@/config/regex"

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      email: "",
      senderName: "",
    }
  },
  methods: {
    submit() {
      return this.$api
        .create("Broker", {
          email: this.email,
          emailSenderName: this.senderName,
          lastName: this.lastName,
          hideInDropdowns: true,
          role: "technical",
        })
        .then(data => {
          App.flashy(this.$t("admin.createBrokerModal.createSuccessful"))
          this.reset()
          this.$emit("update:visible", false)
          this.$emit("success", data)
        })
    },
    reset() {
      this.email = ""
    },
  },
  computed: {
    disabled(): boolean {
      return !EmailValidation.test(this.email)
    },
    lastName(): string {
      return `${this.email.split("@")?.[0]}@`
    },
  },
})
</script>
