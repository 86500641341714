<template>
  <div class="mb-4">
    <div class="mb-1" v-if="resource.dueDate">
      <label class="mr-1"><fa-icon name="clock" /></label>
      <date-picker
        v-model="resource.dueDate"
        type="datetime"
        format="dd.MM.yyyy HH:mm"
        class="w-100 date-picker-select cursor-pointer"
        :placeholder="$t('panes.taskForm.startsAtPh')"
        size="small"
        :clearable="false"
        :picker-options="{ firstDayOfWeek: 1 }"
        @change="updateDate()"
      />
    </div>
    <section class="py-2" v-if="resource.reviewerIds.includes($db.broker.id)">
      <p class="mb-2">
        <strong>{{ $t("task.todoDetails.review.title") }}</strong>
      </p>
      <div class="btn-group btn-group-toggle" :disabled="!resource.reviewState">
        <nice-radio-group
          v-model="resource.reviewState"
          class="whitespace-nowrap"
          @change="updateReviewState"
          :options="[
            { id: 'approved', name: $t('task.todoDetails.review.approved') },
            { id: 'rejected', name: $t('task.todoDetails.review.rejected') },
          ]"
        />
      </div>
    </section>

    <slot />

    <div ref="checklist" class="mt-3" v-if="resource.todos.length > 0">
      <h4 class="text-base">{{ $t("task.todoDetails.title") }}</h4>
      <div class="flex-grow mt-2 mb-1 relative">
        <span class="text-xs text-gray-500 absolute left-[-41px] top-[-5px] w-[36px] text-center">
          {{ completedPercentage }}%
        </span>
        <nice-progress
          :percentage="(completedTodos.length / resource.todos.length) * 100"
          :show-text="false"
          class="flex-grow"
          :status="completedTodos.length == resource.todos.length ? 'success' : null"
        />
      </div>
      <draggable :sort="resource.canAccess" :list="resource.todos" group="todos" @change="updateOrder">
        <todo-item v-for="item in resource.todos" :key="item.id" :item="item" @edit="editTodo" @delete="deleteTodo" />
      </draggable>
    </div>
    <ps-button class="mt-2" active @click="openNew" icon="plus" :title="$t('task.todoDetails.new')" size="small" />
    <form-resource-dialog
      :label="$t('task.todoDetails.formTitle')"
      :submit-func="saveTodo"
      @complete="todoSaved"
      :editing.sync="editable"
    >
      <template v-slot:form="{ item }">
        <form-section>
          <form-row :title="$t('task.todoDetails.name')">
            <nice-input v-model="item.title" size="small" />
          </form-row>
          <form-row :title="$t('task.todoDetails.assigned')">
            <broker-department-select :value="editable" @input="Object.assign(editable, $event)" />
          </form-row>
          <form-row :title="$t('task.todoDetails.dueDate')">
            <date-picker v-model="item.dueDate" />
          </form-row>
        </form-section>
      </template>
    </form-resource-dialog>
  </div>
</template>

<script>
import TodoItem from "./Todoitem"

import confetti from "canvas-confetti"

let confettiFunc
let myCanvas
function getConfetti(targetEl) {
  if (!confettiFunc) {
    myCanvas = document.createElement("canvas")
    myCanvas.setAttribute("width", "650")
    myCanvas.setAttribute("height", "750")
    myCanvas.classList = "fixed pointer-events-none"
    document.getElementById("quicktask").appendChild(myCanvas)
    confettiFunc = confetti.create(myCanvas)
  }

  myCanvas.style.right = "190px"
  myCanvas.style.top = targetEl.getBoundingClientRect().top - 400 + "px" // "450" -> etwa 2/3 von der canvas height

  return confettiFunc
}

function randomInRange(min, max) {
  return Math.random() * (max - min) + min
}

export default {
  props: {
    resource: {},
  },
  data() {
    return {
      editable: null,
      dueDate: this.resource?.dueDate,
    }
  },
  watch: {
    completedPercentage(newValue) {
      if (newValue == 100) this.confettiAnimation()
    },
  },
  methods: {
    openNew() {
      this.editable = {
        title: "",
        brokerId: null,
        departmentId: null,
      }
    },
    updateOrder() {
      this.$api.mutation("sortTodos", {
        taskId: this.resource.id,
        order: this.resource.todos.map(g => g.id),
      })
    },
    editTodo(todo) {
      this.editable = JSON.parse(JSON.stringify(todo))
    },
    deleteTodo(todo) {
      this.$api.destroy("Todo", todo.id)
      this.resource.todos = this.resource.todos.filter(t => t.id != todo.id)
      App.flashy(this.$t("task.todoDetails.deleteSuccess"))
    },
    saveTodo() {
      const payload = { ..._.omit(this.editable, "id"), taskId: this.resource.id }

      if (this.editable.id) {
        return this.$api.update("Todo", this.editable.id, payload)
      } else {
        return this.$api.create("Todo", payload)
      }
    },
    async todoSaved() {
      const { task } = await this.$graphql(`{
        task(id: ${this.resource.id}) {
          todos { id title brokerId dueDate doneAt departmentId }
        }
      }`)
      this.resource.todos = task.todos
    },
    confettiAnimation() {
      getConfetti(this.$refs.checklist)({
        angle: randomInRange(55, 125),
        spread: randomInRange(55, 65),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      })
    },
    updateReviewState() {
      if (this.resource.reviewState == "approved") this.resource.done = true
      this.$api.mutation("updateTask", {
        id: this.resource.id,
        payload: {
          reviewState: this.resource.reviewState,
        },
      })
    },
    updateDate() {
      const payload = {
        dueDate: this.resource.allDay
          ? moment(this.resource.dueDate).set({ h: 0, m: 0 }).toDate()
          : this.resource.dueDate,
      }
      this.$api
        .mutation("updateTask", { payload, id: this.resource.id }, "task { id }")
        .then(() => {
          App.flashy(this.$t("general.changeSaved"))
        })
        .catch(this.catchError)
    },
    catchError(e) {
      this.resource.dueDate = this.dueDate
      return this.$axios.handleError(e)
    },
  },
  computed: {
    completedTodos() {
      return this.resource.todos.filter(t => t.doneAt)
    },
    completedPercentage() {
      return parseInt((this.completedTodos.length / this.resource.todos.length) * 100)
    },
  },
  components: { TodoItem },
  beforeDestroy() {
    myCanvas = null
    confettiFunc = null
  },
}
</script>

<style>
.date-picker-select {
  width: 120px !important;
}
.date-picker-select > .el-input__inner {
  padding: 0 !important;
  text-align: center !important;
  border: none !important;
}

.date-picker-select > .el-input__suffix,
.date-picker-select > .el-input__prefix {
  display: none;
}
</style>
