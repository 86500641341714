import Vue from "vue"
import Vuex from "vuex"
import inbox from "./modules/inbox"
import searchFilter from "./modules/search-filter"
import lastVisited from "./modules/last-visited"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    inbox,
    searchFilter,
    lastVisited
  }
})
