export default {
  methods: {
    // https://github.com/vuejs/vue/issues/1829#issuecomment-624193371
    // data attributes ändern, ohne watchers zu triggern
    $withoutWatchers(cb) {
      if (!this._watcher) return cb()

      const watcher = {
        cb: this._watcher.cb,
        sync: this._watcher.sync,
      }

      this._watcher = Object.assign(this._watcher, { cb: () => null, sync: true })

      cb()

      this._watcher = Object.assign(this._watcher, watcher)
    },
  },
}
