<template>
  <span>
    <template v-for="translation in record.translations">
      <form-row :key="translation.locale" :title="`${fieldLabel} ${flags[translation.locale]}`">
        <nice-input size="small" v-model="translation[fieldName]" @change="toggleDestroyMark(translation)" />
      </form-row>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
  },
  methods: {
    toggleDestroyMark(translation) {
      if (!translation.id) return

      if (translation[this.fieldName] === "") {
        translation._destroy = true
      } else {
        delete translation._destroy
      }
    },
  },
  computed: {
    locales() {
      return [this.$db.shopData.language].concat(this.$db.shopData.supportedPropertyLocales || []).filter(Boolean)
    },
    flags() {
      return {
        en: "🇬🇧",
        de: "🇩🇪",
        hu: "🇭🇺",
        es: "🇪🇸",
        fr: "🇫🇷",
      }
    },
  },
  created() {
    this.locales.forEach(locale => {
      if (!this.record.translations.find(t => t.locale === locale)) {
        this.record.translations.push({ locale, [this.fieldName]: "" })
      }
    })
  },
}
</script>