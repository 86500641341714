<template>
  <nice-tooltip
    :placement="placement"
    :offset="offset"
    transition="none"
    :disabled="disabled"
    :tabindex="-1"
    :open-delay="delay"
  >
    <template v-slot:content>
      <slot name="content">
        <div v-if="htmlAllowed" v-html="content" :class="{ 'text-center': textCenter }"></div>
        <div v-else v-text="content" :class="{ 'text-center': textCenter }"></div>
      </slot>
    </template>
    <slot />
  </nice-tooltip>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
    },
    offset: {
      type: Number,
    },
    delay: {
      type: Number,
    },
    placement: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    htmlAllowed: {
      type: Boolean,
      default: true,
    },
  },
}
</script>