<template>
  <dropdown button-class="details-action-link">
    <dropdown-section v-if="resource.editableByCurrentBroker">
      <dropdown-item
        :title="$t('project.detailDropdown.new')"
        :link="`/portfolio/projects/${resource.id}/units/new`"
      />
      <dropdown-item :title="$t('project.detailDropdown.transfer')" @click="visible = true" />
      <dropdown-item :title="$t('project.detailDropdown.merge')" @click="mergeVisible = true" />
    </dropdown-section>
    <dropdown-section v-if="$db.broker.canExport">
      <dropdown-item
        :title="$t('project.detailDropdown.export')"
        :link="`/portfolio/projects/${resource.id}/export.xlsx`"
        new-tab
      />
    </dropdown-section>
    <dropdown-section>
      <dropdown-item :title="$t('project.detailDropdown.lp')" :link="`/preview/projects/${resource.id}/lp`" new-tab />
    </dropdown-section>
    <dropdown-section>
      <dropdown-item
        v-if="
          resource.editableByCurrentBroker &&
          ($db.shopData.env == 'development' || $db.shopData.activeFeatures.includes('project_report'))
        "
        @click="projectReportSettingsVisible = true"
        :title="$t('project.detailDropdown.report')"
      />
      <dropdown-item
        :title="$t('project.detailDropdown.reportPreview')"
        :link="`/preview/projects/${resource.id}/report`"
        new-tab
      />
    </dropdown-section>
    <dropdown-section>
      <dropdown-item
        v-for="locale in translatedLocales"
        :key="locale"
        :title="$t('project.detailDropdown.print') + ` [${locale.toUpperCase()}]`"
        :link="`/preview/projects/${resource.id}/print?locale=${locale}`"
        new-tab
      />
    </dropdown-section>
    <dropdown-section v-if="resource.editableByCurrentBroker">
      <dropdown-item
        v-if="$db.broker.canEditUnits"
        :title="resource.archived ? $t('project.detailDropdown.unarchive') : $t('project.detailDropdown.archive')"
        @click="toggleArchive"
      />
      <dropdown-item
        v-if="$db.broker.admin || $db.broker.id == resource.brokerId"
        :title="$t('project.detailDropdown.delete')"
        @click="deleteResource"
        class="text-danger"
      />
    </dropdown-section>
    <dropdown-section v-if="$root.dbackdoor && resource.oldCrmId">
      <tooltip :delay="500">
        <dropdown-item title="Copy old CRM-ID" @click="copyOldCrmId" />
        <template v-slot:content>
          <span>
            ID:
            <span class="font-mono">{{ resource.oldCrmId }}</span>
          </span>
        </template>
      </tooltip>
    </dropdown-section>

    <merge-modal :visible.sync="mergeVisible" :projectId="resource.id" :projectName="resource.name" />
    <project-report-settings :visible.sync="projectReportSettingsVisible" :resource="resource" />

    <form-dialog
      :title="$t('project.detailDropdown.transferData')"
      :visible.sync="visible"
      :saving="submitting"
      @submit="updateProjectUnits"
      :submitButtonTitle="$t('project.detailDropdown.transferSubmit')"
      append-to-body
      :autofocus="false"
    >
      <form-section>
        <form-row :title="$t('project.detailDropdown.override')" direction="column">
          <nice-select :options="updatableFields" v-model="fields" multiple />
          <p class="mt-2">
            <a href="#" @click.prevent="selectAll" class="link text-sm">{{ $t("project.detailDropdown.allFields") }}</a>
          </p>
        </form-row>
      </form-section>
    </form-dialog>
  </dropdown>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { DetailProject } from "@/interfaces"
import FORM_FIELDS from "@/config/project/form-fields"
import eventBus from "@/config/event-bus"
import MergeModal from "./MergeModal.vue"
import ProjectReportSettings from "@/components/project/ProjectReportSettings.vue"
import { camelCase } from "@/utils/with-case"

const SPECIAL_FIELDS_MAPPING = {
  street: "street",
  house_number: "houseNum",
  zip_code: "zipCode",
  city: "city",
  administrative_area_level_1: "area",
  country: "country",
  description_note_en: "descriptionNote",
  location_note_en: "locationNote",
  furnishing_note_en: "furnishingNote",
  long_description_note_en: "longDescriptionNote",
  long_location_note_en: "longLocationNote",
  long_furnishing_note_en: "longFurnishingNote",
  courtage_en: "courtage",
  courtage_note_en: "courtageNote",
}

export default Vue.extend({
  props: {
    resource: Object as PropType<DetailProject>,
  },
  components: {
    MergeModal,
    ProjectReportSettings,
  },
  data() {
    return {
      fields: [] as string[],
      visible: false,
      submitting: false,
      mergeVisible: false,
      projectReportSettingsVisible: false,
    }
  },
  methods: {
    toggleArchive() {
      this.resource.archived = !this.resource.archived
      this.$axios.put(`/portfolio/projects/${this.resource.id}/archive`).then(_ => {
        if (this.resource.archived) {
          App.flashy(this.$t("project.detailDropdown.archiveSuccess"))
        } else {
          App.flashy(this.$t("project.detailDropdown.unarchiveSuccess"))
        }
        eventBus.$emit("project-changed")
      })
    },
    deleteResource() {
      this.$warn(
        {
          title: this.$t("project.detailDropdown.delete"),
          desc: this.$t("project.detailDropdown.delConfirm", { name: this.resource.name }),
          confirmButtonText: this.$t("project.detailDropdown.delBtn"),
          redButton: true,
        },
        () => {
          this.$axios
            .delete(`/portfolio/projects/${this.resource.id}`)
            .then(_ => {
              Turbolinks.visit("/portfolio/projects")
              App.flashy(this.$t("project.detailDropdown.delSuccess", { name: this.resource.name }))
            })
            .catch(this.$axios.handleError)
        }
      )
    },
    selectAll() {
      this.fields = this.updatableFields.map(o => o.id)
    },
    updateProjectUnits() {
      this.submitting = true
      this.$api
        .mutation("updateProjectUnits", { projectId: this.resource.id, fields: this.fields })
        .then(_ => {
          App.flashy(this.$t("project.detailDropdown.updateSuccess"))
          this.visible = false
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.submitting = false))
    },
    copyOldCrmId() {
      this.$util.copy(this.resource.oldCrmId)
      App.flashy("ID successfully copied!")
    },
  },
  computed: {
    updatableFields(): any {
      return [
        "street",
        "house_number",
        "zip_code",
        "city",
        "country",
        "location_id",
        "administrative_area_level_1",
        "lat",
        "lng",
        "hide_address",
        "description_note",
        "location_note",
        "furnishing_note",
        "long_description_note",
        "long_location_note",
        "long_furnishing_note",
        "description_note_en",
        "location_note_en",
        "furnishing_note_en",
        "long_description_note_en",
        "long_location_note_en",
        "long_furnishing_note_en",
        "courtage",
        "courtage_note",
        "courtage_en",
        "courtage_note_en",
        "energy_certificate_availability",
        "energy_certificate_creation_date",
        "energy_certificate_start_date",
        "energy_certificate_end_date",
        "building_energy_rating_type",
        "energy_efficiency_class",
        "construction_year",
        "construction_year_unknown",
        "equipment_technology_construction_year",
        "thermal_characteristic",
        "thermal_characteristic_electricity",
        "thermal_characteristic_heating",
        "co_emission",
        "energy_consumption_contains_warm_water",
        "heating_type",
        "firing_types",
      ].map(key => ({
        id: key,
        name:
          (FORM_FIELDS[camelCase(key)] && this.$t(`project.formFields.${camelCase(key)}`)) ||
          this.$t(`project.formFields.${SPECIAL_FIELDS_MAPPING[key]}`) + (key.slice(-2) == "en" ? " 🇬🇧" : "") || // + key.slice(-2) == "en" ? "🇬🇧" : ""
          key,
      }))
    },
    translatedLocales(): string[] {
      const locales = [this.$db.shopData.language]
        .concat(this.$db.shopData.supportedPropertyLocales || [])
        .filter(Boolean)
      return this.resource.translatedLocales?.filter(locale => locales.includes(locale)) || []
    },
  },
})
</script>
