<template>
  <div class="h-100 w-100 relative">
    <div class="pb-2 mb-2 relative flex flex-col justify-center" :class="{ hidden: hideProgress }" style="height: 43px">
      <div class="relative flex justify-between" style="margin-bottom: -21px">
        <div
          v-for="index in steps"
          :key="index"
          class="rounded-full border-blue-600 border z-10 p-2 text-center"
          :class="activeStep >= index ? 'bg-blue-600 text-white' : 'text-blue-600 bg-white'"
        >
          <p class="text-xl leading-none" style="width: 20px; height: 20px">{{ index }}</p>
        </div>
      </div>
      <nice-progress :percentage="percentageValue" :show-text="false" :status="null" />
    </div>
    <div v-for="index in steps" :key="index" :class="{ 'w-100 h-100': activeStep == index - 1 }">
      <slot :name="`step${index}`" v-if="activeStep == index - 1"></slot>
    </div>
    <slot name="finals" v-if="activeStep == steps" :class="{ 'w-100 h-100': activeStep == steps }"></slot>
    <div
      v-if="activeStep != steps"
      class="flex justify-between absolute bottom-0 right-0 w-100 bg-white"
      style="border-top: 1px #ebebeb solid; padding-top: 10px; z-index: 1001"
    >
      <nice-button
        class="mx-3 w-28"
        @click="$emit('update:activeStep', activeStep - 1)"
        :class="{ invisible: !(activeStep > 0 && activeStep < steps) }"
      >
        Zurück
      </nice-button>
      <nice-button
        v-if="activeStep < steps - 1"
        class="mx-3 w-28"
        type="primary"
        @click="$emit('update:activeStep', activeStep + 1)"
        :disabled="disabled"
      >
        Weiter
      </nice-button>
      <nice-button v-if="activeStep == steps - 1" class="mx-3 w-32" type="primary" @click="submit">
        Fertigstellen
      </nice-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 4,
    },
    activeStep: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideProgress: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$emit("update:activeStep", this.steps)
      this.$emit("submit")
    },
  },
  computed: {
    percentageValue() {
      return (100 / (this.steps - 1)) * this.activeStep
    },
  },
}
</script>