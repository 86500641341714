<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="page == 1 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <div
      v-for="item in items"
      :key="item.id"
      class="flex items-center rounded p-2 mx-1 cursor-pointer hover:bg-black/5"
      :class="{ completed: item.done }"
      @click="open(item)"
    >
      <aside class="mr-2">
        <todo-checkbox :resource="item" @click.native.stop v-if="item.conversation_type === 'reminder'" />
        <activities-icon :resource="item" v-else />
      </aside>
      <main class="flex-grow pl-1">
        <p class="leading-snug">
          {{ item.title }}
          <sub-tasks-indicator
            v-if="item.todos && item.todos.length"
            class="ml-1"
            :subTasks.sync="item.todos"
            :resourceId="item.id"
          />
          <comments-indicator
            resource-type="Task"
            :resource-id="item.id"
            :counter.sync="item.comments_count"
            class="ml-1"
          />
        </p>
        <footer @click.stop class="text-sm">
          <router-link
            v-if="item.client"
            :to="$customFilters.clientPath(item.client.id, $db.broker)"
            @click.native.stop
          >
            {{ item.client.name }}
          </router-link>
          <nice-divider direction="vertical" v-if="item.client && item.property" />
          <router-link v-if="item.property" :to="`/portfolio/properties/${item.property.id}`" @click.native.stop>
            {{ item.property.name }}
          </router-link>
        </footer>
      </main>
      <avatar v-if="item.broker_id != $db.broker.id" :broker="item.broker" class="ml-2 shrink-0" size="24px" />
      <time class="text-gray-600 text-sm ml-2 text-right" style="min-width: 62px">
        {{ $customFilters.calendar(item.due_date || item.created_at) }}
      </time>
    </div>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p v-if="!items.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.todos") }}
    </p>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"
import CommentsIndicator from "@/components/CommentsIndicator"
import SubTasksIndicator from "@/components/SubTasksIndicator"
import PriorityIndicator from "@/components/task/PriorityIndicator"
import TodoCheckbox from "@/components/task/TodoCheckbox"
import eventBus from "@/config/event-bus"
import ActivitiesIcon from "@/components/activities/Icon"
import { openTodosWithReviewStateQuery, assignedOrSubscribedTodosQuery } from "@/config/todo/elastic-payload"

export default {
  components: {
    WidgetContainer,
    CommentsIndicator,
    SubTasksIndicator,
    PriorityIndicator,
    TodoCheckbox,
    ActivitiesIcon,
  },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
      noMore: false,
      page: 1,
    }
  },
  methods: {
    async fetchData(loading = true) {
      if (this.loading) return
      this.loading = loading
      const { data } = await this.$axios.post(`/api/v1/activities/search`, {
        filter_set: { must: this.filters },
        per: 10,
        page: this.page,
        sort_by: "starts_at",
        order: "asc",
        todos: true,
      })

      this.items = this.items.concat(
        data.data.map(item => ({
          ...item,
          is_reminder: true,
          reviewerIds: item.reviewer_ids,
          broker: this.$db.shopData.brokers.find(b => b.id === item.broker_id),
          category: this.$db.shopData.todoCategories.find(b => b.id === item.category_id),
          client: item.clients && item.clients.length ? item.clients[0] : undefined,
          property: item.properties && item.properties.length ? item.properties[0] : undefined,
        }))
      )
      this.total = data.meta.total_count
      this.loading = false
      this.noMore = 10 * this.page >= this.total
      this.page++
    },
    open(item) {
      eventBus.$emit("quick-view", {
        type: item.conversation_type === "reminder" ? "todo" : "decision",
        mode: "view",
        id: item.id,
      })
    },
  },
  computed: {
    filters() {
      const brokerIds =
        this.widget.brokerIds && this.widget.brokerIds.length ? this.widget.brokerIds : [this.$db.broker.id]

      const departmentIds = this.$db.shopData.departments
        .filter(d => brokerIds.some(id => d.brokerIds.includes(id)))
        .map(d => d.id)

      let filters = [
        { terms: { conversation_type: ["reminder", "decision"] } },
        {
          bool: openTodosWithReviewStateQuery(this.$db, brokerIds, departmentIds),
        },
      ]

      filters.push(assignedOrSubscribedTodosQuery(this.$db, brokerIds, departmentIds))

      if (Number.isInteger(this.widget.daysStart)) {
        filters.push({
          bool: {
            should: [
              { range: { starts_at: { gte: moment().add(this.widget.daysStart, "days").startOf("day").format() } } },
              { bool: { must_not: { exists: { field: "starts_at" } } } },
            ],
          },
        })
      }

      if (Number.isInteger(this.widget.daysEnd)) {
        filters.push({
          bool: {
            should: [
              { range: { starts_at: { lte: moment().add(this.widget.daysEnd, "days").endOf("day").format() } } },
              { bool: { must_not: { exists: { field: "starts_at" } } } },
            ],
          },
        })
      }

      if (this.widget.categoryIds && this.widget.categoryIds.length) {
        filters.push({ terms: { category_id: this.widget.categoryIds } })
      }

      if (this.widget.hideWithoutDueDate) {
        filters.push({ exists: { field: "starts_at" } })
      }

      return filters
    },
  },
  mounted() {
    this.fetchData()

    this.$pusher.on(`task:${this.$db.broker.id}:saved`, () => this.fetchData(false))
    this.$pusher.on(`task:${this.$db.broker.id}:deleted`, ({ id }) => {
      this.items = this.items.filter(o => o.id != id)
    })
  },
}
</script>
