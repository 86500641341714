<template functional>
  <ps-row-cell
    :data-column="props.fieldName"
    class="psTable-headerCell"
    :class="[data.staticClass, data.class, { 'js-resizable': props.resizable }]"
    :width="props.width"
  >
    <template v-if="props.sortable">
      <div @click="listeners['sort'](props.fieldName)" style="display: flex; cursor: pointer">
        <slot>{{ props.title }}</slot>
        <tip class="ml-2" v-if="props.hint" :hint="props.hint"></tip>
        <div class="ml-2">
          <div>
            <fa-icon v-show="props.sorted === 'desc'" name="sort-up" class="text-primary" />
            <fa-icon v-show="props.sorted === 'asc'" name="sort-down" class="text-primary" />
            <fa-icon v-show="!props.sorted" name="sort" class="text-light" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <slot>{{ props.title }}</slot>
      <tip class="ml-2" v-if="props.hint" :hint="props.hint"></tip>
    </template>
    <div
      v-if="props.resizable"
      class="js-column-resize-grip"
      style="top: 0px; right: 0px; bottom: 0px; width: 5px; position: absolute; cursor: col-resize"
    >
      &nbsp;
    </div>
  </ps-row-cell>
</template>

<script>
export default {
  methods: {
    sort() {
      let direction
      if (!this.sorted) direction = "asc"
      else if (this.sorted === "asc") direction = "desc"
      else if (this.sorted === "desc") direction = undefined
      this.$emit("sort", direction)
    },
  },
}
</script>
