<template>
  <div class="app-panel">
    <div class="psTable-wrap">
      <div class="psTable">
        <header class="psTable-header" :style="`z-index: ${headerZindex}`">
          <ps-header-cell v-if="selected" :width="34">
            <nice-checkbox class="mt-1" :value="allRowsSelected" @change="toggleSelectedAll"></nice-checkbox>
          </ps-header-cell>
          <ps-header-cell
            v-for="col in columns"
            :key="col.key"
            :fieldName="col.key"
            :title="col.title"
            :width="col.width"
            :sortable="sortable && col.sort"
            :class="{ 'psTable-cell--main': col.main, 'justify-end': col.align == 'right' }"
            :resizable="resizable"
            @sort="() => handleSort(col)"
            :sorted="col.key == sortName ? sortDirection : ''"
            :hint="col.hint"
          />
          <ps-header-cell v-if="$scopedSlots['last-cell']" class="psTable-cell--last" />
        </header>
        <main class="psTable-rows" ref="tableRowsWrapper">
          <component
            :is="sortableSingle ? 'draggable' : 'OnlySlot'"
            :value="data"
            @end="handleRowMove"
            handle=".handle"
          >
            <div class="pageLoader" v-if="initialLoading">
              <span class="spinner"></span>
            </div>
            <ps-table-row
              v-else
              v-for="(resource, $index) in data"
              :key="resource.id"
              @dblclick="visitResource(resource)"
              @click.meta="if (selected) toggleSelected(resource)"
              :archived="resource.archived"
              :class="{
                active: $index + 1 == selectedRowIndex,
                ...getCustomRowClasses(resource, $index),
                'highlighted-dark':
                  uniqueRow(resource) && $db.shopData.activeFeatures.includes('gray_background_for_activities'),
                'highlighted-dark-hover': $db.shopData.activeFeatures.includes('gray_background_for_activities'),
                highlighted: uniqueRow(resource),
                'timeline-future':
                  currentSort &&
                  showFutureSeperator(currentSort) &&
                  currentSort.order === 'desc' &&
                  rowInFuture(resource),
                'timeline-past':
                  currentSort &&
                  showFutureSeperator(currentSort) &&
                  currentSort.order === 'asc' &&
                  rowInFuture(resource),
                blocked: resource.blocked,
              }"
            >
              <ps-row-cell v-if="selected" class="psTable-cell--checkbox" :width="34" @dblclick.stop>
                <nice-checkbox
                  class="mt-1"
                  :value="isSelected(resource)"
                  :disabled="!canSelectItem(resource)"
                  @change="toggleSelected(resource)"
                ></nice-checkbox>
              </ps-row-cell>
              <ps-row-cell
                v-for="col in columns"
                :key="col.key"
                :width="col.width"
                :class="{
                  'psTable-cell--main': col.main,
                  'psTable-cell--wrap': col.wrap,
                }"
              >
                <p class="txt-overflow w-100" :class="{ 'text-right': col.align == 'right' }">
                  <slot :name="col.key" :col="col" :resource="resource" :setSelectedRowIndex="setSelectedRowIndex">
                    <component
                      :is="col.render.component"
                      v-bind="col.render.props(getNestedData(resource, col.key), resource)"
                      v-if="col.render && typeof col.render === 'object'"
                    >
                      {{ getNestedData(resource, col.key) }}
                    </component>
                    {{
                      typeof col.render === "function"
                        ? col.render(getNestedData(resource, col.key), resource)
                        : !col.render
                        ? getNestedData(resource, col.key)
                        : undefined
                    }}
                  </slot>
                </p>
              </ps-row-cell>
              <template v-slot:last>
                <slot name="last-cell" :resource="resource" />
              </template>
              <template v-slot:drag v-if="sortableSingle">
                <a class="ml-3 text-gray-600 handle">
                  <fa-icon name="sort" />
                </a>
              </template>
            </ps-table-row>
          </component>

          <div
            v-if="!loading && data && !data.length && (emptyState || $slots['empty-state'])"
            class="flex justify-center items-center h-100 text-center"
            style="width: calc(100vw - 235px - 50px)"
          >
            <slot name="empty-state">
              <span v-html="emptyState"></span>
            </slot>
          </div>
        </main>
        <footer v-if="$slots['summary-row'] && !loading && data && data.length" class="psTable-footer">
          <slot name="summary-row" />
        </footer>
      </div>
    </div>
    <slot name="footer">
      <ps-pagination
        v-if="per && total"
        @update:page="$emit('update:page', $event)"
        :per="per"
        :page="page"
        :page-sizes="pageSizes"
        @update:per="$emit('update:per', $event)"
        :page-size="data.length"
        :total="total"
      />
    </slot>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"
import TableSelectionMixin from "../../mixins/table-selection"
import TableShortcutsMixin from "../../mixins/table-shortcuts"

export default {
  mixins: [TableSelectionMixin, TableShortcutsMixin],
  components: {
    OnlySlot: { template: `<div><slot /></div>` },
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      default: [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    per: {
      type: Number,
    },
    selected: {
      type: Array,
    },
    pageSizes: {
      type: Array,
    },
    resizable: {
      type: Boolean,
    },
    sortable: {
      type: Boolean,
    },
    sortableSingle: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    emptyState: {
      type: String,
      required: false,
      default() {
        return this.$t("psDataTable.empty")
      },
    },
    rowClassFormatter: {
      type: Function,
      required: false,
    },
    shouldSelectItems: {
      type: Boolean,
      default: false,
    },
    disableDbclick: {
      type: Boolean,
      default: false,
    },
    uniqueRow: {
      type: Function,
      default: () => false,
    },
    currentSort: {
      type: Object,
      default: null,
    },
    showFutureSeperator: {
      type: Function,
      default: () => false,
    },
    headerZindex: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      sortName: "",
      sortDirection: "",
      initialLoading: this.loading,
    }
  },
  watch: {
    loading() {
      this.initialLoading = false
    },
    data() {
      // check if the query contains a object id
      if (Object.keys(this.$route.query).length == 1) {
        const id = Number(Object.values(this.$route.query)[0])
        const dataElement = this.data.find(element => element.id === id)
        this.selectedRowIndex = dataElement ? this.data.indexOf(dataElement) + 1 : null
      }
    },
  },
  mounted() {
    eventBus.$off("quick-view@close")
    eventBus.$on("quick-view@close", _ => this.setSelectedRowIndex(null))
  },
  methods: {
    getCustomRowClasses(resource, index) {
      return this.rowClassFormatter?.(resource, index)?.reduce((agg, cur) => ({ ...agg, [cur]: true }), {})
    },
    handleRowMove(event) {
      const clone = JSON.parse(JSON.stringify(this.data))
      clone.splice(event.newIndex, 0, clone.splice(event.oldIndex, 1)[0])
      this.$emit("update:row-moved", clone)
    },
    getNestedData(obj, key) {
      return _.get(obj, key)
    },
    handleSort(col) {
      if (this.sortName !== col.key) {
        this.sortName = col.key
        this.sortDirection = "asc"
      } else if (this.sortDirection === "asc") {
        this.sortDirection = "desc"
      } else if (this.sortDirection === "desc") {
        this.sortName = undefined
        this.sortDirection = undefined
      }
      this.$emit("sort", { col, direction: this.sortDirection })
    },
    visitResource(resource) {
      if (this.disableDbclick) return
      if (this.$parent.visitResource) {
        this.$parent.visitResource(resource)
      } else {
        if (resource.path) Turbolinks.visit(resource.path)
      }
    },
    rowInFuture(resource) {
      let now = new Date()
      if (!resource.date || new Date(resource.date) <= now) return

      let futureData = this.data.filter(a => new Date(a.date) > now)
      if (this.currentSort.order === "desc") {
        return futureData.pop() === resource
      } else {
        return futureData[0] === resource
      }
    },
  },
}
</script>

<style>
.highlighted {
  background-color: #f9fafb;
}
.highlighted-dark {
  background-color: #ededed;
}
.highlighted-dark-hover:hover {
  background-color: #d8d8d8;
}
.blocked .psTable-cell:not(:first-child) a {
  color: red;
}
.timeline-future {
  border-bottom: 1px blue solid;
  position: relative;
}
.timeline-future::before,
.timeline-future::after,
.timeline-past::before,
.timeline-past::after {
  content: "";
  width: 8px;
  height: 8px;
  background: blue;
  border-radius: 50%;
  position: absolute;
}
.timeline-future::before {
  bottom: -4px;
  left: 0px;
}
.timeline-future::after {
  bottom: -4px;
  right: 0px;
}
.timeline-past {
  border-top: 1px blue solid;
  position: relative;
}
.timeline-past::before {
  top: -4px;
  left: 0px;
}
.timeline-past::after {
  top: -4px;
  right: 0px;
}
</style>
