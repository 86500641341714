<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({ setup() {} })
</script>
<script setup lang="ts">
import { IntegrationConsentResponse } from "@/integrations/types/integration-service"
import useI18n from "@/plugins/use-i18n"
import { computed } from "vue"
import { useRouter } from "vue-router/composables"
import { formatDate, timestampToDate } from "@/utils/formatters/date"
import useCore from "@/plugins/use-core"
import { watch } from "fs"

const props = defineProps<{
  lastConsent: IntegrationConsentResponse
  consentText: string
  consentGiven: boolean
  disabled: boolean
}>()

const emit = defineEmits(["consent-change"])
const formattedDate = computed(() =>
  props.lastConsent?.timestamp ? formatDate(timestampToDate(props.lastConsent.timestamp)) : ""
)
const { db } = useCore()
const activeBrokerId = computed(() => db.broker.id)
const broker = computed(() =>
  props.lastConsent.userId
    ? db.shopData.brokers.find(broker => broker.id === Number(props.lastConsent.userId))
    : undefined
)

const { t } = useI18n()
</script>
<template>
  <tooltip
    class="ml-2"
    :disabled="!lastConsent?.timestamp"
    :content="
      lastConsent?.timestamp ? t('integrations.consent.consentGiven', { user: broker?.name, date: formattedDate }) : ''
    "
  >
    <nice-checkbox
      :value="consentGiven"
      @change="value => emit('consent-change', value)"
      :disabled="disabled"
      class="flex"
    >
      <span v-if="consentText" v-html="consentText" />
      <span v-else>{{ t("integrations.consent.consentGeneric") }}</span>
    </nice-checkbox>
  </tooltip>
</template>
