<template>
  <div class="relative bg-white rounded border shadow-sm">
    <div @click="clicked" :title="title" class="pointer px-3 py-2 text-dark standard-hover d-flex align-items-center">
      <span v-if="$slots.icon" class="mr-3 text-gray-600" :class="iconClass">
        <slot name="icon" />
      </span>
      <span class="txt-overflow w-full">
        <span class="block font-medium">
          <slot name="title">{{ title }}</slot>
        </span>
        <span class="block text-sm text-muted">
          <slot name="subtitle" />
        </span>
      </span>
    </div>
    <hover-actions>
      <slot name="actions" />
    </hover-actions>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
export default Vue.extend({
  props: {
    title: String,
    url: {
      type: String,
      required: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    sensitive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass(): string {
      return this.compact ? "text-xl" : "text-3xl"
    },
  },
  methods: {
    clicked(e) {
      if (this.sensitive && this.$root.backdoor) return App.alert(this.$t("documents.itemBackdoor"))

      if (this.url) window.open(this.url, "_blank")
      this.$emit("click", e)
    },
  },
})
</script>