import config from "@/config/config"
import i18n from "@/i18n/index"
import FroalaEditor from "froala-editor"

import "froala-editor/js/languages/de"
import "froala-editor/js/plugins/draggable.min"
import "froala-editor/js/plugins/font_family.min"
import "froala-editor/js/plugins/inline_style.min"
import "froala-editor/js/plugins/lists.min"
import "froala-editor/js/plugins/paragraph_format.min"
import "froala-editor/js/plugins/paragraph_style.min"
import "froala-editor/js/plugins/print.min"
import "froala-editor/js/plugins/quick_insert.min"
import "froala-editor/js/plugins/table.min"
import "froala-editor/js/plugins/url.min"
// import "froala-editor/js/plugins/special_characters.min"
import "froala-editor/js/plugins/align.min"
import "froala-editor/js/plugins/char_counter.min"
import "froala-editor/js/plugins/code_beautifier.min"
import "froala-editor/js/plugins/code_view.min"
import "froala-editor/js/plugins/colors.min"
import "froala-editor/js/plugins/emoticons.min"
import "froala-editor/js/plugins/entities.min"
import "froala-editor/js/plugins/file.min"
import "froala-editor/js/plugins/font_size.min"
import "froala-editor/js/plugins/forms.min"
import "froala-editor/js/plugins/fullscreen.min"
import "froala-editor/js/plugins/image.min"
import "froala-editor/js/plugins/image_manager.min"
import "froala-editor/js/plugins/line_breaker.min"
import "froala-editor/js/plugins/link.min"
import "froala-editor/js/plugins/quote.min"
import "froala-editor/js/plugins/save.min"
import "froala-editor/js/plugins/video.min"
import "froala-editor/js/plugins/word_paste.min"

export const registerAiCommand = (vueCtx, beforeCb = () => {}, afterCb = text => {}, catchCb = () => {}) => {
  FroalaEditor.DefineIcon("aiIcon", { NAME: "magic" })
  FroalaEditor.RegisterCommand("ai", {
    title: "AI",
    type: "dropdown",
    icon: "aiIcon",
    options: {
      reformulate: vueCtx.$t("toolbar.ai.reformulate"),
      rephrase: vueCtx.$t("toolbar.ai.rephrase"),
      summarize: vueCtx.$t("toolbar.ai.summarize"),
    },

    undo: true,
    focus: true, // Focus inside the editor before callback.
    refreshAfterCallback: true, // Refresh the button state after the callback.

    callback: function (cmd, val, params) {
      const selection = this.selection.get().toString()
      if (selection.length === 0) {
        alert("Bitte markiere den Text, den Du bearbeiten möchtest.")
        return
      }

      beforeCb()
      vueCtx.$api
        .mutation("reformulateUsingAi", { text: selection, action: val }, "text")
        .then(({ text }) => {
          if (text) this.html.insert(text)
          afterCb(text)
        })
        .catch(catchCb)
    },
  })
}

export const registerAiTranslateCommand = (vueCtx, beforeCb = () => {}, afterCb = text => {}, catchCb = () => {}) => {
  FroalaEditor.DefineIcon("translationIcon", { NAME: "language" })
  FroalaEditor.RegisterCommand("translate", {
    title: "Translate",
    type: "dropdown",
    icon: "translationIcon",
    options:
      vueCtx.$db.shopData.messageTranslatorSelections && vueCtx.$db.shopData.messageTranslatorSelections.length
        ? vueCtx.$db.shopData.messageTranslatorSelections.reduce(
            (a, v) => ({ ...a, [v]: vueCtx.$t(`admin.messages.translationOptions.${v}`) }),
            {}
          )
        : ["de", "en", "es"].reduce((a, v) => ({ ...a, [v]: vueCtx.$t(`admin.messages.translationOptions.${v}`) }), {}),

    undo: true,
    focus: true, // Focus inside the editor before callback.
    refreshAfterCallback: true, // Refresh the button state after the callback.
    callback: function (cmd, val, params) {
      const selection = this.selection.get().toString()
      if (selection.length === 0) {
        alert("Bitte markiere den Text, den Du übersetzen möchtest.")
        return
      }

      beforeCb()
      vueCtx.$api
        .mutation("reformulateUsingAi", { text: selection, action: "translate", targetLang: val }, "text")
        .then(({ text }) => {
          if (text) this.html.insert(text)
          afterCb(text)
        })
        .catch(catchCb)
    },
  })
}

const iOS = "ontouchstart" in window || (navigator as any).MaxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0

const BASE_FROALA_OPTIONS = {
  key: config.FROALA_EDITOR_KEY,
  attribution: false,
  charCounterCount: false,
  iconsTemplate: "font_awesome_5l",
  shortcuts: false,
  inlineMode: false,
  language: "de",
  imageButtons: ["linkImage", "replaceImage", "removeImage"],
  imageTitle: false,
  imageResize: true,
  imageUploadParam: "image",
  imageUploadURL: "/services/images",
  // imageOutputSize: true
  imageDefaultAlign: "left",
  enter: (window as any).safari || iOS ? FroalaEditor.ENTER_P : FroalaEditor.ENTER_BR,
  useClasses: false,
  zIndex: 1001,
  htmlIgnoreCSSProperties: [
    "box-sizing",
    "border",
    "line-height",
    "clear",
    "border-style",
    "border-color",
    "border-width",
    "list-style",
    "list-style-type",
    "word-break",
    "word-wrap",
  ],
}

export const initDescriptionEditorFor = ($selector, options = {} as any, initializedCallback = undefined) =>
  new FroalaEditor(
    $selector,
    {
      ...BASE_FROALA_OPTIONS,
      placeholderText: $selector.getAttribute("placeholder") || i18n.t("richtextEditor.placeholder"),
      toolbarButtons: [
        "bold",
        "italic",
        "underline",
        "insertLink",
        "formatOL",
        "formatUL",
        "html",
        "clearFormatting",
        "fullscreen",
      ],
      enter: 0, // $.FE.ENTER_P
      useClasses: true,
      heightMax: 450,
      ...options,
    },
    initializedCallback
  )

export const initHtmlEditorFor = ($selector, options = {} as any, initializedCallback = undefined) =>
  new FroalaEditor(
    $selector,
    {
      ...BASE_FROALA_OPTIONS,
      placeholderText: $selector.getAttribute("placeholder") || i18n.t("richtextEditor.placeholder"),
      toolbarButtons: [
        ...(options.preToolbarButtons || []),
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "insertLink",
        "align",
        "formatUL",
        "formatOL",
        "indent",
        "textColor",
        "fontSize",
        "insertImage",
        "insertTable",
        "html",
        "clearFormatting",
        "fullscreen",
      ],
      tableStyles: {
        "fr-dashed-borders": "Gestrichtelter Rahmen",
        "fr-alternate-rows": "Abwechselnde Zeilen",
        "fr-solid-borders": "Mit Rahmen",
      },
      ...options,
    },
    initializedCallback
  )
