<template>
  <span :class="{ birthday: hasBirthday }">
    <fa-icon v-if="hasBirthday" name="birthday-cake" class="mr-1" />
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    dob: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    hasBirthday() {
      if (!this.dob) return false
      const temp = this.dob.split("-")
      const currentDate = new Date()
      const userDate = new Date(temp[0], temp[1] - 1, temp[2])
      if (userDate.getMonth() === currentDate.getMonth() && userDate.getDate() === currentDate.getDate()) return true
      else return false
    },
  },
}
</script>

<style scoped>
.birthday {
  background: -webkit-linear-gradient(#2200ff, #ff0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>