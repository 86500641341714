<template>
  <div class="event-participant">
    <avatar :broker="broker" />
    <div class="ml-2">
      <span :title="participant.email">{{ name }}</span>
    </div>
    <Label class="status" v-if="accepted" label="zugesagt" color="#22ee00" mini>
      <fa-icon name="check" />
    </Label>
    <Label class="status" v-else-if="declined" color="#ee2200" mini>
      <fa-icon name="times" />
    </Label>
  </div>
</template>

<script>
export default {
  props: ["participant"],

  methods: {},

  computed: {
    name() {
      return this.participant.name || this.participant.email
    },
    accepted() {
      return this.participant.status == "yes"
    },
    declined() {
      return this.participant.status == "no"
    },
    broker() {
      const found = this.$db.get("brokers").find(b => b.email == this.participant.email)

      if (found) return found

      return {
        name: this.name,
        color: "#0f55eb",
      }
    },
  },
}
</script>

<style scoped>
.event-participant {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 0.5rem;
}

.status {
  width: 18px;
  margin-left: 0.5rem;
}
</style>