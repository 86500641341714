<template>
  <div class="table-pagination">
    <strong>
      {{ per * (page - 1) + (pageSize ? 1 : 0) }} – {{ per * (page - 1) + pageSize }} {{ $t("psPagination.of") }}
      {{ $customFilters.prettyNumber(total) }}
    </strong>
    <el-pagination
      background
      @current-change="$emit('update:page', $event)"
      :layout="layoutWithFallback"
      :current-page="page"
      :page-size="per"
      :page-sizes="pageSizes"
      @size-change="$emit('update:per', $event)"
      :total="total"
    />
  </div>
</template>

<script>
export default {
  props: {
    per: {
      type: Number,
    },
    page: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    total: {
      type: Number,
    },
    pageSizes: {
      type: Array,
    },
    layout: {
      type: String,
    },
  },
  computed: {
    layoutWithFallback() {
      if (this.layout) {
        return this.layout
      } else if (this.pageSizes) {
        return "sizes, prev, pager, next"
      } else {
        return "prev, pager, next"
      }
    },
  },
}
</script>
