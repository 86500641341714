import.meta.globEager("../../assets/stylesheets/application.scss")
import.meta.globEager("../assets/element-overrides.scss")
import.meta.globEager("../assets/tw-base-propstack.css")
import.meta.globEager("../assets/tw-theme-propstack.css")

import "@/config/datadog"
import "@/config/vendor"
import Vue, { provide } from "vue"
import store from "../store"
import i18n from "../i18n"
import WithoutWatchers from "../mixins/without-watchers"
// import TurbolinksAdapter from "../mixins/turbolinks-adapter"
import TurboStorage from "../mixins/turbo-storage"
import CustomFilters from "../config/filters"
import utilities from "@/config/util"
import router from "@/router"
import * as auth from "@/router/auth"
import addHeader from "@/utils/add-header"

Vue.config.productionTip = false
if (location.href.includes("debug=1")) Vue.config.devtools = true

Vue.prototype.$customFilters = CustomFilters
Vue.prototype.$util = utilities

import ElementUI from "element-ui"
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) })

import "../assets/style.scss"

import Chartist from "vue-chartist"
import "chartist/dist/chartist.css"
Vue.use(Chartist)

import LightGallery from "vue-light-gallery"
Vue.use(LightGallery)

import FetchPlugin from "../plugins/fetch-plugin"
Vue.use(FetchPlugin)

import RealtimePlugin from "../plugins/realtime-plugin"
Vue.use(RealtimePlugin)

import ApiPlugin from "../plugins/api"
Vue.prototype.$api = ApiPlugin

import Warn from "../plugins/warn-plugin"
Vue.prototype.$warn = Warn

import VueSidebarMenu from "@/components/sidebar-menu"
Vue.use(VueSidebarMenu)

import { VueQueryPlugin } from "@tanstack/vue-query"
import { createQueryClient } from "@/config/query-client"
Vue.use(VueQueryPlugin)

Vue.directive("turbo", el => {
  el.addEventListener("click", e => {
    e.preventDefault()
    e.stopPropagation()
    Turbolinks.visit(el.href)
  })
})

Vue.directive("external-link", (el, options) => {
  el.addEventListener("click", e => {
    e.preventDefault()
    e.stopPropagation()
    window.open(options.value)
  })
})

Vue.directive("cancel-read-only", (el, options) => {
  const input = el.querySelector(".el-input__inner")
  input.removeAttribute("readonly")
})

Vue.directive("jump-scroll", (el, options) => {
  el.addEventListener("click", e => {
    e.preventDefault()
    document.getElementById(options.value).scrollIntoView({ behavior: "smooth" })
  })
})

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value()
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  },
})

Vue.directive("redacted", function (el, binding) {
  el.dataset.hjSuppress = true
  if (auth.isBackdoor()) {
    if (!(binding.modifiers.keepText && (binding.value === undefined || binding.value === true)))
      el.innerHTML = "Redacted"
    if (el.hasAttribute("href")) {
      el.href = "javascript:;"
      el.addEventListener("click", e => {
        e.preventDefault()
        App.alert("Aktion nicht erlaubt durch Support")
      })
    }
  }
})

import Notify from "@/plugins/notify-plugin"
Vue.use(Notify)

import VueTippy, { TippyComponent } from "vue-tippy"
Vue.use(VueTippy, {
  theme: "propstack",
  trigger: "click",
  interactive: true,
})
Vue.component("tippy", TippyComponent)

import db from "../config/db"

Vue.mixin(WithoutWatchers)
Vue.mixin(TurboStorage)

import draggable from "vuedraggable"
Vue.component("draggable", draggable)

const eagerComponents = Object.entries(import.meta.globEager("@/components/**/*.vue")).reduce(
  (acc, [path, definition]) => {
    acc[
      path
        .replace(/^@\//, "")
        .replace(/^\.{2}\//, "")
        .replace(/\.vue$/, "")
    ] = definition.default
    return acc
  },
  {}
)

const requireComponent = path => {
  return eagerComponents[
    path
      .replace(/^@/, "")
      .replace(/^\.{2}/, "")
      .replace(/\.vue$/, "")
  ]
}

Vue.component("nested-connection-item", requireComponent("@/components/connections/NestedConnectionItem.vue"))
Vue.component("page", requireComponent("@/components/PageContainer.vue"))
Vue.component("page-header", requireComponent("@/components/PageHeader.vue"))
Vue.component("header-button", requireComponent("@/components/PageHeaderButton.vue"))
Vue.component("search-input", requireComponent("@/components/SearchInput.vue"))
Vue.component("sort-button", requireComponent("@/components/SortButton.vue"))
Vue.component("avatar", requireComponent("@/components/Avatar.vue"))
Vue.component("fa-icon", requireComponent("@/components/FaIcon.vue"))
Vue.component("Label", requireComponent("@/components/Label.vue"))
Vue.component("ps-data-table", requireComponent("@/components/Table/PsDataTable.vue"))
Vue.component("ps-table", requireComponent("@/components/Table/PsTable.vue"))
Vue.component("ps-header-cell", requireComponent("@/components/Table/PsHeaderCell.vue"))
Vue.component("ps-table-row", requireComponent("@/components/Table/PsTableRow.vue"))
Vue.component("ps-row-cell", requireComponent("@/components/Table/PsRowCell.vue"))
Vue.component("ps-pagination", requireComponent("@/components/Table/PsPagination.vue"))
Vue.component("char-counter", requireComponent("@/components/form/CharacterCounter.vue"))
Vue.component("form-dialog", requireComponent("@/components/form/Dialog.vue"))
Vue.component("form-resource-dialog", requireComponent("@/components/form/ResourceDialog.vue"))
Vue.component("date-picker", requireComponent("@/components/form/DatePicker.vue"))
Vue.component("form-section", requireComponent("@/components/form/Section.vue"))
Vue.component("form-row", requireComponent("@/components/form/Row.vue"))
Vue.component("empty-state", requireComponent("@/components/EmptyState.vue"))
Vue.component("nice-bar", requireComponent("@/components/NiceBar.vue"))
Vue.component("spinner", requireComponent("@/components/Spinner.vue"))
Vue.component("nice-select", requireComponent("@/components/NiceSelect.vue"))
Vue.component("tag-select", requireComponent("@/components/TagSelect.vue"))
Vue.component("nice-textarea", requireComponent("@/components/NiceTextarea.vue"))
Vue.component("nice-checkbox", requireComponent("@/components/NiceCheckbox.vue"))
Vue.component("nice-radio-group", requireComponent("@/components/NiceRadioGroup.vue"))
Vue.component("nice-radio", requireComponent("@/components/NiceRadio.vue"))
Vue.component("nice-radio-button", requireComponent("@/components/NiceRadioButton.vue"))
Vue.component("nice-switch", requireComponent("@/components/NiceSwitch.vue"))
Vue.component("nice-slider", requireComponent("@/components/NiceSlider.vue"))
Vue.component("nice-progress", requireComponent("@/components/NiceProgress.vue"))
Vue.component("nice-tooltip", requireComponent("@/components/NiceTooltip.vue"))
Vue.component("nice-badge", requireComponent("@/components/NiceBadge.vue"))
Vue.component("nice-divider", requireComponent("@/components/NiceDivider.vue"))
Vue.component("nice-input", requireComponent("@/components/NiceInput.vue"))
Vue.component("nice-button", requireComponent("@/components/NiceButton.vue"))
Vue.component("nice-button-group", requireComponent("@/components/NiceButtonGroup.vue"))
Vue.component("nice-tag", requireComponent("@/components/NiceTag.vue"))
Vue.component("dropdown", requireComponent("@/components/Dropdown.vue"))
Vue.component("dropdown-section", requireComponent("@/components/DropdownSection.vue"))
Vue.component("dropdown-item", requireComponent("@/components/DropdownItem.vue"))
Vue.component("list-item", requireComponent("@/components/ListItem.vue"))
Vue.component("ps-button", requireComponent("@/components/PsButton.vue"))
Vue.component("tip", requireComponent("@/components/Tip.vue"))
Vue.component("popover-filter-row", requireComponent("@/components/PopoverFilterRow.vue"))
Vue.component("client-select", requireComponent("@/components/ClientSelect.vue"))
Vue.component("property-select", requireComponent("@/components/PropertySelect.vue"))
Vue.component("project-select", requireComponent("@/components/ProjectSelect.vue"))
Vue.component("db-select", requireComponent("@/components/DbSelect.vue"))
Vue.component("broker-department-select", requireComponent("@/components/BrokerDepartmentSelect.vue"))
Vue.component("snippet-select", requireComponent("@/components/SnippetSelect.vue"))
Vue.component("tooltip", requireComponent("@/components/Tooltip.vue"))
Vue.component("select-popover", requireComponent("@/components/SelectPopover.vue"))
Vue.component("dv-row", requireComponent("@/components/detail-view/Row.vue"))
Vue.component("card", requireComponent("@/components/Card.vue"))
Vue.component("currency-input", requireComponent("@/components/CurrencyInput.vue"))
Vue.component("clipboard-copy", requireComponent("@/components/ClipboardCopy.vue"))
Vue.component("accept-contact", requireComponent("@/components/client/AcceptContact.vue"))
Vue.component("gdpr-status", requireComponent("@/components/client/GdprStatus.vue"))
Vue.component("clients-actions-dropdown", requireComponent("@/components/client/ActionsDropdown.vue"))
Vue.component("base-field", requireComponent("@/components/detail-view/BaseField.vue"))
Vue.component("settings-container", requireComponent("@/components/SettingsContainer.vue"))
Vue.component("settings-section", requireComponent("@/components/SettingsSection.vue"))
Vue.component("settings-list", requireComponent("@/components/settings/SettingsList.vue"))
Vue.component("settings-single", requireComponent("@/components/settings/SettingsSingle.vue"))
Vue.component("settings-service", requireComponent("@/components/settings/SettingsService.vue"))
Vue.component("nestable-list-item", requireComponent("@/components/settings/NestableListItem.vue"))
Vue.component("bubble", requireComponent("@/components/Bubble.vue"))
Vue.component("hover-actions", requireComponent("@/components/HoverActions.vue"))
Vue.component("hover-action", requireComponent("@/components/HoverAction.vue"))
Vue.component("file-icon", requireComponent("@/components/FileIcon.vue"))
Vue.component("rich-textarea", requireComponent("@/components/RichTextarea.vue"))
Vue.component("color-picker", requireComponent("@/components/ColorPicker.vue"))
Vue.component("date-cell", requireComponent("@/components/DateCell.vue"))
Vue.component("alert", requireComponent("@/components/Alert.vue"))
Vue.component("broker-avatars", requireComponent("@/components/BrokerAvatars.vue"))
Vue.component("upload-area", requireComponent("@/components/UploadArea.vue"))
Vue.component("pill", requireComponent("@/components/Pill.vue"))
Vue.component("main-sidebar", requireComponent("@/components/sidebar/MainSidebar.vue"))
Vue.component("admin-sidebar", requireComponent("@/components/sidebar/AdminSidebar.vue"))
Vue.component("promo-highlighter", requireComponent("@/components/PromoHighlighter.vue"))
Vue.component("generic-extensions-page", requireComponent("@/components/GenericExtensionsPage.vue"))
Vue.component("draggable", requireComponent("@/components/Draggable.vue"))
Vue.component("present-brokers", requireComponent("@/components/PresentBrokers.vue"))
Vue.component("copy-to-clipboard", requireComponent("@/components/CopyToClipboard.vue"))

// let brokerData = {}
const getComputed = () => ({
  broker() {
    return db.broker
  },
  shop() {
    return db.shopData
  },
  brokerId() {
    return this.broker?.id
  },
  currentBrokerAdmin() {
    return this.broker?.admin
  },
  backdoor() {
    return auth.isBackdoor()
  },
  development() {
    return this.env == "development"
  },
  dbackdoor() {
    return this.backdoor || this.development
  },
  reshare() {
    return !this.enterprise
  },
  enterprise() {
    return this.shop.enterprise
  },
  env() {
    return this.shop.env
  },
  realtimeSubscriptionNamespace() {
    return db.shopData.realtimeSubscriptionNamespace
  },
  isSpanish() {
    return this.shop?.internationalCountry == "spain"
  },
  intercomActive() {
    return (
      this.env === "production" &&
      !this.backdoor &&
      !this.broker.hideIntercom &&
      db.planActive("pro") &&
      !this.shop.activeFeatures.includes("disable_intercom")
    )
  },
  isTranslation() {
    return location.href.includes("translate=1")
  },
})

new Vue({
  components: {
    VueApp: () => import("@/views/App.vue"),
  },
  i18n,
  router,
  data: {
    headers: {},
  },

  // el: $el,
  // mixins: [TurbolinksAdapter],
  computed: getComputed(),
  store,
  // beforeCreate() {
  //   this.turbolinksKey = turbolinksKey
  //   this.$pusher.init()
  // },
  methods: {
    getFirstChild() {
      // router is the first child
      return this.$children?.[0]?.$children?.[0]
    },
    $refresh() {
      const firstChild = this.getFirstChild()
      firstChild?.$refresh?.()
    },
    overrideBackdoor() {
      window.override_backdoor = true
      this.$refresh()
    },
    bootJipt() {
      const _jipt = []
      _jipt.push(["project", "propstack"])
      globalThis._jipt = _jipt
      this.headers["jipt"] = addHeader("script", {
        // async: true,
        src: `//cdn.crowdin.com/jipt/jipt.js`,
      })
    },
  },
  created() {
    App.info = (title, message) => {
      this.$notify.info({ title, message, duration: 10000 })
    }

    const message = (message, type) => {
      this.$message({
        showClose: true,
        // dangerouslyUseHTMLString: true,
        message,
        type,
        duration: type == "error" ? 3000 : 1500,
      })
    }

    // App.flash = (type, msg) => {
    //   message(msg, type == "success" ? "success" : "error")
    // }

    App.flashy = (msg, type) => {
      message(msg, type == "danger" ? "error" : "success")
    }

    App.alert = msg => {
      message(msg, "error")
    }

    Turbolinks.visit = to => {
      try {
        const url = new URL(to)
        if (url.pathname.startsWith(this.$router.options.base)) {
          to = `/${url.pathname.substring(1).split("/").slice(1).join("/")}`
        } else {
          to = url.pathname
        }
      } catch {}
      this.$router.push(to).catch(() => {}) // redundant navigatiopn error catch
    }

    if (window.alertMessage) {
      message(window.alertMessage, "error")
      window.alertMessage = null
    }

    if (window.successMessage) {
      message(window.successMessage, "success")
      window.successMessage = null
    }

    if (this.isTranslation) {
      this.$root.$i18n.locale = "ach"
      this.bootJipt()
    }

    window.vm = this
  },
}).$mount("#vue-app")
