<template>
  <el-dialog
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :title="title || $t('columnsModal.title')"
    width="450px"
  >
    <a href="#" class="light m-l-15 pull-right -mt-7" @click.prevent="reset" v-if="reset">
      {{ $t("followupsDropdown.reset") }}
    </a>
    <columns-list :columns.sync="columns" :translate-for="translateFor" @change="$emit('change', $event)" />
    <nice-select
      v-model="newColumn"
      :options="translatedColumns"
      @change="addColumn"
      :placeholder="$t('columnsModal.addColumn')"
      class="mt-2"
      id-key="name"
      label-key="title"
    >
      <template v-slot:option="{ item }">
        <span v-if="translateFor">
          {{ $t(`${translateFor}.columns.${item.description || item.name || item.key}`) }}
        </span>
        <span v-else>
          {{ item.description || item.title }}
        </span>
      </template>
    </nice-select>
  </el-dialog>
</template>

<script>
import ColumnsList from "./columns/List.vue"

export default {
  components: {
    ColumnsList,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    translateFor: {
      type: String,
      default: "",
    },
    reset: {
      type: Function,
    },
  },
  data() {
    return {
      newColumn: "",
    }
  },
  methods: {
    addColumn() {
      const updatedColumns = JSON.parse(JSON.stringify(this.columns))
      const current = updatedColumns.find(c => c.name === this.newColumn)
      if (current) current.visible = true
      this.$emit("update:columns", updatedColumns)
      this.newColumn = ""
    },
  },
  computed: {
    translatedColumns() {
      const columnsCopy = JSON.parse(JSON.stringify(this.columns)).filter(c => !c.visible)
      return columnsCopy.map(c => ({
        name: c.description || c.name || c.key,
        title: this.translateFor
          ? this.$t(`${this.translateFor}.columns.${c.description || c.name || c.key}`)
          : c.description || c.title,
      }))
    },
  },
}
</script>
