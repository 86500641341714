<template>
  <el-header height="40px" class="notification-bar">
    <span class="notification-text">{{ message }}</span>

    <a :href="buttonLink" target="_blank" class="notification-button">
      <nice-button size="small" plain class="button-text">{{ buttonText }}</nice-button>
    </a>
  </el-header>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonLink: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss" scoped>
$dark-grey: #555555;
$light-grey: #606266;
$light-warning: #f7db9f;

.notification-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light-warning;
  font-size: 13px;
}

.notification-button {
  margin-left: 10px;
}

.button-text {
  font-size: 11px;
  color: $dark-grey;
  &:hover {
    border-color: $dark-grey;
    color: $dark-grey;
  }
}
</style>
