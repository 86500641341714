<template>
  <draggable :list="columns" @change="onColumnSortUpdate" handle=".sortIndicator">
    <template v-for="item in columns">
      <p v-if="item.visible" class="standardList-item" :key="item.name">
        <fa-icon name="sort" class="sortIndicator mr-2" />
        <span v-if="translateFor" class="standardList-itemTitle">
          {{ $t(`${translateFor}.columns.${item.description || item.name || item.key}`) }}
        </span>
        <span v-else class="standardList-itemTitle">{{ item.description || item.title }}</span>
        <a href="#" @click.prevent="removeColumn(item)">
          <fa-icon name="times" class="text-danger" />
        </a>
      </p>
    </template>
  </draggable>
</template>
<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    translateFor: {
      type: String,
      default: "",
    },
  },

  methods: {
    onColumnSortUpdate(event) {
      // let copy = JSON.parse(JSON.stringify(this.columns))
      // copy.splice(event.newIndex, 0, copy.splice(event.oldIndex, 1)[0])
      // this.$emit("update:columns", copy)
      this.$emit("update:columns", this.columns)
    },
    visibleChanged(item) {
      this.$emit("change", item)
      this.$emit("update:columns", this.columns)
    },
    removeColumn(item) {
      item.visible = false
      this.visibleChanged(item)
    },
  },
}
</script>
