<template>
  <div class="mailingStatButton" @click.prevent="$emit('click')" :style="activeStyle">
    <span class="mb-1">{{ label }}</span>
    <h5 class="text-2xl" :style="{ color: color }">
      <span v-if="total" class="count-percentage">{{ Math.round((count / total) * 100) }}%</span>
      <span class="count-absolute">{{ count }}</span>
    </h5>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
    },
    color: {
      type: String,
      default: "#0f55eb",
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeStyle() {
      if (this.selected) {
        return {
          "border-color": this.color,
        }
      }
    },
  },
}
</script>

<style scoped>
.mailingStatButton {
  flex: 1;
  border: 1px solid #dddddd;
  border-radius: 4px;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mailingStatButton:not(:last-child) {
  margin-right: 0.75rem;
}

.mailingStatButton .count-percentage ~ .count-absolute {
  display: none;
}
.mailingStatButton:hover .count-absolute {
  display: initial;
}
.mailingStatButton:hover .count-percentage {
  display: none;
}
</style>
