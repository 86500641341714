<template>
  <el-popover placement="right" trigger="click" width="600" transition="none">
    <button slot="reference" class="tableActions-action">
      <fa-icon name="filter" class="mr-2" />
      {{ $t("inbox.filter.title") }}
    </button>

    <div class="el-popover-inner">
      <div class="searchFilter">
        <popover-filter-row :title="$t('inbox.filter.onlyUnread')">
          <nice-switch :value="value.onlyUnread" @input="update('onlyUnread', $event)" style="height: 100%" />
        </popover-filter-row>

        <popover-filter-row :title="$t('inbox.filter.hasAttachments')">
          <nice-switch :value="value.hasAttachments" @input="update('hasAttachments', $event)" style="height: 100%" />
        </popover-filter-row>

        <popover-filter-row :title="$t('inbox.filter.category')">
          <db-select collection="messageCategories" :value="value.category" @input="update('category', $event)" />
        </popover-filter-row>

        <popover-filter-row :title="$t('inbox.filter.source')">
          <db-select collection="clientSources" :value="value.source" @input="update('source', $event)" />
        </popover-filter-row>

        <popover-filter-row v-if="$db.get('activityGroups').length > 0" :title="$t('inbox.filter.tags')">
          <db-select collection="activityGroups" :value="value.groupId" @input="update('groupId', $event)" />
        </popover-filter-row>

        <popover-filter-row :title="$t('inbox.filter.recipient')">
          <email-address-select
            :value="value.recipient"
            @input="update('recipient', $event)"
            placeholder="interessent@yahoo.com"
          />
        </popover-filter-row>

        <popover-filter-row :title="$t('inbox.filter.sender')">
          <email-address-select
            :value="value.sender"
            @input="update('sender', $event)"
            placeholder="anfrage@immobilienscout24.de"
          />
        </popover-filter-row>

        <popover-filter-row :title="$t('inbox.filter.linkedProperty')">
          <property-select :value="value.propertyId" @input="update('propertyId', $event)" />
        </popover-filter-row>

        <popover-reset-button @click="clear" />
      </div>
    </div>
  </el-popover>
</template>

<script>
import EmailAddressSelect from "@/components/message/EmailAddressSelect"
import PopoverResetButton from "@/components/PopoverResetButton.vue"

export default {
  props: ["value"],

  components: { EmailAddressSelect, PopoverResetButton },

  methods: {
    update(key, value) {
      this.$emit("input", {
        ...this.value,
        [key]: value,
      })
    },

    clear() {
      this.$emit("input", {
        onlyUnread: null,
        hasAttachments: null,
        category: null,
        source: null,
        sender: null,
        recipient: null,
      })
    },
  },
}
</script>

<style scoped>
.clear {
  text-align: center;
}
</style>
