<template>
  <form-dialog
    :title="$t('deals.customColumnsForm.title')"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    @submit="submit"
    :saving="saving"
  >
    <form-section>
      <form-row :title="$t('deals.customColumnsForm.input')">
        <el-select filterable v-model="selected" :placeholder="$t('deals.customColumnsForm.placeholder')" multiple>
          <el-option v-for="item in translatedOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
const OPTIONS = [
  {
    id: "updated_at",
    name: "updatedAt",
  },
  {
    id: "client.phone",
    name: "phone",
  },
  {
    id: "client.address",
    name: "address",
  },
  {
    id: "client.client_status_id",
    name: "clientStatus",
  },
  {
    id: "client_source_id",
    name: "source",
  },
  {
    id: "client.rating",
    name: "rating",
  },
  {
    id: "client.group_ids",
    name: "tags",
  },
  {
    id: "client.last_contact_at",
    name: "lastContact",
  },
  {
    id: "client.gdpr_status",
    name: "gdpr",
  },
  {
    id: "client.accept_contact",
    name: "acceptContact",
  },
  {
    id: "client.broker_id",
    name: "clientBroker",
  },
  {
    id: "feeling",
    name: "feeling",
  },
  {
    id: "contract_duration",
    name: "contract_duration",
  },
]
export default {
  props: ["visible", "value"],

  data({ value }) {
    return {
      saving: false,
      selected: value,
    }
  },

  methods: {
    submit() {
      this.$api.updateShop({
        visibleColumnsInPropertyDeals: this.selected,
      })

      this.$emit("save", this.selected)
      this.$emit("update:visible", false)
    },
  },
  computed: {
    translatedOptions() {
      return OPTIONS.map(o => ({ ...o, name: this.$t(`deals.columns.${o.name}`) })).concat(
        this.$db
          .get("customFieldGroupsForDeals")
          .flatMap(g => g.customFields.map(cf => ({ id: `cf_${cf.name}`, name: cf.prettyName })))
      )
    },
  },
}
</script>
