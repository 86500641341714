<template>
  <div>
    <slot name="folder" v-bind="{ item, level, toggleOpen, open }"></slot>
    <draggable
      tag="ul"
      :list="item.items"
      :group="{ name: 'g1' }"
      @end="handleEnd"
      draggable=".handle"
      :data-id="item.id"
      :data-can-drop="!item.forUpload"
      :class="classes"
      :move="validateMove"
    >
      <li
        v-show="open"
        v-for="(item, idx) in sortedItems"
        :key="idx"
        :data-id="item.id"
        :class="item.folder ? '' : 'handle'"
      >
        <slot name="item" v-if="!item.folder" v-bind="{ item, level }"></slot>
        <folder
          :ref="item.id"
          v-if="item.folder"
          :item="item"
          @update="handleEnd"
          :folder-id="item.id"
          :level="level + 1"
        >
          <template v-slot:item="{ item, level }">
            <slot name="item" v-bind="{ item, level }"></slot>
          </template>
          <template v-slot:folder="{ item, level, toggleOpen, open }">
            <slot name="folder" v-bind="{ item, level, toggleOpen, open }"></slot>
          </template>
        </folder>
      </li>
    </draggable>
  </div>
</template>

<script>
const twClasses = {
  list: "border border-t-0 rounded pr-2 pb-2 pt-2 bg-gray-50 mt-[-5px] pl-[47px]",
  open: "pb-4 list--open",
  flat: "pb-8",
}

export default {
  name: "folder",
  props: {
    item: { type: Object },
    level: { type: Number, default: () => 0 },
  },
  components: {},
  computed: {
    classes() {
      return {
        [twClasses.open]: this.open,
        [twClasses.list]: this.level > 0,
        [twClasses.flat]: this.level <= 0,
      }
    },
    sortedItems() {
      if (this.item.items) return this.sortItems(this.item.items)
    },
  },
  methods: {
    validateMove(evt) {
      return evt?.relatedContext?.component?.$attrs?.["data-can-drop"] ?? true
    },
    toggleOpen(id) {
      this.open = !this.open
    },
    sortItems(items) {
      const sortedFolders = _.sortBy(
        items.filter(i => i.folder),
        folder => [!folder.forUpload, folder.name.toLowerCase()]
      )
      const sortedItems = _.sortBy(
        items.filter(i => !i.folder),
        doc => (doc.title ? doc.title.toLowerCase() : "")
      )
      return [...sortedFolders, ...sortedItems]
    },
    handleEnd(payload) {
      if (this.level === 0) {
        const itemId = payload.item.dataset.id
        const parentId = payload.to.dataset.id
        this.$emit("update", { itemId, parentId, payload })
      } else {
        this.$emit("update", payload)
      }
    },
  },
  data() {
    return {
      open: !this.item.folder,
    }
  },
}
</script>

<style lang="postcss" scoped>
.list--open:empty::before {
  content: "Keine Dokumente in dem Ordner.";
  @apply block text-gray-600 mt-2;
}
.placeholder {
  padding-top: 36px;
  margin-top: -36px;
  background-color: red;
}
</style>
