<template>
  <form-dialog
    :title="subtracting ? $t('worklists.updateModal.title') : $t('worklists.updateModal.altTitle')"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :saving="saving"
    :disabled="!selectedWorklist"
    @submit="updateWorkList"
    append-to-body
  >
    <form-section>
      <form-row :title="$t('worklists.updateModal.option')">
        <nice-select
          :placeholder="$t('worklists.updateModal.placeholder')"
          v-model="updatableId"
          :options="worklists"
        />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
    },
    selected: {
      type: Array,
      required: true,
    },
    subtracting: {
      type: Boolean,
    },
    entity: {
      type: String,
    },
  },

  data() {
    return {
      saving: false,
      updatableId: null,
    }
  },

  methods: {
    updateWorkList() {
      let params
      if (!this.selectedWorklist || this.saving) return

      try {
        const oldIds = JSON.parse(this.selectedWorklist.params).filter_set.must[0].terms.id.map(Number)
        const selectedIds = this.selected.map(Number)

        if (this.subtracting) {
          var newIds = oldIds.filter(id => !selectedIds.includes(id))
        } else {
          var newIds = [...new Set(selectedIds.concat(oldIds))]
        }

        params = {
          ...JSON.parse(this.selectedWorklist.params),
          filter_set: {
            must: [{ terms: { id: newIds } }],
          },
        }
      } catch (error) {
        App.alert("Die Arbeitsliste ist beschädigt & kann nicht mehr aktualisiert werden.")
        return
      }

      this.saving = true
      this.$api
        .update("SearchFilter", this.updatableId, { params: JSON.stringify(params) })
        .then(_ => {
          App.flashy("Arbeitsliste wurde aktualisiert!")
          this.$emit("update:visible", false)
          this.$emit("clear-selected")
          this.$store.commit("searchFilter/replaceItem", {
            ...this.selectedWorklist,
            params: JSON.stringify(params),
          })
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.saving = false))
    },
  },

  computed: {
    worklistType() {
      return this.entity == "for_properties" ? "propertyWorklists" : "worklists"
    },
    worklists() {
      return this.$store.getters[`searchFilter/${this.worklistType}`]
    },
    selectedWorklist() {
      return this.worklists.find(o => o.id == this.updatableId)
    },
  },
}
</script>