<template>
  <a
    :href="href"
    :disabled="disabled"
    @click.stop="clicked"
    class="hover-action"
    :class="{ 'hover-action--sm': size == 'sm' }"
  >
    <span v-if="loading" class="spinner" />
    <fa-icon v-else-if="icon" :name="icon" :class="{ 'fs-12': size == 'sm', 'fs-14': !size }" />
    <slot />
  </a>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sensitive: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: "#",
    },
    size: {
      type: String,
      required: false,
    },
  },
  methods: {
    clicked(e) {
      if (this.download) return
      if (this.href.startsWith("#")) e.preventDefault()
      if (this.disabled) return
      if (this.sensitive && this.$root.backdoor) return App.alert("Aktion nicht erlaubt von Support")
      this.$emit("click", e)
    },
  },
})
</script>

<style scoped>
.hover-action {
  color: inherit;
  font-size: 12px;
  background: white;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  z-index: 2;
  padding: 6px 10px;
}
.hover-action:hover {
  background: rgb(240, 239, 237);
}
.hover-action[disabled] {
  opacity: 1;
  color: #999999;
  pointer-events: initial;
  cursor: not-allowed;
}
.hover-action--sm {
  padding: 4px 8px;
  font-size: 10px;
}
</style>