<template>
  <el-select
    ref="select"
    :value="value"
    @input="$emit('input', $event)"
    @change="changed"
    :multiple="multiple"
    filterable
    v-cancel-read-only
    clearable
    remote
    allow-create
    :placeholder="placeholder"
    default-first-option
    :remote-method="fetch"
    size="small"
  >
    <el-option v-for="email in items" :key="email" :label="email" :value="email" />
  </el-select>
</template>

<script>
import Fuse from "fuse.js"

let brokerSearch = null

export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: "max@muster.de",
    },
  },
  data() {
    return {
      items: [],
      loading: false,
    }
  },
  methods: {
    changed($event) {
      this.$emit("change", $event)
      this.$refs.select.query = ""
    },

    fetch(q) {
      if (!q) return

      this.loading = true

      this.$graphql(`{ emailAddresses(q: "${q}") }`).then(({ emailAddresses }) => {
        const brokers = brokerSearch.search(q).map(result => result.item.email)
        this.items = _.uniq(emailAddresses.concat(brokers))
        this.loading = false
      })
    },
  },
  mounted() {
    if (!brokerSearch)
      brokerSearch = new Fuse(
        this.$db.shopData.brokers.filter(b => !b.archived),
        {
          keys: ["name", "email"],
          threshold: 0.1,
        }
      )
  },
}
</script>
