<template>
  <li
    class="-mx-2 px-2 hover-bg-color rounded search-image-li"
    :class="{
      active: active,
    }"
    @click="$emit('click')"
  >
    <router-link class="flex py-1 justify-between items-center" :to="url">
      <div class="truncate">
        <span :class="{ 'flex items-center flex-grow shrink-0 font-semibold': !icon }">
          <slot name="icon">
            <fa-icon v-if="icon" class="text-lg fa-fw mr-2" :name="icon" />
          </slot>
          <slot name="title">
            <span class="font-semibold">{{ title }}</span>
          </slot>
        </span>
        <slot></slot>
      </div>
      <slot name="subtitle">
        <span v-if="subTitle" v-redacted class="text-gray-600 text-sm">{{ subTitle }}</span>
      </slot>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    icon: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped>
.active {
  background-color: #00000015;
}
.search-image-li::after {
  clear: both;
}
.search-image-li::after,
.search-image-li::before {
  content: " ";
  display: table;
}

.search-image-li > a {
  display: flex;
}

.text-gray-600 text-sm {
  color: gray;
}

.hover-bg-color:hover {
  background-color: #00000015;
}
</style>
