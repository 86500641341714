<template>
  <div>
    <div class="mb-2">
      <el-select filterable v-cancel-read-only v-model="ta.payload.snippetId" placeholder="Textbaustein auswählen">
        <el-option v-for="item in shopData.snippets" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </div>
    <div class="mb-2">
      <input type="checkbox" v-model="ta.openForm" class="mr-1" :id="'ta_open_form_' + ta.id" />
      <label :for="'ta_open_form_' + ta.id">E-Mail-Formular im Anschluss öffnen</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shopData: {
      type: Object,
      required: true,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
}
</script>
