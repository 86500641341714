<template>
  <li :data-id="item.id" class="standardList-item relative bg-white rounded border shadow-sm">
    <router-link
      v-if="item.clientId"
      :to="`/contacts/clients/${item.clientId}`"
      class="standardList-itemTitle text-black"
    >
      {{ item.name }}
    </router-link>
    <span v-else class="standardList-itemTitle text-black">
      {{ item.name }}
      <tooltip class="ml-2 text-yellow-600" :content="$t('task.viewings.item.noContact')">
        <fa-icon name="user-alt-slash" />
      </tooltip>
    </span>

    <span class="mr-2 text-sm text-gray-500" :title="$customFilters.datetime(item.viewingDate)">
      {{ $t("task.viewings.item.time", { time: $customFilters.time(item.viewingDate) }) }}
    </span>

    <hover-actions>
      <hover-action v-if="!item.clientId" icon="plus" @click.prevent="addClientClicked(item)" />
      <hover-action
        v-if="item.email"
        icon="paper-plane"
        @click.prevent="quickviewMail(item.email, resource.id, item.id)"
      />
      <hover-action
        v-if="item.phone"
        icon="phone"
        :href="$customFilters.phonify(item.phone)"
        :data-client-id="item.clientId"
      />
      <hover-action @click="deleteItem" icon="trash-alt" class="text-danger" />
    </hover-actions>
  </li>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  props: {
    resource: {},
    item: {},
  },
  methods: {
    quickviewMail(email, taskId, viewingId) {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            viewingId,
            taskId,
            email,
          },
        },
      })
    },
    deleteItem() {
      this.$warn(
        {
          title: this.$t("task.viewings.delete.title"),
          desc: this.$t("task.viewings.delete.desc"),
          confirmButtonText: this.$t("task.viewings.delete.submit"),
        },
        () => {
          this.$axios.delete(`/viewings/${this.item.id}`)
          this.$emit("remove", this.item)
          App.flashy(this.$t("task.viewings.delete.success"))
        }
      )
    },
    addClientClicked(item) {
      eventBus.$emit("add-client", item)
    },
  },
}
</script>