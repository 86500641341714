import FORM_FIELDS from "@/config/client/form-fields"
import i18n from "@/i18n/index"
import {
  getConditions,
  getInvestmentCategories,
  getMarketingTypes,
  getPurchaseForms,
  getRecommendedUseTypes,
  getRelevantRsCategories,
  getRelevantRsTypes,
} from "./get-property-options-mapping"
import { formatGroupsByCategories } from "@/config/detail-view-form"

const snakeCase = (text: string): string =>
  text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase()

const optionsType = {
  name: "String",
  emails: "String",
  address: "String",
  phone: "String",
  link: "String",
  boolean: "Boolean",
  date: "Date",
  dropdown: "Array",
  string: "String",
  text: "String",
  relationship: "Array",
  children: "String",
  phones: "String",
  multiselect: "Array",
  number: "Number",
  richtext: "String",
}

export const getClientColumns = db => {
  const options: any[] = []

  const mappedOptions = Object.entries(FORM_FIELDS)
    .map(([k, v]) => ({
      group: v.group,
      value: v.filterName ? v.filterName : snakeCase(k),
      type: v.type ? optionsType[v.type] : "String",
      label: v.label,
      dboptions: v.dbOptions,
      options:
        typeof v.options == "function"
          ? v.options({ db })
          : v.options && v.options.length
          ? v.options.map(o => ({
              ...o,
              name: o.translate ? i18n.t(`clients.formFieldOptions.${o.name}`) : o.name,
            }))
          : null,
      filterable: v.filterable,
      remote: v.group == "Verknüpfungen" ? "client" : null,
      unit: v.unit,
    }))
    .filter(n => (typeof n.filterable == "function" ? n.filterable({ db }) : n.filterable))
  options.push(...mappedOptions)

  options.push(
    ...[
      // {
      //   group: 'general',
      //   value: 'item_id',
      //   type: 'String',
      //   label: 'Kundennummer'
      // },
      {
        group: "general",
        value: "salutation",
        type: "Array",
        options: [
          { id: "ms", name: "Frau" },
          { id: "mr", name: "Herr" },
        ].concat(db.shopData.salutations.map(o => ({ id: o.internalName, name: o.name }))),
      },
      {
        group: "general",
        value: "creator_id",
        type: "Array",
        dboptions: "activeBrokers",
      },
      {
        group: "client",
        value: "phone_numbers",
        type: "String",
      },
      {
        group: "general",
        value: "academic_title",
        type: "String",
      },
      {
        group: "general",
        value: "first_name",
        type: "String",
      },
      {
        group: "general",
        value: "last_name",
        type: "String",
      },
      {
        group: "client",
        value: "street_address",
        type: "String",
      },
      {
        group: "client",
        value: "zip_code",
        type: "String",
      },
      {
        group: "client",
        value: "city",
        type: "String",
      },
      {
        group: "client",
        value: "home_country",
        type: "Array",
        options: db.shopData.countries.map(c => ({ id: c.name, name: c.name })),
      },
      {
        group: "general",
        value: "newsletter_unsubscribed",
        type: "Boolean",
      },
      {
        group: "general",
        value: "email_verified",
        type: "Boolean",
      },
      {
        group: "general",
        value: "created_at",
        type: "Date",
      },
      {
        group: "general",
        value: "updated_at",
        type: "Date",
      },
      {
        group: "general",
        value: "last_contact_at",
        type: "Date",
      },
      {
        group: "company",
        value: "is_company",
        type: "Boolean",
      },
      {
        group: "company",
        value: "office_zip_code",
        type: "String",
      },
      {
        group: "company",
        value: "office_city",
        type: "String",
      },
      {
        group: "company",
        value: "office_country",
        type: "Array",
        options: db.shopData.countries.map(c => ({ id: c.name, name: c.name })),
      },
      {
        group: "leadScore",
        value: "score",
        type: "Number",
      },
      {
        group: "general",
        value: "rent_date",
        type: "String",
      },
    ]
  )

  if (db.shopData.env === "development" || db.shopData.addonsEnabled) {
    options.push(
      ...[
        {
          group: "leadScore",
          value: "phone_number_validated",
          type: "Boolean",
        },
        {
          group: "leadScore",
          value: "email_validated",
          type: "Boolean",
        },
        {
          group: "leadScore",
          value: "home_address_validated",
          type: "Boolean",
        },
      ]
    )
  }

  if (db.shopData.enterprise) {
    options.push(
      ...[
        {
          group: "savedquery",
          value: "saved_queries_count",
          type: "Number",
          filterOptions: ["missing", "exists"],
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.active",
          type: "Boolean",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.property_group_ids",
          type: "Array",
          dboptions: "propertyGroups",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.location_ids",
          type: "Array",
          dboptions: "groupedLocations",
          array: true,
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.cities",
          type: "Array",
          options: db.shopData.cities.map(o => ({ id: o, name: o })),
          array: true,
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.regions",
          type: "Array",
          options: db.shopData.regions.map(o => ({ id: o, name: o })),
          array: true,
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.marketing_type",
          type: "Array",
          options: getMarketingTypes(db).map(({ value, label }) => ({ id: value, name: label })),
          null: true,
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.rs_types",
          type: "Array",
          options: getRelevantRsTypes(db, true).map(({ value, label }) => ({ id: value, name: label })),
          array: true,
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.rs_categories",
          type: "Array",
          options: getRelevantRsCategories(db, true).map(({ value, label }) => ({ id: value, name: label })),
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.price",
          type: "Number",
          range: "saved_queries.price",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.price_to",
          type: "Number",
          range: "saved_queries.price",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.base_rent_to",
          type: "Number",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.living_space",
          type: "Number",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.net_floor_space",
          type: "Number",
          range: "saved_queries.net_floor_space",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.net_floor_space_to",
          type: "Number",
          range: "saved_queries.net_floor_space",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.number_of_rooms",
          type: "Number",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.number_of_bed_rooms",
          type: "Number",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.condition",
          type: "Array",
          options: getConditions(db).map(({ label, value }) => ({ id: value, name: label })),
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.rented",
          type: "Boolean",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.recommended_use_types",
          type: "Array",
          options: getRecommendedUseTypes(db).map(({ label, value }) => ({ id: value, name: label })),
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.investment_category",
          type: "Array",
          options: getInvestmentCategories(db).map(({ label, value }) => ({ id: value, name: label })),
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.purchase_form",
          type: "Array",
          options: getPurchaseForms(db).map(({ label, value }) => ({ id: value, name: label })),
        },
      ]
    )

    options.push(
      ...db.shopData.customFieldGroupsForProperties
        .map(g => g.customFields)
        .flat()
        .filter(cf => cf.forSavedQueries)
        .map(cf => getCustomField(cf, db.shopData.countries, "query", "saved_queries"))
    )

    options.push(
      ...[
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.updated_at",
          type: "Date",
        },
        {
          group: "savedquery",
          nested: "saved_queries",
          value: "saved_queries.created_at",
          type: "Date",
        },

        // # {
        // #     group: 'inquiry',
        // #     nested: 'inquiries',
        // #     value: 'inquiries.source_id',
        // #     type: 'Array',
        // #     options: @shop.client_sources.map(&:json_format),
        // #     label: 'Anfrage-Quellen'
        // #   },
        //   # Vandenberg braucht diesen Filter für Nachfassmail: Kein Kontakt...
        {
          group: "inquiry",
          nested: "inquiries",
          value: "inquiries.created_at",
          type: "Date",
        },
        {
          group: "inquiry",
          nested: "inquiries",
          value: "inquiries.property_ids",
          type: "Array",
          remote: "property",
        },
        {
          group: "inquiry",
          nested: "inquiries",
          value: "inquiries.project_ids",
          type: "Array",
          dboptions: "projects",
        },

        {
          group: "reasons",
          nested: "reservation_reasons",
          value: "reservation_reasons.reservation_reason_id",
          type: "Array",
          dboptions: "cancelationReasons",
        },
        {
          group: "reasons",
          nested: "reservation_reasons",
          value: "reservation_reasons.property_ids",
          type: "Array",
          remote: "property",
        },
        {
          group: "reasons",
          nested: "reservation_reasons",
          value: "reservation_reasons.project_ids",
          type: "Array",
          dboptions: "projects",
        },

        {
          group: "deals",
          nested: "client_properties",
          value: "client_properties.deal_stage_id",
          type: "Array",
          dboptions: "groupedDealStages",
        },
        {
          group: "deals",
          nested: "client_properties",
          value: "client_properties.start_date",
          type: "Date",
        },
        {
          group: "deals",
          nested: "client_properties",
          value: "client_properties.property_id",
          type: "Array",
          remote: "property",
        },
        {
          group: "deals",
          nested: "client_properties",
          value: "client_properties.project_id",
          type: "Array",
          dboptions: "projects",
        },
        {
          group: "relations",
          value: "project_ids",
          type: "Array",
          dboptions: "projects",
        },
        {
          group: "relations",
          value: "related_client_ids",
          type: "Array",
          remote: "client",
        },
        {
          group: "relations",
          value: "owned_property_ids",
          type: "Array",
          remote: "property",
        },
        // # {
        // #   group: 'relations',
        // #   value: 'related_property_ids',
        // #   type: 'Array',
        // #   remote: 'property',
        // #   label: 'Ansprechpartner für'
        // # },
        // # {
        // #   group: 'Notizen',
        // #   nested: 'notes',
        // #   value: 'notes.note_type_id',
        // #   type: 'Array',
        // #   options: @shop.note_types.for_notes.map(&:json_format),
        // #   label: 'Notiztyp'
        // # },
        // # {
        // #   group: 'Notizen',
        // #   nested: 'notes',
        // #   value: 'notes.created_at',
        // #   type: 'Date',
        // #   label: 'Notiz-Erstellungsdatum'
        // # },
        // # {
        // #   group: 'Notizen',
        // #   nested: 'notes',
        // #   value: 'notes.project_ids',
        // #   type: 'Array',
        // #   dboptions: :projects,
        // #   label: 'Projekt'
        // # },
        // # {
        // #   group: 'Notizen',
        // #   nested: 'notes',
        // #   value: 'notes.group_ids',
        // #   type: 'Array',
        // #   options: @shop.groups.for_activities.map(&:json_format),
        // #   label: 'Notiz-Merkmale'
        // # },
        // # {
        // #   group: 'Aufgaben',
        // #   nested: 'reminders',
        // #   value: 'reminders.note_type_id',
        // #   type: 'Array',
        // #   options: @shop.note_types.for_reminders.map(&:json_format),
        // #   label: 'Aufgaben-Typ'
        // # },
        // # {
        // #   group: 'Aufgaben',
        // #   nested: 'reminders',
        // #   value: 'reminders.starts_at',
        // #   type: 'Date',
        // #   label: 'Aufgaben-Fälligkeit'
        // # },
        // # {
        // #   group: 'Aufgaben',
        // #   nested: 'reminders',
        // #   value: 'reminders.project_ids',
        // #   type: 'Array',
        // #   dboptions: :projects,
        // #   label: 'Projekt'
        // # },
        // # {
        // #   group: 'Aufgaben',
        // #   nested: 'reminders',
        // #   value: 'reminders.group_ids',
        // #   type: 'Array',
        // #   options: @shop.groups.for_activities.map(&:json_format),
        // #   label: 'Aufgaben-Merkmale'
        // # },
        // # {
        // #   group: 'Termine',
        // #   nested: 'events',
        // #   value: 'events.note_type_id',
        // #   type: 'Array',
        // #   options: @shop.note_types.for_events.map(&:json_format),
        // #   label: 'Termin-Typ'
        // # },
        // # {
        // #   group: 'Termine',
        // #   nested: 'events',
        // #   value: 'events.starts_at',
        // #   type: 'Date',
        // #   label: 'Termin-Datum'
        // # },
        // # {
        // #   group: 'Termine',
        // #   nested: 'events',
        // #   value: 'events.project_ids',
        // #   type: 'Array',
        // #   dboptions: :projects,
        // #   label: 'Projekt'
        // # },
        // # {
        // #   group: 'Termine',
        // #   nested: 'events',
        // #   value: 'events.group_ids',
        // #   type: 'Array',
        // #   options: @shop.groups.for_activities.map(&:json_format),
        // #   label: 'Termin-Merkmale'
        // # },
      ]
    )
  }

  options.push(
    ...db.shopData.customFieldGroupsForClients.map(g => g.customFields.map(cf => getCustomField(cf, db, g.name))).flat()
  )

  if (db.shopData.env === "development" || db.shopData.id == 39) {
    // Vandenberg
    options.push(
      ...[
        {
          group: "clientAcc",
          value: "confirmed_at",
          type: "Date",
        },
        {
          group: "clientAcc",
          value: "has_account",
          type: "Boolean",
        },
      ]
    )
  }

  options.push(
    ...[
      {
        group: "gdpr",
        value: "cp_delete_request_date",
        type: "Date",
      },
    ]
  )

  return options
}

const getCustomField = (cf, db, group, nested?) => {
  let filterType
  let filterOptions
  let dbOptions
  switch (cf.fieldType) {
    case "Broker":
    case "CountrySelect":
    case "Dropdown":
    case "Tags":
    case "TagsByCategory":
    case "Multiselect":
      filterType = "Array"
      break
    case "Boolean":
      filterType = "Boolean"
      break
    case "Number":
      filterType = "Number"
      break
    case "Date":
    case "DateTime":
      filterType = "Date"
      break
    case "String":
    case "Text":
      filterType = "String"
  }
  switch (cf.fieldType) {
    case "Dropdown":
    case "Tags":
    case "Multiselect":
      filterOptions = cf.customOptions.map(c => ({ id: c.id, name: c.name }))
      break
    case "TagsByCategory":
      filterOptions = formatGroupsByCategories(cf)({ db: db })
      break
    case "Broker":
      dbOptions = "activeBrokers"
      break
    case "CountrySelect":
      filterOptions = db.shopData.countries.map(c => ({ id: c.id, name: c.name }))
      break
  }
  return {
    group: group,
    nested: nested,
    value: [nested, cf.indexName].filter(Boolean).join("."),
    type: filterType,
    dboptions: dbOptions,
    options: filterOptions,
    label: cf.prettyName,
    isCustomField: true,
  }
}
