<template>
  <div class="searchFilter-item">
    <a href="#" @click.prevent="$emit('remove')" class="searchFilter-remove">
      <fa-icon name="times" />
    </a>
    <div class="searchFilter-column">
      <nice-select
        v-model="condition.key"
        @change="resetCondition"
        :options="variables"
        placeholder="Attribut"
        allow-create
      />
    </div>
    <div class="mr-2" style="width: 150px">
      <nice-select
        v-model="condition.match"
        placeholder="Operator"
        :options="operators"
        label-key="label"
        id-key="value"
      />
    </div>
    <div :class="{ invisible: condition.match == 'exists' || condition.match == 'missing' }" style="width: 250px">
      <db-select
        key="brokers"
        v-if="selectedVariable && selectedVariable.type == 'broker'"
        v-model="condition.value"
        collection="unarchivedBrokers"
        allow-create
      />
      <project-select
        key="projects"
        v-else-if="selectedVariable && selectedVariable.type == 'project'"
        v-model="condition.value"
        allow-create
      />
      <db-select
        :key="selectedVariable.dboptions"
        v-else-if="selectedVariable && selectedVariable.dboptions"
        v-model="condition.value"
        :collection="selectedVariable.dboptions"
        allow-create
      />
      <nice-select
        :key="selectedVariable.name"
        v-else-if="selectedVariable && selectedVariable.plabeloptions"
        v-model="condition.value"
        :options="$db.shopData.propertyOptionsMapping[selectedVariable.plabeloptions]"
        allow-create
      />
      <nice-select
        :key="selectedVariable.name"
        v-else-if="selectedVariable && selectedVariable.grouped"
        v-model="condition.value"
        :options="actionOptionsData[selectedVariable.grouped]"
        allow-create
        :children-key="selectedVariable.options"
        grouped
      />
      <nice-select
        :key="selectedVariable.name"
        v-else-if="selectedVariable && selectedVariable.options"
        v-model="condition.value"
        :options="selectedVariable.options"
        allow-create
      />
      <nice-input size="small" v-else v-model="condition.value" />
    </div>
  </div>
</template>

<script>
import { OPERATORS, getVariablesForModel } from "@/config/recipes"

const BOOLEAN_OPERATORS = [
  {
    value: "exists",
    label: "ist aktiv",
  },
  {
    value: "missing",
    label: "ist inaktiv",
  },
]

export default {
  props: {
    condition: {
      type: Object,
      required: true,
    },
    actionOptionsData: {
      type: Object,
    },
    recipes: {
      type: Array,
      required: true,
    },
    recipe: {
      type: Object,
      required: true,
    },
    recipesConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groupedOptions: null,
    }
  },
  methods: {
    resetCondition() {
      this.condition.match = undefined
      this.condition.value = undefined
    },
  },
  computed: {
    selectedTrigger() {
      if (!this.recipe.triggerType) return null
      return this.recipesConfig.triggers.find(a => a.name == this.recipe.triggerType)
    },
    selectedAction() {
      if (!this.recipe.actionType) return null
      return this.recipesConfig.actions.find(a => a.name == this.recipe.actionType)
    },
    triggerModel() {
      return this.selectedTrigger?.model
    },
    variables() {
      if (this.triggerModel) {
        return getVariablesForModel(this.$db, this.recipesConfig, this.triggerModel).filter(v => v.label)
      } else {
        let variables = []
        const previousRecipe = this.recipes.find(r => r.nextRecipeIds?.includes(this.recipe.id))
        const triggerModel = this.recipesConfig.triggers.find(a => a.name == previousRecipe?.triggerType)?.model
        if (triggerModel) {
          variables = variables.concat(
            getVariablesForModel(this.$db, this.recipesConfig, triggerModel, { prefix: "previous__" })
          )
        }

        const actionModel = this.recipesConfig.actions.find(a => a.name == previousRecipe?.actionType)?.model
        if (actionModel) {
          variables = variables.concat(getVariablesForModel(this.$db, this.recipesConfig, actionModel))
        }

        return variables.filter(v => v.label)
      }
    },
    selectedVariable() {
      return this.variables.find(o => o.id == this.condition.key)
    },
    operators() {
      if (this.selectedVariable?.type == "boolean") return BOOLEAN_OPERATORS

      return OPERATORS
    },
  },
}
</script>
