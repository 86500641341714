<template>
  <div
    class="cursor-pointer p-2 bg-gray-50 rounded border border-gray-100 relative hover:border-gray-200 hover:bg-gray-100 transition-all"
    @click="visit"
    style="flex: 0 0 auto; min-height: 58px"
    :class="{
      archived: resource.done,
      'bg-red-50 hover:bg-red-100 border-red-200 hover:border-red-300': resource.priority > 0 && !resource.done,
      'bg-blue-50 hover:bg-blue-100 border-blue-200 hover:border-blue-300': resource.priority < 0 && !resource.done,
    }"
  >
    <div>
      <p class="font-medium">
        {{ resource.title }}
      </p>
      <p class="text-sm text-gray-600">
        <span v-for="(c, idx) in resource.clients" :key="c.id">
          <nice-divider direction="vertical" v-if="idx > 0" class="mx-1" />
          <router-link :to="$customFilters.clientPath(c.id)" class="link" @click.native.stop>
            {{ c.name }}
          </router-link>
        </span>
        <nice-divider direction="vertical" v-if="resource.clients.length && resource.properties.length" class="mx-1" />
        <router-link
          v-for="p in resource.properties"
          :key="p.id"
          :to="`/portfolio/properties/${p.id}`"
          class="link"
          @click.native.stop
        >
          {{ p.name }}
        </router-link>
      </p>
      <footer class="flex items-center text-sm mt-1 justify-between">
        <aside class="flex items-center">
          <avatar :broker-id="resource.brokerId" size="20px" class="mr-2" />
          <span
            v-if="resource.dueDate"
            :class="{
              'text-red-600': $customFilters.isInPast(resource.dueDate),
              'text-yellow-600': $customFilters.isToday(resource.dueDate),
            }"
          >
            {{ $customFilters.date(resource.dueDate) }}
          </span>
        </aside>
        <comments-indicator resource-type="Task" :resource-id="resource.id" :counter.sync="resource.commentsCount">
          <template v-slot:default="{ counter }">
            <span v-if="counter" class="text-xs text-gray-700">
              {{ counter }}
              <fa-icon name="comment" />
            </span>
          </template>
        </comments-indicator>
      </footer>
    </div>
    <!-- <dropdown class="absolute top-2 right-2 invisible group-hover:visible">
      <dropdown-section>
        <dropdown-item
          v-if="resource.client && resource.client.email"
          @click="openQuickview('mail', resource)"
          :title="$t('deals.lastCell.email')"
        />
        <dropdown-item @click="openQuickview('note', resource)" :title="$t('deals.lastCell.note')" />
        <dropdown-item @click="openQuickview('todo', resource)" :title="$t('deals.lastCell.todo')" />
        <dropdown-item @click="openQuickview('event', resource)" :title="$t('deals.lastCell.event')" />
      </dropdown-section>
      <dropdown-section>
        <dropdown-item
          v-if="!resource.deal_stage || resource.deal_stage.chance > 0"
          @click="openQuickview('reason', resource)"
          :title="$t('deals.cancellation')"
        />
        <dropdown-item
          v-if="$db.broker.canDeleteDeals"
          class="text-red-600"
          @click="deleteDeal"
          :title="$t('deals.delete')"
        />
      </dropdown-section>
    </dropdown> -->
  </div>
</template>

<script>
import CommentsIndicator from "@/components/CommentsIndicator"
import eventBus from "@/config/event-bus"

export default {
  components: {
    CommentsIndicator,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  methods: {
    visit() {
      eventBus.$emit("quick-view", {
        type: "todo",
        mode: "view",
        id: this.resource.id,
      })
    },
    // openQuickview(type, { clientId, propertyId, projectId }, props = {}) {
    //   eventBus.$emit("quick-view", {
    //     type: type,
    //     mode: "edit",
    //     params: {
    //       source: {
    //         clientIds: clientId ? [clientId] : [],
    //         propertyIds: propertyId ? [propertyId] : [],
    //         projectIds: projectId ? [projectId] : [],
    //         ...props,
    //       },
    //     },
    //   })
    // },
    // deleteDeal() {
    //   this.$warn(
    //     {
    //       title: this.$t("deals.delWarnTitle"),
    //       desc: this.$t("deals.delWarnDesc"),
    //       confirmButtonText: this.$t("deals.delWarnSubmit"),
    //       redButton: true,
    //     },
    //     async () => {
    //       await this.$axios.delete(`/services/reservations/${this.resource.id}`)
    //       App.flashy(this.$t("deals.delWarnSuccess"))
    //       this.$emit("delete", this.resource)
    //     }
    //   )
    // },
  },
}
</script>