import { camelCase } from "@/utils/with-case"
import FORM_FIELDS from "./form-fields"
import { customFieldFormat } from "@/config/property/editable-fields"

const availableFields = [
  "broker_id",
  "second_broker_id",
  { id: "add_broker_ids", dbOptions: "brokers" },
  { id: "sub_broker_ids", dbOptions: "brokers" },
  { id: "add_department_ids", dbOptions: "departments" },
  { id: "sub_department_ids", dbOptions: "departments" },
  { id: "add_access_broker_ids", dbOptions: "brokers" },
  { id: "sub_access_broker_ids", dbOptions: "brokers" },
  { id: "add_access_department_ids", dbOptions: "departments" },
  { id: "sub_access_department_ids", dbOptions: "departments" },
  { id: "add_contact_sync_broker_ids", dbOptions: "activeBrokers", type: "multiselect" },
  "client_status_id",
  "client_source_id",
  { id: "add_project_id", dbOptions: "projects" },
  { id: "sub_project_id", dbOptions: "projects" },
  { id: "salutation", dbOptions: "salutations" },
  "commercial",
  "company",
  "position",
  "newsletter",
  "property_mailing_wanted",
  "accept_contact",
  { id: "add_group_ids", dbOptions: "clientGroups" },
  { id: "sub_group_ids", dbOptions: "clientGroups" },
  "locale",
  "rating",
  "archived",
  "gdpr_status",
  "client_reason_id",
  "keep_data_till",
]

export const getEditableFields = db => {
  let rawFields: any[] = availableFields
  let fields = rawFields.map(key => {
    if (typeof key == "object") return key

    const field = FORM_FIELDS[camelCase(key)]
    return {
      id: key,
      ...field,
      options: typeof field.options == "function" ? field.options({ db }) : field.options,
    }
  })
  if (db.featureActive("tipster_portal")) {
    fields = fields.concat([{ id: "create_tipster", type: "boolean" }])
  }

  fields = fields.concat(
    db.shopData.customFieldGroupsForClients
      .map(cg => cg.customFields)
      .flat()
      .map(cf => customFieldFormat(cf, db))
      .flat()
  )

  return fields
}
