<template>
  <div style="flex: 1">
    <columns-modal
      :visible.sync="columns.dialogVisible"
      :columns.sync="columns.data"
      @change="columnVisibleChanged"
      :reset="resetColumnsConditional"
    />

    <ps-data-table
      :columns="tableColumns"
      :data="data"
      :per="per"
      @update:per="$emit('update:per', $event)"
      :page-sizes="pageSizes"
      :page="page"
      @update:page="$emit('update:page', $event)"
      :total="total"
      :selected="selected"
      :resizable="resizable"
      :sortable="true"
      @update:selected="$emit('update:selected', $event)"
      @sort="handleSort"
      :loading="loading"
      class="white-header"
    >
      <template v-slot:photo="{ resource }">
        <div
          v-if="resource.title_image"
          class="property-img"
          :style="{ 'background-image': `url('${resource.title_image}')` }"
        ></div>
        <div v-else class="property-img"></div>
      </template>
      <template v-slot:name="{ resource }">
        <p class="txt-overflow flex items-center">
          <span class="truncate">
            <router-link :to="resource.path" class="link">{{ resource.name }}</router-link>
          </span>
          <dropdown
            v-if="resource.forks_count && resource.forks_count > 0"
            width="350"
            v-on:open="() => fetchForksIfNecessary(resource)"
            :append-to-body="false"
            class="variant-popover"
          >
            <template v-slot:reference>
              <tooltip :content="$t('property.variants')">
                <span class="cursor-pointer text-purple-700">
                  <fa-icon name="code-branch" class="ml-1" />
                  <span class="text-sm">{{ resource.forks_count }}</span>
                </span>
              </tooltip>
            </template>
            <dropdown-section>
              <dropdown-item
                v-for="child in resource.forks || []"
                :key="child.id"
                :title="child.name"
                @click="$router.push(`/portfolio/properties/${child.id}`)"
              >
                <template v-slot:title>
                  <div class="flex py-1" :class="{ archived: child.archived }">
                    <aside class="mr-2 mt-1">
                      <thumbnail :image-url="child.titleImageUrl" />
                    </aside>
                    <main class="flex-1">
                      <p>{{ child.name }}</p>
                      <p class="text-gray-600 text-sm">
                        {{
                          $t("property.createdAt", {
                            date: $customFilters.date(child.createdAt),
                          })
                        }}
                      </p>
                    </main>
                    <aside class="ml-2 inline-flex items-center">
                      <tooltip :content="child.published ? $t('property.published') : $t('property.unpublished')">
                        <div
                          class="rounded-full h-3 w-3"
                          :class="{ 'bg-gray-200': !child.published, 'bg-green-600': child.published }"
                        ></div>
                      </tooltip>
                    </aside>
                  </div>
                </template>
              </dropdown-item>
            </dropdown-section>
          </dropdown>
          <Label
            v-if="$parent.activePropertyIds && $parent.activePropertyIds.includes(parseInt(resource.id))"
            label="Bereits verbunden"
            color="#ff9900"
          />
        </p>
        <!-- #{if p.unit_id && !p.project_id then "<p class=\"light\">#{ p.address }</p>" else ''}" -->
      </template>
      <template v-slot:unit_id="{ resource }">
        <router-link v-if="resource.unit_id" :to="resource.path" class="link">{{ resource.unit_id }}</router-link>
      </template>
      <template v-slot:street_number="{ resource }">
        <router-link v-if="resource.street_number" :to="resource.path" class="link">
          {{ resource.street_number }}
        </router-link>
      </template>
      <template v-slot:title="{ resource }">
        <router-link v-if="resource.title" :to="resource.path" class="link">{{ resource.title }}</router-link>
      </template>
      <template v-slot:project_id="{ resource }">
        <p>
          <router-link
            v-if="resource.project"
            :to="'/portfolio/projects/' + resource.project.id + '/units'"
            class="link"
          >
            {{ resource.project.name }}
          </router-link>
        </p>
      </template>
      <template v-slot:groups="{ resource }">{{ resource.groups.join(", ") }}</template>
      <template v-slot:floor="{ resource }">{{ $customFilters.floor(resource.floor) }}</template>
      <template v-slot:number_of_rooms="{ resource }">
        {{ $customFilters.prettyNumber(resource.number_of_rooms, 1) }}
      </template>
      <template v-slot:property_space_value="{ resource }">
        {{ $customFilters.prettySpace(resource.property_space_value) }}
      </template>
      <template v-slot:plot_area="{ resource }">{{ $customFilters.prettySpace(resource.plot_area) }}</template>
      <template v-slot:min_divisible="{ resource }">{{ $customFilters.prettySpace(resource.min_divisible) }}</template>
      <template v-slot:get_price="{ resource }">
        <span>
          {{ $customFilters.numberToCurrency(resource.get_price, getCurrency(resource)) }}
        </span>
      </template>
      <template v-slot:price_per_sqm="{ resource }">
        {{ $customFilters.prettyNumber(resource.price_per_sqm, resource.marketing_type == "BUY" ? 0 : 2) }}
      </template>
      <template v-slot:sold_price="{ resource }">
        {{ $customFilters.numberToCurrency(resource.sold_price, getCurrency(resource)) }}
      </template>
      <template v-slot:base_rent="{ resource }">
        {{ $customFilters.prettyNumber(resource.base_rent, 2, false) }}
      </template>
      <template v-slot:total_rent="{ resource }">
        {{ $customFilters.prettyNumber(resource.total_rent, 2, false) }}
      </template>
      <template v-slot:heating_costs="{ resource }">
        {{ $customFilters.prettyNumber(resource.heating_costs, 2, false) }}
      </template>
      <template v-slot:rental_income="{ resource }">
        {{ $customFilters.prettyNumber(resource.rental_income) }}
      </template>
      <template v-slot:rental_income_actual="{ resource }">
        {{ $customFilters.prettyNumber(resource.rental_income_actual) }}
      </template>
      <template v-slot:rental_income_target="{ resource }">
        {{ $customFilters.prettyNumber(resource.rental_income_target) }}
      </template>
      <template v-slot:rent_subsidy="{ resource }">
        {{ $customFilters.prettyNumber(resource.rent_subsidy) }}
      </template>
      <template v-slot:maintenance_reserve="{ resource }">
        {{ $customFilters.prettyNumber(resource.maintenance_reserve) }}
      </template>
      <template v-slot:price_multiplier="{ resource }">
        {{ $customFilters.prettyNumber(resource.price_multiplier, 1) }}
      </template>
      <template v-slot:yield_actual="{ resource }">
        {{ $customFilters.numberToCurrency(resource.yield_actual, "%", true) }}
      </template>
      <template v-slot:yield_target="{ resource }">
        {{ $customFilters.numberToCurrency(resource.yield_target, "%", true) }}
      </template>
      <template v-slot:lat="{ resource }">
        {{ $customFilters.formatNumberWithUnit(resource.lat, null, true, 7) }}
      </template>
      <template v-slot:lng="{ resource }">
        {{ $customFilters.formatNumberWithUnit(resource.lng, null, true, 7) }}
      </template>
      <template v-slot:marketing_type="{ resource }">
        {{ resource.marketing_type == "BUY" ? "Kauf" : "Miete" }}
      </template>
      <template v-slot:rs_category_label="{ resource }">
        {{ getCustomRsCategoryLabel(resource) }}
      </template>
      <template v-slot:owners.last_name="{ resource }">
        <p v-for="o in resource.owners" :key="o.id">
          <router-link :to="'/contacts/clients/' + o.id + '/conversation'" class="link">{{ o.last_name }}</router-link>
        </p>
      </template>
      <template v-slot:created_at="{ resource }">
        <date-cell :datetime="resource.created_at" />
      </template>
      <template v-slot:updated_at="{ resource }">
        <date-cell :datetime="resource.updated_at" />
      </template>
      <template v-slot:broker="{ resource }">
        <avatar v-if="resource.broker" :broker="resource.broker" />
      </template>
      <template v-slot:team_id="{ resource }">
        <p v-if="resource.team">{{ resource.team.name }}</p>
      </template>
      <template v-slot:status="{ resource }">
        <property-status-label
          :propertyStatusId="resource.property_status_id"
          @click="openPropertyEditForm(resource)"
        />
      </template>
      <template v-slot:highest_deal.deal_stage_id="{ resource }">
        <Label
          v-if="resource.highest_deal_stage"
          :label="resource.highest_deal_stage.name"
          :color="resource.highest_deal_stage.color"
        />
      </template>
      <template v-slot:highest_aquisition_deal.deal_stage_id="{ resource }">
        <Label
          v-if="resource.highest_aquisition_deal_stage"
          :label="resource.highest_aquisition_deal_stage.name"
          :color="resource.highest_aquisition_deal_stage.color"
        />
      </template>
      <template v-slot:highest_purchase_deal.deal_stage_id="{ resource }">
        <Label
          v-if="resource.highest_purchase_deal_stage"
          :label="resource.highest_purchase_deal_stage.name"
          :color="resource.highest_purchase_deal_stage.color"
        />
      </template>
      <template v-slot:highest_rental_deal.deal_stage_id="{ resource }">
        <Label
          v-if="resource.highest_rental_deal_stage"
          :label="resource.highest_rental_deal_stage.name"
          :color="resource.highest_rental_deal_stage.color"
        />
      </template>
      <template v-slot:rented="{ resource }">
        <fa-icon v-if="resource.rented" name="check" />
        <span v-else>–</span>
      </template>
      <template v-slot:has_courtage="{ resource }">
        <fa-icon v-if="resource.has_courtage" name="check" />
        <span v-else></span>
      </template>
      <template v-slot:sold_date="{ resource }">{{ $customFilters.date(resource.sold_date) }}</template>
      <template v-slot:duration_from="{ resource }">{{ $customFilters.date(resource.duration_from) }}</template>
      <template v-slot:duration_until="{ resource }">
        <span :style="getDurationUntilStyle(resource)">{{ $customFilters.date(resource.duration_until) }}</span>
      </template>
      <template v-slot:contract_duration="{ resource }">
        <tooltip
          v-if="contractDuration(resource) && contractDuration(resource) <= 100 && contractDuration(resource) >= 0"
          :content="
            $t('property.contractDurationTooltip', {
              count: Math.ceil((new Date(resource.duration_until) - new Date()) / (1000 * 3600 * 24)),
            })
          "
        >
          <nice-progress
            :percentage="contractDuration(resource)"
            :show-text="false"
            style="width: 50px; rotate: 180deg"
            subtype="contractDuration"
          />
        </tooltip>
      </template>
      <template v-slot:deals="{ resource }">
        <deals-column :unit="resource" @open="openReservationForm(resource)"></deals-column>
      </template>
      <template v-slot:floorplans="{ resource }">
        <floorplan :unit="resource"></floorplan>
      </template>
      <template v-slot:parking_space_price="{ resource }">
        {{ $customFilters.prettyNumber(resource.parking_space_price) }}
      </template>
      <template v-slot:condition="{ resource }">
        <span v-if="resource.condition">{{ $db.shopData.propertyOptionsMapping.condition[resource.condition] }}</span>
      </template>
      <template v-slot:publishings="{ resource }">
        <div>
          <tooltip v-if="resource.publishings.length > 0" :content="resource.publishings.map(o => o.name).join('<br>')">
            <Label color="#22ff00">
              <fa-icon name="check" />
              <small>
                {{ resource.publishings.length }}
              </small>
            </Label>
          </tooltip>
        </div>
      </template>
      <template v-slot:balcony="{ resource }">
        <fa-icon v-if="resource.balcony" name="check" />
        <span v-else>–</span>
      </template>
      <template v-slot:total_commission="{ resource }">
        <span>
          {{ $customFilters.numberToCurrency(resource.total_commission, getCurrency(resource)) }}
        </span>
      </template>
      <template v-for="cf in customFields" v-slot:[cf.fieldName]="{ resource }">
        <custom-value :key="cf.name" :resource="resource" :cf="cf" />
      </template>
      <template v-for="rsName in $db.shopData.relationshipPartnerNames" v-slot:[`rs_${rsName.id}`]="{ resource }">
        <div :key="rsName.id">
          <p v-for="(rs, $idx) in resource.relationships.filter(o => o.name == rsName.name)" :key="$idx">
            <router-link :to="$customFilters.clientPath(rs.client_id)" class="link">{{ rs.client_name }}</router-link>
          </p>
        </div>
      </template>
      <template v-slot:last-cell="{ resource }">
        <slot name="last-cell" :resource="resource" />
      </template>
      <template v-slot:footer>
        <slot name="footer" />
      </template>
      <template v-slot:summary-row>
        <slot name="summary-row" :items="data" :columns="tableColumns" />
      </template>
    </ps-data-table>

    <deal-form :visible.sync="dealFormVisible" :cp="cp" @save="dealFormSaved" />
    <property-edit-form
      :editable="editableProperty"
      :visible="!!editableProperty"
      @close="editableProperty = null"
      @complete="updateEditable"
    />
  </div>
</template>

<script>
import { debounce } from "@/utils"
import { formatRow, formatOptions, CustomColumnsMixin, DEFAULT_COLUMNS } from "../config/property"
import ColumnsModal from "./ColumnsModal"
import CustomValue from "./Table/CustomValue"
import Floorplan from "./properties/Floorplan"
import DealsColumn from "./properties/DealsColumn"
import PropertyEditForm from "./property/EditFormDialog"
import PropertyStatusLabel from "./property/StatusLabel"
import DealForm from "./deals/Form"
import { format as formatField } from "../config/property/field-config"
import Thumbnail from "@/components/property/Thumbnail.vue"

const CURRENCY_MAP = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  CHF: "CHF",
  HUF: "Ft",
  AED: "AED",
  euro: "€",
  chf: "CHF",
  aed: "AED",
}

export default {
  mixins: [CustomColumnsMixin],

  components: {
    ColumnsModal,
    CustomValue,
    Floorplan,
    DealForm,
    DealsColumn,
    PropertyEditForm,
    PropertyStatusLabel,
    Thumbnail,
  },

  props: [
    "customColumns",
    "customColumnChoices",
    "fetchDefaultColumns",
    "storeColumns",
    "fetchProperties",
    "per",
    "selected",
    "pageSizes",
    "page",
    "resizable",
  ],

  data() {
    return {
      data: [],
      loading: true,
      total: 0,
      dealFormVisible: false,
      cp: {},
      editableProperty: null,
    }
  },

  methods: {
    async fetchForksIfNecessary(resource) {
      if (resource.forks && resource.forks.length > 0) return
      const {
        property: { forks },
      } = await this.$graphql(`{
            property(id: ${resource.id}) {
              forks { id name archived titleImageUrl createdAt published }
            }
          }`)
      resource.forks = forks
      this.data = [...this.data]
    },
    getCurrency(resource) {
      return resource.currency || this.$db.shopData.isoCurrency
    },
    getCustomRsCategoryLabel(resource) {
      return Number(resource.rs_category_label)
        ? this.customCategories.find(c => c.id == resource.rs_category_label)?.name
        : resource.rs_category_label
    },
    format(col, value) {
      return formatField(col, value)
    },
    contractDuration(resource) {
      if (!resource.duration_until || (!resource.duration_from && !resource.marketing_start_date)) return ""
      const from = resource.marketing_start_date
        ? new Date(resource.marketing_start_date).setHours(0, 0, 0, 0)
        : new Date(resource.duration_from).setHours(0, 0, 0, 0)
      const until = new Date(resource.duration_until).setHours(23, 59, 99, 999)
      const now = new Date().getTime()
      if (from > until || now > until) return ""
      return (1 - (now - from) / (until - from)) * 100
    },
    getDurationUntilStyle(resource) {
      if (!resource.duration_until || (!resource.duration_from && !resource.marketing_start_date)) return ""
      const from = resource.marketing_start_date
        ? new Date(resource.marketing_start_date).setHours(0, 0, 0, 0)
        : new Date(resource.duration_from).setHours(0, 0, 0, 0)
      const until = new Date(resource.duration_until).setHours(23, 59, 99, 999)
      const now = new Date().getTime()
      if (from > until || now > until) return ""
      const durationPercentage = 1 - (now - from) / (until - from)
      return durationPercentage <= 0.15
        ? "color: rgb(245, 108, 108);"
        : durationPercentage > 0.15 && durationPercentage <= 0.3
        ? "color: rgb(230, 162, 60);"
        : ""
    },
    sortData(col, direction) {
      this.data = _.orderBy(this.data, direction ? col.sort : "unit_id", direction || "asc")
    },
    handleSort({ col, direction }) {
      this.sortData(col, direction)
    },
    fetchData: debounce(async function (refresh = false) {
      this.loading = true
      try {
        const { data, total } = await this.fetchProperties(refresh)
        this.data = data.map(item => formatRow(item, this.rowOptions))
        this.total = total
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$axios.handleError(e)
        }
      } finally {
        this.loading = false
      }
    }, 20),
    openReservationForm(unit) {
      this.cp = {
        propertyId: parseInt(unit.id),
        brokerId: this.$db.broker.id,
        date: moment().toDate(),
        dealPipelineId: (this.$db.get("dealPipelines")[0] && this.$db.get("dealPipelines")[0].id) || null,
      }
      this.dealFormVisible = true
    },
    openPropertyEditForm(unit) {
      if (
        !this.$db.broker.canEditUnits &&
        (!this.$db.broker.canEditSharedUnits ||
          (!this.record.broker_ids?.includes(this.$db.broker.id) &&
            !this.record.department_ids?.some(id => this.$db.broker.departmentIds.includes(id))))
      )
        return
      this.editableProperty = unit
    },
    updateEditable(edited) {
      this.data = this.data.map(o => (o.id == edited.id ? edited : o))
      this.editableProperty = null
    },
    dealFormSaved() {
      setTimeout(() => this.fetchData(true), 1000)
    },
  },
  computed: {
    rowOptions() {
      return formatOptions(this.$db.shopData)
    },
    resetColumnsConditional() {
      return !!this.fetchDefaultColumns ? this.resetColumns : undefined
    },
    tableColumns() {
      const customDetailViewFields = this.$db.broker.propertyDetailViewGroups
        .map(group => group.detailViewFields)
        .flat(1)
      return DEFAULT_COLUMNS.concat(this.userColumns).map(col => ({
        ...col,
        key: col.name,
        title: customDetailViewFields.find(f => f.fieldName === col.name)?.title || col.title,
        align: CURRENCY_MAP[col.unit] && col.name.includes("cf_") ? "right" : col.align,
      })) // Mapping muss sein, weil die columns "name" nutzen und nicht "key"
    },
    customCategories() {
      return this.$db.shopData.customCategories.map(c => ({ ...c, label: c.name, value: c.id }))
    },
    customFields() {
      return _.flatten(
        this.$db.get("customFieldGroupsForProperties").map(g =>
          g.customFields.map(cf => ({
            ...cf,
            fieldName: `cf_${cf.name}`,
          }))
        )
      )
    },
  },
  mounted() {},
}
</script>

<style>
.variant-popover > .el-popper {
  z-index: 10 !important;
  left: 2rem !important;
}
.white-header > .psTable-wrap > .psTable > header {
  background-color: white;
}
</style>
