<template>
  <div class="relative bg-white">
    <span
      v-if="sortable"
      class="handle absolute bottom-0 top-0 flex items-center text-gray-600 cursor-move"
      style="right: -20px"
    >
      <fa-icon name="sort"></fa-icon>
    </span>
    <div @click="clicked" class="px-2 py-2 standard-hover rounded" :class="{ pointer: showActions }">
      <span class="txt-overflow w-full flex items-center justify-between">
        <div style="max-width: 270px" class="flex items-center">
          <span class="block font-medium truncate" :title="item.name">{{ item.name }}</span>
          <tooltip v-if="sharedWithMe && brokerInfo" :content="brokerInfo.name">
            <span class="text-sm ml-2 text-gray-400">
              {{ $t("worklists.item.by", { name: brokerInfo.initials }) }}
            </span>
          </tooltip>
          <tooltip
            v-if="!sharedWithMe && isShared"
            :content="
              sharedBrokers ? $t('worklists.item.shared', { name: sharedBrokers }) : $t('worklists.item.sharedAll')
            "
            class="ml-1"
          >
            <fa-icon name="user-friends" class="text-green-600" />
          </tooltip>
          <fa-icon name="check" class="ml-2 text-green-400" v-if="selected" />
        </div>
        <span class="text-gray-500">
          {{
            $t(`worklists.item.${worklistType[item.forEntity]}`) +
            ": " +
            JSON.parse(this.item.params).filter_set.must[0].terms.id.length
          }}
        </span>
      </span>
    </div>
    <hover-actions>
      <hover-action v-if="showActions" @click="$emit('edit')" icon="pencil" />
      <hover-action v-if="showActions" @click="$emit('delete')" icon="trash-alt" class="text-danger" />
    </hover-actions>
  </div>
</template>

<script>
const WORKLIST_TYPE = {
  for_contacts: "typeContacts",
  for_properties: "typeProperties",
}

export default {
  props: {
    item: {
      type: Object,
      reqired: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (this.showActions) this.$emit("click")
    },
  },
  computed: {
    worklistType() {
      return WORKLIST_TYPE
    },
    isShared() {
      return (
        (this.item.brokerIds && this.item.brokerIds.length) ||
        (this.item.departmentIds && this.item.departmentIds.length) ||
        this.item.isPublic
      )
    },
    sharedWithMe() {
      return this.item.brokerId != this.$db.broker.id
    },
    brokerInfo() {
      const broker = this.$db.shopData.brokers.find(n => n.id === this.item.brokerId)
      return broker ? { name: broker.internalName, initials: broker.initials } : null
    },
    sharedBrokers() {
      return this.item.brokerIds
        ? this.$db.shopData.brokers
            .filter(n => this.item.brokerIds.includes(n.id))
            .map(x => x.name)
            .join(", ")
        : undefined
    },
  },
}
</script>