<template>
  <div>
    <div v-if="unit.floorplan_urls && unit.floorplan_urls.length > 0">
      <a :href="'/portfolio/properties/' + unit.id + '/floorplan'" target="_blank">Grundriss</a>
    </div>
    <el-upload
      v-else
      ref="floorplanUpload"
      :action="'/api/v1/floorplans?property_id=' + unit.id"
      :multiple="false"
      :with-credentials="true"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-success="onSuccess"
      accept="image/*,application/pdf"
    >
      <a v-if="!loading" slot="trigger" href="#" @click.prevent>
        <fa-icon name="plus" />
      </a>
      <span v-if="loading" class="spinner"></span>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: ["unit"],

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    beforeUpload(file) {
      this.loading = true
      return true
    },

    onSuccess(response, file, fileList) {
      this.loading = false
      return (this.unit.floorplan_urls = this.unit.floorplan_urls.concat([response.url]))
    },
  },
}
</script>
