<script lang="ts" setup>
import { Connection } from "@/interfaces"
import useI18n from "@/plugins/use-i18n"

const { item } = defineProps<{ item: Connection }>()

const { t } = useI18n()
const $t = (key: string) => t(`admin.connections.is24.${key}`)
</script>

<template>
  <form-section :title="$t('otoa.title')">
    <form-row :title="$t('otoa.accessBrokerIds')">
      <db-select collection="brokers" multiple v-model="item.is24OtoaAccessBrokerIds" />
    </form-row>
    <form-row :title="$t('otoa.accessDepartmentIds')">
      <db-select collection="departments" multiple v-model="item.is24OtoaAccessDepartmentIds" />
    </form-row>
  </form-section>
</template>
