<template>
  <nav class="page-sidebar bg-gray-50">
    <div class="sidebar-menu">
      <sidebar-menu :menu="menu" :show-one-child="true" />
    </div>
  </nav>
</template>

<script>
const getReshareMenu = () => [
  { component: { template: `<div class="m-t-15"></div>` } },
  { href: "/dashboard", name: "back", icon: "fal fa-long-arrow-left text-primary" },
  { header: true, name: "settings" },
  { href: "/admin/settings/shop", name: "company", icon: "fal fa-briefcase", sortSection: "settings" },
  { href: "/admin/settings", name: "system", icon: "fal fa-cog", attributes: { exact: true }, sortSection: "settings" },
  { href: "/admin/connections", name: "portals", icon: "fal fa-plug", sortSection: "settings" },
  { href: "/admin/settings/contacts", name: "contacts", icon: "fal fa-address-card", sortSection: "settings" },
  { href: "/admin/settings/properties", name: "properties", icon: "fal fa-home", sortSection: "settings" },
  { href: "/admin/settings/saved_queries", name: "queries", icon: "fal fa-search-location", sortSection: "settings" },
  { href: "/admin/snippet_categories", name: "snippets", icon: "fal fa-font", sortSection: "settings" },
  { href: "/admin/shop/micro_sites", name: "lps", icon: "fal fa-browser", sortSection: "settings" },
  { href: "/admin/shop/pdf_exposees", name: "exposees", icon: "fal fa-file-pdf", sortSection: "settings" },
  { href: "/admin/locations", name: "locations", icon: "fal fa-map-marker-alt", sortSection: "settings" },
  { href: "/admin/custom_fields", name: "customfields", icon: "fal fa-wrench", sortSection: "settings" },
  { component: { template: `<div style="height: 80px;"></div>` } },
]

const getUserMenu = broker =>
  [
    { component: { template: `<div class="m-t-15"></div>` } },
    { href: "/dashboard", name: "back", icon: "fal fa-long-arrow-left text-primary" },
    broker.canEditPortals || broker.canEditExtensions || broker.canEditMailAccounts
      ? {
          header: true,
          name: "interfaces",
        }
      : undefined,
    broker.canEditPortals
      ? {
          href: "/admin/connections",
          name: "portals",
          icon: "fal fa-plug",
        }
      : undefined,
    broker.canEditExtensions ? { href: "/admin/extensions", name: "extensions", icon: "fal fa-plug" } : undefined,
    broker.canEditMailAccounts
      ? {
          href: "/admin/mail_accounts",
          name: "mailaccounts",
          icon: "fal fa-plug",
        }
      : undefined,
    { header: true, name: "settings" },
    { href: "/admin/brokers", name: "brokers", icon: "fal fa-user", sortSection: "settings" },
    broker.canEditShopData
      ? {
          href: "/admin/settings/shop",
          name: "company",
          icon: "fal fa-briefcase",
          sortSection: "settings",
        }
      : undefined,
    broker.canEditGdprData
      ? { href: "/admin/settings/gdpr", name: "gdpr", icon: "fal fa-shield-alt", sortSection: "settings" }
      : undefined,
    broker.canEditUnits
      ? { href: "/admin/locations", name: "locations", icon: "fal fa-map-marker-alt", sortSection: "settings" }
      : undefined,
    broker.canEditLetterTemplates
      ? { href: "/admin/letter_templates", name: "letters", icon: "fal fa-file-word", sortSection: "settings" }
      : undefined,
    { href: "/admin/settings/messages", name: "emails", icon: "fal fa-paper-plane", sortSection: "settings" },
    // broker.canSeeMarketing ? { href: "/admin/newsletter", name: "newsletter", icon: "fal fa-plug" } : undefined,
    broker.canEditLandingpages
      ? { href: "/admin/shop/micro_sites", name: "lps", icon: "fal fa-browser", sortSection: "settings" }
      : undefined,
    broker.canEditGroups
      ? { href: "/admin/super_groups", name: "supergroups", icon: "fal fa-tags", sortSection: "settings" }
      : undefined,
    broker.canEditPipelines
      ? { href: "/admin/settings/pipelines", name: "pipelines", icon: "fal fa-funnel-dollar", sortSection: "settings" }
      : undefined,
    broker.canEditUnits
      ? { href: "/admin/shopwindows", name: "shopwindows", icon: "fal fa-window-restore", sortSection: "settings" }
      : undefined,
    broker.canChangeTasks
      ? { href: "/admin/booking_calendars", name: "bookings", icon: "fal fa-calendar-plus", sortSection: "settings" }
      : undefined,
    { href: "/admin/snippet_categories", name: "snippets", icon: "fal fa-font", sortSection: "settings" },
    { component: { template: `<div style="height: 80px;"></div>` } },
  ].filter(Boolean)

const getAdminMenu = ({ db }) =>
  [
    {
      component: { template: `<div class="m-t-15"></div>` },
    },
    {
      href: "/dashboard",
      name: "back",
      icon: "fal fa-long-arrow-left text-primary",
    },
    {
      header: true,
      name: "interfaces",
    },
    {
      href: "/admin/connections",
      name: "portals",
      icon: "fal fa-plug",
    },
    { href: "/admin/extensions", name: "extensions", icon: "fal fa-plug" },
    {
      href: "/admin/api_keys",
      name: "api_keys",
      icon: "fal fa-plug",
    },
    {
      href: "/admin/mail_accounts",
      name: "mailaccounts",
      icon: "fal fa-plug",
    },
    {
      header: true,
      name: "company",
    },
    {
      href: "/admin/brokers",
      name: "brokers",
      icon: "fal fa-user",
    },
    ...(db.shopData.franchiser ? [{ href: "/admin/franchise", name: "franchise", icon: "fal fa-store" }] : []),
    {
      href: "/admin/teams",
      name: "teams",
      icon: "fal fa-users-class",
    },
    {
      href: "/admin/settings/shop",
      name: "company",
      icon: "fal fa-briefcase",
    },
    {
      href: "/admin/departments",
      name: "departments",
      icon: "fal fa-users",
    },
    {
      href: "/admin/automations",
      name: "automations",
      icon: "fal fa-magic",
    },
    {
      href: "/admin/rights",
      name: "rights",
      icon: "fal fa-cog",
    },
    {
      header: true,
      name: "settings",
    },
    { href: "/admin/settings/tasks", name: "tasks", icon: "fal fa-tag", sortSection: "settings" },
    db.featureActive("task_pipelines")
      ? {
          href: "/admin/settings/task_pipelines",
          name: "task_pipelines",
          icon: "fal fa-filter",
          sortSection: "settings",
        }
      : undefined,
    ...((db.shopData.activeFeatures.includes("ph_valuation") || db.shopData.activeFeatures.includes("sn_valuation")) &&
    db.broker.canSeeMarketing
      ? [{ href: "/admin/shop/valuations", name: "valuations", icon: "fal fa-star-half-alt", sortSection: "settings" }]
      : []),
    { href: "/admin/locations", name: "locations", icon: "fal fa-map-marker-alt", sortSection: "settings" },
    { href: "/admin/letter_templates", name: "letters", icon: "fal fa-file-word", sortSection: "settings" },
    { href: "/admin/custom_fields", name: "customfields", icon: "fal fa-wrench", sortSection: "settings" },
    { href: "/admin/settings/gdpr", name: "gdpr", icon: "fal fa-shield-alt", sortSection: "settings" },
    {
      href: "/admin/settings/lead_inquiries",
      name: "leadinquiries",
      icon: "fal fa-smile-plus",
      sortSection: "settings",
    },
    {
      href: "/admin/settings/property_report",
      name: "propertyreport",
      icon: "fal fa-user-chart",
      sortSection: "settings",
    },
    { href: "/admin/settings/messages", name: "emails", icon: "fal fa-paper-plane", sortSection: "settings" },
    { href: "/admin/settings/contacts", name: "contacts", icon: "fal fa-address-card", sortSection: "settings" },
    { href: "/admin/shop/micro_sites", name: "lps", icon: "fal fa-browser", sortSection: "settings" },
    { href: "/admin/shop/lead_score", title: "Lead-Score", icon: "fal fa-star-half-alt", sortSection: "settings" },
    db.shopData.activeFeatures.includes("immo_valuation")
      ? {
          href: "/admin/shop/immo_valuation",
          title: "Leadfisher",
          icon: "fal fa-hand-holding-usd",
          sortSection: "settings",
        }
      : undefined,
    db.featureActive("tipster_portal")
      ? {
          href: "/admin/shop/tipster_portal",
          title: "Tipster Portal",
          icon: "fal fa-lightbulb",
          sortSection: "settings",
        }
      : undefined,
    { href: "/admin/settings/media", name: "media", icon: "fal fa-image", sortSection: "settings" },
    { href: "/admin/super_groups", name: "supergroups", icon: "fal fa-tags", sortSection: "settings" },
    { href: "/admin/settings/properties", name: "properties", icon: "fal fa-home", sortSection: "settings" },
    { href: "/admin/shop/pdf_exposees", name: "exposees", icon: "fal fa-file-pdf", sortSection: "settings" },
    { href: "/admin/settings/deals", name: "deals", icon: "fal fa-dollar-sign", sortSection: "settings" },
    { href: "/admin/settings/pipelines", name: "pipelines", icon: "fal fa-funnel-dollar", sortSection: "settings" },
    { href: "/admin/settings/reports", name: "reports", icon: "fal fa-chart-bar", sortSection: "settings" },
    { href: "/admin/settings/saved_queries", name: "queries", icon: "fal fa-search-location", sortSection: "settings" },
    {
      href: "/admin/settings",
      name: "system",
      icon: "fal fa-cog",
      attributes: { exact: true },
      sortSection: "settings",
    },
    { href: "/admin/shopwindows", name: "shopwindows", icon: "fal fa-window-restore", sortSection: "settings" },
    { href: "/admin/settings/events", name: "events", icon: "fal fa-calendar-alt", sortSection: "settings" },
    { href: "/admin/booking_calendars", name: "bookings", icon: "fal fa-calendar-plus", sortSection: "settings" },
    { href: "/admin/snippet_categories", name: "snippets", icon: "fal fa-font", sortSection: "settings" },
    { component: { template: `<div style="height: 80px;"></div>` } },
  ].filter(Boolean)

export default {
  name: "AdminSidebar",
  data() {
    return {
      values: undefined,
    }
  },
  computed: {
    menu() {
      let raw = []
      if (this.$root.reshare) {
        raw = getReshareMenu(this.$db.broker)
      } else if (this.$db.broker.admin) {
        raw = getAdminMenu({ db: this.$db })
      } else {
        raw = getUserMenu(this.$db.broker)
      }
      return raw
        .map(m => {
          let { title, name } = m
          if (!title && name) title = this.$t(`adminSidebar.${name}`)

          return { ...m, title }
        })
        .reduce((agg, cur) => {
          if (agg.length === 0 || cur.sortSection !== agg[agg.length - 1]?.[0]?.sortSection) {
            agg.push([])
          }
          agg[agg.length - 1].push(cur)
          return agg
        }, [])
        .flatMap(cur => {
          return cur[0].sortSection ? cur.sort((a, b) => a.title.localeCompare(b.title)) : cur
        })
    },
  },
}
</script>
