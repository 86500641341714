<template>
  <div
    class="d-flex align-items-center standard-hover pt-2 pb-2 -ml-11 -mr-2 px-2 rounded"
    :class="{ completed: item.doneAt }"
  >
    <nice-checkbox
      :value="!!item.doneAt"
      @change="doneChanged"
      class="mr-3"
      :disabled="!$db.broker.admin && item.brokerId && item.brokerId != $db.broker.id"
    />
    <div class="flex-1" style="word-break: break-word">
      <span>{{ item.title }}</span>
    </div>
    <span class="ml-2 text-right -my-1 flex items-center">
      <span v-if="item.dueDate" class="text-xs" :class="{ 'text-red-600': past, 'text-gray-600': !past }">
        <fa-icon name="clock" />
        {{ $customFilters.date(item.dueDate) }}
      </span>
      <avatar v-if="assigned" :broker="assigned" class="ml-3" />
      <dropdown class="ml-2 -mr-2">
        <dropdown-section>
          <dropdown-item :title="$t('task.edit')" @click="$emit('edit', item)" />
          <dropdown-item :title="$t('task.delete')" class="text-red-600" @click="$emit('delete', item)" />
        </dropdown-section>
      </dropdown>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },
  methods: {
    doneChanged() {
      this.item.doneAt = this.item.doneAt ? null : moment().format()
      this.$axios.put(`/services/todos/${this.item.id}`, { checked: !!this.item.doneAt })
    },
  },
  computed: {
    assigned() {
      if (this.item.brokerId) {
        return this.$db.shopData.brokers.find(b => b.id == this.item.brokerId)
      }
    },
    past() {
      if (this.item.dueDate && !this.item.doneAt) {
        return moment(this.item.dueDate).isBefore(moment())
      }
    },
  },
}
</script>
