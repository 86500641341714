import useI18n from "@/plugins/use-i18n"
import { MaybeRef, get, useToggle } from "@vueuse/core"
import { Component, computed } from "vue"
import { toPropRefs, useState } from "../utils/composables"
import { useIntegrationUpdateMutation } from "./mutations/use-integration-update-mutation"
import { BehaviourTrackingEvent } from "./types/behaviour-service"
import { Integration, IntegrationDynamicConfiguration } from "./types/integration-service"
import { useIntegrationConsent } from "./use-integration-consent"
import { useIntegrationMapping } from "./use-integration-mapping"
import { useNotifications } from "./use-notifications"
import { useTracking } from "./use-tracking"

export type UseIntegrationAPI = ReturnType<typeof useIntegrationAPI>

export const useIntegrationAPI = <T extends Integration>(integration: MaybeRef<T>) => {
  const { t } = useI18n()
  const {
    id: integrationId,
    configuration,
    fixedConfiguration,
    consent: lastConsent,
  } = toPropRefs(() => get(integration), ["id", "configuration", "fixedConfiguration", "consent"])
  const { openError } = useNotifications()
  const { trackEvent } = useTracking<BehaviourTrackingEvent>()
  const updateIntegrationMutation = useIntegrationUpdateMutation()
  const { storeConsent, revokeConsent } = useIntegrationConsent()

  // this state combines the state of consent and any pending changes
  const [isToggling, setToggling] = useToggle(false)

  const [pendingChanges, setPendingChanges] = useState<Partial<T["configuration"]>>({})

  const isUpdating = computed(() => get(updateIntegrationMutation.isPending) || get(isToggling))

  // get translations and possible configuration components
  const mapping = useIntegrationMapping(integrationId)

  // consent
  const consentRequired = computed(() => !!get(fixedConfiguration)?.requiresConfirmLegalConsent)

  const isEnabled = computed(() => get(configuration)?.enabled)

  // if (!mapping) {
  //   throw new Error(`No mapping found for integration ${integration.id}.`)
  // }
  const ConfigurationComponent = computed(() => get(mapping).ConfigurationComponent as Component | undefined)

  const onChange = (changes: Partial<T["configuration"]>) => {
    setPendingChanges(oldPendingChanges => ({
      ...oldPendingChanges,
      ...changes,
    }))
  }

  const onUpdate = async (configuration: IntegrationDynamicConfiguration) => {
    const _integration = get(integration)
    return updateIntegrationMutation.mutate(
      { id: _integration.id, configuration },
      {
        onError: () => {
          openError(t("common.error"))
        },
      }
    )
  }

  const useConfiguration = toPropRefs(
    () => get(mapping)?.useConfiguration?.({ t, configuration: get(configuration), onUpdate }),
    ["onDisable", "onEnable", "onSave", "allowSave", "requiresValidConfiguration", "checkConfiguration"]
  )

  // allow to override the default configuration via onUpdate
  const {
    onDisable: configuredOnDisable,
    onEnable: configuredOnEnable,
    onSave: configuredOnSave,
    allowSave, // indicate whether we allow to save the configuration in the generic integration UI actions
    requiresValidConfiguration,
    checkConfiguration,
  } = useConfiguration

  // onSave will automatically save the pending changes queued up by onChange adding pending changes and potentially additional changes
  const onSave = async (immediateChanges?: Partial<T["configuration"]>) => {
    const _integration = get(integration)
    const _pendingChanges = get(pendingChanges)
    const changesToSave = { ..._pendingChanges, ...immediateChanges }
    if (!Object.keys(changesToSave).length) {
      return
    }
    const configurationToSave = { ..._integration.configuration, ...changesToSave }
    setPendingChanges({})
    const _configuredOnSave = get(configuredOnSave)
    return await _configuredOnSave?.(configurationToSave)
  }

  // this contains 2 steps. First we store the consent, then we enable the integration
  const onEnable = async () => {
    const _integration = get(integration)
    setToggling(true)
    trackEvent({
      feature: "integrations",
      action: "enable_integration",
      metadata: {
        integration: _integration.id,
      },
    })

    const _fixedConfiguration = get(fixedConfiguration)
    if (_fixedConfiguration.requiresConfirmLegalConsent && !_integration.consent) {
      const _consentText = get(mapping).consentText
      // what should happen? error or fallback text?
      if (!_consentText) {
        throw new Error(`No consent text found for integration ${_integration.id}.`)
      }

      const consentResponse = await storeConsent(_integration.id, {
        consentText: _consentText,
      })

      if (consentResponse.status === "error") {
        setToggling(false)
        return consentResponse
      }
    }

    const _pendingChanges = get(pendingChanges)
    const _configuredOnEnable = get(configuredOnEnable)
    // triggering the enable will also save the pending changes if there are any
    const response = await _configuredOnEnable?.(_pendingChanges)
    setPendingChanges({})

    setToggling(false)
    return response
  }

  // contains 2 steps. First we revoke the consent, then we disable the integration
  const onDisable = async () => {
    const _integration = get(integration)
    setToggling(true)
    trackEvent({
      feature: "integrations",
      action: "disable_integration",
      metadata: {
        integration: _integration.id,
      },
    })

    const _fixedConfiguration = get(fixedConfiguration)
    if (_fixedConfiguration.requiresConfirmLegalConsent && _integration.consent) {
      const revokeResponse = await revokeConsent(_integration.id)

      if (revokeResponse.status === "error") {
        setToggling(false)
        return revokeResponse
      }
    }
    const _configuredOnDisable = get(configuredOnDisable)
    // HINT: we are discard pending changes when disabling, but the onDisable api still requires the parameters, so it can pass it through
    const response = await _configuredOnDisable?.({})
    setToggling(false)
    return response
  }

  const onToggle = async () => {
    const _configuration = get(configuration)
    if (_configuration.enabled) {
      return onDisable()
    } else {
      return onEnable()
    }
  }

  const dispatchOrder = async (data: any) => {
    const _integration = get(integration)
    // this could trigger order process in the future
    trackEvent({
      feature: "integrations",
      action: "order_integration",
      metadata: { integration: _integration.id, ...data },
    })
  }

  const trackUsage = (data: any) => {
    const _integration = get(integration)
    trackEvent({
      feature: "integrations",
      action: "use_integration",
      metadata: { integration: _integration.id, ...data },
    })
  }

  const futureConfiguration = computed(() => ({ ...get(configuration), ...get(pendingChanges) }))
  const isValidConfiguration = computed(() => {
    const _checkConfiguration = get(checkConfiguration)
    return _checkConfiguration?.(get(futureConfiguration))
  })

  const isUsable = computed(() => get(isEnabled) && get(isValidConfiguration))
  const hasPendingChanges = computed(() => Object.keys(get(pendingChanges)).length > 0)

  const isReady = computed(() => Boolean(get(integration)) && Boolean(get(mapping)) && Boolean(get(configuration))) // custom: ensure that everything is loaded
  return {
    isReady,
    isEnabled,
    isUsable,
    mapping,
    isValidConfiguration,
    requiresValidConfiguration,
    useConfiguration,
    onToggle,
    onDisable,
    allowSave,
    onSave, // save data including pending changes
    onChange, // set pending changes
    pendingChanges,
    hasPendingChanges,
    onEnable,
    dispatchOrder,
    trackUsage,
    isUpdating,
    isToggling,
    consentRequired,
    lastConsent,
    ConfigurationComponent,
  }
}
