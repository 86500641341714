<template>
  <spinner v-if="loading" class="w-100 h-100" />
  <section v-else class="widget-container pt-3 px-3 h-100 flex flex-col relative">
    <header class="px-1">
      <h4 class="text-xl">
        <slot name="title">{{ title }}</slot>
      </h4>
      <p v-if="desc" class="text-sm text-gray-600 whitespace-nowrap overflow-hidden">{{ desc }}</p>
    </header>
    <main
      class="-mx-2 mt-2 h-100 pb-5"
      :class="{ 'overflow-scroll': widget.category !== 'charts', block: widget.category === 'charts' }"
      v-infinite-scroll="infiniteScroll"
      :infinite-scroll-disabled="infiniteScrollDisabled"
      infinite-scroll-distance="5"
    >
      <slot />
    </main>
    <div
      class="from-gray-50 h-12 absolute bottom-0 left-0 right-0 pointer-events-none"
      :class="{ 'bg-gradient-to-t': gradient && !widget.color }"
      :style="widget.color && `background-image: linear-gradient(to top, ${widget.color}, rgb(255, 255, 255, 0))`"
    ></div>
  </section>
</template>

<script>
import moment from "moment-timezone"

const getFilters = ({ widget, config, db }) => {
  config = config[widget.category]
  if (!config || !config.filters) return []

  return config.filters
    .filter(
      f =>
        f.name != "color" &&
        (Array.isArray(widget[f.name]) ? widget[f.name].length : widget[f.name] == 0 || widget[f.name])
    )
    .map(f => {
      let value = widget[f.name]
      if (f.type == "multiselect" && f.dboptions) {
        const collectionName = f.dboptions
        value = (typeof db[collectionName] === "function" ? db[collectionName]() : db.shopData[collectionName])
          ?.filter(o => value.includes(o.id))
          .map(o => o.name)
          .join(", ")
      } else if (f.type == "dropdown" && f.dboptions) {
        const collectionName = f.dboptions
        value =
          (typeof db[collectionName] === "function" ? db[collectionName]() : db.shopData[collectionName])?.find(
            o => value == o.id
          )?.name || value
      } else if (f.type == "checkbox") {
        value = "ja"
      } else if (f.type == "days") {
        value = moment().add(value, "days").calendar()
      } else if (f.type == "component" && f.dboptions) {
        const collectionName = f.dboptions
        const filter = widget["location"].split("-").slice(-1)?.[0]
        value =
          (typeof db[collectionName] === "function" ? db[collectionName]() : db.shopData[collectionName])?.find(
            o => filter == o.id
          )?.name || value
      }

      return { label: f.label, value }
    })
    .filter(f => !!f.label && !!f.value)
}

export default {
  props: {
    widget: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    infiniteScroll: Function,
    infiniteScrollDisabled: Boolean,
    gradient: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    title() {
      return this.widget.title || this.config[this.widget.category].label
    },
    desc() {
      const { widget, config, $db } = this
      return (
        widget.subTitle ||
        getFilters({ widget, config, db: $db })
          .map(o => `${o.label}: ${o.value}`)
          .join(" | ")
      )
    },
  },
}
</script>
