<template>
  <form-dialog
    title="Folge-Aktivitäten für Aufgabe"
    :visible="visible"
    @close="resetDialog"
    :saving="submitting"
    @submit="submit"
    submitButtonTitle="Abschicken und ausführen"
  >
    <center-container v-if="loading" height="150px">
      <spinner />
    </center-container>
    <div v-else>
      <table class="mb-3">
        <tr v-if="shopData.clients && shopData.clients.length > 0">
          <td class="pr-3"><strong>Kontakt:</strong></td>
          <td>{{ shopData.clients.map(o => o.name).join(", ") }}</td>
        </tr>
        <tr v-if="shopData.properties && shopData.properties.length > 0">
          <td class="pr-3"><strong>Objekt:</strong></td>
          <td>{{ shopData.properties.map(o => o.name).join(", ") }}</td>
        </tr>
      </table>

      <div class="todoActivities">
        <todo-activity v-for="(item, idx) in items" :key="idx" :step="idx + 1" :ta="item">
          <component :is="item.activityType" :ta="item" :shop-data="shopData" />
        </todo-activity>
      </div>
    </div>
  </form-dialog>
</template>

<script>
import eventBus from "@/config/event-bus"
import TodoActivity from "@/components/todo-activities/TodoActivity"
import Note from "@/components/todo-activities/Note"
import Todo from "@/components/todo-activities/Todo"
import Cancelation from "@/components/todo-activities/Cancelation"
import Message from "@/components/todo-activities/Message"
import Property from "@/components/todo-activities/Property"
import CenterContainer from "@/components/CenterContainer.vue"

export default {
  data() {
    return {
      todoId: null,
      visible: false,
      loading: false,
      shopData: {},
      items: [],
      submitting: false,
    }
  },
  components: {
    TodoActivity,
    Note,
    Todo,
    Cancelation,
    Message,
    Property,
    CenterContainer,
  },
  methods: {
    async fetchData() {
      this.loading = true
      const { todo, shop } = await this.$graphql(`{
        todo(id: ${this.todoId}) {
          category {
            todoActivities {
              id,
              label,
              activityType,
              mandatory,
              payload { snippetId brokerId noteTypeId todoTypeId dueDate eventTypeId propertyStatusId reservationReasonId reviewerIds }
            }
          },
          clients {
            name
          },
          properties {
            name,
            propertyStatus {
              name
            }
          }
        },
        shop {
          noteCategories { id, name },
          todoCategories { id, name },
          eventCategories { id, name },
          snippets { id, name },
          cancelationReasons { id, name },
          propertyStatuses { id, name },
        }
      }`)

      this.shopData = {
        ...shop,
        properties: todo.properties,
        clients: todo.clients,
      }
      this.items =
        todo.category &&
        todo.category.todoActivities.map(item => ({
          ...item,
          prefilledReservationReasonId: item.payload.reservationReasonId,
          prefilledNoteTypeId: item.payload.noteTypeId,
          prefilledSnippetId: item.payload.snippetId,
          execute: item.mandatory,
        }))
      this.loading = false
    },

    quickviewMail(taskId, snippetId) {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            taskId,
            snippetId,
          },
        },
      })
    },

    submit() {
      this.submitting = true
      const payload = this.items
        .filter(i => i.execute && !i.openForm)
        .map(item => ({
          id: item.id,
          payload: item.payload,
        }))

      this.$axios
        .post(`/services/tasks/${this.todoId}/execute_todo_activities`, {
          todo_activities: payload,
        })
        .then(() => {
          const item = this.items.find(i => i.openForm)

          if (item) {
            this.quickviewMail(this.todoId, item.payload.snippetId)
            return
          }
        })
        .then(() => {
          window.TodosApp && window.TodosApp.fetchData()
          this.visible = false
          App.flashy("Folge-Aktivitäten erfolgreich ausgeführt!")
        })
        .catch(this.$axios.handleError)
        .then(() => (this.submitting = false))
    },

    resetDialog() {
      this.todoId = null
      this.items = []
      this.visible = false
      window.TodosApp && window.TodosApp.fetchData()
    },
  },
  mounted() {
    window.TaskApp = {}
    window.TaskApp.openTodoActivitesModal = todoId => {
      this.todoId = todoId
      this.fetchData()
      this.visible = true
    }
  },
}
</script>

<style scoped></style>
