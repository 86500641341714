<template>
  <el-select
    ref="select"
    filterable
    remote
    v-cancel-read-only
    :clearable="clearable"
    :multiple="multiple"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    default-first-option
    :placeholder="$t('clientSelect.placeholder')"
    :remote-method="fetchClients"
    :loading="loading"
    :disabled="disabled"
    :size="size"
    v-bind="$attrs"
  >
    <el-option
      v-for="item in clients"
      :key="item.id"
      :label="item.name"
      :value="item.id"
      :disabled="disableLocked && item.locked"
      class="client-select"
    >
      <div :class="{ archvied: item.archived }">
        <p>
          <fa-icon name="lock" class="mr-1" v-if="item.locked" />
          <strong>{{ item.name }}</strong>
        </p>
        <p v-if="item.email" class="light">{{ item.email }}</p>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { cacheClients } from "@/config/remote-data-cache"

export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    includeChildren: {
      type: Boolean,
      default: true,
    },
    disableLocked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    fetchType: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      clients: [],
      loading: false,
    }
  },

  methods: {
    async fetchClients(query) {
      if (!query) {
        this.loading = false
        this.clients = []
        return
      }

      this.loading = true
      const data = await this.fetchMethod(query)
      this.clients = data
      cacheClients(data)
      this.loading = false
    },
    async defaultFetch(query) {
      const { data } = await this.$axios.get(
        `/contacts/clients?q=${query}${this.includeChildren ? "&include_children=1" : ""}`
      )

      return data.data
    },
    fetchCommissionSplitClients(query) {
      return this.$graphql(
        `query CommissionSplitClients {
        commissionSplitClients(query: $query) { id name email archived locked }
      }`,
        { query }
      )
        .then(({ commissionSplitClients }) => commissionSplitClients)
        .catch(this.$axios.handleError)
    },
    focus() {
      this.$refs.select.focus()
    },
    prefill() {
      if (!this.value) return
      const ids = this.multiple ? this.value.join(",") : this.value
      if (!ids) return

      this.$axios.get(`/contacts/clients?client_ids=${ids}`).then(({ data }) => {
        this.clients = data.data
        cacheClients(data.data)
        this.$emit("ready")
      })
    },
  },

  computed: {
    fetchMethod() {
      return this[`fetch${this.fetchType}`] || this.defaultFetch
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.prefill()
    })
  },
}
</script>

<style scoped>
.client-select {
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
