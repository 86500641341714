import i18n from "@/i18n/index"
import { adminPermissionTree } from "../router/auth"

interface NavigationItem {
  href: string
  title: any
  icon: string
  restrict?: (broker: any, shopData?: any) => boolean
}

const ALL_NAVIGATION_ITEMS: NavigationItem[] = [
  { href: "/dashboard", title: i18n.t("sidebar.dashboard"), icon: "tachometer-alt-average" },
  { href: "/mailbox/messages", title: i18n.t("sidebar.mailbox"), icon: "envelope" },
  { href: "/contacts/clients", title: i18n.t("sidebar.clients"), icon: "address-card" },
  { href: "/calendar", title: i18n.t("sidebar.calendar"), icon: "calendar-alt" },
  { href: "/opportunities", title: i18n.t("sidebar.opportunities"), icon: "dollar-sign" },
  { href: "/portfolio/properties", title: i18n.t("sidebar.properties"), icon: "home" },
  { href: "/portfolio/projects", title: i18n.t("sidebar.projects"), icon: "building" },
  { href: "/marketing/mailings", title: i18n.t("sidebar.mailings"), icon: "bullhorn" },
  { href: "/tasks/todos", title: i18n.t("sidebar.todos"), icon: "check" },
  { href: "/contacts/feed", title: i18n.t("sidebar.activities"), icon: "list-alt" },

  { href: "/admin/settings/shop", title: "Unternehmen", icon: "briefcase" },
  { href: "/admin/settings", title: "Systemeinstellungen", icon: "cog" },
  { href: "/admin/connections", title: "Portale", icon: "plug" },
  { href: "/admin/settings/saved_queries", title: "Suchprofil-Einstellungen", icon: "search-location" },
  { href: "/admin/shop/micro_sites", title: "Landing Pages", icon: "browser" },
  { href: "/admin/shop/pdf_exposees", title: "PDF Exposés", icon: "file-pdf" },
  { href: "/admin/locations", title: "Geolagen", icon: "map-marker-alt" },
  { href: "/admin/custom_fields", title: "Custom Felder", icon: "wrench" },
  { href: "/admin/brokers", title: "Nutzer", icon: "user" },
  { href: "/admin/letter_templates", title: "Briefvorlagen", icon: "file-word" },
  { href: "/admin/snippet_categories", title: "Textbausteine", icon: "font" },

  { href: "/admin/settings/contacts", title: "Kontakt-Einstellungen", icon: "address-card" },
  { href: "/admin/settings/contacts#clientStatuses", title: "Kontakt-Status", icon: "address-card" },
  { href: "/admin/settings/contacts#clientSources", title: "Quellen", icon: "address-card" },
  { href: "/admin/settings/contacts#cancelationReasons", title: "Absagegründe", icon: "address-card" },
  { href: "/admin/settings/contacts#clientReasons", title: "DSGVO-Speichergründe", icon: "address-card" },
  { href: "/admin/settings/contacts#salutations", title: "Kontakt-Anreden", icon: "address-card" },
  {
    href: "/admin/detail_view_groups",
    title: "Eigene Kontakt-Vorlage",
    icon: "address-card",
    restrict: broker => broker.admin,
  },

  { href: "/admin/settings/properties", title: "Objekt-Einstellungen", icon: "home" },
  { href: "/admin/settings/properties#propertyStatuses", title: "Objekt-Status", icon: "home" },

  { href: "/admin/settings/messages", title: "E-Mail-Einstellungen", icon: "paper-plane" },
  { href: "/admin/settings/messages#smartFolders", title: "E-Mail-Ordner", icon: "paper-plane" },
  { href: "/admin/shopwindows", title: "Schaufenster-Screens", icon: "window-restore" },
  { href: "/admin/newsletter", title: "Newsletter", icon: "plug" },
  { href: "/admin/settings/messages#messageTemplates", title: "HTML Vorlagen", icon: "paper-plane" },
  { href: "/admin/super_groups", title: "Merkmale", icon: "tags" },
  { href: "/admin/settings/deals", title: "Pipelines", icon: "dollar-sign" },
  { href: "/admin/booking_calendars", title: "Termin-Planer", icon: "calendar-plus" },
  {
    href: "/admin/mailgun",
    title: "Mailgun",
    icon: "plug",
  },
  {
    href: "/admin/messagebird",
    title: "MessageBird",
    icon: "plug",
  },
  {
    href: "/admin/api_keys",
    title: "Propstack",
    icon: "plug",
  },
  {
    href: "/admin/mail_accounts",
    title: "E-Mail-Konten",
    icon: "plug",
  },
  {
    href: "/admin/teams",
    title: "Mandanten",
    icon: "users-class",
  },
  {
    href: "/admin/departments",
    title: "Teams",
    icon: "users",
  },
  {
    href: "/admin/automations",
    title: "Automatisierungen",
    icon: "magic",
  },
  {
    href: "/admin/rights",
    title: "Rollen & Rechte",
    icon: "cog",
  },
  {
    href: "/admin/settings/tasks",
    title: "Aktivitätstypen",
    icon: "tag",
  },
  { href: "/admin/settings/gdpr", title: "DSGVO", icon: "shield-alt" },
  { href: "/admin/settings/lead_inquiries", title: "Eigentümer-Anfragen", icon: "smile-plus" },
  { href: "/admin/shop/lead_score", title: "Lead-Score", icon: "star-half-alt" },
  { href: "/admin/settings/media", title: "Medien-Einstellungen", icon: "image" },
  { href: "/admin/settings/property_report", title: "Objekt-Report", icon: "user-chart" },
  { href: "/admin/settings/reports", title: "Reports", icon: "chart-bar" },
  { href: "/admin/settings/events", title: "Termin-Einstellungen", icon: "calendar-alt" },

  {
    href: "/admin/settings/text_snippets",
    title: "Objekt-Textbausteine",
    icon: "home",
    restrict: broker => broker.admin,
  },
  {
    href: "/admin/settings/property_view",
    title: "Eigene Objekt-Vorlage",
    icon: "home",
    restrict: broker => broker.admin,
  },
  {
    href: "/admin/settings/exposee_view",
    title: "Eigene Exposé-Anordnung",
    icon: "home",
    restrict: broker => broker.admin,
  },
  {
    href: "/admin/settings/project_view",
    title: "Eigene Projekt-Vorlage",
    icon: "home",
    restrict: broker => broker.admin,
  },
  {
    href: "/admin/settings/custom_object_types",
    title: "Eigene Objektarten",
    icon: "home",
    restrict: broker => broker.admin,
  },
  {
    href: "/admin/settings/messages#messageCi",
    title: "CI-Einstellung für ausgehende E-Mails",
    icon: "paper-plane",
    restrict: broker => broker.admin,
  },
  {
    href: "/admin/settings/messages#messageDefaultSignature",
    title: "Globale E-Mail Signatur",
    icon: "paper-plane",
    restrict: broker => broker.admin,
  },
  { href: "/admin/shop/valuations", title: "Bewertungen", icon: "star-half-alt" },
  { href: "/admin/shop/immo_valuation", title: "Leadfisher", icon: "hand-holding-usd" },
]

export const getSearchFormList: (db: any) => NavigationItem[] = db =>
  ALL_NAVIGATION_ITEMS.filter(({ href, restrict }) => {
    const path = href.split("#")[0]
    const found = adminPermissionTree.find(branch => branch[0].includes(path))
    return restrict ? restrict(db.broker, db.shopData) : !found || found[1](db.broker, db.shopData)
  })
