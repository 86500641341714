<template>
  <el-dialog :visible="visible" :title="title" width="400px" @close="$emit('update:visible', false)" append-to-body>
    <form @submit.prevent="confirmSubmit">
      <label style="white-space: pre-wrap; word-break: keep-all">{{ formattedDescription }}</label>
      <br />
      <br />
      <nice-input size="small" type="number" v-model.number="securityCheckField" :placeholder="placeholder" class="-mb-5" />
      <slot />
    </form>
    <div slot="footer" class="dialog-footer">
      <nice-button
        :disabled="correctValue != securityCheckField"
        type="danger"
        :loading="submitting"
        @click="confirmSubmit"
        class="w-100 h-12"
      >
        {{ $t("numberConfirmDialog.confirm") }}
      </nice-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    correctValue: {
      type: Number,
    },
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    request: {
      type: Function,
    },
  },
  data() {
    return {
      securityCheckField: null,
      submitting: false,
    }
  },
  computed: {
    formattedDescription() {
      return this.description.replaceAll(/\{\d+\}/g, () => this.correctValue)
    },
  },
  methods: {
    confirmSubmit() {
      if (this.correctValue == this.securityCheckField) {
        this.submit()
      } else {
        App.alert(this.$t("numberConfirmDialog.error"))
      }
    },
    submit() {
      this.submitting = true
      this.request().then(() => {
        this.submitting = false
        this.$emit("submitted")
      })
    },
  },
}
</script>