<template>
  <li>
    <router-link :to="href" class="header-navigationItem" :class="{ active: active }">
      <slot />
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.header-navigationItem {
  display: block;
  font-weight: 500;
  top: 0;
  height: 30px;
  padding: 0 0.9em;
  line-height: 30px;
  margin: 0 5px 0 0;
  border-radius: 30px;
  color: rgba(255, 255, 255, 0.7);
}

.header-navigationItem:hover,
.header-navigationItem:focus {
  color: white;
}

.header-navigationItem.active {
  color: white;
}
</style>
