import moment from "moment"
import axios from "../config/axios"
import db from "../config/db"
import cache from "@/config/memory-cache"

axios.handleError = error => {
  if (error.response && error.response.data) {
    const msg = error.response.data.errors
      ? error.response.data.errors.join(", ")
      : "Etwas ist leider schief gelaufen. Bitte probiere es später erneut."
    App.alert(msg)
  } else if (error.error_messages) {
    App.alert(error.error_messages.join(", "))
  } else {
    console.error(error)
  }
}

let cancelFetch = null
axios.postWithCancel = (url, payload) => {
  if (cancelFetch) {
    cancelFetch.cancel() // cancel  previous ajax if exists
  }
  cancelFetch = axios.CancelToken.source() // creates a new different token for upcomming ajax (overwrite the previous one)
  return axios.post(url, payload, { cancelToken: cancelFetch.token })
}

axios.getWithCancel = url => {
  if (cancelFetch) {
    cancelFetch.cancel() // cancel  previous ajax if exists
  }
  cancelFetch = axios.CancelToken.source() // creates a new different token for upcomming ajax (overwrite the previous one)
  return axios.get(url, { cancelToken: cancelFetch.token })
}

const gqlQuote = val => `"${val}"`

const gqlArray = val => `[${val.join(", ")}]`

function gqlArrayToUnquotedJSON(obj) {
  const objString = Object.entries(obj)
    .map(([key, value]) => `${key}:${JSON.stringify(value)}`)
    .join(",")

  return `{${objString}}`
}

const prepareValue = val => {
  if (val instanceof Array) {
    return gqlArray(val.map(v => prepareValue(v)))
  }
  if (moment.isMoment(val) || val instanceof Date) {
    return gqlQuote(moment(val).utc().format())
  }
  if (typeof val === "number" || val instanceof Number || typeof val === "boolean" || val instanceof Boolean) {
    return val.toString()
  }
  if (typeof val === "string" || val instanceof String) {
    if (val.startsWith("$")) {
      return val.substring(1)
    }
    return gqlQuote(val)
  }
  if (val === undefined) {
    return "null"
  }
  if (typeof val === "object" && val !== null) {
    return gqlArrayToUnquotedJSON(val)
  }
  throw new Error(`Undefined GraphQL value type \"${typeof val}\"`)
}

const prepareQuery = (query, params) => {
  if (!params) return query
  return query.replace(/\$(\w+)/g, (_, key) => {
    if (!key in params) throw new Error(`Variable "${key}" is not defined in GraphQL params`)
    return prepareValue(params[key])
  })
}

export default {
  install(Vue, _) {
    Vue.prototype.$graphql = async function (
      query,
      params,
      shouldCacheResult = false,
      forceRefresh = false,
      throwError = false
    ) {
      try {
        let cacheKey
        if (shouldCacheResult) {
          const match = /query\s+(\w+)\s*\{/gm.exec(query)
          if (match && match.length > 1) {
            cacheKey = match[1]
            if (!forceRefresh) {
              const result = cache.get(cacheKey)
              if (result) return result
            }
          }
        }
        const response = await axios.post(`/api/v1/graphql`, { query: prepareQuery(query, params) })
        if (!response.data.data) {
          console.error(response.data)
          throw "Fehler beim Laden der Daten aufgetreten."
        }
        const data = response.data.data
        if (cacheKey) {
          cache.set(cacheKey, data)
        }
        return data
      } catch (e) {
        const errorMessage = e.response?.data?.error?.message || e.response?.data?.errors?.join(", ") || e
        App.alert(errorMessage)

        if (throwError) throw e

        if (e.response && e.response.status === 401) {
          const currentLocation = window.location.href
          this.$router.goBack()
          if (window.location.href == currentLocation) this.$router.replace("/")
        }
      }
    }

    Vue.prototype.$axios = axios

    Vue.prototype.$db = db
  },
}
