<template>
  <form-dialog
    :title="$t('client.mergeModal.title')"
    width="500px"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :submitButtonTitle="$t('client.mergeModal.submit')"
    @submit="confirmMerge"
    footerType="bigButton"
    :disabled="loading || !selected"
    :saving="loading"
    append-to-body
  >
    <form-section>
      <p class="mb-1">{{ $t("client.mergeModal.clientData") }}</p>
      <form-row>
        <client-select v-model="selected" />
      </form-row>
      {{ $t("client.mergeModal.with") }}
      <strong>{{ client.name }}</strong>
    </form-section>
    <nice-checkbox v-model="deleteClient" v-if="$db.broker.canDeleteClients" class="">
      {{ $t("client.mergeModal.del") }}
    </nice-checkbox>
  </form-dialog>
</template>

<script>
export default {
  props: ["visible", "client"],
  data() {
    return {
      loading: false,
      deleteClient: false,
      selected: null,
    }
  },
  methods: {
    confirmMerge() {
      this.loading = true

      this.$axios
        .post(`/contacts/clients/${this.client.id}/merge`, {
          other_id: this.selected,
          delete_client: this.deleteClient,
        })
        .then(() => {
          this.loading = false
          this.$emit("update:visible", false)
          App.flashy(this.$t("client.mergeModal.success"))
        })
        .catch(err => {
          this.loading = false
          this.$axios.handleError(err)
        })
    },
  },
}
</script>
