<template>
  <dropdown width="280">
    <template v-slot:reference>
      <button
        :class="`w-12 h-12 rounded-full border shadow-md absolute ${helpButtonPosition} bottom-5 z-50`"
        :style="{ background: $db.broker.headerColor || '#0f55eb' }"
      >
        <strong class="text-2xl text-white">?</strong>
        <nice-badge :is-dot="true" subType="chatMessageIndicator" v-if="unreadMessages != 0"></nice-badge>
      </button>
    </template>
    <dropdown-section>
      <dropdown-item link="/upvoty-login" new-tab>
        <template v-slot:title>
          <fa-icon name="comment-alt-edit" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.upvoty") }}
        </template>
      </dropdown-item>
      <dropdown-item v-if="intercomActive" @click="openChat">
        <template v-slot:title>
          <fa-icon name="comment" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.chat") }}
          <nice-badge v-if="unreadMessages != 0" :value="unreadMessages"></nice-badge>
        </template>
      </dropdown-item>
    </dropdown-section>
    <dropdown-section>
      <dropdown-item link="https://propstack.zendesk.com/hc/de" new-tab>
        <template v-slot:title>
          <fa-icon name="book-open" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.articles") }}
        </template>
      </dropdown-item>
      <dropdown-item v-if="intercomActive" @click="openSpace">
        <template v-slot:title>
          <fa-icon name="compass" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.tours") }}
        </template>
      </dropdown-item>
      <dropdown-item link="https://youtube.com/playlist?list=PLZ62J2PcG52w1anFwNs9RY1tRrUbQ9r9M" new-tab>
        <template v-slot:title>
          <fa-icon name="list-ol" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.basics") }}
        </template>
      </dropdown-item>
      <dropdown-item link="https://www.propstack.de/webinare" new-tab>
        <template v-slot:title>
          <fa-icon name="webcam" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.webinars") }}
        </template>
      </dropdown-item>
      <dropdown-item link="https://www.propstack.de/mediathek" new-tab>
        <template v-slot:title>
          <fa-icon name="tv-retro" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.media") }}
        </template>
      </dropdown-item>
      <dropdown-item link="https://propstack.zendesk.com/hc/de/categories/18311299731357-Updates" new-tab>
        <template v-slot:title>
          <fa-icon name="sync" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.changelog") }}
        </template>
      </dropdown-item>
      <dropdown-item link="https://propstack.statuspage.io/" new-tab>
        <template v-slot:title>
          <fa-icon name="signal" class="fa-fw mr-1 text-gray-600" />
          {{ $t("help.systemStatus") }}
        </template>
      </dropdown-item>
    </dropdown-section>
    <dropdown-section>
      <dropdown-item :title="$t('help.clearCache')" @click="clearCache" class="text-yellow-600" />
    </dropdown-section>
  </dropdown>
</template>

<script>
export default {
  props: { unreadMessages: { type: Number, default: 0 } },
  computed: {
    intercomActive() {
      return this.$root.intercomActive
    },
    helpButtonPosition() {
      return this.$route.path == `/portfolio/properties/${this.$route.params.id}/valuation` ? "right-5" : "left-5"
    },
  },
  methods: {
    clearCache() {
      this.$db.reset()
      location.reload()
    },
    openChat() {
      zE("messenger", "open")
    },
    openSpace() {
      if (window.Intercom) Intercom("showSpace", "tasks")
    },
  },
}
</script>

<style>
</style>
