let turbolinksStorage = {}

function saveState(key, data) {
  turbolinksStorage[key] = JSON.stringify(data)
}

function getSavedState(key) {
  const savedState = turbolinksStorage[key]
  return savedState ? JSON.parse(savedState) : null
}

export default {
  beforeMount: function() {
    if (!this.storageKey || !this.storageFields) return

    const state = getSavedState(this.storageKey)
    if (!state) return

    this.$withoutWatchers(() => {
      this.storageFields.forEach(key => {
        if (state[key] && this.hasOwnProperty(key)) this[key] = state[key]
      })
    })
  },

  beforeDestroy: function() {
    if (!this.storageKey || !this.storageFields) return

    saveState(this.storageKey, _.pick(this.$data, this.storageFields))
  }
}
