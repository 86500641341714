import { get, MaybeRef } from "@vueuse/core"
import { computed } from "vue"
import { Integration } from "./types/integration-service"

export const useIntegrationVisibility = (integration: MaybeRef<Integration>) => {
  const isHidden = computed(() => get(integration)?.fixedConfiguration?.hidden ?? true)

  const isWidgetHidden = computed(
    () => get(integration)?.configuration?.widgetHidden && !get(integration)?.configuration.enabled
  ) // custom

  // const isDebugVisible = isHidden && EnvironmentUtil.isDevelopmentOrSupportToken;
  return { isHidden, isWidgetHidden }
}
