<template>
  <el-checkbox
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :class="{ niceCheckboxDanger: type == 'danger' }"
    :title="title"
    :label="label"
    :disabled="disabled"
  >
    <slot></slot>
  </el-checkbox>
</template>

<script>
export default {
  props: {
    value: { required: true },
    type: { type: String, default: "primary" },
    label: { type: String, default: undefined },
    title: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
}
</script>

<style>
.niceCheckboxDanger > .el-checkbox__input.is-checked + .el-checkbox__label {
  color: red;
}
.niceCheckboxDanger > .el-checkbox__input.is-checked .el-checkbox__inner,
.niceCheckboxDanger > .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: red;
  background-color: red;
}
.niceCheckboxDanger > .el-checkbox__input > .el-checkbox__inner:hover {
  border-color: red;
}
.niceCheckboxDanger > .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: red;
}
</style>