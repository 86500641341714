import Pusher from "pusher-js"

export default {
  install(Vue, _) {
    try {
      const pusher = new Pusher(document.querySelector("meta[name=pusher-key]").content, {
        cluster: "eu",
        authEndpoint: "/api/v1/pusher/auth",
        auth: {
          headers: {
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
          },
        },
      })
      const subscribed = false
      let shopChannelName = null
      let shopChannel = {}

      Vue.prototype.$pusher = {
        init: channel => {
          if (subscribed || shopChannelName === channel) return

          shopChannel = pusher.subscribe(channel)
          pusher.subscribe(`${channel.split("-").slice(0, -1).join("-")}-global`)
          shopChannelName = channel
        },
        subscribe: channelName => {
          return pusher.subscribe(channelName)
        },
        unsubscribe: channelName => {
          return pusher.unsubscribe(channelName)
        },
        on: (channelName, cb) => {
          pusher.bind(channelName, cb)
        },
        off: channelName => {
          pusher.unbind(channelName)
        },
        trigger: (eventName, data) => {
          shopChannel.trigger(eventName, data)
        },
      }
    } catch (error) {
      console.log("Unfortunately something crashed when connecting to the realtime server, see this:", error)
    }
  },
}
