<template>
  <a class="standardList-item" @click.prevent="$emit('click')" v-if="!!item">
    <span
      v-if="deletable && (!sharedWithMe || $db.broker.admin)"
      @click.stop="$emit('delete')"
      class="standardList-remove"
    >
      <fa-icon name="trash-alt" />
    </span>
    <span v-if="editable && (!sharedWithMe || $db.broker.admin)" @click.stop="$emit('edit')" class="mr-2">
      <fa-icon name="pencil" />
    </span>
    <span class="standardList-itemTitle">
      <span :title="item.name">{{ item.name }}</span>
      <span v-if="sharedWithMe && brokerInitials" class="text-sm text-gray-400">von {{ brokerInitials }}</span>
      <tooltip
        v-if="!sharedWithMe && isShared"
        :content="sharedBrokers ? `Geteilt mit: ${sharedBrokers}` : 'Mit weiteren Nutzern geteilt'"
        class="ml-1"
      >
        <fa-icon name="user-friends" class="text-green-600" />
      </tooltip>
    </span>
    <fa-icon
      title="Filter"
      v-if="item.storageType == 'filter' || item.storageType == 'filter_and_layout'"
      name="filter"
    />
    <fa-icon
      title="Spaltenanordnung"
      v-if="item.storageType == 'layout' || item.storageType == 'filter_and_layout'"
      name="line-columns"
      class="ml-2"
    />
    <tooltip v-if="item.mcExportable" class="ml-2" content="Wird im Newsletter-Export verwendet">
      <fa-icon name="megaphone" />
    </tooltip>
    <tooltip
      v-if="item.widgetActive"
      class="ml-2"
      content="Iframe ist verfügbar.<br>Klicke, um die URL zu kopieren."
      placement="right"
    >
      <fa-icon @click.native.stop="copy(item.widgetUrl)" name="browser" class="text-yellow-800" />
    </tooltip>
    <fa-icon v-if="selected" name="check" class="text-green-600 ml-2" />
  </a>
  <a :title="title" class="standardList-item" @click.prevent="$emit('click')" v-else>
    <span class="standardList-itemTitle">{{ title }}</span>
  </a>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
    },
    deletable: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
  },
  methods: {
    copy(url) {
      this.$util.copy(url)
      App.flashy("URL wurde in die Zwischenablage kopiert.")
    },
  },
  computed: {
    isShared() {
      return (
        (this.item.brokerIds && this.item.brokerIds.length) ||
        (this.item.departmentIds && this.item.departmentIds.length) ||
        this.item.isPublic
      )
    },
    sharedWithMe() {
      return this.item.brokerId != this.$db.broker.id
    },
    brokerInitials() {
      return this.$db.shopData.brokers.find(n => n.id === this.item.brokerId)?.initials
    },
    sharedBrokers() {
      return this.item.brokerIds
        ? this.$db.shopData.brokers
            .filter(n => this.item.brokerIds.includes(n.id))
            .map(x => x.name)
            .join(", ")
        : undefined
    },
  },
}
</script>