<template>
  <nice-input
    v-if="!showDecimals"
    type="text"
    size="small"
    :placeholder="placeholder"
    v-model="displayValue"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
  />
  <input
    v-else
    type="text"
    v-model="displayValue"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
    class="dv-input"
  />
</template>

<script>
import { numberToCurrency } from "../config/number-filters"

export default {
  props: {
    value: {},
    placeholder: {},
    currency: {},
    showDecimals: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isInputActive: false,
    }
  },
  computed: {
    currencyWithFallback() {
      return this.currency || this.$root.shop ? this.$root.shop.currency : null
    },
    displayValue: {
      get() {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value
        } else {
          // User is not modifying now. Format display value for user interface
          return numberToCurrency(this.value, this.currencyWithFallback)
        }
      },
      set(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
        if (!newValue) newValue = null
        // Ensure that it is not NaN
        if (newValue && isNaN(newValue)) newValue = 0

        this.$emit("input", newValue)
      },
    },
  },
}
</script>