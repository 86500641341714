<template>
  <el-select
    ref="select"
    :multiple="multiple"
    filterable
    v-cancel-read-only
    clearable
    :value="value"
    default-first-option
    @input="input"
    @change="$emit('change', $event)"
    :placeholder="$t('projects.placeholder')"
    :reserve-keyword="reserveKeyword"
    remote
    :remote-method="fetchProjects"
    :loading="loading"
    :size="size"
  >
    <el-option v-for="project in projects" :key="project.id" :label="project.name" :value="project.id" />
  </el-select>
</template>

<script>
import { cacheProjects } from "@/config/remote-data-cache"

const MAX_NUMBER_OF_PROJECTS = 50

export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    reserveKeyword: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
  },

  data() {
    return {
      projects: [],
      loading: false,
    }
  },
  watch: {
    value(newValue) {
      if (newValue && !this.projects.length) {
        this.prefill()
      }
    },
  },
  methods: {
    async fetchProjects(query) {
      if (!query) {
        this.projects = []
        return
      }

      // this.loading = true
      const { data } = await this.$axios.get(`/api/v1/projects?q=${query}&per=${MAX_NUMBER_OF_PROJECTS}`)
      this.projects = data
      cacheProjects(data)
      // this.loading = false
    },
    input($event) {
      this.$emit("input", $event)
      this.$refs.select.$refs.input.select()
    },
    prefill() {
      if (!this.value) return
      const ids = this.multiple ? this.value.join(",") : this.value
      if (!ids) return

      this.$axios.get(`/api/v1/projects?project_ids=${ids}`).then(({ data }) => {
        this.projects = data
        cacheProjects(data)
        this.$emit("ready")
      })
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.prefill()
    })
  },
}
</script>
