<template>
  <div class="tableActions-search">
    <fa-icon name="search" class="tableActions-searchIcon" />
    <input
      ref="searchInput"
      type="search"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      autocomplete="off"
      class="tableActions-searchInput"
    />
    <span v-if="value && value.length > 0" @click.prevent="clearSearch" class="tableActions-searchClose">
      <fa-icon name="times" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return this.$t("searchInput.placeholder")
      },
    },
  },
  methods: {
    clearSearch() {
      this.$emit("input", "")
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.searchInput.focus()
    }
  },
}
</script>