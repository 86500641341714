<template>
  <el-badge :value="value" :is-dot="isDot" :class="computedStyleClass">
    <slot></slot>
  </el-badge>
</template>

<script>
export default {
  props: {
    value: {},
    isDot: { type: Boolean, default: false },
    subType: { type: String },
  },

  computed: {
    computedStyleClass() {
      switch (this.subType) {
        case "taskStage": {
          return "task-stage-badge"
        }
        case "chatMessageIndicator": {
          return "chat-message-indicator"
        }
      }
      return ""
    },
  },
}
</script>

<style>
.task-stage-badge {
  width: 0;
}

.task-stage-badge > .el-badge__content {
  font-size: 10px !important;
  height: 16px !important;
  line-height: 14px !important;
  top: -6px !important;
  right: 6px !important;
  background-color: inherit;
}

.chat-message-indicator {
  position: absolute;
  top: 0;
  right: 0;
}

.chat-message-indicator > .el-badge__content {
  height: 15px !important;
  width: 15px !important;
}
</style>