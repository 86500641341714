<template>
  <import-csv
    @update:modalVisible="$emit('update:modalVisible', $event)"
    :schema="schema"
    :validate-async="validateAsync"
    :submit-async="submitAsync"
    type="deal"
    :store-key="`deals-csv-${$db.broker.id}`"
    example-url="https://pro-propstack-public.s3.eu-west-1.amazonaws.com/helpcenter/Deals.csv"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import Vue from "vue"
import ImportCsv from "@/components/ImportCsv.vue"

const SCHEMA = [
  "propstack_id",
  "datensatznummer",
  "phase",
  "objekt",
  "objekt_id",
  "objekt_datensatznummer",
  "kontakt_e_mail",
  "kontakt_id",
  "kontakt_datensatznummer",
  "kontakt_kundennummer",
  "benutzer_id",
  "benutzer_e_mail",
  "benutzer",
  "datum",
  "preis",
]
const customFieldKey = "customFieldGroupsForDeals"

export default Vue.extend({
  components: {
    ImportCsv,
  },
  computed: {
    schema(): { id: string; name: string }[] {
      return [
        ...SCHEMA.map(r => ({ id: r, name: this.$t(`clients.csv.modal.columns.deal.${r}`) as string })),
        ...this.$db.shopData[customFieldKey]
          .flatMap(g => g.customFields)
          .map(r => ({ id: `cf_${r.name}`, name: r.prettyName })),
      ]
    },
  },
  methods: {
    validateAsync(rows) {
      return this.$api
        .mutation("validateDealImportJson", { json: rows }, "jsonImportValidation { unknownHeaders }")
        .catch(this.$axios.handleError)
    },
    submitAsync(rows) {
      this.$api.mutation("importDealJson", { json: rows })
    },
  },
})
</script>
