<template>
  <div ref="body">
    <div class="d-flex justify-content-between align-items-top" :class="{ 'flex-col': direction == 'vertical' }">
      <div>
        <slot name="label" v-bind="{ label, hint }">
          <div class="flex items-center">
            <p class="fs-16 semi-bold">{{ label }}</p>
            <div
              v-if="valueIndicatorFunc()"
              class="ml-2 bg-green-500 rounded-full w-5 h-5 flex items-center justify-center"
              style="padding-top: 1px"
            >
              <fa-icon name="check" class="text-white text-xs" />
            </div>
          </div>
          <p class="fs-14 mt-1 text-gray-700" v-html="hint"></p>
        </slot>
      </div>
      <div
        :class="{
          'inline-edit-width': !noInlineWidth && $scopedSlots.edit,
          'ml-4 mt-1': direction == 'horizontal',
          'mt-2': direction == 'vertical',
        }"
      >
        <slot name="edit" v-bind="{ form: shop }">
          <a v-if="$scopedSlots.form" class="text-primary fs-16 link" @click="edit" :disabled="disabled">
            <span>{{ $t("settings.edit") }}</span>
          </a>
          <nice-switch
            v-else-if="checkbox"
            :value="shop[checkbox]"
            @change="saveCheckbox"
            :active-color="negative ? '#c53030' : null"
            :disabled="disabled"
          />
        </slot>
      </div>
    </div>
    <form-dialog
      :title="label"
      :visible="!!editing"
      @close="editing = null"
      :submit-func="save"
      @complete="handleSave"
      :autofocus="false"
      :width="formWidth"
      :validate="() => validate(editing)"
    >
      <slot name="form" v-if="editing" v-bind="{ form: editing }"></slot>
    </form-dialog>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: Object,
    },
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
    checkbox: {
      type: String,
    },
    update: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
    noInlineWidth: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: undefined,
    },
    valueIndicatorFunc: {
      type: Function,
      default: () => {},
    },
    validate: {
      type: Function,
      default: () => true,
    },
    formWidth: {
      type: String,
      default: "780px",
    },
    direction: {
      type: String,
      default: "horizontal",
    },
  },
  watch: {
    $route() {
      if (!this.query) return
      if (this.$route.query.setting === this.query && !this.editing) {
        this.editing = JSON.parse(JSON.stringify(this.shop))
      } else if (this.$route.query.setting !== this.query && this.editing) {
        this.editing = null
      }
    },
    editing() {
      if (!this.query) return
      if (this.$route.query.setting === this.query && !this.editing) {
        this.$router.push({ query: { ...this.$route.query, setting: undefined } })
      } else if (this.editing) {
        this.$router.push({ query: { ...this.$route.query, setting: this.query } })
      }
    },
    $route() {
      if (this.$route.hash) {
        this.scrollToElement(this.$route.hash)
      }
    },
  },
  data() {
    return {
      editing: null,
    }
  },
  methods: {
    close() {
      this.editing = null
    },
    edit() {
      this.editing = JSON.parse(JSON.stringify(this.shop))
    },
    handleSave(res) {
      if (!res) return
      App.flashy(this.$t("admin.components.shopForm.successfullySavedChanges"))
    },
    save() {
      const payload = this.fields.length ? _.pick(this.editing, this.fields) : this.editing
      return this.update(payload, this.close)
    },
    saveCheckbox() {
      const payload = { [this.checkbox]: !this.shop[this.checkbox] }
      this.update(payload, this.close)
    },
    scrollToElement(hash) {
      const el = this.$refs.body
      if (el && el.id == hash.substring(1)) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    },
  },
  mounted() {
    if (this.query && this.$route.query.setting === this.query) {
      this.editing = JSON.parse(JSON.stringify(this.shop))
    }
    if (this.$route.hash) {
      setTimeout(() => {
        this.scrollToElement(this.$route.hash)
      }, 500)
    }
  },
}
</script>

<style scoped>
.inline-edit-width > div {
  width: 205px;
}
</style>
