<template>
  <nice-tooltip :content="title" transition="none" :open-delay="100">
    <button class="massActions-button" @click.prevent="$emit('click')">
      <fa-icon :name="icon" />
    </button>
  </nice-tooltip>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.massActions-button {
  background: none;
  padding: 0;
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  margin: 0 0.375rem;
}

.massActions-button:hover {
  background: #e9e9e9;
}
</style>
